export default {
  BuildingContent: {
    aboutTheLocation: 'About the location',
    class: 'Class',
    buildingClass: 'Building class',
    buildingClassAHelpText:
      'is new or high-quality buildings located in desirable neighborhoods, that offer amenities. Rent for these buildings are at the high end of the market.',
    buildingClassBHelpText:
      'buildings have average rents for the market. They could be a bit older, but have often been upgraded, while offering few amenities.',
    buildingClassCHelpText:
      'buildings are more than 20 years old, usually do not offer amenities, and have below-average rent for the market.',
    whyIsThisBuilding: 'Why this building?',
    distanceToPublicTransport: 'Distance to public transport',
    elevator: 'Elevator',
    openingHours: 'Opening hours',
    type: 'Type',
    elevatorNumber: 'Number',
    QRCodeOfTheBuilding: 'QR-code of the building',
    viewQRCode: 'View QR-code',
    Listings: {
      listingsInThisBuilding: 'Listings in this building',
      noListings: 'There are no any listings in this building',
      showMoreListings: 'Show more {{count}} listings',
      addAListing: 'Add a listing',
    },
  },
};
