import React, { useState } from 'react';
import {
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { OrderDirection } from 'enums/OrderDirection';
import { isEmpty, range } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Meta } from 'types/meta';
import clsx from 'utils/clsx';

import styles from './styles';

type DataTableColumn<T> = {
  name: string;
  head: string;
  sortable: boolean;
  sortField?: string;
  renderCell: (resource: T, handlers?: { [handler: string]: (resource: T) => void }) => string | JSX.Element;
};

type DataTableProps<T> = {
  meta: Meta;
  columns: DataTableColumn<T>[];
  loading: boolean;
  orderDirection?: OrderDirection;
  orderBy?: string;
  onPageChange?: (page: number) => void;
  onRequestSort?: (orderBy: string, orderDirection: OrderDirection) => void;
  resources: T[];
  rowPlaceholderHeight?: number;
  noResourcesText?: string;
  handlers?: { [handler: string]: (resourse: T) => void };
};

function DataTable<T extends { id: ID }>(props: DataTableProps<T>): JSX.Element {
  const { t } = useTranslation('adminComponents');
  const [hoveredRowId, setHoveredRowId] = useState<ID | null>(null);

  const {
    meta,
    columns,
    loading,
    orderDirection,
    orderBy,
    onPageChange,
    onRequestSort,
    resources,
    rowPlaceholderHeight = 32,
    noResourcesText = t('DataTable.noResourcesText'),
    handlers,
  } = props;

  const handleRowHover = (id: ID) => {
    setHoveredRowId(id);
  };

  const handleRowUnHover = () => {
    setHoveredRowId(null);
  };

  const handleRequestSort = (name: string) => {
    const isAsc = orderBy === name && orderDirection === OrderDirection.asc;
    const newOrderDirection = isAsc ? OrderDirection.desc : OrderDirection.asc;
    onRequestSort(name, newOrderDirection);
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => onPageChange(page);

  const renderLoaderCells = () =>
    range(0, meta.perPage).map((rowIndex: number) => (
      <TableRow key={rowIndex}>
        <TableCell colSpan={columns.length}>
          <Skeleton variant="rectangular" height={rowPlaceholderHeight} />
        </TableCell>
      </TableRow>
    ));

  const renderHeadCells = (column: DataTableColumn<T>) => {
    const isActive = orderBy === column.name || orderBy === column.sortField;
    return (
      <TableCell key={column.name} variant="head">
        {column.sortable ? (
          <TableSortLabel
            active={isActive}
            direction={isActive ? orderDirection : OrderDirection.asc}
            onClick={() => handleRequestSort(column.sortField || column.name)}
          >
            {column.head}
          </TableSortLabel>
        ) : (
          column.head
        )}
      </TableCell>
    );
  };

  const renderEmptyResultCells = (): JSX.Element => {
    return (
      <TableRow>
        <TableCell align="center" colSpan={columns.length}>
          <Typography component="p" variant="body3">
            {noResourcesText}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderBodyCells = (): JSX.Element | JSX.Element[] => {
    if (isEmpty(resources)) {
      return renderEmptyResultCells();
    }

    return resources.map((resource: T) => {
      if (Array.isArray(resource)) {
        return resource.map((item, index) => {
          const uniqKey = `${item.id}-${index}`;
          return (
            <TableRow
              onMouseEnter={() => handleRowHover(item.id)}
              onMouseLeave={handleRowUnHover}
              sx={clsx({}, [[styles.rowWithHover, item.id === hoveredRowId]])}
              key={uniqKey}
            >
              {columns.map(column => {
                const { rowSpan } = column;
                const notLast = index !== resource.length - 1 && !rowSpan;
                const rowSpanValue = rowSpan && index === 0 ? resource.length : undefined;
                const shouldNotDrawCell = rowSpan && index > 0;
                if (shouldNotDrawCell) {
                  return null;
                }
                return (
                  <TableCell sx={clsx({}, [[styles.noBorder, notLast]])} rowSpan={rowSpanValue} key={column.name}>
                    {column.renderCell({ ...item, isHovered: item.id === hoveredRowId }, handlers)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        });
      }

      return (
        <TableRow
          sx={clsx(null, [[styles.rowWithHover, resource.id === hoveredRowId]])}
          key={resource.id}
          onMouseEnter={() => handleRowHover(resource.id)}
          onMouseLeave={handleRowUnHover}
        >
          {columns.map(column => (
            <TableCell key={column.name}>
              {column.renderCell({ ...resource, isHovered: resource.id === hoveredRowId }, handlers)}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{columns.map(column => renderHeadCells(column))}</TableRow>
          </TableHead>
          <TableBody>{loading ? renderLoaderCells() : renderBodyCells()}</TableBody>
        </Table>
      </TableContainer>

      {!loading && meta.totalPages > 1 && (
        <Pagination count={meta.totalPages} page={meta.currentPage} onChange={handlePageChange} />
      )}
    </>
  );
}

export default DataTable;
