export default {
  BaseLayout: {
    Header: {
      login: 'Login',
      loginToAccount: 'Log in to account',
    },
    Footer: {
      about: 'About',
      helpAndSupport: 'Help & Support',
    },
  },
  Locations: {
    title: 'Locations',
    NY: 'New York',
    NJ: 'New Jersey',
  },
  HowItWorks: 'How it works',
  scheduleDemo: 'Schedule a demo',
  WhyTrot: 'Why TROT?',
  ExploreResources: 'Explore Resources',
  ForOwners: {
    title: 'For Owners',
    flexSpaceRevolution: 'Flex Space Revolution',
  },
  ForTrotters: {
    title: 'For Trotters',
  },
  AboutTrot: {
    title: 'About TROT',
    whoWeAre: 'Who We Are',
    ourServices: 'Our Services',
    faqS: 'FAQs',
    meetTheTeam: 'Meet the team',
  },
  ContactUs: {
    title: 'Contact Us',
    contactSupport: 'Contact Support',
    helpCenter: 'Help Center',
  },
  ThePowerOfFlex: 'Trot Blog',
};
