import { FC } from 'react';
import InfoTable from 'components/InfoTable';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'types/resources/PaymentMethods';

type PaymentMethodDetailsProps = {
  paymentMethod: PaymentMethod;
  withBorder?: boolean;
};

const PaymentMethodDetails: FC<PaymentMethodDetailsProps> = props => {
  const { t } = useTranslation('components');

  const { paymentMethod, withBorder } = props;

  const rows = [
    {
      label: t('PaymentMethodDetails.cardBrand'),
      description: t('PaymentMethodDetails.cardBrandDescription'),
      data: paymentMethod.card.brand,
    },
    {
      label: t('PaymentMethodDetails.lastDigits'),
      description: t('PaymentMethodDetails.lastDigitsDescription'),
      data: paymentMethod.card.last4,
    },
    {
      label: t('PaymentMethodDetails.expirationDate'),
      data: `${paymentMethod.card.expMonth}/${paymentMethod.card.expYear}`,
    },
  ];

  return <InfoTable withBorder={withBorder} rows={rows} />;
};

export default PaymentMethodDetails;
