import R from 'ramda';

export const set = <T>(object: T, path: string[], value: unknown): T => {
  const lens = R.lensPath(path);
  return R.set(lens, value, object);
};

export const addToDestroyableList = <T extends { id: ID; _destroy?: boolean }>(
  list: T[],
  item: T,
): Array<T | Omit<T, '_destroy'>> => {
  const itemIndex = R.findIndex(R.propEq('id', item.id))(list);

  if (itemIndex >= 0) {
    const changedItem = R.dissoc('_destroy', { ...item });
    return R.update(itemIndex, changedItem, list);
  }

  return [...list, item];
};

export const removeFromDestroyableList = <T extends { id: ID; _destroy?: boolean }>(list: T[], item: T): T[] => {
  const itemIndex = R.findIndex(R.propEq('id', item.id))(list);
  const changedItem = R.assoc('_destroy', true, { ...item });

  return R.update(itemIndex, changedItem, list);
};

export const isCheckedInDestroyableList = <T extends { id: ID; _destroy?: boolean }>(list: T[], item: T): boolean => {
  const currentItem = list.find(listItem => listItem.id === item.id);
  if (R.isNil(currentItem)) {
    return false;
  }

  if (R.has('_destroy', currentItem)) {
    return false;
  }

  return true;
};

export const getNotDestroyableItems = <T extends { id: ID; _destroy?: boolean }>(list: T[]): T[] => {
  return R.reject(R.has('_destroy'), list);
};

export const findNestedItemByParentAndChildIds = <T extends { id: ID }, TT extends { id: ID }>(
  collection: T[],
  property: string,
  parentId: ID,
  childId: ID,
): TT | null => {
  const parent = collection.find((item: T) => item.id === parentId);

  if (parent) {
    return parent[property].find((child: TT) => child.id === childId) || null;
  }

  return null;
};
