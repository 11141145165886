import { AxiosResponse } from 'axios';
import { SignInFormDataToSubmit } from 'forms/public/signInForm';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const SessionRepository = {
  create(params: SignInFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1SessionPath();
    return Fetcher.post(url, params);
  },
  delete(): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1SessionPath();
    return Fetcher.delete(url);
  },
};

export default SessionRepository;
