import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  text: {
    marginTop: '8px',
    whiteSpace: 'pre-wrap',
  },
};

export default styles;
