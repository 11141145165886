/* eslint-disable no-param-reassign */
import LandlordRepository from 'repositories/landlord/LandlordRepository';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LandlordInSettings } from 'types/resources/Landlord';
import { FetchStatus } from 'enums/FetchStatus';

export type LandlordSliceStateType = {
  landlord: LandlordInSettings;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadLandlord = createAsyncThunk('landlord/load', LandlordRepository.index);

export const updateLandlord = createAsyncThunk('landlord/update', LandlordRepository.update);

const initialState: LandlordSliceStateType = {
  landlord: null,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlord',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadLandlord.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadLandlord.fulfilled, (state, { payload }) => {
      state.landlord = payload;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadLandlord.rejected, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.failed;
      state.index.error = payload;
    });
    builder.addCase(updateLandlord.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateLandlord.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateLandlord.rejected, (state, { payload }) => {
      state.update.fetchStatus = FetchStatus.failed;
      state.update.error = payload;
    });
  },
});

export default slice.reducer;
