import Loader from 'components/Loader';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useEffect, Suspense, FC, lazy, useState } from 'react';
import { useUsers, useGoogleService, useIntercom } from 'hooks';
import { Loader as GoogleLoader } from '@googlemaps/js-api-loader';
import { appRoutes } from 'routes';
import { UsersPresenter } from 'presenters';
import ScrollToTop from 'components/ScrollToTop';

const PublicRouter = lazy(() => import('routers/public/Router'));
const AdminRouter = lazy(() => import('routers/admin/Router'));
const LandlordRouter = lazy(() => import('routers/landlord/Router'));
const TrotterRouter = lazy(() => import('routers/trotter/Router'));

const MainRouter: FC = () => {
  const [isInitialLoadFinished, setIsInitialLoadFinished] = useState<boolean>(false);
  const { loadCurrentUser, currentUser } = useUsers();
  const { initGoogle } = useGoogleService();
  useIntercom();

  const initialLoad = async () => {
    await loadCurrentUser();
    setIsInitialLoadFinished(true);
  };

  useEffect(() => {
    initialLoad();

    const loader = new GoogleLoader({
      apiKey: Settings.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
    });
    loader.load().then(initGoogle);
  }, []);

  const options = [
    {
      key: 'admin',
      access: UsersPresenter.hasAdminAccess(currentUser),
      path: appRoutes.admin.rootPath(),
      router: <AdminRouter />,
    },
    {
      key: 'landlord',
      access: UsersPresenter.hasLandlordAccess(currentUser) || UsersPresenter.hasEmployeeAccess(currentUser),
      path: appRoutes.landlord.rootPath(),
      router: <LandlordRouter />,
    },
    {
      key: 'user',
      access: UsersPresenter.hasTrotterAccess(currentUser),
      path: appRoutes.trotter.rootPath(),
      router: <TrotterRouter />,
    },
    {
      key: 'public',
      access: true,
      path: appRoutes.public.rootPath(),
      router: <PublicRouter />,
    },
  ];

  if (!isInitialLoadFinished) return <Suspense fallback={<Loader open />} />;

  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<Loader open />}>
        <Switch>
          {options.map(option => {
            if (!option.access) return null;
            return (
              <Route key={option.key} path={option.path}>
                {option.router}
              </Route>
            );
          })}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default MainRouter;
