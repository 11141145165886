import Building from 'types/resources/Building';
import Space from 'types/resources/Space';
import { isNil } from 'ramda';
import Media from 'types/resources/Media';
import { sortMediasByTags } from 'utils/galleryAlbum';
import i18n from 'locales/i18n';
import { BuildingAction, BuildingMediaTag, BuildingState, BuildingStatePresentation } from 'enums/resources/Building';
import { addPhotoMediaType, addVideoMediaType, getHeadImageUrl, addPhotoLabel, addVideoLabel } from 'utils/mediaUtils';
import { isBlank } from 'utils/data';

export const minSpacePrice = (building: Building): number => {
  return Math.min(...building.spaces.map((space: Space) => space.pricePerMonth));
};

export const coverUrl = (building: Building): string | null =>
  getHeadImageUrl(building.photos, Object.keys(BuildingMediaTag));

export const sortedMedias = (building: Building): Media[] => {
  const label = i18n.t('publicPages:BuildingSpace.label');
  const photos = (building?.photos || []).map(addPhotoMediaType).map(photo => addPhotoLabel(photo, label));
  const videos = (building?.videos || []).map(addVideoMediaType).map(video => addVideoLabel(video, label));
  return sortMediasByTags([...photos, ...videos], Object.keys(BuildingMediaTag));
};

type BuildingStatePredicates = {
  isInitial: boolean;
  isActive: boolean;
  isDisabled: boolean;
  isArchived: boolean;
  isDraft: boolean;
};

export const getBuildingState = (building: Building): BuildingStatePredicates => {
  const isInitial = building.state === BuildingState.initial || isNil(building.state);
  const isActive = building.state === BuildingState.active;
  const isDisabled = building.state === BuildingState.disabled;
  const isArchived = building.state === BuildingState.archived;
  const isDraft = building.state === BuildingState.draft;

  return {
    isInitial,
    isActive,
    isDisabled,
    isArchived,
    isDraft,
  };
};

export const availableActions = (building: Building): BuildingAction[] => {
  const { isInitial, isActive, isDisabled, isDraft } = getBuildingState(building);

  if (isInitial || isActive || isDisabled || isDraft) {
    return [BuildingAction.archive];
  }

  return [];
};

export const hasGeoCoords = (building: Building): boolean => {
  return !isNil(building.latitude) && !isNil(building.longitude);
};

export const getBuildingClassTooltipText = (building: Building): string | null => {
  switch (building.buildingClass) {
    case 'a':
      return i18n.t('landlordComponents:BuildingContent.buildingClassAHelpText');
    case 'b':
      return i18n.t('landlordComponents:BuildingContent.buildingClassBHelpText');
    case 'c':
      return i18n.t('landlordComponents:BuildingContent.buildingClassCHelpText');
    default:
      return null;
  }
};

export const state = (building: Building): string => {
  const statePresentation = BuildingStatePresentation[building.state];

  return i18n.t(`resources:building.states.${statePresentation}`).toUpperCase();
};

export const isSavingDraftAvailable = (building?: Building): boolean => {
  if (isBlank(building)) {
    return true;
  }

  const { isInitial, isDraft } = getBuildingState(building as Building);

  return isInitial || isDraft;
};
