import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    flexShrink: 0,
    marginBottom: {
      mobile: '10px',
      desktop: 0,
    },
  },
};

export default styles;
