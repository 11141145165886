import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  search: {
    border: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
    backgroundColor: 'common.white',
    '& .search': {
      borderRadius: 0,
      borderRight: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
      flexBasis: '30%',
    },
    '& .workers, .office': {
      borderRight: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
      flexBasis: '15%',
      '& svg:first-child': {
        mr: 1,
      },
    },
    '& input': {
      border: 'none !important',
    },
    '& svg': {
      color: 'brightBlue.main',
    },
  },
  searchButton: {
    m: 0.5,
    width: 115,
    height: 50,
  },
  toggleOpenAllFilters: {
    ml: 'auto',
  },
  formGroup: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    mb: 2,
  },
  formGroupTitle: {
    marginBottom: '8px',
    mr: 3,
    minWidth: '120px',
  },
  formGroupInput: {
    width: {
      mobile: '100%',
      desktop: 135,
    },
    '& input': {
      paddingLeft: '18px',
    },
  },
  checkbox: {
    color: 'navy.lighter',
  },
  checkboxWrapper: {
    marginLeft: '11px',
    marginBottom: '10px',
    userSelect: 'none',
    maxWidth: '230px',
  },
  allFilters: {
    display: 'block',
    marginTop: {
      mobile: '16px',
      desktop: '40px',
    },
  },
  mainFiltersText: {
    display: {
      mobile: 'none',
      desktop: 'block',
    },
    marginBottom: '32px',
  },
  select: {
    height: 'auto',
    '& fieldset': {
      display: 'none',
    },
  },
  clearAllFilters: {
    ml: 'auto',
    color: 'red.main',
    flexShrink: 0,
    '& svg': {
      width: 15,
      height: 15,
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      flexDirection: 'row-reverse',
    },
    '& input': {
      pl: '8px !important',
    },
    height: '100%',
  },
  datePickerButton: {
    ml: 2,
  },
  badges: {
    '& .MuiChip-root': {
      mt: 1,
    },
  },
  amenitiesTitle: {
    marginBottom: {
      mobile: 1,
      desktop: 4,
    },
  },
  amenitiesGrid: {
    maxHeight: {
      mobile: 'auto',
      desktop: 327,
    },
  },
  root: {
    marginBottom: {
      mobile: '40px',
      tablet: '90px',
    },
    flexDirection: {
      mobile: 'column',
      desktop: 'row',
    },
  },
  applyFiltersButton: {
    width: '100%',
    height: '50px',
    marginBottom: '16px',
  },
};

export default styles;
