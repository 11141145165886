import { FC, useState } from 'react';
import copy from 'clipboard-copy';
import { Stack, Typography, Box, ButtonBase, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks';
import Icon from 'components/Icon';
import InputField from 'components/InputField';
import {
  emailLink,
  whatsappLink,
  facebookLink,
  twitterLink,
  iMessageLink,
  facebookMessengerLink,
} from 'utils/shareUtil';

import styles from './styles';

const ShareSpace: FC = () => {
  const { t } = useTranslation(['publicPages']);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { closeModal } = useModals();

  const spaceUrl = window.location.href;

  const socialNetworks = [
    {
      icon: 'shareEmail',
      label: t('publicPages:BuildingSpace.shareSpace.email.label'),
      link: emailLink(spaceUrl, {
        subject: t('publicPages:BuildingSpace.shareSpace.email.subject'),
        body: t('publicPages:BuildingSpace.shareSpace.cta'),
        separator: t('publicPages:BuildingSpace.shareSpace.email.separator'),
      }),
    },
    {
      icon: 'shareMessages',
      label: t('publicPages:BuildingSpace.shareSpace.messages.label'),
      link: iMessageLink(spaceUrl, {
        body: t('publicPages:BuildingSpace.shareSpace.cta'),
        separator: t('publicPages:BuildingSpace.shareSpace.messages.separator'),
      }),
    },
    {
      icon: 'shareWhatsapp',
      label: t('publicPages:BuildingSpace.shareSpace.whatsapp.label'),
      link: whatsappLink(spaceUrl, {
        title: t('publicPages:BuildingSpace.shareSpace.cta'),
        separator: t('publicPages:BuildingSpace.shareSpace.whatsapp.separator'),
      }),
    },
    {
      icon: 'shareMessenger',
      label: t('publicPages:BuildingSpace.shareSpace.messenger.label'),
      link: facebookMessengerLink(spaceUrl, {
        appId: '1097200951196675',
      }),
    },
    {
      icon: 'shareFacebook',
      label: t('publicPages:BuildingSpace.shareSpace.facebook.label'),
      link: facebookLink(spaceUrl, {}),
    },
    {
      icon: 'shareTwitter',
      label: t('publicPages:BuildingSpace.shareSpace.twitter.label'),
      link: twitterLink(spaceUrl, {
        title: t('publicPages:BuildingSpace.shareSpace.cta'),
        via: t('publicPages:BuildingSpace.shareSpace.twitter.via'),
        hashtags: [
          t('publicPages:BuildingSpace.shareSpace.twitter.hashtags.trot'),
          t('publicPages:BuildingSpace.shareSpace.twitter.hashtags.revolution'),
          t('publicPages:BuildingSpace.shareSpace.twitter.hashtags.future'),
          t('publicPages:BuildingSpace.shareSpace.twitter.hashtags.real'),
          t('publicPages:BuildingSpace.shareSpace.twitter.hashtags.cre'),
        ],
      }),
    },
  ];

  const handleCopyButtonClick = () => {
    copy(spaceUrl);
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={() => closeModal()}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('publicPages:BuildingSpace.shareSpace.share')}
      </Typography>
      <Box sx={styles.socialNetworks}>
        {socialNetworks.map(network => {
          return (
            <Box key={network.label} sx={styles.socialNetworkWrapper}>
              <ButtonBase sx={styles.socialNetwork} component="a" href={network.link} target="_blank">
                <Box sx={styles.socialNetworkIconWrapper}>
                  <Icon name={network.icon} />
                </Box>
                <Typography sx={styles.label} variant="body5">
                  {network.label}
                </Typography>
              </ButtonBase>
            </Box>
          );
        })}
      </Box>
      <Box sx={styles.copy}>
        <InputField value={spaceUrl} sx={styles.copyInput} />
        <Tooltip
          open={showTooltip}
          title={t('publicPages:BuildingSpace.shareSpace.copied')}
          leaveDelay={500}
          onClose={handleTooltipClose}
        >
          <ButtonBase sx={styles.copyButton} onClick={handleCopyButtonClick}>
            <Typography variant="bold4">{t('publicPages:BuildingSpace.shareSpace.copy')}</Typography>
          </ButtonBase>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default ShareSpace;
