export enum BuildingClass {
  a = 'a',
  b = 'b',
  c = 'c',
}

export enum BuildingType {
  traditional = 'traditional',
  loft = 'loft',
  office = 'office',
  medical = 'medical',
  other = 'other',
}

export enum ElevatorType {
  manual = 'manual',
  automatic = 'automatic',
  other = 'other',
}

export enum BuildingMediaTag {
  exterior = 'exterior',
  lobby = 'lobby',
  elevators = 'elevators',
}

export enum BuildingState {
  initial = 'initial',
  active = 'active',
  disabled = 'disabled',
  archived = 'archived',
  draft = 'draft',
}

export enum BuildingStatePresentation {
  initial = 'initial',
  active = 'active',
  disabled = 'disabled',
  archived = 'archived',
  draft = 'draft',
}

export enum BuildingAction {
  activate = 'activate',
  disable = 'disable',
  archive = 'archive',
}
