import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  container: {
    width: '100%',
    maxWidth: 1440,

    marginLeft: 'auto',
    marginRight: 'auto',

    padding: {
      mobile: '0 24px',
      tablet: '0 60px',
      desktop: '0 75px',
    },
  },
  containerSmall: {
    padding: {
      mobile: '0 24px',
      tablet: '0 60px',
      desktop: '0 75px',
    },
  },
};

export default styles;
