import { Link } from 'react-router-dom';
import { MenuItemType, MenuItemTypeWithoutShow, NewTabLinkAttributesType, LinkAttributesType } from 'types/utils';

export const getNewBlankAttributes = (item: MenuItemTypeWithoutShow): NewTabLinkAttributesType | null => {
  return item.isNewTabOpen ? { target: '_blank', rel: 'noopener noreferrer' } : null;
};

export const getPathTypeAttributes = (item: MenuItemTypeWithoutShow): LinkAttributesType | null => {
  if (item.link) {
    return { to: item.link, component: Link };
  }

  if (item.href) {
    return { href: item.href, component: 'a' };
  }

  return null;
};

export const addShowTrue = (item: MenuItemTypeWithoutShow): MenuItemType => ({ ...item, show: true });
