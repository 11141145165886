import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import Slider from 'components/Slider';
import ImagePlaceholder from 'components/ImagePlaceholder';
import MediaComponent from 'components/MediaComponent';
import { take, range, isNil } from 'ramda';
import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import Media from 'types/resources/Media';
import { isVideo } from 'utils/galleryAlbum';
import clsx from 'utils/clsx';

import PopupGallery from './components/PopupGallery';
import styles from './styles';

type GalleryProps = {
  previewMediasCount?: number;
  medias: Media[];
  placeholderImageName: string;
  header?: JSX.Element;
  small?: boolean;
  shouldOpenGallery?: boolean;
  setShouldOpenGallery?: Dispatch<SetStateAction<boolean>>;
  type?: 'preview-grid-slider' | 'slider-modal' | 'slider';
};

const Gallery: FC<GalleryProps> = props => {
  const {
    previewMediasCount = 4,
    medias = [],
    placeholderImageName,
    header,
    small = false,
    shouldOpenGallery,
    setShouldOpenGallery = () => {},
    type = 'preview-grid-slider',
  } = props;

  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const totalMediasOnGallery = previewMediasCount + 1;

  const [coverMedia, ...otherMedias] = medias;
  const previewMedias = take(previewMediasCount, otherMedias);
  const previewPlaceholdersCount = previewMediasCount - previewMedias.length;

  const handleDialogOpen = () => {
    setDialogOpen(true);
    setShouldOpenGallery(true);
  };

  const handleDialogClose = () => {
    setClickedIndex(null);
    setDialogOpen(false);
    setShouldOpenGallery(false);
  };

  const handleSlideClick = (slideIndex: number | null) => {
    setClickedIndex(slideIndex);
  };

  useEffect(() => {
    if (shouldOpenGallery) {
      handleDialogOpen();
    }
  }, [shouldOpenGallery]);

  if (type === 'slider') {
    return <Slider medias={medias} />;
  }

  if (type === 'slider-modal') {
    return (
      <>
        <Slider preventPlayVideo medias={medias} onSlideClick={handleSlideClick} clickedIndex={clickedIndex} />
        <PopupGallery
          skipGrid
          open={!isNil(clickedIndex)}
          clickedIndex={clickedIndex}
          header={header}
          medias={medias}
          onClose={handleDialogClose}
        />
      </>
    );
  }

  return (
    <>
      <Box sx={clsx(styles.root, [[styles.small, small]])}>
        <Box sx={clsx(styles.mainPicture, [[styles.pictureSmall, small]])}>
          <MediaComponent type="preview" media={coverMedia} size="medium" />
        </Box>
        {previewMedias.map((previewMedia, index) => {
          const isMoreMedias = medias.length > totalMediasOnGallery && index === previewMediasCount - 1;
          const showVideoPlayIcon = index + 1 !== previewMediasCount && isVideo(previewMedia);

          return (
            <Box sx={clsx(styles.picture, [[styles.pictureSmall, small]])} key={previewMedia.id}>
              <MediaComponent
                type="preview"
                media={previewMedia}
                onClick={handleDialogOpen}
                showIcon={showVideoPlayIcon}
              />
              {isMoreMedias && (
                <button type="button" onClick={handleDialogOpen}>
                  <Box sx={styles.counter}>
                    <Typography align="center" sx={styles.counterText} variant="body1">
                      +{medias.length - previewMediasCount}
                    </Typography>
                  </Box>
                </button>
              )}
            </Box>
          );
        })}

        {range(0, previewPlaceholdersCount).map(index => (
          <Box sx={styles.picture} key={index}>
            <ImagePlaceholder name={placeholderImageName} />
          </Box>
        ))}
      </Box>

      <PopupGallery
        open={dialogOpen}
        header={header}
        medias={medias}
        onClose={handleDialogClose}
        clickedIndex={clickedIndex}
        onSlideClick={handleSlideClick}
      />
    </>
  );
};

export default Gallery;
