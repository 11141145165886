import { FetchStatusType } from 'types/utils';
import { useState } from 'react';
import { FetchStatus } from 'enums/FetchStatus';
import getFetchStatus from 'utils/fetchStatus';

const inferredCall = <ArgumentsType extends unknown[], ReturnValue>(
  functionToCall: (...args: ArgumentsType) => Promise<ReturnValue>,
  ...args: ArgumentsType
): Promise<ReturnValue> => {
  return functionToCall(...args);
};

type UseLoading = <ArgumentsType extends unknown[], ReturnValue>(
  func: (...args: ArgumentsType) => Promise<ReturnValue>,
) => {
  funcWithLoading: (...args: ArgumentsType) => Promise<ReturnValue>;
} & FetchStatusType;

const useLoading: UseLoading = func => {
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.idle);

  const funcWithLoading = (...args: Parameters<typeof func>) => {
    setStatus(FetchStatus.pending);
    return inferredCall(func, ...args)
      .then(result => {
        setStatus(FetchStatus.fulfilled);
        return result;
      })
      .catch((error: unknown): never => {
        setStatus(FetchStatus.failed);
        throw error;
      });
  };

  const complexFetchStatus: FetchStatusType = getFetchStatus(status);

  return {
    funcWithLoading,
    ...complexFetchStatus,
  };
};

export default useLoading;
