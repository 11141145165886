import * as yup from 'yup';
import { Meta } from 'types/meta';
import Amenity from 'types/resources/Amenity';
import { AmenityType } from 'enums/resources/Amenity';
import { pick } from 'ramda';
import { cardinalPointsToTopLeftAndBottomRight } from 'utils/googleHelper';
import { SpaceType } from 'enums/resources/Space';
import { Sort } from 'types/utils';

export type SearchFormData = {
  pricePerMonthMin?: string;
  pricePerMonthMax?: string;
  squareMin?: string;
  squareMax?: string;
  capacity?: {
    min?: number;
    max?: number;
  };
  place?: string;
  amenities?: Amenity[];
  cardinalPoints?: google.maps.LatLngBoundsLiteral;
  zoom?: number;
  verificationState?: string;
  showcase?: boolean;
  elevator?: boolean | null;
  onlyAvailable?: boolean | null;
  spaceType?: string;
  dateOfStart?: string;
  s?: Sort;
  perPage?: number;
};

export type SearchFormDataToSubmit = {
  q: {
    pricePerMonthMin: string;
    pricePerMonthMax: string;
    squareMin: string;
    squareMax: string;
    capacity: {
      min: number;
      max: number;
    };
    amenities: Amenity[];
    buildingAmenities?: number[];
    spaceAmenities?: number[];
    geoBoundingBox?: GeoBoundingBox;
    verificationState?: string;
    showcase?: boolean;
    elevator?: boolean | null;
    onlyAvailable?: boolean | null;
    spaceType?: string;
    dateOfStart?: string;
    s: Sort;
  };
  page: number;
  perPage: number;
};

export const validationFields = {
  pricePerMonthMin: yup.string().nullable().default(null),
  pricePerMonthMax: yup.string().nullable().default(null),
  squareMin: yup.string().nullable().default(null),
  squareMax: yup.string().nullable().default(null),
  capacity: yup.object({
    min: yup.number().nullable().default(null).lessThan(yup.ref('max')),
    max: yup.number().nullable().default(null).moreThan(yup.ref('min')),
  }),
  amenities: yup.array().default([]),
  verificationState: yup.string().nullable().default(null),
  showcase: yup.boolean().nullable().default(null),
  elevator: yup.boolean().nullable().default(null),
  onlyAvailable: yup.boolean().nullable().default(null),
  spaceType: yup.string().default(SpaceType.office),
  dateOfStart: yup.string().nullable().default(null),
  s: yup
    .object({
      field: yup.string().nullable().default(null),
      order: yup.string().nullable().default(null),
    })
    .nullable()
    .default(null),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues: SearchFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: SearchFormData, meta: Meta): SearchFormDataToSubmit => {
  const newValues = pick(
    [
      'pricePerMonthMin',
      'pricePerMonthMax',
      'squareMin',
      'squareMax',
      'capacity',
      'geoBoundingBox',
      'id',
      'verificationState',
      'elevator',
      'onlyAvailable',
      'spaceType',
      'showcase',
      'dateOfStart',
    ],
    values,
  );

  const buildingAmenities = values.amenities
    .filter(amenity => amenity.type === AmenityType.building)
    .map(amenity => amenity.id);
  const spaceAmenities = values.amenities
    .filter(amenity => amenity.type === AmenityType.space)
    .map(amenity => amenity.id);

  return {
    q: {
      ...newValues,
      buildingAmenities,
      spaceAmenities,
      ...(values.cardinalPoints && {
        geoBoundingBox: cardinalPointsToTopLeftAndBottomRight(values.cardinalPoints),
      }),
      s: values.s,
    },
    page: meta.currentPage,
    perPage: values.perPage || meta.perPage,
  };
};
