import { createDraftBuilding, updateDraftBuilding } from 'store/landlord/draft/buildingsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { BuildingFormSubmitData } from 'types/resources/Building';
import getFetchStatus from 'utils/fetchStatus';

type UseBuildingsType = {
  isCreateDraftBuildingPending: boolean;
  isUpdateDraftBuildingPending: boolean;
  createDraftBuilding: (newBuilding: BuildingFormSubmitData) => void;
  updateDraftBuilding: (buildingId: ID, building: BuildingFormSubmitData) => void;
};

const useBuildings = (): UseBuildingsType => {
  const dispatch = useAppDispatch();
  const {
    create: { fetchStatus: createFetchStatus },
    update: { fetchStatus: updateFetchStatus },
  } = useAppSelector(globalState => globalState.landlordBuildings);

  const { isPending: isCreateDraftBuildingPending } = getFetchStatus(createFetchStatus);
  const { isPending: isUpdateDraftBuildingPending } = getFetchStatus(updateFetchStatus);

  return {
    isCreateDraftBuildingPending,
    isUpdateDraftBuildingPending,
    createDraftBuilding: (newBuilding: BuildingFormSubmitData) => dispatch(createDraftBuilding(newBuilding)),
    updateDraftBuilding: (buildingId: ID, updatableBuilding: BuildingFormSubmitData) =>
      dispatch(updateDraftBuilding({ buildingId, building: updatableBuilding })),
  };
};

export default useBuildings;
