import { FC } from 'react';
import { Typography, Stack, Tooltip } from '@material-ui/core';
import Icon from 'components/Icon';
import Box from 'components/Box';
import EditLink from 'components/EditLink';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/string';
import Building from 'types/resources/Building';
import { BuildingPresenter } from 'presenters';

import styles from './styles';

type ComponentData = {
  data: string | undefined | number;
  itemTitle: string;
  iconName: string;
};

interface StackRowProps {
  title: string;
  items: ComponentData[];
  path: string;
  building?: Building;
}

const isClassItem = (itemTitle: string): boolean => itemTitle === 'Class';

const StackRow: FC<StackRowProps> = props => {
  const { title, items, path, building } = props;

  const { t } = useTranslation(['landlordComponents']);

  const renderBuildingClassTooltip = () => {
    const tooltip = (
      <Box sx={styles.tooltip}>
        <Typography variant="body4" component="p">
          <Typography variant="bold3">
            {t('landlordComponents:BuildingContent.class')} {capitalize(building.buildingClass)}
          </Typography>{' '}
          {BuildingPresenter.getBuildingClassTooltipText(building)}
        </Typography>
      </Box>
    );

    return (
      <Tooltip title={tooltip} arrow placement="top" enterTouchDelay={0}>
        <Box sx={styles.helpBuildingClassBlock}>
          <Icon sx={styles.helpBuildingClassIcon} name="helpCircleFilled" />
        </Box>
      </Tooltip>
    );
  };

  return (
    <Box sx={styles.itemContainer}>
      <Typography sx={styles.contentTitle} variant="bold3" component="h6">
        {title}
        <EditLink path={path} />
      </Typography>
      <Stack direction="row" sx={styles.list}>
        {items.map(({ data, itemTitle, iconName }) => {
          if (!data) return null;
          return (
            <Box sx={{ ...styles.listItem, ...styles.itemWidth }} key={itemTitle}>
              <Icon name={iconName} />
              <Typography variant="body5">
                {itemTitle}: {capitalize(String(data))}
              </Typography>
              {isClassItem(itemTitle) && renderBuildingClassTooltip()}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default StackRow;
