import { FC, useState, useEffect } from 'react';
import { Button, Stack, Typography, TextField, FormHelperText, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { initialValues, validationSchema, attributesToSubmit, DeclineFormData } from 'forms/admin/DeclineForm';
import { FormikHelpers, useFormik } from 'formik';
import { handleErrors } from 'utils/errors';
import { useModals } from 'hooks';
import { useSpaces } from 'hooks/landlords';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import SpacesWithCheckbox from 'components/SpacesWithCheckbox';
import { landlordSuggestSpacesParams } from 'utils/loadingParams';
import { isEmpty } from 'ramda';

import styles from './styles';

const MAX_DESCRIPTION_LENGTH = 80;

const BookingRequestDeclineAndSuggestSpaces: FC = () => {
  const { t } = useTranslation(['adminPages', 'landlordPages', 'resources']);
  const { closeModal, extra } = useModals();
  const [suggestedSpacesIds, setSuggestedSpacesIds] = useState<ID[]>([]);

  const { suggestedSpaces, loadSuggestedSpaces, isLoadSuggestSpacesFinished } = useSpaces();

  const { onDecline, onCancel } = extra;

  const fetchSuggestedSpaces = () => {
    const params = landlordSuggestSpacesParams();
    loadSuggestedSpaces(params);
  };

  useEffect(() => {
    fetchSuggestedSpaces();
  }, []);

  const onSubmit = async (formData: DeclineFormData, { setSubmitting, setErrors }: FormikHelpers<DeclineFormData>) => {
    const params = attributesToSubmit(formData);
    try {
      onDecline({ ...params, suggestedSpacesIds });
      closeModal();
    } catch (error: unknown) {
      handleErrors(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const { values, handleChange, handleBlur, handleSubmit, touched, errors, isSubmitting, isValid } = formik;

  const handleCancelClick = () => (onCancel ? onCancel() : closeModal());

  if (!isLoadSuggestSpacesFinished) {
    return <Loader open />;
  }

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={handleCancelClick}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('landlordPages:Listing.UserRental.popup.title')}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4">
        {t('adminPages:Listing.declineSubtitle')}
      </Typography>
      <Box onSubmit={handleSubmit} component="form">
        <Box sx={styles.textarea}>
          <TextField
            inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
            error={touched.declineReason && Boolean(errors.declineReason)}
            helperText={touched.declineReason && errors.declineReason}
            name="declineReason"
            id="declineReason"
            fullWidth
            value={values.declineReason}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('adminPages:Listing.typeText')}
          />
          <FormHelperText variant="outlined" sx={styles.symbolsCounter}>
            {values.declineReason.length} / {MAX_DESCRIPTION_LENGTH}
          </FormHelperText>
        </Box>
        <SpacesWithCheckbox spaces={suggestedSpaces} onSpaceSelect={ids => setSuggestedSpacesIds(ids)} />
        <Box sx={styles.actions}>
          <Button
            sx={styles.confirmButton}
            disabled={!isValid || isSubmitting || isEmpty(suggestedSpacesIds)}
            type="submit"
          >
            <Typography variant="tabsTextBold" component="p">
              {t('landlordPages:Listing.UserRental.declineTheRequestAndOfferSpaces')}
            </Typography>
          </Button>
          <Button sx={styles.cancelButton} variant="text" onClick={handleCancelClick}>
            <Typography variant="tabsTextBold" component="p">
              {t('adminPages:Listing.back')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default BookingRequestDeclineAndSuggestSpaces;
