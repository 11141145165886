import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { isEmpty } from 'ramda';
import Icon from 'components/Icon';
import clsx from 'utils/clsx';

import styles from './styles';

type LandlordActionsProps<T> = {
  resource: T;
  openIndex: ID | null;
  anchorEl: Element;
  handleCloseActionsMenu: () => void;
  handleMenuActionsClick: (event: React.MouseEvent<HTMLElement>, landlord: T) => void;
  handleMenuActionsItemClick: (landlord: T, option: string) => void;
  actions: string[];
  translateAction: (action: string) => string;
};

function MenuActions<T extends { id: ID }>(props: LandlordActionsProps<T>): JSX.Element {
  const {
    resource,
    openIndex,
    anchorEl,
    handleCloseActionsMenu,
    handleMenuActionsClick,
    handleMenuActionsItemClick,
    actions,
    translateAction,
  } = props;

  const emptyActions = isEmpty(actions);
  const shouldOpenMenu = openIndex === resource.id;

  return (
    <>
      <IconButton
        sx={clsx({}, [[styles.hidden, emptyActions]])}
        onClick={(e: React.MouseEvent<HTMLElement>) => handleMenuActionsClick(e, resource)}
      >
        <Icon name="points" />
      </IconButton>
      {!emptyActions && (
        <Menu id="long-menu" anchorEl={anchorEl} open={shouldOpenMenu} onClose={handleCloseActionsMenu}>
          {actions.map(action => (
            <MenuItem key={action} onClick={() => handleMenuActionsItemClick(resource, action)}>
              <Typography variant="body3">{translateAction(action)}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

export default MenuActions;
