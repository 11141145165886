import { FC } from 'react';
import { Button } from '@material-ui/core';
import { useGoogleAuth } from 'hooks';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

import styles from './styles';

type GoogleSignInProps = {
  isInHeroBlock?: boolean;
};

const GoogleSignIn: FC<GoogleSignInProps> = props => {
  const { isInHeroBlock } = props;
  const { isFinished, authorizationUrl } = useGoogleAuth();
  const { t } = useTranslation('components');

  return (
    <Button
      href={authorizationUrl}
      component="a"
      disabled={!isFinished}
      variant="outlined"
      sx={styles.button}
      size={isInHeroBlock ? 'small' : 'medium'}
    >
      <Icon name="google" sx={styles.icon} />
      {t('GoogleSignIn.signIn')}
    </Button>
  );
};

export default GoogleSignIn;
