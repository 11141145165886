import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: {
      desktop: '70%',
    },
    maxWidth: '300px',
    flexGrow: 1,
    marginBottom: {
      mobile: '10px',
      desktop: 0,
    },
  },
};

export default styles;
