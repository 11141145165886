import * as yup from 'yup';
import i18n from 'locales/i18n';
import { SpaceRentCancelParams } from 'types/resources/Space';
import { SpaceRentCancellationReason } from 'enums/resources/SpaceRent';

export const MAX_DESCRIPTION_LENGTH = 80;

export type SpaceRentCancellationFormData = {
  cancellationReason: string;
  cancellationReasonOther?: string;
  cancellationNote: string;
};

const cancellationReasonFieldName = i18n.t('landlordPages:Listings.ConfirmCancellation.cancellationReason');
const cancellationNoteFieldName = i18n.t('landlordPages:Listings.ConfirmCancellation.cancellationNote');

export const validationFields = {
  cancellationReason: yup
    .string()
    .default('')
    .required(() => i18n.t('forms:validations.required', { field: cancellationReasonFieldName })),
  cancellationNote: yup
    .string()
    .default('')
    .required(() => i18n.t('forms:validations.required', { field: cancellationNoteFieldName })),
  cancellationReasonOther: yup
    .string()
    .default('')
    .when('cancellationReason', {
      is: (cancellationReason: string) => cancellationReason === 'other',
      then: yup
        .string()
        .default('')
        .required(() => i18n.t('forms:validations.required', { field: cancellationReasonFieldName })),
    }),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues: SpaceRentCancellationFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: SpaceRentCancellationFormData): SpaceRentCancelParams => {
  const { cancellationReason, cancellationNote, cancellationReasonOther } = values;

  const cancellationReasonText =
    cancellationReason === SpaceRentCancellationReason.other
      ? cancellationReasonOther
      : i18n.t(`landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.${cancellationReason}`);

  return {
    cancellationReason: cancellationReasonText,
    cancellationNote,
  };
};
