/* eslint-disable no-new */
import { FC } from 'react';
import { BoxProps, Box } from '@material-ui/core';
import Building from 'types/resources/Building';
import useMap from 'hooks/useMap';
import { SearchFormData } from 'forms/public/searchForm';

type Props = {
  buildings?: Building[];
  onCardinalPointsChange: (bounds: google.maps.LatLngBoundsLiteral, zoom: number) => void;
  filters?: SearchFormData;
  hoveredBuildingId?: ID | null;
};

const Map: FC<Props & BoxProps> = (props): JSX.Element => {
  const { buildings = [], onCardinalPointsChange, filters, hoveredBuildingId, ...rest } = props;
  const { ref } = useMap({ buildings, onCardinalPointsChange, filters, hoveredBuildingId });

  return <Box {...rest} ref={ref} />;
};

export default Map;
