import { SxStyles, Theme } from 'types/theme';
import theme from 'theme';

const styles: SxStyles = {
  goBackCard: {
    backgroundColor: 'white.main',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 30px',
    cursor: 'pointer',
    borderBottom: (trotTheme: Theme) => `1px solid ${trotTheme.palette.navy.secondary}`,
    display: 'flex',
    [theme.breakpoints.up('chat')]: {
      display: 'none',
    },
  },
  image: {
    width: '32px',
    height: '32px',
    marginRight: '12px',
    flexShrink: 0,
    overflow: 'hidden',
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
    },
  },
  card: {
    marginLeft: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    width: '10px',
    height: '10px',
    display: {
      mobile: 'block',
      desktop: 'none',
    },
  },
};

export default styles;
