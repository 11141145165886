import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { LoadingParams } from 'utils/loadingParams';
import Building, { BuildingResources } from 'types/resources/Building';

const BuildingsRepository = {
  index(params: LoadingParams): Promise<BuildingResources> {
    const url = apiRoutes.apiSiteV1AdminBuildingsPath();
    return Fetcher.get(url, params);
  },
  show(buildingId: ID): Promise<Building> {
    const url = apiRoutes.apiSiteV1AdminBuildingPath(buildingId);
    return Fetcher.get(url);
  },
  delete(buildingId: ID): Promise<void> {
    const url = apiRoutes.apiSiteV1AdminBuildingPath(buildingId);
    return Fetcher.delete(url);
  },
};

export default BuildingsRepository;
