export enum FetchStatuses {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  failed = 'failed',
}

export type FetchStatus = FetchStatuses.idle | FetchStatuses.pending | FetchStatuses.fulfilled | FetchStatuses.failed;

export type FetchStatusResult = {
  isIdle: boolean;
  isPending: boolean;
  isFulfilled: boolean;
  isFailed: boolean;
};
