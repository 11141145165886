import { AxiosResponse } from 'axios';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const AmenitiesRepository = {
  index(): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1AmenitiesPath();
    return Fetcher.get(url);
  },
};

export default AmenitiesRepository;
