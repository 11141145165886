/* eslint-disable no-param-reassign */
import BuildingsRepository from 'repositories/admin/BuildingsRepository';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import Building from 'types/resources/Building';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import { LoadingParams } from 'utils/loadingParams';

export type BuildingsSliceStateType = {
  buildings: Building[];
  building: Building;
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadBuildings = createAsyncThunk('buildings/load', BuildingsRepository.index);
export const loadBuilding = createAsyncThunk('buildings/loadBuilding', async (buildingId: ID, { rejectWithValue }) => {
  try {
    return await BuildingsRepository.show(buildingId);
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteBuilding = createAsyncThunk('buildings/deleteBuilding', BuildingsRepository.delete);

export type BuildingsSliceActionsType = {
  loadBuildings: (params: LoadingParams) => Promise<void>;
};

const initialState: BuildingsSliceStateType = {
  buildings: [] as Building[],
  building: {} as Building,
  meta: {
    count: null,
    totalCount: null,
    perPage: 10,
    currentPage: 1,
    totalPages: null,
    nextPage: null,
  },
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    resetBuilding(state) {
      state.building = initialState.building;
      state.show = initialState.show;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadBuildings.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadBuildings.fulfilled, (state, { payload }) => {
      state.buildings = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadBuildings.rejected, (state, { payload }) => {
      state.index.fetchStatus = FetchStatus.failed;
      state.index.error = payload;
    });
    builder.addCase(loadBuilding.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadBuilding.fulfilled, (state, { payload }) => {
      state.building = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadBuilding.rejected, (state, { error }) => {
      state.show.error = error;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(deleteBuilding.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteBuilding.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteBuilding.rejected, (state, { error }) => {
      state.delete.fetchStatus = FetchStatus.failed;
      state.delete.error = error;
    });
  },
});

const {
  actions: { resetBuilding },
} = slice;

export { resetBuilding };

export default slice.reducer;
