import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    marginRight: '16px',
  },
  linkLight: {
    color: 'black.main',
  },
  linkBase: {
    color: 'black.main',
  },
  linkHome: {
    color: 'white.main',
  },
  phoneIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '4px',
  },
  phoneIconWrapperLight: {
    backgroundColor: 'common.white',
  },
  phoneIconWrapperBase: {
    backgroundColor: 'navy.background',
  },
  phoneIconWrapperHome: {
    backgroundColor: 'common.white',
  },
  phoneIcon: {
    fill: '#050C1D',
    width: '16px',
    height: '16px',
  },
};

export default styles;
