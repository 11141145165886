import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import { SpaceRent } from 'types/resources/Space';
import { SpaceRentPresenter } from 'presenters';

import styles from './styles';

type SpaceStatusBadgeProps = {
  spaceRent: SpaceRent;
};

const SpaceStatusBadge: FC<SpaceStatusBadgeProps> = props => {
  const { spaceRent } = props;
  const style = `state_${spaceRent.state}`;
  const statusText = SpaceRentPresenter.state(spaceRent);

  return (
    <Box sx={styles[style]}>
      <Typography variant="bold7">{statusText}</Typography>
    </Box>
  );
};

export default SpaceStatusBadge;
