import { FC, useState, useEffect } from 'react';
import { Box, CardMedia, Typography } from '@material-ui/core';
import ImagePlaceholder from 'components/ImagePlaceholder';
import Checkbox from 'components/Checkbox';
import { useTranslation } from 'react-i18next';
import Space from 'types/resources/Space';
import { SpacePresenter } from 'presenters';
import { isNil } from 'ramda';
import { formatDateTimeShortMonth } from 'utils/dateTimeUtils';

import styles from './styles';

type SpacesWithCheckboxProps = {
  spaces: Space[];
  onSpaceSelect: (selectedSpaceIds: ID[]) => void;
};

const SpacesWithCheckbox: FC<SpacesWithCheckboxProps> = props => {
  const { spaces, onSpaceSelect } = props;
  const { t } = useTranslation(['resources', 'common']);

  const [selectedIds, setSelectedIds] = useState<ID[]>([]);

  const handleSpaceSelect = (id: ID) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(prev => prev.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds(prev => [...prev, id]);
    }
  };

  useEffect(() => {
    onSpaceSelect(selectedIds);
  }, [selectedIds]);

  return (
    <Box sx={styles.root}>
      {spaces.map(space => {
        const imageUrl = SpacePresenter.coverUrl(space);
        const price = SpacePresenter.pricePerMonthFormatted(space, 'common:inMonth');

        const getAvailableAt = () => {
          if (SpacePresenter.isAvailableAtInPastOrToday(space)) {
            return (
              <Typography variant="body4" sx={styles.availableFrom}>
                {t('resources:space.availableNow')}
              </Typography>
            );
          }

          return (
            <Box>
              <Typography component="span" variant="body4" sx={styles.availableFrom}>
                {t('resources:space.availableFrom')}
                {': '}
              </Typography>
              <Typography component="span" variant="body4">
                {formatDateTimeShortMonth(space.availableAt)}
              </Typography>
            </Box>
          );
        };

        return (
          <Box key={space.id}>
            <Box sx={styles.card}>
              <Box sx={styles.cardImage}>
                {isNil(imageUrl) ? (
                  <ImagePlaceholder sx={styles.photo} name="space" preserveAspectRatio="none" />
                ) : (
                  <CardMedia sx={styles.photo} image={imageUrl} />
                )}
              </Box>
              <Box sx={styles.spaceInfo}>
                <Typography sx={styles.title} variant="subtitle1">
                  {space.title}
                </Typography>
                <Typography sx={styles.address} variant="body4">
                  {space.building.address}
                </Typography>
                {getAvailableAt()}
                <Typography variant="body4">{price}</Typography>
              </Box>
              <Checkbox
                sx={styles.checkbox}
                checked={selectedIds.includes(space.id)}
                onChange={() => handleSpaceSelect(space.id)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SpacesWithCheckbox;
