export default {
  user: {
    firstName: 'First name',
    lastName: 'Last name',
    password: 'Password',
    position: 'Position',
    positionPlaceholder: 'Sales Manager',
    phone: 'Phone',
    phonePlaceholder: '(012) 345-6789',
    emailPlaceholder: 'email@example.com',
    states: {
      all: 'All',
      initial: 'pending',
      active: 'active',
      disabled: 'disabled',
    },
    actions: {
      activate: 'Activate',
      disable: 'Disable',
    },
  },
  company: {
    name: 'Company name',
    address: 'Company address',
    phone: 'Company phone',
  },
  employee: {
    accessTypes: {
      readOnly: 'read only',
      fullAccess: 'full access',
      noAccess: 'no access',
    },
    messaging: 'Messaging',
    listingsAndBuildings: 'Listings & Buildings',
    payments: 'Payments',
    signingContracts: 'Signing contracts',
  },
  landlord: {
    name: 'Landlord',
    namePlural: 'Landlords',
    states: {
      all: 'all',
      initial: 'pending',
      verified: 'verified',
      unverified: 'unverified',
      archived: 'archived',
    },
    actions: {
      verify: 'Verify',
      unverify: 'Unverify',
      archive: 'Archive',
    },
  },
  location: {
    namePlural: 'Locations',
  },
  spaceRent: {
    monthlyPayment: 'monthly payment',
    states: {
      initial: 'initial',
      waitingForApproval: 'awaiting confirmation',
      approved: 'booked',
      declined: 'declined',
      active: 'rented',
      completed: 'completed',
    },
  },
  space: {
    namePlural: 'Spaces',
    cleaningIncluded: 'Cleaning included',
    operableWindows: 'Operable windows',
    states: {
      all: 'all',
      initial: 'pending',
      waitingForApproval: 'waiting for approval',
      needsRevision: 'needs revision',
      rentRequested: 'awaiting confirmation',
      rented: 'rented',
      active: 'active',
      disabled: 'disabled',
      archived: 'archived',
      draft: 'draft',
    },
    actions: {
      toReview: 'Send to Review',
      archive: 'Archive',
      delete: 'Delete',
      unpublish: 'Unpublish',
      cancelLicense: 'Cancel license',
    },
    airConditioningType: 'Air conditioning type',
    airConditioningTypePlaceholder: 'Central, window units, etc.',
    airConditioningTypeValues: {
      central: 'Central',
      windowUnits: 'Window units',
      none: 'None',
    },
    heatingType: 'Heating type',
    heatingTypePlaceholder: 'Forced hot air, steam, etc.',
    heatingTypeValues: {
      forcedHotAir: 'Forced Hot Air',
      steam: 'Steam',
      radiant: 'Radiant',
      other: 'Other',
    },
    flooringType: 'Flooring type',
    flooringTypePlaceholder: 'Hardwood, concrete, etc.',
    flooringTypeValues: {
      hardwood: 'Hardwood',
      concrete: 'Concrete',
      carpet: 'Carpet',
      vinyl: 'Vinyl',
      other: 'Other',
    },
    floor: 'Partial/Entire',
    floorValues: {
      partial: 'Partial',
      entire: 'Entire',
    },
    viewType: 'Space view',
    viewTypePlaceholder: 'Courtyard, park, etc.',
    viewTypeValues: {
      courtyard: 'Courtyard',
      park: 'Park',
      water: 'Water',
      windowless: 'Windowless',
      cityscape: 'Cityscape',
      other: 'Other',
    },
    parkingSpacesIncluded: 'Parking spaces included',
    parkingSpacesIncludedPc: '{{value }} pc.',
    parkingSpacesIncludedPlaceholder: 'Whole number',
    outdoorSpaceDescription: 'Outdoor space description',
    outdoorSpaceDescriptionPlaceholder: 'Roof top deck',
    privateBathroom: 'Private bathroom',
    privateBathroomPlaceholder: "2 bathrooms, Men's and Women's, Handicap accessible",
    restrictedUses: 'Restricted uses',
    restrictedUsesPlaceholder: 'Pets are prohibited',
    restrictedUsesInputPlaceholder: 'Please indicate which business your space is not suitable for',
    restrictedUsesAnnotation:
      'Include any rules or restrictions Trotters should know about, like pets being prohibited or restrictions on specific types of businesses',
    internetProvider: 'Internet provider',
    internetProviderPlaceholder: 'Optimum, Verizon, Spectrum, RCN or Other',
    internetProviderValues: {
      optimum: 'Optimum',
      verizon: 'Verizon',
      spectrum: 'Spectrum',
      rcn: 'RCN',
      other: 'Other',
    },
    aboutTheSpace: 'About the space',
    spaceType: 'Space type',
    minimalRentalPeriod: 'Minimal rental period',
    spaceTypePlaceholder: 'Office or retail',
    spaceTypeValues: {
      office: 'Office',
      retail: 'Retail',
    },
    ceilingHeight: 'Ceiling height',
    ceilingHeightFt: '{{value }} ft.',
    ceilingHeightPlaceholder: '200',
    restrictedUsesValues: {
      accounting_office: 'Accounting office',
      architect_office: 'Architect office',
      marketing_firm: 'Marketing firm',
      advertising_agency: 'Advertising agency',
      dentist_office: 'Dentist office',
      doctors_office: "Doctor's office",
      chiropractor: 'Chiropractor',
      technology_firm: 'Technology firm',
      staffing_firm: 'Staffing firm',
      event_planning_company: 'Event planning company',
      showroom: 'Showroom',
      nail_salon: 'Nail salon',
      hair_salon: 'Hair salon',
      cell_phone_or_electronic_repair: 'Сell phone/electronic repair',
      general_and_executive_office: 'General & Executive office',
      travel_agency: 'Travel agency',
      government_agency: 'Government agency',
      spa: 'Spa',
      therapy_or_psychiatry_office: 'Therapy/Psychiatry Office',
      social_club: 'Social club',
      live_or_work: 'Live/Work',
      dance_studio: 'Dance studio',
      school: 'School',
      food_or_cooking: 'Food/Cooking',
      warehouse_or_storage: 'Warehouse/Storage',
      factory_or_manufacturing: 'Factory/Manufacturing',
      other: 'Other',
    },
    availableFrom: 'Available from',
    notAvailable: 'Currently unavailable',
    availableNow: 'Available now',
    dateOfStart: 'Date of start',
    dateOfStartValidation: 'Date of start is required',
    rentalPeriod: 'Rental period',
    availableFromWithDate: 'Available from: {{date}}',
    month: '{{count}} Month',
    month_plural: '{{count}} Months',
  },
  building: {
    name: 'Building',
    namePlural: 'Buildings',
    freightElevator: 'Freight elevator',
    onSiteManagement: 'On-Site Management',
    ownerOccupied: 'Owner Occupied',
    bicycleParkingAccess: 'Bicycle Access / Parking',
    onSiteFood: 'On-Site Food (description if yes)',
    onSiteFoodPlaceholder: 'Restaurant, cafeteria, convenience store',
    onSiteGym: 'On-Site Gym',
    onSiteEntertainment: 'On-Site Entertainment (description if yes)',
    onSiteEntertainmentPlaceholder: 'Movie theater, bowling alley',
    commonAreaBathrooms: 'Common Area Bathrooms (description if yes)',
    commonAreaBathroomsPlaceholder: '2 Common bathrooms per floor, handicap accessible',
    accessHours: 'Building Access Hours (description if yes)',
    accessHoursPlaceholder: 'e.g. “Mon-Fri 7-7pm, Sat/Sun 10-4pm”',
    lobbyAttendantHours: 'Lobby Attendant Hours (description if yes)',
    lobbyAttendantHoursPlaceholder: 'e.g. “Mon-Fri 7-7pm, Sat/Sun 10-4pm”',
    numberOfElevators: 'Number of Elevators',
    buildingClass: 'Building Class',
    buildingClassPlaceholder: 'A, B or C',
    buildingClassValues: {
      a: 'A',
      b: 'B',
      c: 'C',
    },
    buildingType: 'Building Type',
    buildingTypePlaceholder: 'Traditional, Loft, etc.',
    buildingTypeValues: {
      traditional: 'Traditional',
      loft: 'Loft',
      office: 'Office',
      medical: 'Medical',
      other: 'Other',
    },
    elevatorType: 'Elevator Type',
    elevatorTypePlaceholder: 'Manual, automatic, etc.',
    elevatorTypeValues: {
      manual: 'Manual',
      automatic: 'Automatic',
      other: 'Other',
    },
    states: {
      archived: 'archived',
      draft: 'draft',
    },
    actions: {
      archive: 'Delete',
    },
  },
  listing: {
    name: 'Listing',
    namePlural: 'Listings',
    status: 'Status',
    age: 'Time on TROT',
    listingsCount: '{{count}} Listing',
    listingsCount_plural: '{{count}} Listings',
    verified: 'Verified',
    showcase: 'Showcase',
    verifiedDescription: 'This space has been reviewed by our TROT team',
    cannotBeBooked: 'This space is unavailable for your desired start date. Save to favorites for future updates!',
    rentedBy: 'Rented by',
    dateOfStart: 'Date of start',
    licensePeriod: 'License period',
    availableFrom: 'Available from',
    trotterAvatar: 'User Avatar',
    chooseDate: 'Choose date',
    nextPaymentDate: 'Next payment date',
    rentableSquareTooltipText:
      'Each owner calculates rentable square footage differently. Refer to the floor plan in the listing for dimensions.',
  },
  message: {
    namePlural: 'Messages',
  },
  imageTags: {
    exterior: {
      title: 'Exterior',
      subtitle:
        'We suggest 2-3 images or a high-resolution video to show the outside of your building from several angles',
    },
    lobby: {
      title: 'Lobby',
      subtitle: 'We suggest 2-3 images or a high-resolution video to show the entire lobby or reception space',
    },
    elevators: {
      title: 'Elevators',
      subtitle: 'Upload images to show the quantity, size, and current condition of the elevators',
    },
    interior: {
      title: 'Office Space',
      subtitle:
        'Upload up to 10 photos as you think are needed to show the different areas of the space. You can also add captions to provide more detail',
    },
    commonArea: {
      title: 'Common area',
      subtitle:
        'Upload up to 10 photos as you think are needed to show the common areas available in the building. You can also add captions to provide more detail',
    },
    floorPlans: {
      title: 'Floor plans',
      subtitle: 'Upload up to 10 images or scans of the listing floor plan',
    },
  },
  amenity: {
    operableWindows: 'Operable windows',
    cleaningIncluded: 'Cleaning included',
  },
  notChosen: 'Not Chosen',
  choosePeriod: 'Choose period',
};
