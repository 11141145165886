import SetupIntentsRepository from 'repositories/currentUser/SetupIntentsRepository';
import { SetupIntentResponse } from 'types/resources/SetupIntents';

import useLoading from './useLoading';

type UseSetupIntentsType = {
  createSetupIntent: () => Promise<SetupIntentResponse>;
  showSetupIntent: () => Promise<SetupIntentResponse>;
  destroySetupIntent: () => Promise<void>;
  isDestroyPending: boolean;
};

const useSetupIntents = (): UseSetupIntentsType => {
  const { create, show, destroy } = SetupIntentsRepository;
  const { isPending: isDestroyPending, funcWithLoading: destroyWithLoading } = useLoading(destroy);

  return {
    createSetupIntent: create,
    showSetupIntent: show,
    destroySetupIntent: destroyWithLoading,
    isDestroyPending,
  };
};

export default useSetupIntents;
