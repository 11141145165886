import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikHelpers } from 'formik';
import { SignUpFormData, initialValues, validationSchema, attributesToSubmit } from 'forms/public/signUpForm';
import Icon from 'components/Icon';
import InputField from 'components/InputField';
import SignInFormsToggler from 'components/SignInFormsToggler';
import Separator from 'components/Separator';
import GoogleSignIn from 'components/GoogleSignIn';
import { useRouter } from 'hooks';
import UsersRepository from 'repositories/UsersRepository';
import Loader from 'components/Loader';
import { handleErrors } from 'utils/errors';
import { appRoutes } from 'routes';
import { makePathWithQueryString } from 'utils/routes';
import { SignUpFormTab } from 'enums/SignUpFormTab';
import clsx from 'utils/clsx';

import styles from './styles';

type SignUpProps = {
  onTabChange?: (newActiveTab: SignUpFormTab) => void;
  isInHeroBlock?: boolean;
};

const SignUp: FC<SignUpProps> = props => {
  const { onTabChange, isInHeroBlock } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);

  const {
    push,
    camelizedQuery: { redirectUrl },
  } = useRouter();

  const onSubmit = async (formData: SignUpFormData, { setSubmitting, setErrors }: FormikHelpers<SignUpFormData>) => {
    const params = attributesToSubmit(formData);
    try {
      await UsersRepository.create(params);
      const path = makePathWithQueryString(appRoutes.public.emailConfirmationPath(), {
        email: formData.email,
        redirectUrl,
      });
      push(path);
    } catch (error: unknown) {
      handleErrors(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });
  const { values, handleChange, handleBlur, handleSubmit, touched, errors, isSubmitting } = formik;

  return (
    <Box sx={clsx(styles.wrapper, [[styles.wrapperHero, isInHeroBlock]])}>
      <Typography variant={isInHeroBlock ? 'h2' : 'mainPageHeading1'} sx={styles.titleWithMarker} component="h1">
        {t('components:SignUp.createAccount')}
      </Typography>
      <SignInFormsToggler activeTab={SignUpFormTab.trotters} onTabChange={onTabChange} isInHeroBlock={isInHeroBlock} />
      <Box sx={styles.base}>
        <Box sx={styles.container}>
          <Box sx={styles.form} noValidate onSubmit={handleSubmit} component="form">
            <Box sx={styles.row}>
              <InputField
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                fullWidth
                id="firstName"
                name="firstName"
                autoComplete="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                sx={clsx(styles.firstName, [[styles.fieldHero, isInHeroBlock]])}
                placeholder={t('common:firstName')}
                size={isInHeroBlock ? 'small' : 'medium'}
              />
              <InputField
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
                placeholder={t('common:lastName')}
                size={isInHeroBlock ? 'small' : 'medium'}
              />
            </Box>
            <InputField
              error={touched?.company?.name && !!errors.company?.name}
              helperText={touched.company?.name && errors.company?.name}
              fullWidth
              id="company.name"
              name="company.name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.company?.name}
              sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
              placeholder={t('common:companyName')}
              size={isInHeroBlock ? 'small' : 'medium'}
            />
            <InputField
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
              placeholder={t('common:yourEmail')}
              startAdornment={<Icon name="email" sx={styles.icon} />}
              size={isInHeroBlock ? 'small' : 'medium'}
            />
            <Box sx={styles.password}>
              <InputField
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                fullWidth
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder={t('resources:user.password')}
                sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
                startAdornment={<Icon name="password" sx={styles.icon} />}
                size={isInHeroBlock ? 'small' : 'medium'}
              />
            </Box>

            <Button sx={styles.submit} type="submit" disabled={isSubmitting} size={isInHeroBlock ? 'small' : 'medium'}>
              <Typography>{t('common:createAccount')}</Typography>
            </Button>
            <Separator>{t('common:or')}</Separator>
            <GoogleSignIn isInHeroBlock={isInHeroBlock} />
            <Typography sx={styles.signInTypo} variant="body3">
              {t('components:SignUp.alreadyHaveAccount')}{' '}
              <Typography
                variant="body3"
                component={Link}
                to={makePathWithQueryString(appRoutes.public.signInPath(), { redirectUrl })}
              >
                {t('components:SignUp.signIn')}
              </Typography>
            </Typography>
          </Box>
          <Loader open={isSubmitting} />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
