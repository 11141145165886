import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTableContainerType = {
  defaultProps: ComponentsProps['MuiTableContainer'];
  styleOverrides: ComponentsOverrides['MuiTableContainer'];
};

const MuiTableContainer: MuiTableContainerType = {
  styleOverrides: {},
  defaultProps: {
    sx: {
      border: '1px solid #E5E7F0',
      borderRadius: '4px',
      borderWidth: '1px 1px 0 1px',
    },
  },
};

export default MuiTableContainer;
