import { includes } from 'ramda';
import i18n from 'locales/i18n';
import { isSecurePassword } from 'utils/passwords';

export const NO_SPACES_RULE = {
  name: 'no-spaces',
  test: (value: string): boolean => (value ? !includes(' ', value) : true),
  message: i18n.t('forms:validations.noSpace'),
};

export const IS_STRONG_PASSWORD = {
  name: 'is-strong-password',
  test: (value: string): boolean => (value ? isSecurePassword(value) : false),
  message: i18n.t('forms:validations.passwordNotStrong'),
};

export const IS_BOOKING_WINDOW_CORRECT = {
  name: 'is-booking-window-correct',
  test: (value: number): boolean => value === 30 || value === 60,
  message: i18n.t('forms:validations.bookingWindow'),
};

export const IS_MINIMAL_RENTAL_PERIOD_CORRECT = {
  name: 'is-rental-period-correct',
  test: (value: number): boolean => value >= 1 && value <= 12,
  message: i18n.t('forms:validations.minimalRentalPeriod'),
};
