import { FC } from 'react';
import Icon from 'components/Icon';
import clsx from 'utils/clsx';

import styles from './styles';

type VideoPlayIconProps = {
  size?: 'small' | 'medium' | 'large';
};

const VideoPlayIcon: FC<VideoPlayIconProps> = props => {
  const { size = 'small' } = props;

  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isLarge = size === 'large';

  const iconStyles = clsx(styles.playIcon, [
    [styles.playIconSmall, isSmall],
    [styles.playIconMedium, isMedium],
    [styles.playIconLarge, isLarge],
  ]);

  return <Icon sx={iconStyles} name="playCircle" />;
};

export default VideoPlayIcon;
