import { FC } from 'react';
import { Stack, Typography, Box, Avatar, Button, TextField, FormHelperText, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks';
import { useSpaceRents } from 'hooks/landlords';
import Icon from 'components/Icon';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { isEmpty, isNil } from 'ramda';
import { SpacePresenter, UsersPresenter } from 'presenters';
import { formatDateTimeShortMonth } from 'utils/dateTimeUtils';
import { FormikHelpers, useFormik } from 'formik';
import {
  initialValues,
  validationSchema,
  attributesToSubmit,
  SpaceRentCancellationFormData,
  MAX_DESCRIPTION_LENGTH,
} from 'forms/landlord/spaceRentCancellationForm';
import { handleErrors } from 'utils/errors';
import { SpaceRentCancellationReason } from 'enums/resources/SpaceRent';
import { ModalType } from 'enums/ModalType';
import { getSpaceRentWithActiveSubscription } from 'utils/spaceUtils';

import styles from './styles';

const LandlordSpaceRentCancel: FC = () => {
  const { t } = useTranslation(['landlordPages', 'common']);

  const { cancel, isCancelPending } = useSpaceRents();
  const { openModal, closeModal, extra } = useModals();
  const { space, loadSpaces } = extra;

  const spaceRent = getSpaceRentWithActiveSubscription(space);

  const onSubmit = async (
    formData: SpaceRentCancellationFormData,
    { setSubmitting, setErrors }: FormikHelpers<SpaceRentCancellationFormData>,
  ) => {
    const params = attributesToSubmit(formData);

    try {
      await cancel(spaceRent.id, params);
      openModal({
        type: ModalType.landlordSpaceRentCancelFinish,
        extra: {
          space,
          spaceRent,
          loadSpaces,
        },
      });
    } catch (error: unknown) {
      handleErrors(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const { values, handleChange, handleBlur, handleSubmit, touched, errors, isValid, setFieldValue } = formik;

  const handleCancelClick = () => closeModal();

  const handleChangeCancellationReason = e => {
    const { value } = e.target;

    setFieldValue('cancellationReason', value);

    if (value === !SpaceRentCancellationReason.other) {
      setFieldValue('cancellationReasonOther', '');
    }
  };

  const imageUrl = SpacePresenter.coverUrl(space);
  const fullName = UsersPresenter.fullName(spaceRent.user);
  const trotterCompanyName = spaceRent?.user?.company?.name;
  const trotterCompanyAddress = spaceRent?.user?.company?.address;

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={handleCancelClick}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('landlordPages:Listings.ConfirmCancellation.title')}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4">
        {t('landlordPages:Listings.ConfirmCancellation.subtitle', { fullName })}
      </Typography>
      <Box sx={styles.spaceCard}>
        <Box sx={styles.info}>
          <Box sx={styles.preview}>
            {isNil(imageUrl) ? <ImagePlaceholder name="space" /> : <img src={imageUrl} alt={space.title} />}
          </Box>
          <Box sx={styles.text}>
            <Typography sx={styles.spaceTitle} variant="body3">
              {space.title}
            </Typography>
            <Typography sx={styles.address} variant="body5">
              {space.building.address}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.price}>
          <Typography sx={styles.priceText} variant="body3">
            {SpacePresenter.pricePerMonthFormatted(spaceRent, 'common:inMonth')}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.trotterCard}>
        <Box sx={styles.avatar}>
          <Avatar alt="avatar" src={spaceRent.user.avatarUrl} />
        </Box>
        <Box sx={styles.trotterInfo}>
          <Typography sx={styles.trotterName} variant="h6" component="p">
            {UsersPresenter.fullName(spaceRent.user)}
          </Typography>
          {trotterCompanyName && (
            <Typography sx={styles.companyText} variant="body4" component="span">
              <Icon sx={styles.textIcon} name="suitcase" />
              {trotterCompanyName}
            </Typography>
          )}
          {trotterCompanyAddress && (
            <Typography sx={styles.companyText} variant="body4" component="p">
              <Icon sx={styles.textIcon} name="label" />
              {trotterCompanyAddress}
            </Typography>
          )}
          <Box sx={styles.dates}>
            <Box sx={styles.startDate}>
              <Icon sx={styles.textIcon} name="calendar" />
              <Typography variant="subtitle3" whiteSpace="pre-wrap">
                {t('landlordPages:Listings.startDate')}
              </Typography>
              <Typography variant="body4" sx={{ marginLeft: 'auto' }}>
                {formatDateTimeShortMonth(spaceRent.projectedActivatedAt)}
              </Typography>
            </Box>
            <Box sx={styles.endDate}>
              <Icon sx={styles.textIcon} name="calendar" />
              <Typography variant="subtitle3" whiteSpace="pre-wrap">
                {t('landlordPages:Listings.endDate')}
              </Typography>
              <Typography variant="body4" sx={{ marginLeft: 'auto' }}>
                {formatDateTimeShortMonth(spaceRent.projectedCompletedAt)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box onSubmit={handleSubmit} component="form">
        <Typography component="p" variant="subtitle3">
          {t('landlordPages:Listings.ConfirmCancellation.cancellationReason')}
          <Typography component="span" color="red.main">
            *
          </Typography>
        </Typography>
        <Box sx={styles.cancellationReason}>
          {isEmpty(values.cancellationReason) && (
            <Box sx={styles.cancellationReasonPlaceholder}>
              <Typography variant="body3">
                {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonPlaceholder')}
              </Typography>
            </Box>
          )}
          <TextField
            error={touched.cancellationReason && Boolean(errors.cancellationReason)}
            helperText={touched.cancellationReason && errors.cancellationReason}
            name="cancellationReason"
            id="cancellationReason"
            fullWidth
            select
            value={values.cancellationReason}
            onChange={handleChangeCancellationReason}
            onBlur={handleBlur}
          >
            <MenuItem value={SpaceRentCancellationReason.noLonger}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.noLonger')}
            </MenuItem>
            <MenuItem value={SpaceRentCancellationReason.tenantExpanding}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.tenantExpanding')}
            </MenuItem>
            <MenuItem value={SpaceRentCancellationReason.tenantLongTerm}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.tenantLongTerm')}
            </MenuItem>
            <MenuItem value={SpaceRentCancellationReason.issues}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.issues')}
            </MenuItem>
            <MenuItem value={SpaceRentCancellationReason.complicated}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.complicated')}
            </MenuItem>
            <MenuItem value={SpaceRentCancellationReason.expensive}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.expensive')}
            </MenuItem>
            <MenuItem value={SpaceRentCancellationReason.other}>
              {t('landlordPages:Listings.ConfirmCancellation.cancellationReasonValues.other')}
            </MenuItem>
          </TextField>
        </Box>
        {values.cancellationReason === SpaceRentCancellationReason.other && (
          <Box sx={styles.cancellationReasonTextarea}>
            <TextField
              inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
              error={touched.cancellationReasonOther && Boolean(errors.cancellationReasonOther)}
              helperText={touched.cancellationReasonOther && errors.cancellationReasonOther}
              name="cancellationReasonOther"
              id="cancellationReasonOther"
              fullWidth
              value={values.cancellationReasonOther}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t('landlordPages:Listings.ConfirmCancellation.cancellationReasonOtherPlaceholder')}
            />
            <FormHelperText variant="outlined" sx={styles.symbolsCounter}>
              {values.cancellationReasonOther.length} / {MAX_DESCRIPTION_LENGTH}
            </FormHelperText>
          </Box>
        )}
        <Typography component="p" variant="subtitle3" sx={styles.cancellationNote}>
          {t('landlordPages:Listings.ConfirmCancellation.cancellationNote')}
          <Typography component="span" color="red.main">
            *
          </Typography>
        </Typography>
        <Box sx={styles.textarea}>
          <TextField
            inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
            error={touched.cancellationNote && Boolean(errors.cancellationNote)}
            helperText={touched.cancellationNote && errors.cancellationNote}
            name="cancellationNote"
            id="cancellationNote"
            fullWidth
            value={values.cancellationNote}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('landlordPages:Listings.ConfirmCancellation.cancellationNotePlaceholder', {
              firstName: spaceRent.user.firstName,
            })}
          />
          <FormHelperText variant="outlined" sx={styles.symbolsCounter}>
            {values.cancellationNote.length} / {MAX_DESCRIPTION_LENGTH}
          </FormHelperText>
        </Box>
        <Box>
          <Button type="submit" sx={styles.button} disabled={!isValid || isCancelPending}>
            <Typography variant="tabsTextBold">
              {t('landlordPages:Listings.ConfirmCancellation.cancelLicense')}
            </Typography>
          </Button>
          <Button sx={styles.button} variant="text" onClick={handleCancelClick}>
            <Typography variant="tabsTextBold">{t('landlordPages:Listings.ConfirmCancellation.cancel')}</Typography>
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default LandlordSpaceRentCancel;
