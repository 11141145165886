import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTableCellType = {
  defaultProps: ComponentsProps['MuiTableCell'];
  styleOverrides: ComponentsOverrides['MuiTableCell'];
};

const MuiTableCell: MuiTableCellType = {
  styleOverrides: {
    root: {
      padding: '20px',
      fontSize: 15,
      fontWeight: 500,
      fontFamily: 'Heebo',
    },
    head: {
      backgroundColor: '#F5F6FA',
    },
  },
  defaultProps: {},
};

export default MuiTableCell;
