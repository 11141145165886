import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  paper: {},
  close: {
    position: 'absolute',
    right: 12,
    top: 12,
  },
  content: {
    maxWidth: '420px',
    padding: '64px 60px 58px',
  },
  modalTitle: {
    marginBottom: '32px',
    textAlign: 'center',
    letterSpacing: '-0.02em',
  },
  cropperWrapper: {
    position: 'relative',
    width: '100%',
    height: '300px',
    '& .reactEasyCrop_Container': {
      cursor: 'auto',
    },
    '& .reactEasyCrop_CropArea': {
      cursor: 'move',
      border: '1px dashed rgba(255, 255, 255, 0.3)',
      borderRadius: '8px',
    },
    '& .reactEasyCrop_CropArea:before': {
      display: 'none',
    },
    '& .reactEasyCrop_CropArea:after': {
      display: 'none',
    },
  },
  rotateControls: {
    display: 'flex',
    justifyContent: 'space-between',
    letterSpacing: '0.1em',
    padding: '26px 16px',
    '& svg': {
      width: 18,
      height: 18,
      marginRight: '12px',
    },
  },
  rotateLeftbutton: {
    width: 'auto',
    height: '18px',
  },
  rotateRightbutton: {
    width: 'auto',
    height: '18px',
    '& svg': {
      transform: 'rotateY(180deg)',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '24px',
  },
  saveButton: {
    width: 'auto',
    height: '43px',
    marginRight: '24px',
  },
  deleteButton: {
    color: 'navy.main',
    width: 'auto',
    height: '43px',
  },
};

export default styles;
