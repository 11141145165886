import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks';
import { appRoutes } from 'routes';
import Box from 'components/Box';
import clsx from 'utils/clsx';
import { SignUpFormTab } from 'enums/SignUpFormTab';

import styles from './styles';

type SignInFormsTogglerProps = {
  activeTab: SignUpFormTab;
  onTabChange: (newActiveTab: SignUpFormTab) => void;
  isInHeroBlock?: boolean;
};

const mapSignUpFormTabToPath = {
  [SignUpFormTab.trotters]: appRoutes.public.signUpPath(),
  [SignUpFormTab.owners]: appRoutes.public.signUpOwnerPath(),
};

const SignInFormsToggler: FC<SignInFormsTogglerProps> = props => {
  const { activeTab, onTabChange, isInHeroBlock } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);
  const { push } = useRouter();

  const isSignUpTrottersActive = activeTab === SignUpFormTab.trotters;
  const isSignUpOwnersActive = activeTab === SignUpFormTab.owners;

  const handleTabChange = (newActiveTab: SignUpFormTab) => {
    if (onTabChange) {
      onTabChange(newActiveTab);
      return;
    }

    const path = mapSignUpFormTabToPath[newActiveTab];
    push(path);
  };

  return (
    <>
      <Box sx={styles.root}>
        <Typography
          variant="h4"
          onClick={() => handleTabChange(SignUpFormTab.trotters)}
          sx={clsx(styles.link, [[styles.linkActive, isSignUpTrottersActive]])}
        >
          {t('components:SignUp.forTrotters')}
        </Typography>

        <Typography
          variant="h4"
          onClick={() => handleTabChange(SignUpFormTab.owners)}
          sx={clsx(styles.link, [[styles.linkActive, isSignUpOwnersActive]])}
        >
          {t('components:SignUp.forOwners')}
        </Typography>
      </Box>
      {isSignUpTrottersActive && (
        <Typography component="p" sx={styles.heading} variant={isInHeroBlock ? 'body8' : 'body7'}>
          {t('components:SignUp.heading')}
        </Typography>
      )}
      {isSignUpOwnersActive && (
        <Typography component="p" sx={styles.heading} variant={isInHeroBlock ? 'body8' : 'body7'}>
          {t('components:SignUpOwner.heading')}
        </Typography>
      )}
    </>
  );
};

export default SignInFormsToggler;
