import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  popper: {
    zIndex: 10,
  },
  paper: {
    transform: 'translateY(8px) !important',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: 0,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: '50%',
      width: 10,
      height: 10,
      backgroundColor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  placeholderWrapper: {
    justifyContent: 'flex-start',
    height: 'fit-content',
    borderRadius: '0 !important',
    paddingLeft: 0,
    '&:hover': {
      background: 'none',
    },
    '&:focus *': {
      visibility: 'visible',
    },
    color: 'black.main',
  },
  menuList: {
    padding: '5px 0px',
    outline: 'none',
  },
};

export default styles;
