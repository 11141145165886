import { apiRoutes } from 'routes';
import { LegalEntity, LegalEntitiesResources, LegalEntityFormSubmitData } from 'types/resources/LegalEntity';
import Fetcher from 'utils/fetcher';

const LegalEntitiesRepository = {
  index(params: QueryParams): Promise<LegalEntitiesResources> {
    const url = apiRoutes.apiSiteV1LandlordLegalEntitiesPath();
    return Fetcher.get(url, params);
  },
  create(legalEntity: LegalEntityFormSubmitData): Promise<LegalEntity> {
    const url = apiRoutes.apiSiteV1LandlordLegalEntitiesPath();
    return Fetcher.post(url, legalEntity);
  },
  show(legalEntityId: ID): Promise<LegalEntity> {
    const url = apiRoutes.apiSiteV1LandlordLegalEntityPath(legalEntityId);
    return Fetcher.get(url);
  },
  update({
    legalEntityId,
    legalEntity,
  }: {
    legalEntityId: ID;
    legalEntity: LegalEntityFormSubmitData;
  }): Promise<LegalEntity> {
    const url = apiRoutes.apiSiteV1LandlordLegalEntityPath(legalEntityId);
    return Fetcher.put(url, legalEntity);
  },
  delete(legalEntityId: ID): Promise<void> {
    const url = apiRoutes.apiSiteV1LandlordLegalEntityPath(legalEntityId);
    return Fetcher.delete(url);
  },
};

export default LegalEntitiesRepository;
