import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  rowWithHover: {
    backgroundColor: 'navy.background',
  },
  noBorder: {
    borderBottom: 'none',
  },
};

export default styles;
