import { FC, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import Box from 'components/Box';
import clsx from 'utils/clsx';
import RenderGoBack from 'components/Chat/components/RenderGoBack';

import styles from './styles';
import 'stream-chat-react/dist/css/index.css';

const ChatWrapper: FC = props => {
  const { navOpen, openMobileNav } = useChatContext();

  useEffect(() => {
    openMobileNav();
  }, []);

  const wrapperStyles = clsx(styles.root, [[styles.rootNavClosed, !navOpen]]);

  return (
    <Box sx={wrapperStyles}>
      <RenderGoBack />
      {props.children}
    </Box>
  );
};

export default ChatWrapper;
