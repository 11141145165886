import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Box, Typography, Stack, ButtonBase } from '@material-ui/core';
import Icon from 'components/Icon';
import { appRoutes, marketingRoutes } from 'routes';
import { useScreen } from 'hooks';
import AccountMenuMobile from 'components/AccountMenuMobile';
import DropDownMenu from 'components/DropDownMenu';
import { HeaderLayout } from 'enums/HeaderLayout';
import DropDownMenuPlaceholder from 'components/Header/components/DropDownMenuPlaceholder';
import DropDownMenuList from 'components/Header/components/DropDownMenuList';
import clsx from 'utils/clsx';
import PhoneNumber from 'components/PhoneNumber';

import styles from './styles';

type NonAuthUserProps = {
  isRootPath: boolean;
  type: HeaderLayout;
};

const NonAuthUser: FC<NonAuthUserProps> = props => {
  const { isRootPath, type } = props;

  const { t } = useTranslation(['publicLayouts', 'layouts', 'common', 'resources']);
  const { lessThanDesktop } = useScreen();

  const renderLogin = () => {
    if (isRootPath) {
      return (
        <Button
          component={Link}
          to={appRoutes.public.signInPath()}
          size="small"
          variant="outlined"
          sx={styles.signInButton}
        >
          <Icon name="lock" />
          <Typography variant="body3">{t('publicLayouts:BaseLayout.Header.login')}</Typography>
        </Button>
      );
    }
    return (
      <Box sx={styles.loginToAccountBlock}>
        <PhoneNumber layoutType={type} />
        <ButtonBase component={Link} to={appRoutes.public.signInPath()} sx={styles.loginToAccount}>
          <Icon name="lock" />
          <Typography variant="body3">{t('publicLayouts:BaseLayout.Header.loginToAccount')}</Typography>
        </ButtonBase>
      </Box>
    );
  };

  const renderLinks = () => {
    return (
      <>
        {isRootPath && (
          <>
            <PhoneNumber layoutType={type} />
          </>
        )}
      </>
    );
  };

  if (lessThanDesktop) {
    return <AccountMenuMobile />;
  }

  const isLightLayout = type === HeaderLayout.light;
  const isBaseLayout = type === HeaderLayout.base;
  const isHomeLayout = type === HeaderLayout.home;

  const linkStyles = clsx(styles.link, [
    [styles.linkLight, isLightLayout],
    [styles.linkBase, isBaseLayout],
    [styles.linkHome, isHomeLayout],
  ]);

  return (
    <Box sx={styles.root}>
      <Stack sx={styles.links} direction={{ mobile: 'column', tablet: 'row' }}>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:Locations.title')} type={type} />}
          >
            <DropDownMenuList placeholder="locations" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:ForOwners.title')} type={type} />}
          >
            <DropDownMenuList placeholder="forOwners" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:ForTrotters.title')} type={type} />}
          >
            <DropDownMenuList placeholder="forTrotters" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:AboutTrot.title')} type={type} />}
          >
            <DropDownMenuList placeholder="aboutTrot" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:ContactUs.title')} type={type} />}
          >
            <DropDownMenuList placeholder="contactUs" />
          </DropDownMenu>
        </Box>
        <Typography sx={linkStyles} variant="body3" href={marketingRoutes.rootPath()} component="a">
          {t('publicLayouts:ThePowerOfFlex')}
        </Typography>
      </Stack>
      <Box sx={styles.loginBlock}>
        {renderLinks()}
        {renderLogin()}
      </Box>
    </Box>
  );
};

export default NonAuthUser;
