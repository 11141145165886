import { useEffect } from 'react';
import useNotifications from 'hooks/useNotifications';
import useRouter from 'hooks/useRouter';
import { Notification } from 'enums/Notification';
import { useTranslation } from 'react-i18next';

const QUERY_PARAM_KEY = 'notification';

const useAuthNotifications = (): void => {
  const { openNotification } = useNotifications();
  const { location, history } = useRouter();
  const { t } = useTranslation('forms');

  const queryParams = new URLSearchParams(location.search);
  const notification = queryParams.get(QUERY_PARAM_KEY);

  useEffect(() => {
    if (notification === Notification.accountDisabled) {
      openNotification({ title: t('validations.auth.title'), description: t('validations.auth.description') });

      queryParams.delete(QUERY_PARAM_KEY);
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [notification]);
};

export default useAuthNotifications;
