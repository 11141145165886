import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import dictonary from './dictionary';

type ImagePlaceholderProps = {
  name: string;
};

const ImagePlaceholder: FC<ImagePlaceholderProps & SvgIconProps> = ({ name, ...props }) => {
  const { viewBox, width, height, id } = dictonary[name];
  return (
    <SvgIcon viewBox={viewBox} width={width} height={height} {...props}>
      <use xlinkHref={id} />
    </SvgIcon>
  );
};

export default ImagePlaceholder;
