/* eslint-disable no-param-reassign */
import TrottersRepository from 'repositories/admin/TrottersRepository';
import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import User from 'types/resources/User';

export type TrottersSliceStateType = {
  trotters: User[];
  trotter: User;
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadTrotters = createAsyncThunk('admin/trotters/load', TrottersRepository.index);
export const updateTrotter = createAsyncThunk('admin/trotters/update', TrottersRepository.update);

const initialState: TrottersSliceStateType = {
  trotters: [] as User[],
  trotter: {} as User,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  meta: {
    count: null,
    totalCount: null,
    perPage: 10,
    currentPage: 1,
    totalPages: null,
    nextPage: null,
  },
};

const slice = createSlice({
  name: 'trotters',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadTrotters.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadTrotters.fulfilled, (state, { payload }) => {
      state.trotters = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadTrotters.rejected, state => {
      state.trotters = [];
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateTrotter.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateTrotter.fulfilled, (state, { payload }) => {
      state.update.fetchStatus = FetchStatus.fulfilled;
      state.trotter = payload;
    });
    builder.addCase(updateTrotter.rejected, (state, { error }) => {
      state.update.fetchStatus = FetchStatus.failed;
      state.update.error = error;
    });
  },
});

export default slice.reducer;
