export enum ModalType {
  declineWithReason = 'declineWithReason',
  trotRules = 'trotRules',
  buildingQRCode = 'buildingQRCode',
  cancelAgreement = 'cancelAgreement',
  landlordBookingRequest = 'landlordBookingRequest',
  landlordSpaceRentCancel = 'landlordSpaceRentCancel',
  landlordSpaceRentCancelFinish = 'landlordSpaceRentCancelFinish',
  landlordSpaceRentCancelSuggestSpaces = 'landlordSpaceRentCancelSuggestSpaces',
  landlordSpaceRentCancelSuggestSpacesFinish = 'landlordSpaceRentCancelSuggestSpacesFinish',
  shareSpace = 'shareSpace',
  bookingRequestDeclineAndSuggestSpaces = 'bookingRequestDeclineAndSuggestSpaces',
}
