import { useState, FC } from 'react';
import { Typography, Button, ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { take, isEmpty, isNil } from 'ramda';
import Box from 'components/Box';
import Icon from 'components/Icon';
import ImagePlaceholder from 'components/ImagePlaceholder';
import Building from 'types/resources/Building';
import Space from 'types/resources/Space';
import { SpacePresenter } from 'presenters';
import { appRoutes } from 'routes';
import { useRouter } from 'hooks';

import styles from './styles';

type ListingsProps = {
  building: Building;
  pathToViewListing: (buildingId: ID, spaceId: ID) => string;
  isAdminPage?: boolean;
};

const DEFAULT_LISTINGS_COUNT = 3;

const Listings: FC<ListingsProps> = props => {
  const { building, pathToViewListing, isAdminPage } = props;
  const [shownListingsCount, increaseCount] = useState(DEFAULT_LISTINGS_COUNT);
  const { t } = useTranslation('landlordComponents');

  const increaseList = () => {
    increaseCount(shownListingsCount + DEFAULT_LISTINGS_COUNT);
  };

  const { push } = useRouter();
  const handleAddNewListingClick = () => {
    push(appRoutes.landlord.newListingWithBuildingIdPath(building.id));
  };

  const renderSpaces = () => {
    const { spaces, id: buildingId } = building;

    const prepared: Space[] = spaces.filter(space => space).sort((spaceA, spaceB) => spaceA.id - spaceB.id);
    const shownSpaces = take(shownListingsCount, prepared);

    return shownSpaces.map(space => {
      const imageUrl = SpacePresenter.coverUrl(space);
      return (
        <tr key={space.id}>
          <td className="preview">
            <Box sx={styles.preview}>
              {isNil(imageUrl) ? <ImagePlaceholder name="space" /> : <img src={imageUrl} alt={space.title} />}
            </Box>
          </td>
          <td>
            <Typography variant="body3" component="p" sx={styles.title}>
              {space.title}
            </Typography>
          </td>
          <td>
            <Typography component="p" variant="body3" sx={styles.square} noWrap>
              {SpacePresenter.squareFormatted(space)}
            </Typography>
          </td>
          <td>
            <Typography component="p" variant="body3" sx={styles.price} noWrap>
              {SpacePresenter.pricePerMonthFormatted(space, 'common:inMonth')}
            </Typography>
          </td>
          <td align="right">
            <ButtonBase sx={styles.arrowButton} component={Link} to={pathToViewListing(buildingId, space.id)}>
              <Icon name="arrow" />
            </ButtonBase>
          </td>
        </tr>
      );
    });
  };

  const spacesCount = building.spaces.length;
  const showMoreListingsButton = shownListingsCount < spacesCount;
  const showMoreListingsCount = Math.min(DEFAULT_LISTINGS_COUNT, spacesCount - shownListingsCount);

  return (
    <>
      <Typography sx={styles.listingsTitle} variant="h5">
        {t('BuildingContent.Listings.listingsInThisBuilding')}
      </Typography>

      {isEmpty(building.spaces) ? (
        <Typography sx={styles.description} variant="body5" component="p">
          {t('BuildingContent.Listings.noListings')}
        </Typography>
      ) : (
        <>
          <Box sx={styles.table}>
            <table>
              <tbody>{renderSpaces()}</tbody>
            </table>
          </Box>
          <Box sx={styles.buttonsBlock}>
            {showMoreListingsButton && (
              <ButtonBase sx={styles.showButton} onClick={increaseList}>
                <Typography variant="bold4" component="p" sx={styles.showButtonText}>
                  <Icon name="plus" />
                  {t('BuildingContent.Listings.showMoreListings', { count: showMoreListingsCount })}
                </Typography>
              </ButtonBase>
            )}

            {!isAdminPage && (
              <Button sx={styles.addButton} size="medium" onClick={handleAddNewListingClick}>
                <Typography variant="tabsTextBold">{t('BuildingContent.Listings.addAListing')}</Typography>
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Listings;
