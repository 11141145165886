import { FC } from 'react';
import { Stack } from '@material-ui/core';
import PlacesAutocomplete from 'components/PlacesAutocomplete';

import styles from './styles';

type LocationInputProps = {
  onPlaceChange: (result: google.maps.GeocoderResult, place: string) => void;
  initialPlace: string;
};

const LocationInput: FC<LocationInputProps> = props => {
  const { onPlaceChange, initialPlace } = props;

  return (
    <Stack direction="row" sx={styles.autocompleteWrapper}>
      <PlacesAutocomplete onChange={onPlaceChange} initialValue={initialPlace} />
    </Stack>
  );
};

export default LocationInput;
