import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Popper, Grow, Paper, ClickAwayListener, MenuList, PopperPlacementType } from '@material-ui/core';
import Box from 'components/Box';
import { useRouter } from 'hooks';
import clsx from 'utils/clsx';
import { SxStyles } from 'types/theme';

import styles from './styles';

type DropDownMenuProps = {
  placeholder: React.ReactNode;
  children: React.ReactNode;
  placement?: PopperPlacementType;
  openOnHover?: boolean;
  buttonStyles?: SxStyles;
};

const DropDownMenu = (props: DropDownMenuProps): JSX.Element => {
  const { placeholder, children, placement = 'bottom', openOnHover = false, buttonStyles = {} } = props;
  const { location } = useRouter();

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const handleMouseEnter = () => {
    if (openOnHover) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (openOnHover) {
      setOpen(false);
    }
  };

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <IconButton
        size="small"
        sx={clsx(styles.placeholderWrapper, [[buttonStyles, true]])}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {placeholder}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement}
        transition
        disablePortal
        style={styles.popper as React.CSSProperties}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        }}
      >
        {({ TransitionProps }) => {
          const childrenWithProps = React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { setOpen });
            }
            return child;
          });

          return (
            <Grow {...TransitionProps}>
              <Paper elevation={0} sx={styles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocus={open}
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={styles.menuList}
                  >
                    {childrenWithProps}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </Box>
  );
};

export default DropDownMenu;
