import { OrderDirection } from 'enums/OrderDirection';
import { SpaceState } from 'enums/resources/Space';

export type LoadingParams = {
  page: number;
  perPage: number;
  q: Record<string, unknown>;
};

export const adminLandlordsParams = (
  page: number,
  perPage: number,
  orderDirection: OrderDirection,
  orderBy: string,
  landlordState: string,
  landlordName: string,
): LoadingParams => ({
  page,
  perPage,
  q: { state_eq: landlordState, s: [`${orderBy} ${orderDirection}`], name_cont: landlordName },
});

export const adminUsersParams = (
  page: number,
  perPage: number,
  orderDirection: OrderDirection,
  orderBy: string,
  userState?: string,
  userName?: string,
): LoadingParams => ({
  page,
  perPage,
  q: { state_eq: userState, s: [`${orderBy} ${orderDirection}`], first_name_cont: userName },
});

export const adminBuildingsParams = (params: {
  page: number;
  perPage: number;
  orderDirection?: OrderDirection;
  orderBy?: string;
  searchValue?: string;
  landlordId?: number;
}): LoadingParams => {
  const { page, perPage, orderDirection = null, orderBy = null, landlordId = null, searchValue = null } = params;

  return {
    page,
    perPage,
    q: {
      landlord_id_eq: landlordId,
      address_or_landlord_name_cont: searchValue,
      s: [`${orderBy} ${orderDirection}`],
    },
  };
};

export const landlordBuildlingsParams = (
  page: number,
  perPage: number,
  orderDirection: OrderDirection,
  orderBy: string,
  address?: string,
  landlordId?: string,
  stateIn?: string[],
): LoadingParams => ({
  page,
  perPage,
  q: {
    s: [`${orderBy} ${orderDirection}`],
    address_cont: address,
    landlord_id_eq: landlordId,
    state_in: stateIn,
  },
});

export const landlordSpacesParams = (
  page: number,
  perPage: number,
  orderDirection: OrderDirection,
  orderBy: string,
  buildingId: number,
): LoadingParams => ({
  page,
  perPage,
  q: { s: [`${orderBy} ${orderDirection}`], building_id_eq: buildingId },
});

export const landlordSuggestSpacesParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: {
    state_in: ['active', 'rented'],
    available_at_not_null: 1,
  },
});

export const landlordRentRequestedSpacesParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: { state_eq: SpaceState.rentRequested },
});

export const landlordRentedSpacesParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: { space_rents_state_in: ['active', 'approved'] },
});

export const adminSpacesParams = (params: {
  page: number;
  perPage: number;
  orderDirection?: OrderDirection;
  orderBy?: string;
  state?: SpaceState | null;
  landlordName?: string;
  landlordId?: number;
  buildingId?: string;
}): LoadingParams => {
  const {
    page,
    perPage,
    orderDirection = null,
    orderBy = null,
    state = null,
    landlordName = null,
    landlordId = null,
    buildingId = null,
  } = params;

  return {
    page,
    perPage,
    q: {
      s: [`${orderBy} ${orderDirection}`],
      state_eq: state,
      title_or_landlord_name_cont: landlordName,
      building_landlord_id_eq: landlordId,
      building_id_eq: buildingId,
    },
  };
};

export const landlordEmployeesParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: {},
});

export const landlordLegalEntitiesParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: {},
});

export const trotterFavoriteSpacesParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: {},
});

export const trotterSpaceRentsInProgressParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: {
    state_in: ['waiting_for_approval'],
  },
});

export const trotterSpaceRentsRentedParams = (): LoadingParams => ({
  page: 1,
  perPage: 100,
  q: {
    state_in: ['approved', 'active'],
  },
});
