import { FC } from 'react';
import { isNil } from 'ramda';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatDateTimeForFilters } from 'utils/dateTimeUtils';
import { useScreen } from 'hooks';
import clsx from 'utils/clsx';
import Box from 'components/Box';
import Icon from 'components/Icon';

import styles from './styles';

type DateOfStartPlaceholderProps = {
  date: string;
};

const DateOfStartPlaceholder: FC<DateOfStartPlaceholderProps> = props => {
  const { date } = props;

  const { t } = useTranslation(['common']);

  const { lessThanDesktop } = useScreen();

  const isDateSet = !isNil(date);

  return (
    <Box sx={clsx(styles.root, [[styles.mobileDateField, lessThanDesktop]])}>
      <Box sx={styles.calendarIconBlock}>
        <Icon name="calendar" />
      </Box>
      {isDateSet ? (
        <Typography sx={clsx(styles.dateOfStart, [[styles.setDate, true]])} variant="body3" component="p">
          {formatDateTimeForFilters(date)}
        </Typography>
      ) : (
        <Typography sx={styles.dateOfStart} variant="body3" component="p">
          {t('common:dateOfStart')}
        </Typography>
      )}
    </Box>
  );
};
export default DateOfStartPlaceholder;
