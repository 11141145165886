import { AxiosResponse } from 'axios';
import { RegistrationCompleteFormDataToSubmit } from 'forms/public/registrationCompleteForm';
import { ConfirmUserFormDataToSubmit } from 'forms/public/confirmUserForm';
import { ResendConfirmationFormData } from 'forms/public/resendConfirmation';
import { SignUpFormDataToSubmit } from 'forms/public/signUpForm';
import { ResetPasswordFormDataToSubmit } from 'forms/public/resetPasswordForm';
import { NewPasswordFormDataToSubmit } from 'forms/public/newPasswordForm';
import { SetPasswordFormDataToSubmit } from 'forms/public/setPasswordForm';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import User from 'types/resources/User';
import { EditProfileFormDataForSubmit } from 'forms/public/editProfileForm';

const UsersRepository = {
  current(): Promise<User> {
    const url = apiRoutes.currentApiSiteV1UsersPath();
    return Fetcher.get(url);
  },
  create(params: SignUpFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1UsersPath();
    return Fetcher.post(url, params);
  },
  update(params: EditProfileFormDataForSubmit): Promise<User> {
    const url = apiRoutes.apiSiteV1UsersPath();
    return Fetcher.put(url, params);
  },
  resendConfirmation(params: ResendConfirmationFormData): Promise<AxiosResponse> {
    const url = apiRoutes.resendConfirmationApiSiteV1UsersPath();
    return Fetcher.put(url, params);
  },
  invitationConfirm(params: SetPasswordFormDataToSubmit): Promise<AxiosResponse> {
    const path = apiRoutes.invitationConfirmApiSiteV1UsersPath();
    return Fetcher.put(path, params);
  },
  passwordReset(params: ResetPasswordFormDataToSubmit): Promise<AxiosResponse> {
    const path = apiRoutes.apiSiteV1UserPasswordResetPath();
    return Fetcher.post(path, params);
  },
  passwordUpdate(params: NewPasswordFormDataToSubmit): Promise<AxiosResponse> {
    const path = apiRoutes.apiSiteV1UserPasswordResetPath();
    return Fetcher.put(path, params);
  },
  confirm(params: ConfirmUserFormDataToSubmit): Promise<AxiosResponse> {
    const path = apiRoutes.confirmApiSiteV1UsersPath();
    return Fetcher.put(path, params);
  },
  registrationComplete(params: RegistrationCompleteFormDataToSubmit): Promise<AxiosResponse> {
    const path = apiRoutes.apiSiteV1UsersPath();
    return Fetcher.put(path, params);
  },
};

export default UsersRepository;
