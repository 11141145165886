import { FC } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import Box from 'components/Box';
import Icon from 'components/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getPathTypeAttributes, getNewBlankAttributes } from 'utils/linkUtils';
import { MenuItemType } from 'types/utils';

import styles from './styles';

type AccordionMenuProps = {
  title: string;
  iconName: string;
  defaultExpanded: boolean;
  menuItems: MenuItemType[];
};

const AccordionMenu: FC<AccordionMenuProps> = props => {
  const { title, iconName, defaultExpanded, menuItems } = props;

  const filteredMenuItems = menuItems.filter(menuItem => menuItem.show);

  const renderMenuItems = () =>
    filteredMenuItems.map(menuItem => {
      return (
        <Typography
          {...getPathTypeAttributes(menuItem)}
          {...getNewBlankAttributes(menuItem)}
          key={menuItem.label}
          sx={styles.navItemAccordeon}
          variant="subtitle7"
        >
          {menuItem.label}
        </Typography>
      );
    });

  return (
    <Accordion defaultExpanded={defaultExpanded} sx={styles.accordeon}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography sx={styles.accordeonBlock} variant="subtitle5">
          <Icon name={iconName} />
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={styles.nav}>{renderMenuItems()}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMenu;
