import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTableSortLabel = {
  defaultProps: ComponentsProps['MuiTableSortLabel'];
  styleOverrides: ComponentsOverrides['MuiTableSortLabel'];
};

const TableSortLabel: MuiTableSortLabel = {
  styleOverrides: {},
  defaultProps: {
    IconComponent: KeyboardArrowUpIcon,
  },
};

export default TableSortLabel;
