import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    justifyContent: 'space-between',
    display: {
      mobile: 'flex',
    },
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    alignItems: 'center',
    width: '100%',
    '& span': {
      cursor: 'pointer',
    },
  },
  createAccount: {
    color: 'white.main',
    textDecoration: 'none',
    marginRight: '16px',
    marginTop: {
      mobile: '16px',
      tablet: 0,
    },
    textAlign: 'center',
    '@media (min-width: 1023px) and (max-width: 1065px)': {
      marginRight: '16px',
    },
  },
  signInButton: {
    display: 'flex',
    border: 'none',
    height: '30px',
    padding: 0,
    width: 107,
    '&:hover': {
      border: 'none',
    },
    '& svg': {
      marginBottom: '3px',
      marginRight: 1,
      width: 16,
      height: 16,
    },
  },
  loginToAccountBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: {
      desktop: '20px',
      largeScreen: 0,
    },
  },
  loginToAccount: {
    display: 'flex',
    width: 'max-content',
    height: '30px',
    border: 'none',
    padding: 0,
    '&:hover': {
      border: 'none',
    },
    '& svg': {
      marginRight: 1,
      width: 16,
      height: 16,
    },
  },
  link: {
    textDecoration: 'none',
    marginLeft: '16px',
  },
  linkLight: {
    color: 'black.main',
  },
  linkBase: {
    color: 'black.main',
  },
  linkHome: {
    color: 'common.white',
  },
  links: {
    alignItems: 'center',
  },
  loginBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default styles;
