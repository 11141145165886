import { Box } from '@material-ui/core';
import { ChannelListMessengerProps } from 'stream-chat-react';
import { ChatChannel } from 'types/chat';
import ChannelListItemTrotter from 'components/Chat/components/ChannelListItemTrotter';

import styles from './styles';

const CustomChannelListTrotter = (props: ChannelListMessengerProps): JSX.Element => {
  const { error, loading, LoadingErrorIndicator, LoadingIndicator, loadedChannels } = props;

  if (error) {
    return <LoadingErrorIndicator type="connection" />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={styles.channelList}>
      <ChannelListItemTrotter loadedChannels={loadedChannels as ChatChannel[]} />
    </Box>
  );
};

export default CustomChannelListTrotter;
