import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiPaperType = {
  defaultProps: ComponentsProps['MuiPaper'];
  styleOverrides: ComponentsOverrides['MuiPaper'];
};

const MuiPaper: MuiPaperType = {
  styleOverrides: {},
  defaultProps: {
    elevation: 0,
  },
};

export default MuiPaper;
