import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  space: {
    cursor: 'pointer',
    padding: '10px',
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',

    '& svg': {
      width: '12px',
    },
  },
  arrowOpened: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: '12px',
  },
};

export default styles;
