import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { BankAccountInterface, BankAccountParams, LinkTokenResponse } from 'types/resources/PaymentTokens';

export default {
  plaidLinkToken(): Promise<LinkTokenResponse> {
    const path = apiRoutes.linkTokenApiSiteV1LandlordPaymentTokenPath();
    return Fetcher.post(path);
  },
  createCustomer(params: BankAccountParams): Promise<BankAccountInterface> {
    const path = apiRoutes.apiSiteV1LandlordPaymentTokenPath();
    return Fetcher.post(path, params);
  },
  getSource(): Promise<BankAccountInterface> {
    const path = apiRoutes.apiSiteV1LandlordPaymentTokenPath();
    return Fetcher.get(path);
  },
};
