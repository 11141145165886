type DocumentRoutes = {
  [key: string]: () => string;
};

const documentRoutes: DocumentRoutes = {
  privacyPolicyPath: () => 'https://help.trotflex.com/en/articles/6104567-privacy-policy',
  standardsPolicyPath: () => 'https://help.trotflex.com/en/articles/6104546-standards-policy',
  acceptableUsePolicyPath: () => 'https://help.trotflex.com/en/articles/6104501-acceptable-use-policy',
  nonDiscriminationPolicyPath: () => 'https://help.trotflex.com/en/articles/6104526-non-discrimination-policy',
  forceMajeurePolicyPath: () => 'https://help.trotflex.com/en/articles/6104518-force-majeure-policy',
  smsTermsPath: () => 'https://help.trotflex.com/en/articles/6104508-sms-terms',
  termsOfService: () => 'https://help.trotflex.com/en/articles/6104493-terms-of-service',
};

export default documentRoutes;
