export default {
  BuildingCard: {
    matchYourCriteria: 'matches your search criteria',
    matchYourCriteria_plural: 'match your search criteria',
    showSpaces: 'Show space',
    showSpaces_plural: 'Show spaces',
    hideSpaces: 'Hide space',
    hideSpaces_plural: 'Hide spaces',
    startingFrom: 'Starting from',
    pricePerMonth: '{{price}} / month',
    from: 'From',
    capacity: 'Capacity',
    spaceInfo: '{{location}} • {{square}} sqft.',
    spacesInBuilding: '{{count}} space in this building',
    spacesInBuilding_plural: '{{count}} spaces in this building',
  },
  GoogleSignIn: {
    signIn: 'Sign in with Google',
  },
  EmailConfirmation: {
    confirmEmail: 'Confirm your email',
    enterCode: 'Enter a 6 digit code, we’ve just sent you',
    didntReceiveCode: `Didn’t receive the code?`,
    resend: 'Resend',
  },
  Filter: {
    workers: 'People',
    office: 'Office type',
    date: 'Date of start',
    showAllFilters: 'Show all filters',
    clearAllFilters: 'Clear applied filters',
    hideAllFilters: 'Hide filters',
    applyFilters: 'Apply filters',
    mainFilters: 'Main filters',
    amenities: 'Amenities',
    appliedFilters: 'Applied filters:',
    pricePerMonth: 'Price per month',
    squareFeet: 'Square Feet',
    min$: 'Min, $',
    max$: 'Max, $',
    minft: 'Min, ft2',
    maxft: 'Max, ft2',
    min: 'Min',
    max: 'Max',
    withinDistance: 'within distance',
    mi: '{{count}} mi',
    elevator: 'Elevator',
    peopleLabel: 'People',
    peopleFrom: 'from',
    peopleTo: 'to',
    onlyAvailable: 'Show only available',
  },
  FinishSignUp: {
    gladToMeetYou: 'Glad to meet you!',
    moreAboutYou: 'Tell us how you found out about TROT',
    acceptedTermsAndConditions: 'I accept TROT',
    termsAndConditionsRequired: 'Terms & Conditions is required',
    promoEmails: 'I want to receive promotional emails from TROT',
  },
  PlacesAutocomplete: {
    placeholder: 'New York, United States',
  },
  ResetPassword: {
    enterEmail: 'To reset your password, please enter the email',
    resetInstruction: 'We’ve just sent you a link to reset your password. Check your email and follow instructions.',
    checkYourInbox: 'Check your inbox',
  },
  SignIn: {
    logIn: 'Log In',
    pleaseLogIn: 'In order to request a quote, please log in to your account',
    dontHaveAccount: `Don’t have an account? Create one`,
    here: 'here',
    signUp: 'Sign Up',
    welcome: 'Welcome to TROT. Log in below.',
  },
  SignUp: {
    createAccount: 'Create Account',
    description: 'Create new account to request quotes and communicate with landlords',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign In',
    areYou: 'Are you a building owner?',
    requestInvatation: 'Request an invitation',
    heading: 'Looking for flex space? Join TROT below:',
    forTrotters: 'for trotters',
    forOwners: 'for owners',
  },
  SignUpOwner: {
    looking: 'Looking for flex space for your business?',
    create: 'Create your account here.',
    request: 'request invitation',
    heading: 'Building owners, request an invitation below:',
    success:
      'Your request has been submitted and you will be contacted shortly by TROT! Thanks for your interest and looking for to TROTTING!',
  },
  SearchLine: {
    search: 'Search',
  },
  SelectSpaceState: {
    status: 'Status: ',
  },
  ImageUpload: {
    positionAndSizeYourLogo: 'Position and size your Logo',
    left: 'left',
    right: 'right',
    apply: 'apply',
    cancel: 'cancel',
  },
  PaymentMethodDetails: {
    cardBrand: 'Card Brand',
    cardBrandDescription: 'Name of the organization facilitates payment card transaction',
    lastDigits: 'Last 4 digits',
    lastDigitsDescription: 'The last four digits of the card number',
    expirationDate: 'Expiration Date',
  },
  SignDocuments: {
    goBackButton: 'Go back',
    title: 'Sign document',
    subtitle: 'To work with Trot, you need to sign the following document:',
    documentTitle: 'Trot Terms of Service',
    termsOfServiceAgree: 'I have read and agreed to the Terms of Service',
    iHaveReadAndAgreed: 'I have read and agreed to the ',
    privacyPolicy: 'Privacy Policy',
    standardsPolicy: 'Standards Policy',
    acceptableUsePolicy: 'Acceptable Use Policy',
    nonDiscriminationPolicy: 'Non-Discrimination Policy',
    forceMajeurePolicy: 'Force Majeure Policy',
    smsTerms: 'SMS Terms',
    continueButton: 'Continue',
  },
};
