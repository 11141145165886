import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    marginBottom: '5px',
    height: '367px',
    display: 'grid',
    gap: '5px',
    gridTemplateColumns: '1fr 88px',
    gridTemplateRows: 'repeat(4, 1fr)',
  },
  small: {
    gap: 'initial',
    columnGap: '5px',
    maxHeight: '170px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
  },
  mainPicture: {
    position: 'relative',
    backgroundColor: '#ededed',
    gridRow: '1 / span 4',
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  pictureSmall: {
    maxHeight: '170px',
  },
  picture: {
    backgroundColor: '#ededed',
    position: 'relative',
    '& img': {
      display: 'block',
      width: '100%',
      height: '88px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& button': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
  },
  counter: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    color: 'common.white',
    backdropFilter: 'blur(7px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  counterText: {
    fontSize: 30,
    lineHeight: '30px',
    fontWeight: 500,
  },
};

export default styles;
