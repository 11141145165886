import { FC } from 'react';
import VideoPlayIcon from 'components/VideoPlayIcon';
import Icon from 'components/Icon';
import Box from 'components/Box';
import VideoPlayer from 'components/VideoPlayer';
import Matterport from 'components/Matterport';
import ImagePlaceholder from 'components/ImagePlaceholder';
import Media from 'types/resources/Media';
import { isMatterport, isPhoto, isVideo } from 'utils/galleryAlbum';
import { getMediaPreviewUrl } from 'utils/mediaUtils';
import clsx from 'utils/clsx';

import styles from './styles';

type MediaComponentProps = {
  type: 'preview' | 'interactive';
  media: Media;
  preventPlayVideo?: boolean;
  onClick?: () => void;
  showIcon?: boolean;
  size?: 'small' | 'medium' | 'large';
  showFull?: boolean;
};

const MediaComponent: FC<MediaComponentProps> = props => {
  const {
    media,
    type,
    preventPlayVideo = false,
    onClick = () => {},
    size = 'small',
    showIcon = true,
    showFull = false,
  } = props;

  if (type === 'preview') {
    if (!media) {
      return <ImagePlaceholder name="building" />;
    }
    if (isPhoto(media) || isVideo(media)) {
      return (
        <>
          <img
            onClick={onClick}
            src={getMediaPreviewUrl(media, { full: showFull })}
            alt={media.caption}
            role="presentation"
          />
          {isVideo(media) && showIcon && <VideoPlayIcon size={size} />}
        </>
      );
    }

    if (isMatterport(media)) {
      return (
        <Box onClick={onClick} sx={styles.matterport}>
          <Icon name="matterport" />
        </Box>
      );
    }
  }

  if (type === 'interactive') {
    if (isVideo(media)) {
      return (
        <Box sx={clsx(styles.video, [[styles.pointerEventsNone, preventPlayVideo]])}>
          <VideoPlayer url={media.url} />
        </Box>
      );
    }

    if (isPhoto(media)) {
      return <img src={getMediaPreviewUrl(media, { full: showFull })} alt={media.caption} role="presentation" />;
    }

    if (isMatterport(media)) {
      return <Matterport url={media.url} />;
    }
  }

  return null;
};

export default MediaComponent;
