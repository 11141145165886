import MetropolisSemiBold from 'assets/fonts/Metropolis-SemiBold.woff2';
import MetropolisBold from 'assets/fonts/Metropolis-Bold.woff2';
import MetropolisExtraBold from 'assets/fonts/Metropolis-ExtraBold.woff2';
import MetropolisMedium from 'assets/fonts/Metropolis-Medium.woff2';
import MetropolisMediumItalic from 'assets/fonts/Metropolis-MediumItalic.woff2';
import MetropolisLight from 'assets/fonts/Metropolis-Light.woff2';
import HeeboBold from 'assets/fonts/Heebo-Bold.woff2';
import HeeboRegular from 'assets/fonts/Heebo-Regular.woff2';
import HeeboMedium from 'assets/fonts/Heebo-Medium.woff2';
import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiCssBaselineType = {
  defaultProps: ComponentsProps['MuiCssBaseline'];
  styleOverrides: ComponentsOverrides['MuiCssBaseline'];
};

const MuiCssBaseline: MuiCssBaselineType = {
  styleOverrides: `
    @font-face {
      font-family: 'Heebo';
      src: url('${HeeboRegular}');
    }

    @font-face {
      font-family: 'Heebo';
      font-weight: 500;
      src: url('${HeeboMedium}');
    }

    @font-face {
      font-family: 'Heebo';
      font-weight: 700;
      src: url('${HeeboBold}');
    }

    @font-face {
      font-family: 'Metropolis';
      font-weight: 600;
      src: url('${MetropolisSemiBold}');
    }

    @font-face {
      font-family: 'Metropolis';
      font-weight: 700;
      src: url('${MetropolisBold}');
    }

    @font-face {
      font-family: 'Metropolis';
      font-weight: 800;
      src: url('${MetropolisExtraBold}');
    }

    @font-face {
      font-family: 'Metropolis';
      font-weight: 500;
      src: url('${MetropolisMedium}');
    }

    @font-face {
      font-family: 'Metropolis';
      font-weight: 300;
      src: url('${MetropolisLight}');
    }

    @font-face {
      font-family: 'Metropolis';
      font-weight: 500;
      font-style: 'italic';
      src: url('${MetropolisMediumItalic}');
    }
  `,
  defaultProps: {},
};

export default MuiCssBaseline;
