import { FC, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSetupIntents } from 'hooks';
import Loader from 'components/Loader';
import { SetupIntent } from 'types/resources/SetupIntents';

import CardPaymentForm from './components/CardPaymentForm';

type CardPaymentSettingsType = {
  onPaymentMethodLoad: () => void;
};

const CardPaymentSettings: FC<CardPaymentSettingsType> = props => {
  const { onPaymentMethodLoad } = props;

  const stripePromise = loadStripe(Settings.stripePublishableKey);
  const { createSetupIntent, showSetupIntent } = useSetupIntents();
  const [setupIntent, setSetupIntent] = useState<SetupIntent>(null);

  const getSetupIntent = async () => {
    const showResult = await showSetupIntent();
    if (showResult.setupIntent) {
      setSetupIntent(showResult.setupIntent);
    } else {
      const createResult = await createSetupIntent();
      setSetupIntent(createResult.setupIntent);
    }
  };

  useEffect(() => {
    getSetupIntent();
  }, []);

  if (!setupIntent) return <Loader open />;

  const options = {
    clientSecret: setupIntent?.clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CardPaymentForm onPaymentMethodLoad={onPaymentMethodLoad} />
    </Elements>
  );
};

export default CardPaymentSettings;
