import i18n from 'locales/i18n';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import parseISO from 'date-fns/parseISO';
import { parseDate } from 'utils/dateTimeUtils';
import { SpaceRent } from 'types/resources/Space';
import { fullValueWithComma } from 'utils/currency';
import { SpaceRentState, SpaceRentSubscriptionState } from 'enums/resources/SpaceRent';
import { FormattedPaymentSchedule } from 'types/resources/FormattedPaymentSchedule';
import { PaymentSchedule } from 'types/resources/PaymentSchedule';

export const prettyPrepaidAt = (spaceRent: SpaceRent): string => {
  const date = parseISO(spaceRent.prepaidAt);

  // November 24, 2021 11:02 (GMT+05:00)
  return format(date, 'MMMM d, y HH:mm (OOOO)');
};

export const prettyRenewedAt = (spaceRent: SpaceRent): string => {
  const date = parseDate(spaceRent.renewedAt);

  // February 14, 2022
  return format(date, 'MMMM d, y');
};

export const prettyProjectedActivatedAt = (spaceRent: SpaceRent): string => {
  const date = parseDate(spaceRent.projectedActivatedAt);

  return format(date, 'MMMM d, y');
};

export const prettyProjectedCompletedAt = (spaceRent: SpaceRent): string => {
  const endDate = parseDate(spaceRent.projectedCompletedAt);

  return format(endDate, 'MMMM d, y');
};

export const totalPriceFormatted = (spaceRent: SpaceRent): string => {
  return fullValueWithComma(spaceRent.pricePerPeriod);
};
export const initialLeaseTerm = (spaceRent: SpaceRent): string => {
  // December 1, 2021 - March 1, 2022
  return `${prettyProjectedActivatedAt(spaceRent)} - ${prettyProjectedCompletedAt(spaceRent)}`;
};

export const paymentSchedulesFormatted = (spaceRent: SpaceRent): FormattedPaymentSchedule[] =>
  spaceRent?.paymentSchedules.map((paymentSchedule: PaymentSchedule): FormattedPaymentSchedule => {
    const paymentDate = parseDate(paymentSchedule.paymentDate);

    const date = format(paymentDate, 'MM/dd/yyyy');
    const amount = fullValueWithComma(paymentSchedule.price);
    const period =
      paymentSchedule.monthsCount > 1
        ? `${paymentSchedule.monthsCount}-${i18n.t('resources:spaceRent.monthlyPayment')}`
        : i18n.t('resources:spaceRent.monthlyPayment');
    return { date, amount, period };
  });

export const renewalTerm = (spaceRent: SpaceRent): string => {
  const beginDate = parseDate(spaceRent.projectedCompletedAt);
  const begin = format(beginDate, 'MMMM d, y');
  const endDate = addMonths(beginDate, 3);
  const end = format(endDate, 'MMMM d, y');

  // December 1, 2021 - March 1, 2022
  return `${begin} - ${end}`;
};

export const formattedRentalPeriod = (spaceRent: SpaceRent): string => {
  const beginDate = parseDate(spaceRent.projectedActivatedAt);
  const begin = format(beginDate, 'MM/dd/yyyy');

  const endDate = parseDate(spaceRent.projectedCompletedAt);
  const end = format(endDate, 'MM/dd/yyyy');

  return `${begin} - ${end}`;
};

export const orderNumber = (spaceRent: SpaceRent): string => {
  return spaceRent.id.toString().padStart(6, '0');
};

export const isSubscriptionActive = (spaceRent: SpaceRent): boolean => {
  return spaceRent.subscriptionState === SpaceRentSubscriptionState.subscriptionActive;
};

export const isSubscriptionInactive = (spaceRent: SpaceRent): boolean => {
  return spaceRent.subscriptionState === SpaceRentSubscriptionState.subscriptionInactive;
};

export const getWaitingForApprovalSpaceRent = (spaceRents: SpaceRent[]): SpaceRent | null => {
  return spaceRents.find(spaceRent => spaceRent.state === SpaceRentState.waitingForApproval) || null;
};

export const state = (spaceRent: SpaceRent): string => {
  const statePresentation = SpaceRentState[spaceRent.state];

  return i18n.t(`resources:spaceRent.states.${statePresentation}`).toUpperCase();
};

type SpaceRentStatePredicates = {
  isWaitingForApprovalSpaceRent: boolean;
  isApprovedSpaceRent: boolean;
  isActiveSpaceRent: boolean;
};

export const getSpaceRentState = (spaceRent: SpaceRent): SpaceRentStatePredicates => {
  const isWaitingForApprovalSpaceRent = spaceRent.state === SpaceRentState.waitingForApproval;
  const isApprovedSpaceRent = spaceRent.state === SpaceRentState.approved;
  const isActiveSpaceRent = spaceRent.state === SpaceRentState.active;

  return {
    isWaitingForApprovalSpaceRent,
    isApprovedSpaceRent,
    isActiveSpaceRent,
  };
};
