export enum LandlordState {
  initial = 'initial',
  verified = 'verified',
  unverified = 'unverified',
  archived = 'archived',
}

export enum LandlordStatePresentation {
  initial = 'initial',
  verified = 'verified',
  unverified = 'unverified',
  archived = 'archived',
}

export enum LandlordAction {
  verify = 'verify',
  unverify = 'unverify',
  archive = 'archive',
}
