export enum UserType {
  trotter = 'Trotter',
  superAdmin = 'SuperAdmin',
  admin = 'Admin',
  guest = 'Guest',
}

export enum UserState {
  initial = 'initial',
  active = 'active',
  disabled = 'disabled',
}

export enum UserStatePresentation {
  initial = 'initial',
  active = 'active',
  disabled = 'disabled',
}

export enum UserAction {
  activate = 'activate',
  disable = 'disable',
}
