import { FC, useState } from 'react';
import { Button, IconButton, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { isEmpty } from 'ramda';
import clsx from 'utils/clsx';

import styles from './styles';

type SearchLineProps = {
  onSearch: (searchValue: string) => void;
};

const SearchLine: FC<SearchLineProps> = props => {
  const { t } = useTranslation('components');
  const { onSearch } = props;
  const [value, setValue] = useState<string>('');
  const [isSearchLineOpen, setSearchLineOpen] = useState<boolean>(false);
  const toggleSearchLine = () => setSearchLineOpen(!isSearchLineOpen);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchValue: { value: string };
    };
    const { value: text } = target.searchValue;
    onSearch(text);
  };

  const handleClearSearchClick = () => {
    setValue('');
    onSearch('');
  };

  const loupeStyles = clsx(styles.loupe, [[styles.loupeOpened, isSearchLineOpen]]);

  return (
    <Box sx={styles.search}>
      {isSearchLineOpen ? (
        <form onSubmit={handleSubmit}>
          <IconButton onClick={toggleSearchLine} sx={loupeStyles}>
            <Icon name="loupe" />
          </IconButton>
          <TextField
            onChange={handleChange}
            name="searchValue"
            placeholder={t('SearchLine.search')}
            sx={styles.inputField}
            variant="standard"
            value={value}
            InputProps={{ disableUnderline: true }}
            autoFocus
          />
          {!isEmpty(value) && (
            <Button sx={styles.closeButton} onClick={handleClearSearchClick}>
              <Icon name="close" />
            </Button>
          )}
          <Button sx={styles.searchButton} type="submit">
            <Icon name="vector" />
          </Button>
        </form>
      ) : (
        <IconButton onClick={toggleSearchLine} sx={loupeStyles}>
          <Icon name="loupe" />
        </IconButton>
      )}
    </Box>
  );
};

export default SearchLine;
