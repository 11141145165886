import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiOutlinedInputType = {
  defaultProps: ComponentsProps['MuiOutlinedInput'];
  styleOverrides: ComponentsOverrides['MuiOutlinedInput'];
};

const MuiOutlinedInput: MuiOutlinedInputType = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        fontSize: 15,
        height: 50,
        background: '#fff',
        paddingLeft: 24,
        paddingRight: 24,
        '& fieldset': {
          border: '1px solid #DADEE5',
          borderRadius: 4,
        },
        [theme.breakpoints.up('desktop')]: {
          height: 60,
        },
      };
    },
    multiline: {
      height: 'auto !important',
    },
    input: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    inputMultiline: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    sizeSmall: {
      height: '40px !important',
      paddingLeft: 0,
      paddingRight: 0,
      '& .MuiInputBase-input': {
        height: '40px !important',
      },
    },
  },
  defaultProps: {},
};

export default MuiOutlinedInput;
