export enum ListingMediaTag {
  interior = 'interior',
  commonArea = 'commonArea',
  floorPlans = 'floor',
  matterport = 'matterport',
}

export enum SpaceState {
  initial = 'initial',
  waitingForApproval = 'waiting_for_approval',
  needsRevision = 'needs_revision',
  rentRequested = 'rent_requested',
  rented = 'rented',
  active = 'active',
  disabled = 'disabled',
  archived = 'archived',
  draft = 'draft',
}

export enum SpaceStatePresentation {
  initial = 'initial',
  waiting_for_approval = 'waitingForApproval',
  needs_revision = 'needsRevision',
  rent_requested = 'rentRequested',
  rented = 'rented',
  active = 'active',
  disabled = 'disabled',
  archived = 'archived',
  draft = 'draft',
}

export enum SpaceVerificationState {
  notVerified = 'not_verified',
  verified = 'verified',
}

export enum SpaceVerificationStateTransition {
  verify = 'verify',
  unverify = 'unverify',
}

export enum ContractSpaceState {
  inProgress = 'inProgress',
  rented = 'rented',
  saved = 'saved',
}

export enum SpaceAction {
  toReview = 'to_review',
  delete = 'delete',
  unpublish = 'unpublish',
  cancelLicense = 'cancel_license',
}

export enum AirConditioningType {
  central = 'central',
  windowUnits = 'window_units',
  none = 'none',
}

export enum HeatingType {
  forcedHotAir = 'forced_hot_air',
  steam = 'steam',
  radiant = 'radiant',
  other = 'other',
}

export enum FlooringType {
  hardwood = 'hardwood',
  concrete = 'concrete',
  carpet = 'carpet',
  vinyl = 'vinyl',
  other = 'other',
}

export enum Floor {
  partial = 'partial',
  entire = 'entire',
}

export enum ViewType {
  courtyard = 'courtyard',
  park = 'park',
  water = 'water',
  windowless = 'windowless',
  cityscape = 'cityscape',
  other = 'other',
}

export enum SpaceType {
  office = 'office',
  retail = 'retail',
}

export enum RestrictedUseType {
  accounting_office = 'accounting_office',
  architect_office = 'architect_office',
  marketing_firm = 'marketing_firm',
  advertising_agency = 'advertising_agency',
  dentist_office = 'dentist_office',
  doctors_office = 'doctors_office',
  chiropractor = 'chiropractor',
  technology_firm = 'technology_firm',
  staffing_firm = 'staffing_firm',
  event_planning_company = 'event_planning_company',
  showroom = 'showroom',
  nail_salon = 'nail_salon',
  hair_salon = 'hair_salon',
  cell_phone_or_electronic_repair = 'cell_phone_or_electronic_repair',
  general_and_executive_office = 'general_and_executive_office',
  travel_agency = 'travel_agency',
  government_agency = 'government_agency',
  spa = 'spa',
  therapy_or_psychiatry_office = 'therapy_or_psychiatry_office',
  social_club = 'social_club',
  live_or_work = 'live_or_work',
  dance_studio = 'dance_studio',
  school = 'school',
  food_or_cooking = 'food_or_cooking',
  warehouse_or_storage = 'warehouse_or_storage',
  factory_or_manufacturing = 'factory_or_manufacturing',
  other = 'other',
}

export enum InternetProviderType {
  optimum = 'optimum',
  verizon = 'verizon',
  spectrum = 'spectrum',
  rcn = 'rcn',
  other = 'other',
}
