import { FC, useState } from 'react';
import { Button, Stack, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks';
import Icon from 'components/Icon';

import styles from './styles';

type Extra = {
  onConfirm: () => Promise<void>;
};

const CancelAgreement: FC = () => {
  const { closeModal, extra } = useModals();
  const { onConfirm } = extra as Extra;
  const { t } = useTranslation(['common', 'publicPages']);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const handleConfirm = async () => {
    setIsButtonDisabled(true);
    await onConfirm();
    closeModal();
  };

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.head}>
        <Typography sx={styles.title} variant="h4">
          {t('publicPages:SpacesAndContracts.cancelAgreementConfirmationTitle')}
        </Typography>
        <Box sx={styles.closeButton} component="button" onClick={() => closeModal()}>
          <Icon name="close" />
        </Box>
      </Box>
      <Typography sx={styles.description} variant="body1">
        {t('publicPages:SpacesAndContracts.cancelAgreementConfirmationDescription')}
      </Typography>

      <Box sx={styles.actions}>
        <Button sx={styles.yesButton} onClick={() => handleConfirm()} disabled={isButtonDisabled}>
          {t('common:yes')}
        </Button>
        <Button variant="text" onClick={() => closeModal()}>
          {t('common:no')}
        </Button>
      </Box>
    </Stack>
  );
};
export default CancelAgreement;
