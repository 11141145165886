import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  itemText: {
    marginLeft: '4px',
    maxWidth: '200px',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    opacity: 0.5,
    lineHeight: '16px',
    marginTop: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default styles;
