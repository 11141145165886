import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import { Typography } from '@material-ui/core';
import Building from 'types/resources/Building';
import { BuildingPresenter } from 'presenters';
import { fullValueWithComma } from 'utils/currency';

import styles from './styles';

type PriceProps = {
  building: Building;
};

const Price: FC<PriceProps> = props => {
  const { building } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);

  return (
    <Box sx={styles.root}>
      <Typography variant="body3" color="black.darker">
        {t('components:BuildingCard.startingFrom')}
      </Typography>
      <Typography variant="body2">
        {t('components:BuildingCard.pricePerMonth', {
          price: fullValueWithComma(BuildingPresenter.minSpacePrice(building)),
        })}
      </Typography>
    </Box>
  );
};

export default Price;
