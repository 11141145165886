import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  button: {
    width: '100%',
    fontWeight: 500,
    color: 'navy.main',
    borderColor: 'gray.light',
  },
  icon: {
    marginRight: '5px',
  },
};

export default styles;
