import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiCheckboxType = {
  defaultProps: ComponentsProps['MuiCheckbox'];
  styleOverrides: ComponentsOverrides['MuiCheckbox'];
};

const MuiCheckbox: MuiCheckboxType = {
  styleOverrides: {
    root: {
      width: 24,
      height: 24,
      border: '1px solid #CED2DC',
      borderRadius: '4px',
      marginRight: 10,
    },
  },
  defaultProps: {},
};

export default MuiCheckbox;
