/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';
import { FetchStatus } from 'enums/FetchStatus';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import AuthsRepository from 'repositories/google/AuthsRepository';

export type GoogleAuthSliceStateType = {
  authorizationUrl: string;
  fetchStatus: FetchStatus;
};

export type GoogleAuthSliceActionsType = {
  resetGoogleAuth: () => void;
};

const initialState: GoogleAuthSliceStateType = {
  authorizationUrl: null,
  fetchStatus: FetchStatus.idle,
};

export const createGoogleAuthAthorizationURL = createAsyncThunk(
  'googleAuth/authorizationURL/create',
  AuthsRepository.create,
);

const slice = createSlice({
  name: 'googleAuth',
  initialState,
  reducers: {
    resetGoogleAuth() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(createGoogleAuthAthorizationURL.pending, state => {
      state.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createGoogleAuthAthorizationURL.fulfilled, (state, { payload }) => {
      state.fetchStatus = FetchStatus.fulfilled;
      state.authorizationUrl = payload.authorizationUrl;
    });
  },
});

const { actions } = slice;

export const useGoogleAuthActions = (): GoogleAuthSliceActionsType => {
  const dispatch = useAppDispatch();

  const resetGoogleAuth = () => {
    dispatch(actions.resetGoogleAuth());
  };

  return {
    resetGoogleAuth,
  };
};

export default slice.reducer;
