import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SpacePresenter } from 'presenters';
import { Typography, Button } from '@material-ui/core';
import { useSpaces } from 'hooks';
import { appRoutes } from 'routes';
import Box from 'components/Box';
import Icon from 'components/Icon';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { isNil } from 'ramda';
import { pricePerMonthFormatted, square } from 'presenters/SpacePresenter';

import MessageBox from '../MessageBox';

import styles from './styles';

type MessageWithSpaceInfoProps = {
  spaceId: ID;
  isMyMessage: boolean;
};

const MessageWithSpaceInfo: FC<MessageWithSpaceInfoProps> = props => {
  const { t } = useTranslation(['publicPages']);

  const { spaceId, isMyMessage } = props;
  const { space, building, loadSpace, isLoadSpaceFulfilled } = useSpaces();

  useEffect(() => {
    loadSpace(spaceId);
  }, []);

  if (!isLoadSpaceFulfilled) {
    return null;
  }

  const imageUrl = SpacePresenter.coverUrl(space);
  return (
    <MessageBox isMyMessage={isMyMessage} large>
      <Box sx={styles.wrapper}>
        <Box sx={styles.image}>
          {isNil(imageUrl) ? <ImagePlaceholder name="space" /> : <img src={imageUrl} alt={space.title} />}
        </Box>
        <Box sx={styles.info}>
          <Box sx={styles.title}>
            <Typography variant="h4" component="p" sx={styles.address}>
              {building.address}
            </Typography>
            <Typography variant="h4" component="p" sx={styles.spaceTitle}>
              {space.title}
            </Typography>
          </Box>
          <Typography component="p" variant="body4" sx={styles.square}>
            <Icon name="cube" />
            {square(space)}
          </Typography>
          <Typography component="p" variant="body4" sx={styles.capacity}>
            <Icon name="twoPerson" />
            {t('publicPages:BuildingSpace.teammates', { count: space.maxCapacity })}
          </Typography>
          <Button
            variant="text"
            sx={styles.button}
            component={Link}
            to={appRoutes.public.buildingSpacePath(building.id, space.id)}
          >
            {pricePerMonthFormatted(space, 'publicPages:BuildingSpace.fromPrice')}
            <Box sx={styles.arrow}>
              <Icon name="arrow" />
            </Box>
          </Button>
        </Box>
      </Box>
    </MessageBox>
  );
};

export default MessageWithSpaceInfo;
