import { apiRoutes } from 'routes';
import User, { TrotterFormSubmitData, UserResources } from 'types/resources/User';
import Fetcher from 'utils/fetcher';
import { LoadingParams } from 'utils/loadingParams';

const TrottersRepository = {
  index(params: LoadingParams): Promise<UserResources> {
    const url = apiRoutes.apiSiteV1AdminTrottersPath();
    return Fetcher.get(url, params);
  },
  update({ trotterId, trotter }: { trotterId: ID; trotter: TrotterFormSubmitData }): Promise<User> {
    const url = apiRoutes.apiSiteV1AdminTrotterPath(trotterId);
    return Fetcher.put(url, trotter);
  },
};

export default TrottersRepository;
