import { FC } from 'react';
import { Select as MuiSelect, MenuItem, SelectProps as MuiSelectProps } from '@material-ui/core';

import styles from './styles';

type RenderValue = {
  value: string;
  label?: string;
};

type SelectProps = {
  values?: RenderValue[];
};

const Select: FC<SelectProps & MuiSelectProps> = props => {
  const { values = [], label, ...rest } = props;

  const menuItems = values.map(({ value, label: itemLabel }) => {
    return (
      <MenuItem value={value} key={value}>
        {itemLabel || value}
      </MenuItem>
    );
  });

  return (
    <MuiSelect sx={styles.select} {...rest}>
      {label && (
        <MenuItem color="black.darker" value="">
          {label}
        </MenuItem>
      )}
      {menuItems}
    </MuiSelect>
  );
};

export default Select;
