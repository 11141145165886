import { SpaceType } from 'enums/resources/Space';
import { SpaceRentPresenter } from 'presenters';
import Space, { SpaceRent } from 'types/resources/Space';

export const isOfficeSpaceType = (spaceType: string): boolean => spaceType === SpaceType.office;
export const isRetailSpaceType = (spaceType: string): boolean => spaceType === SpaceType.retail;

export const getSpaceRentWithActiveSubscription = (space: Space): SpaceRent | null => {
  return space.spaceRents.find(spaceRent => {
    const { isActiveSpaceRent } = SpaceRentPresenter.getSpaceRentState(spaceRent);
    const isSubscriptionActive = SpaceRentPresenter.isSubscriptionActive(spaceRent);

    return isActiveSpaceRent && isSubscriptionActive;
  });
};
