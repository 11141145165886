import { FC, useState, useRef, useEffect } from 'react';
import { BoxProps, Box, Divider } from '@material-ui/core';
import { isNil } from 'ramda';
import Building from 'types/resources/Building';
import Space from 'types/resources/Space';
import { useScreen, useSearch } from 'hooks';
import { isSpaceInBuilding } from 'utils/buildingUtils';

import Address from './components/Address';
import SpacesMatches from './components/SpacesMatches';
import Price from './components/Price';
import ShowHideSpaces from './components/ShowHideSpaces';
import Spaces from './components/Spaces';
import Image from './components/Image';
import Class from './components/Class';
import styles from './styles';

type BuildingCardProps = {
  building: Building;
  onSpaceClick: (space: Space) => void;
  onBuildingClick: (building: Building) => void;
  selectedSpaceId: ID | null;
  onBuildingHover?: (buildingId: ID) => void;
};

const BuildingCard: FC<BuildingCardProps & BoxProps> = (props): JSX.Element => {
  const { building, onSpaceClick, onBuildingClick, selectedSpaceId, onBuildingHover, ...rest } = props;
  const { lastSelectedBuildingId, setLastSelectedBuildingId, setLastSelectedPage } = useSearch();
  const [isSpacesOpened, setIsSpacesOpened] = useState<boolean>(
    lastSelectedBuildingId === building.id || isSpaceInBuilding(building, selectedSpaceId),
  );
  const buildingCardRef = useRef(null);

  useEffect(() => {
    if (buildingCardRef.current && lastSelectedBuildingId === building.id) {
      buildingCardRef.current.scrollIntoView();
      setLastSelectedBuildingId(null);
      setLastSelectedPage(1);
    }
  }, [buildingCardRef]);

  useEffect(() => {
    setIsSpacesOpened(isSpaceInBuilding(building, selectedSpaceId));
  }, [selectedSpaceId]);

  const { isFablet } = useScreen();

  const handleSpacesToggle = (): void => {
    setIsSpacesOpened(!isSpacesOpened);
  };

  const spacesCount = building.spaces.length;

  const { buildingClass } = building;

  return (
    <Box
      onMouseEnter={() => onBuildingHover(building.id)}
      onMouseLeave={() => onBuildingHover(null)}
      sx={styles.base}
      {...rest}
    >
      <Box sx={styles.hiddenBox} ref={buildingCardRef} />
      <Box sx={styles.card}>
        <Image building={building} onClick={onBuildingClick} />
        <Box sx={styles.content}>
          <Box sx={styles.addressAndPrice}>
            <Address building={building} />
            <Price building={building} />
          </Box>
          {!isNil(buildingClass) && <Class building={building} />}
          {isFablet && <Divider sx={styles.divider} />}
          <Box sx={styles.spacesMathes}>
            <SpacesMatches spacesCount={spacesCount} />
            <ShowHideSpaces
              isSpacesOpened={isSpacesOpened}
              onSpacesToggle={handleSpacesToggle}
              spacesCount={spacesCount}
            />
          </Box>
        </Box>
      </Box>
      <Spaces
        building={building}
        selectedSpaceId={selectedSpaceId}
        onSpaceClick={onSpaceClick}
        isSpacesOpened={isSpacesOpened}
      />
    </Box>
  );
};

export default BuildingCard;
