import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  wrapper: {
    display: 'flex',
    width: '100%',
  },
  title: {
    display: 'flex',
    marginBottom: '10px',
    flexDirection: 'column',
  },
  address: {
    marginRight: '7px',
    marginBottom: {
      mobile: '5px',
      tablet: 0,
    },
    fontSize: {
      mobile: '15px',
      tablet: '16px',
    },
  },
  spaceTitle: {
    width: '100%',
    fontSize: {
      mobile: '15px',
      tablet: '16px',
    },
    opacity: 0.5,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '20px',
    paddingLeft: 0,
    paddingBottom: 0,
    width: '100%',
  },
  square: {
    opacity: 0.5,
    marginBottom: '3px',
    alignItems: 'center',
    display: 'flex',
    '& svg': {
      width: '14px',
      height: '14px',
      marginRight: '7px',
    },
  },
  capacity: {
    opacity: 0.5,
    marginBottom: '14px',
    alignItems: 'center',
    display: 'flex',
    lineHeight: {
      mobile: '15px',
    },
    '& svg': {
      width: '14px',
      height: '14px',
      marginRight: '7px',
    },
  },
  button: {
    width: 'fit-content',
    height: '20px',
    justifyContent: 'flex-start',
    padding: 0,
    fontSize: {
      mobile: '12px',
      tablet: '14px',
    },
  },
  arrow: {
    marginLeft: '10px',
    marginBottom: '3px',
    transform: 'rotate(-180deg)',
    '& svg': {
      width: {
        mobile: '1px',
        tablet: '16px',
      },
      height: {
        mobile: '10px',
        tablet: '16px',
      },
    },
  },
  image: {
    maxWidth: '120px',
    maxHeight: '150px',
    height: 'auto',
    overflow: 'hidden',
    marginRight: '25px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(3)',
    },
  },
};

export default styles;
