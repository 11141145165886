import { FC } from 'react';
import { Typography, ButtonBase, Box, Stack } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import { SearchFormData } from 'forms/public/searchForm';
import Amenity from 'types/resources/Amenity';
import { prettyFilter, prettyElevatorFilter, prettyOnlyAvailableFilter } from 'utils/filterUtils';

import styles from './styles';

type AppliedFiltersProps = {
  hasChangedPreApplyFilters: () => boolean;
  isAllFiltersOpened: boolean;
  filters: SearchFormData;
  onAmenityDelete: (amenity: Amenity) => () => void;
  onFilterRemove: (filter: 'pricePerMonth' | 'square' | 'elevator' | 'onlyAvailable') => () => void;
  onFiltersReset: () => void;
};

const AppliedFilters: FC<AppliedFiltersProps> = props => {
  const { hasChangedPreApplyFilters, isAllFiltersOpened, filters, onAmenityDelete, onFilterRemove, onFiltersReset } =
    props;

  const { t } = useTranslation(['components', 'common']);

  const noChanges = !hasChangedPreApplyFilters();
  if (isAllFiltersOpened || noChanges) {
    return null;
  }

  const prettyPrice = prettyFilter('pricePerMonth', filters);
  const prettySquare = prettyFilter('square', filters);
  const prettyElevator = prettyElevatorFilter(filters.elevator);
  const prettyOnlyAvailable = prettyOnlyAvailableFilter(filters.onlyAvailable);
  const prettyAmenities = filters.amenities.map(amenity => (
    <Badge
      sx={styles.badge}
      onDelete={onAmenityDelete(amenity)}
      key={amenity.id}
      label={<Typography variant="bold3">{amenity.name}</Typography>}
    />
  ));

  return (
    <Box sx={styles.root}>
      <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap" sx={styles.badges}>
        <Typography sx={styles.title} variant="body3">
          {t('components:Filter.appliedFilters')}
        </Typography>
        {prettyPrice && (
          <Badge
            sx={styles.badge}
            onDelete={onFilterRemove('pricePerMonth')}
            label={<Typography variant="bold3">{prettyPrice}</Typography>}
          />
        )}
        {prettySquare && (
          <Badge
            sx={styles.badge}
            onDelete={onFilterRemove('square')}
            label={<Typography variant="bold3">{prettySquare}</Typography>}
          />
        )}
        {prettyElevator && (
          <Badge
            sx={styles.badge}
            onDelete={onFilterRemove('elevator')}
            label={<Typography variant="bold3">{prettyElevator}</Typography>}
          />
        )}
        {prettyOnlyAvailable && (
          <Badge
            sx={styles.badge}
            onDelete={onFilterRemove('onlyAvailable')}
            label={<Typography variant="bold3">{prettyOnlyAvailable}</Typography>}
          />
        )}
        {prettyAmenities}
      </Stack>

      <ButtonBase sx={styles.clearAllFilters} onClick={onFiltersReset}>
        <Icon name="close" />
        <Typography variant="body3">{t('components:Filter.clearAllFilters')}</Typography>
      </ButtonBase>
    </Box>
  );
};

export default AppliedFilters;
