/* eslint-disable no-param-reassign */
import SpacesRepository from 'repositories/landlord/draft/SpacesRepository';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { FetchStatus } from 'enums/FetchStatus';

export type SpacesSliceStateType = {
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const createDraftSpace = createAsyncThunk('landlords/draft/spaces/create', SpacesRepository.create);
export const updateDraftSpace = createAsyncThunk('landlords/draft/spaces/update', SpacesRepository.update);

const initialState: SpacesSliceStateType = {
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords/draft/spaces',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createDraftSpace.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createDraftSpace.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createDraftSpace.rejected, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = payload;
    });
    builder.addCase(updateDraftSpace.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateDraftSpace.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateDraftSpace.rejected, state => {
      state.update.fetchStatus = FetchStatus.failed;
    });
  },
});

export default slice.reducer;
