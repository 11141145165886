import { UppyFile, FileProgress } from '@uppy/core';
import { useUpload } from 'hooks';
import { useState, useRef, useEffect } from 'react';
import Media from 'types/resources/Media';
import Gallery from 'types/resources/Gallery';
import {
  GALLERY_UPLOAD_CONFIG,
  isNewMedia,
  removeFromList,
  reorderMedias,
  sortMediasByOrder,
  findPreviewByMediaId,
  isVideoFormat,
  getLastAvailableOrderForTag,
} from 'utils/galleryAlbum';
import { removeFromDestroyableList } from 'utils/storeUtils';
import { GalleryFileState } from 'enums/GalleryFileState';
import { MediaType } from 'enums/MediaType';

const useGallery = (existedMedias: Media[]): Gallery => {
  const [medias, setMedias] = useState<Media[]>(existedMedias);
  const previews = useRef([]);

  useEffect(() => {
    setMedias(prevMedias =>
      prevMedias.map(media => {
        const preview = findPreviewByMediaId(previews.current, media.id);
        return {
          ...media,
          url: preview || media.url,
        };
      }),
    );
  }, [previews.current]);

  const { uppy, fileData, removeFile } = useUpload({
    config: GALLERY_UPLOAD_CONFIG.config,
    thumbnails: GALLERY_UPLOAD_CONFIG.thumbnails,
    // Fired each time an individual file upload progress is available
    onProgress: (file: UppyFile, progress: FileProgress) => {
      const progressInPercent = Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100);

      setMedias(prevMedias => {
        const mediaInProgress = {
          ...file,
          percent: progressInPercent,
          state: GalleryFileState.inProgress,
          tag: file.source,
          isNew: true,
        };

        const currentMedia = prevMedias.find(media => media.id === file.id);

        if (currentMedia) {
          return prevMedias.map(media => {
            if (media.id === file.id) {
              // currently uploaded medias with id:string
              return mediaInProgress;
            }
            // existed medias with id:number
            return media;
          });
        }

        return [...prevMedias, mediaInProgress];
      });
    },
    // Fired each time a single upload is completed.
    onFinishUpload: (file: UppyFile, response) => {
      const mediaHasVideoFormat = isVideoFormat(file.name);

      setMedias(prevMedias => {
        const convertedFileToMedia = {
          id: file.id,
          caption: '',
          url: mediaHasVideoFormat ? URL.createObjectURL(file.data) : findPreviewByMediaId(previews.current, file.id),
          tag: file.source,
          file: fileData({ ...file, response }),
          isNew: true,
          state: GalleryFileState.complete,
          type: mediaHasVideoFormat ? MediaType.video : MediaType.photo,
        };

        return prevMedias.map(media => {
          if (media.id === file.id) {
            return {
              ...convertedFileToMedia,
              // uploaded media with no order
              order: getLastAvailableOrderForTag(
                prevMedias.filter(m => m.state !== GalleryFileState.inProgress),
                file.source,
              ),
            };
          }
          // existed media that already has an order
          return media;
        });
      });
    },
    onThumbnailGenerated: (thumbnails: { id: ID; preview: string }[]) => {
      previews.current = thumbnails;
    },
  });

  const removeMedia = (media: Media) => {
    setMedias(prevMedias => {
      const newMedias = isNewMedia(media)
        ? removeFromList(prevMedias, media)
        : removeFromDestroyableList(prevMedias, media);
      if (isNewMedia(media)) {
        removeFile(media.id as string);
      }
      const reorderedMedias = reorderMedias(newMedias, media.tag);
      return reorderedMedias;
    });
  };

  const changeSort = (newOrderedMedias: Media[]) => {
    setMedias(prevMedias => {
      const newMedias = [...prevMedias];
      newOrderedMedias.forEach((newOrderedMedia, index) => {
        const mediaIndex = newMedias.findIndex(newMedia => newMedia.id === newOrderedMedia.id);
        newMedias[mediaIndex] = { ...newMedias[mediaIndex], order: index };
      });
      return sortMediasByOrder(newMedias);
    });
  };

  const changeCaption = (mediaId: ID, caption: string) => {
    setMedias(prevMedias => {
      const newMedias = [...prevMedias];
      const mediaIndex = newMedias.findIndex(newMedia => String(newMedia.id) === String(mediaId));
      newMedias[mediaIndex] = { ...newMedias[mediaIndex], caption };
      return newMedias;
    });
  };

  return {
    uppy,
    medias,
    changeSort,
    changeCaption,
    removeMedia,
  };
};

export default useGallery;
