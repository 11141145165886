import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider, Stack } from '@material-ui/core';
import Icon from 'components/Icon';
import SocialLinks from 'components/SocialLinks';
import Container from 'components/Container';
import { appRoutes, externalRoutes, marketingRoutes } from 'routes';
import { NewYork, makeSearchPathWithCoordinates, NewJersey } from 'utils/locations';
import { useScreen } from 'hooks';
import { Link } from 'react-router-dom';
import clsx from 'utils/clsx';
import { MenuItemTypeWithoutShow } from 'types/utils';
import { getNewBlankAttributes } from 'utils/linkUtils';

import styles from './styles';

type ColumnType = {
  title: string;
  titleWithLink?: boolean;
  href?: string;
  menu: MenuItemTypeWithoutShow[];
  withDivider?: boolean;
};

const getColumnLabelAttributes = (column: ColumnType) => {
  return column.titleWithLink ? { href: column.href, component: 'a' } : null;
};

const Footer: FC = () => {
  const { t } = useTranslation(['publicLayouts', 'resources', 'layouts', 'common']);
  const { lessThanTablet } = useScreen();

  const thePowerOfFlex: ColumnType = {
    title: t('publicLayouts:ThePowerOfFlex'),
    titleWithLink: true,
    href: marketingRoutes.rootPath(),
    menu: [],
  };

  const forOwners: ColumnType = {
    title: t('publicLayouts:ForOwners.title'),
    menu: [
      {
        label: t('publicLayouts:WhyTrot'),
        href: marketingRoutes.whyTrotForOwnersPath(),
      },
      {
        label: t('publicLayouts:ForOwners.flexSpaceRevolution'),
        href: marketingRoutes.spaceRevolutionPath(),
      },
      // {
      //   label: t('publicLayouts:ExploreResources'),
      //   href: marketingRoutes.exploreResourcesForOwnersPath(),
      // },
      {
        label: t('publicLayouts:HowItWorks'),
        href: marketingRoutes.howItWorksForOwnersPath(),
      },
      {
        label: t('publicLayouts:scheduleDemo'),
        href: marketingRoutes.scheduleDemoPath(),
      },
    ],
  };

  const forTrotters: ColumnType = {
    title: t('publicLayouts:ForTrotters.title'),
    withDivider: true,
    menu: [
      {
        label: t('publicLayouts:WhyTrot'),
        href: marketingRoutes.whyTrotForTrottersPath(),
      },
      // {
      //   label: t('publicLayouts:ExploreResources'),
      //   href: marketingRoutes.exploreResourcesForTrottersPath(),
      // },
      {
        label: t('publicLayouts:HowItWorks'),
        href: marketingRoutes.howItWorksForTrottersPath(),
      },
    ],
  };

  const aboutTrot: ColumnType = {
    title: t('publicLayouts:AboutTrot.title'),
    withDivider: true,
    menu: [
      {
        label: t('publicLayouts:AboutTrot.whoWeAre'),
        href: marketingRoutes.rootPath(),
      },
      {
        label: t('publicLayouts:AboutTrot.ourServices'),
        href: marketingRoutes.ourServicesPath(),
      },
      {
        label: t('publicLayouts:AboutTrot.faqS'),
        href: externalRoutes.intercomHelp(),
        isNewTabOpen: true,
      },
      {
        label: t('publicLayouts:AboutTrot.meetTheTeam'),
        href: marketingRoutes.meetTheTeamPath(),
      },
    ],
  };

  const contactUs: ColumnType = {
    title: t('publicLayouts:ContactUs.title'),
    titleWithLink: true,
    withDivider: true,
    href: marketingRoutes.contactUsPath(),
    menu: [
      {
        label: t('publicLayouts:ContactUs.helpCenter'),
        href: externalRoutes.intercomHelp(),
        isNewTabOpen: true,
      },
      {
        label: t('publicLayouts:ContactUs.contactSupport'),
        href: externalRoutes.intercomHelp(),
        isNewTabOpen: true,
      },
      {
        label: t('common:trotPhoneNumberLabel'),
        href: `tel:${Settings.trotPhoneNumber}`,
        isNewTabOpen: true,
      },
    ],
  };

  const columns = [thePowerOfFlex, forOwners, forTrotters, aboutTrot, contactUs];

  const renderColumn = (column: ColumnType) => (
    <Box sx={clsx(styles.column, [[styles.withDivider, column.withDivider && !lessThanTablet]])}>
      <Typography {...getColumnLabelAttributes(column)} sx={styles.columnTitle} variant="bold2">
        {column.title}
      </Typography>
      {column.menu.map(item => (
        <Typography
          key={item.label}
          sx={styles.link}
          component="a"
          href={item.href}
          {...getNewBlankAttributes(item)}
          variant="subtitle7"
        >
          {item.label}
        </Typography>
      ))}
    </Box>
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.towns}>
        <Icon name="towns" />
        <Icon name="towns" />
        <Icon name="towns" />
        <Icon name="towns" />
        <Icon name="towns" />
      </Box>
      <Container>
        <>
          <Box sx={styles.nav}>
            <Box sx={styles.column}>
              <Typography sx={styles.columnTitle} variant="bold2">
                {t('publicLayouts:Locations.title')}
              </Typography>
              <Typography
                sx={styles.link}
                to={makeSearchPathWithCoordinates(NewYork)}
                component={Link}
                variant="subtitle7"
              >
                {t('publicLayouts:Locations.NY')}
              </Typography>
              <Typography
                sx={styles.link}
                to={makeSearchPathWithCoordinates(NewJersey)}
                component={Link}
                variant="subtitle7"
              >
                {t('publicLayouts:Locations.NJ')}
              </Typography>
            </Box>
            <Box sx={styles.emptyColumn} />
            {columns.map(column => renderColumn(column))}
          </Box>
          <Box sx={styles.divider}>
            <Divider />
          </Box>
          <Box sx={styles.copyright}>
            <Stack sx={styles.copyrightAndText} direction={{ mobile: 'column', tablet: 'row' }} spacing={3}>
              <Typography variant="body3">{t('layouts:trotInc', { year: new Date().getFullYear() })}</Typography>
              <Typography
                variant="body3"
                sx={styles.privacyLink}
                target="_blank"
                to={appRoutes.public.privacyPolicyPdfPath()}
                component={Link}
              >
                {t('layouts:privacy')}
              </Typography>
              <Typography
                sx={styles.privacyLink}
                target="_blank"
                to={appRoutes.public.termsOfServicePdfPath()}
                component={Link}
                variant="body3"
              >
                {t('common:termsAndConditions')}
              </Typography>
            </Stack>
            <Box sx={styles.social}>
              <SocialLinks />
            </Box>
          </Box>
        </>
      </Container>
    </Box>
  );
};

export default Footer;
