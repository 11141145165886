import { FC } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useScreen, useModals } from 'hooks';
import { ModalType } from 'enums/ModalType';

import styles from './styles';
import DeclineWithReason from './components/DeclineWithReason';
import TrotRules from './components/TrotRules';
import BuildingQRCode from './components/BuildingQRCode';
import CancelAgreement from './components/CancelAgreement';
import LandlordBookingRequest from './components/LandlordBookingRequest';
import LandlordSpaceRentCancel from './components/LandlordSpaceRentCancel';
import LandlordSpaceRentCancelFinish from './components/LandlordSpaceRentCancelFinish';
import LandlordSpaceRentCancelSuggestSpaces from './components/LandlordSpaceRentCancelSuggestSpaces';
import LandlordSpaceRentCancelSuggestSpacesFinish from './components/LandlordSpaceRentCancelSuggestSpacesFinish';
import ShareSpace from './components/ShareSpace';
import BookingRequestDeclineAndSuggestSpaces from './components/BookingRequestDeclineAndSuggestSpaces';

type MappingType = {
  [key in ModalType]: FC;
};

const mapping: MappingType = {
  declineWithReason: DeclineWithReason,
  trotRules: TrotRules,
  buildingQRCode: BuildingQRCode,
  cancelAgreement: CancelAgreement,
  landlordBookingRequest: LandlordBookingRequest,
  shareSpace: ShareSpace,
  landlordSpaceRentCancel: LandlordSpaceRentCancel,
  landlordSpaceRentCancelFinish: LandlordSpaceRentCancelFinish,
  landlordSpaceRentCancelSuggestSpaces: LandlordSpaceRentCancelSuggestSpaces,
  landlordSpaceRentCancelSuggestSpacesFinish: LandlordSpaceRentCancelSuggestSpacesFinish,
  bookingRequestDeclineAndSuggestSpaces: BookingRequestDeclineAndSuggestSpaces,
};

const Modal: FC = (): JSX.Element => {
  const { isOpened, closeModal, type } = useModals();
  const { isMobile } = useScreen();

  const handleClose = () => {
    closeModal();
  };

  const Component = mapping[type];

  return (
    <Dialog open={isOpened} onClose={handleClose} fullScreen={isMobile} PaperProps={{ sx: styles.paper }}>
      <DialogContent sx={styles.content}>{Component && <Component />}</DialogContent>
    </Dialog>
  );
};

export default Modal;
