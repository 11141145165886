import { ComponentsOverrides, ComponentsProps, LinkProps } from '@material-ui/core';
import { CSSInterpolation } from '@material-ui/system';

type MuiLinkType = {
  defaultProps: ComponentsProps['MuiLink'];
  styleOverrides: ComponentsOverrides['MuiLink'];
  variants: { props: Partial<LinkProps<'a', Record<string, unknown>>>; style: CSSInterpolation }[];
};

// TODO: add "black" variant to <MUILink variant="..."/>  autocomplete
const MuiLink: MuiLinkType = {
  styleOverrides: {
    root: {
      color: '#416AF1 ',
    },
  },
  defaultProps: {
    underline: 'always',
  },
  variants: [
    {
      props: { variant: 'tableLink' },
      style: {
        color: '#050C1D',
        textDecorationColor: '#050C1D',
      },
    },
  ],
};

export default MuiLink;
