import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  preview: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F6FA',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  text: {
    textAlign: 'center',
    maxWidth: '250px',
  },
};

export default styles;
