import { FC } from 'react';
import { useChatContext } from 'stream-chat-react';
import Icon from 'components/Icon';
import Box from 'components/Box';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { CHAT_TROT_TYPE } from 'utils/chat';
import ChannelItemText from 'components/Chat/components/ChannelItemText';
import ChannelItemUnread from 'components/Chat/components/ChannelItemUnread';
import ChannelItemCard from 'components/Chat/components/ChannelItemCard';
import { ChatChannel } from 'types/chat';

import styles from './styles';

type ChannelListItemTrotterProps = {
  loadedChannels: ChatChannel[];
};

const ChannelListItemTrotter: FC<ChannelListItemTrotterProps> = props => {
  const { loadedChannels } = props;
  const { setActiveChannel, channel: activeChannel } = useChatContext();

  return (
    <Box>
      {loadedChannels.map(channel => {
        const title = channel?.data?.building?.name;
        const subtitle = channel?.data?.space?.name;
        const img = channel?.data?.space?.image || channel?.data?.building?.image;
        const unreadMessages = channel.countUnread();
        const isActive = activeChannel?.cid === channel.cid;

        if (channel.type !== CHAT_TROT_TYPE) {
          return null;
        }

        const handleCardClick = () => {
          setActiveChannel(channel);
        };

        return (
          <ChannelItemCard key={channel.id} isActive={isActive} handleCardClick={handleCardClick}>
            <Box sx={styles.card}>
              <Box sx={styles.image}>
                {img ? <img src={img} alt={subtitle} /> : <ImagePlaceholder name="building" />}
              </Box>
              <ChannelItemText title={title} subtitle={subtitle} />
              <ChannelItemUnread unreadMessages={unreadMessages} />
            </Box>
            <Box>
              <Icon sx={styles.icon} name="messengerRightArrow" />
            </Box>
          </ChannelItemCard>
        );
      })}
    </Box>
  );
};

export default ChannelListItemTrotter;
