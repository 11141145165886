import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  toggleOpenAllFilters: {
    minWidth: '135px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: {
      mobile: '16px 0',
      desktop: '16px 0',
    },
    marginLeft: {
      mobile: 'initial',
      desktop: 'auto',
    },
  },
  showAllFiltersText: {
    width: '135px',
  },
  filtersExpandIcon: {
    display: {
      desktop: 'none',
    },
    '& svg': {
      color: 'black.main',
    },
  },
  filtersMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '16px',
    '& svg': {
      color: 'brightBlue.main',
    },
  },
};

export default styles;
