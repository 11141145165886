import { Theme } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

declare module '@material-ui/core/styles' {
  interface TypographyVariants {
    mainPageHeading1: React.CSSProperties;
    heading1: React.CSSProperties;
    heading2: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    body8: React.CSSProperties;
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    subtitle5: React.CSSProperties;
    subtitle6: React.CSSProperties;
    subtitle7: React.CSSProperties;
    subtitle8: React.CSSProperties;
    bold1: React.CSSProperties;
    bold2: React.CSSProperties;
    bold3: React.CSSProperties;
    bold4: React.CSSProperties;
    bold5: React.CSSProperties;
    bold6: React.CSSProperties;
    bold7: React.CSSProperties;
    bold8: React.CSSProperties;
    mainPageSmall: React.CSSProperties;
    mainPageMedium: React.CSSProperties;
    tabsTextBold: React.CSSProperties;
    tabsTextItalic: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mainPageHeading1: React.CSSProperties;
    heading1: React.CSSProperties;
    heading2: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    body7?: React.CSSProperties;
    body8?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    subtitle5?: React.CSSProperties;
    subtitle6?: React.CSSProperties;
    subtitle7?: React.CSSProperties;
    subtitle8?: React.CSSProperties;
    bold1?: React.CSSProperties;
    bold2?: React.CSSProperties;
    bold3?: React.CSSProperties;
    bold4?: React.CSSProperties;
    bold5?: React.CSSProperties;
    bold6?: React.CSSProperties;
    bold7?: React.CSSProperties;
    bold8?: React.CSSProperties;
    mainPageSmall: React.CSSProperties;
    mainPageMedium: React.CSSProperties;
    tabsTextBold: React.CSSProperties;
    tabsTextItalic: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@material-ui/core/Typography' {
  interface TypographyPropsVariantOverrides {
    mainPageHeading1: true;
    heading1: true;
    heading2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    body8: true;
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    subtitle6: true;
    subtitle7: true;
    subtitle8: true;
    bold1: true;
    bold2: true;
    bold3: true;
    bold4: true;
    bold5: true;
    bold6: true;
    bold7: true;
    bold8: true;
    mainPageSmall: true;
    mainPageMedium: true;
    h1: false;
    tabsTextBold: true;
    tabsTextItalic: true;
  }
}

const makeTypography = (theme: Theme): TypographyOptions => {
  // const fablet = theme.breakpoints.up('fablet');
  const tablet = theme.breakpoints.up('tablet');
  const desktop = theme.breakpoints.up('desktop');
  // const largeScreen = theme.breakpoints.up('largeScreen');

  return {
    fontFamily: 'Metropolis',
    mainPageHeading1: {
      fontFamily: 'Metropolis',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '38px',

      [tablet]: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 48,
        lineHeight: '58px',
      },
    },
    heading1: {
      fontFamily: 'Metropolis',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '38.4px',

      [desktop]: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 40,
        lineHeight: '52px',
      },
    },
    heading2: {
      fontFamily: 'Metropolis',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '24.2px',

      [tablet]: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 32,
        lineHeight: '32px',
      },

      [desktop]: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 30,
        lineHeight: '30px',
      },
    },
    mainPageSmall: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '22px',

      [tablet]: {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 14,
        lineHeight: '22.4px',
      },

      [desktop]: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '22.4px',
      },
    },
    h2: {
      fontFamily: 'Metropolis',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '26px',

      [tablet]: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 32,
        lineHeight: '32px',
      },
    },
    mainPageMedium: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',

      [desktop]: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '26px',
      },

      [tablet]: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '20px',
      },
    },
    h3: {
      fontFamily: 'Metropolis',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '20px',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '18px',
    },
    h5: {
      fontFamily: 'Metropolis',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '12px',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '16px',
    },
    body1: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '160%',
    },
    body2: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
    },
    body3: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '15px',
    },
    body4: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '22.4px',
    },
    body5: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '100%',
    },
    body6: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '20px',
    },
    body7: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 24,
      lineHeight: '40px',
    },
    body8: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '25px',
    },
    bold1: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '100%',
    },
    bold2: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '100%',
    },
    bold3: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '100%',
    },
    bold4: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '100%',
    },
    bold5: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '100%',
    },
    bold6: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 9,
      lineHeight: '9px',
    },
    bold7: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 11,
      lineHeight: '11px',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    bold8: {
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: 32,
      lineHeight: '32px',
    },
    subtitle1: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '25.6px',
    },
    subtitle2: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '18px',
    },
    subtitle3: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22.4px',
    },
    subtitle4: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '100%',
    },
    subtitle5: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '100%',
    },
    subtitle6: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '18px',
    },
    subtitle7: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '100%',
    },
    subtitle8: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 24,
      lineHeight: '40px',
    },
    tabsTextBold: {
      fontFamily: 'Heebo',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '15px',
      letterSpacing: '0.1em',
    },
    tabsTextItalic: {
      fontFamily: 'Metropolis',
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '18.2px',
    },
  };
};

export { makeTypography };
