import { concat } from 'ramda';
import qs from 'qs';

export interface Routes {
  [key: string]: (...args: ID[]) => string;
}

export const makeRoutes = <T extends Routes>(routes: T, basepath = '/'): T => {
  return Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: (...params: ID[]) => concat(basepath, routes[key](...params)).replace(/\/\//, '/'),
    }),
    {},
  ) as T;
};

export const makePathWithQueryString = (basePath: string, params: { [key: string]: string | number }): string => {
  return `${basePath}${qs.stringify(params, { skipNulls: true, addQueryPrefix: true })}`;
};
