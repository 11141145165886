import { SxStyles } from 'types/theme';

const statusBadge = {
  display: 'inline-flex',
  padding: '6.5px 8.5px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'transparent',
  textAlign: 'center',
};

const styles: SxStyles = {
  state_waiting_for_approval: {
    ...statusBadge,
    opacity: 0.5,
    color: 'navy.main',
    borderColor: 'navy.main',
    backgroundColor: 'white.main',
  },
  state_needs_revision: {
    ...statusBadge,
    color: 'red.main',
    borderColor: 'red.main',
    backgroundColor: 'white.main',
  },
  state_rent_requested: {
    ...statusBadge,
    opacity: 0.6,
    color: 'white.main',
    backgroundColor: 'navy.main',
  },
  state_rented: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'navy.main',
  },
  state_active: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'success.main',
  },
  state_initial: {
    ...statusBadge,
    color: 'success.main',
    borderColor: 'success.main',
  },
  state_disabled: {
    ...statusBadge,
    color: 'gray.main',
    borderColor: 'gray.main',
  },
  state_archived: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'navy.main',
    opacity: 0.3,
  },
  state_rent_requested: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'red.attention',
  },
  state_draft: {
    ...statusBadge,
    opacity: 0.6,
    color: 'white.main',
    backgroundColor: 'gray.main',
  },
};

export default styles;
