import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  spaces: {
    display: 'none',
  },
  spacesOpened: {
    display: 'block',
  },
  spaceCard: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    padding: '16px 28px 16px 20px',
    '& .building': {
      mr: 1,
      color: 'brightBlue.main',
      width: 22,
      height: 22,
    },
    '&:first-of-type': {
      marginTop: '4px',
    },
  },
  spaceCardSelected: {
    backgroundColor: 'navy.background',
  },
  arrow: {
    ml: 'auto',
    mt: 'auto',
    mb: 'auto',
    cursor: 'pointer',
    color: 'brightBlue.main',
    width: 22,
    height: 21,
    transform: 'rotateY(180deg)',
  },
  topContent: {
    display: 'flex',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    marginRight: {
      mobile: '8px',
      largeScreen: 0,
    },
    maxWidth: '180px',
    width: '100%',
    flexShrink: {
      largeScreen: 0,
    },
  },
  contentColumn: {
    marginTop: {
      mobile: '12px',
      tablet: 0,
    },
    flexGrow: 1,
  },
  spaceTitleBox: {
    display: 'flex',
  },
  verifiedBox: {
    display: 'flex',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
    marginLeft: '5px',
  },
  divider: {
    mx: 3,
    borderColor: (theme: Theme) => `${theme.palette.navy.background} !important`,
  },
  squareBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  square: {
    marginTop: '5px',
  },
  tooltip: {
    padding: '10px',
  },
  content: {
    display: 'flex',
    width: '100%',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    justifyContent: 'space-between',
    paddingRight: '20px',
    alignContent: 'flex-start',
  },

  contentWithRentedSpace: {
    opacity: 0.5,
  },
  availableAt: {
    maxWidth: '80px',
    width: '100%',
  },
  capacity: {
    maxWidth: '100px',
    width: '100%',
  },
  price: {
    maxWidth: '125px',
    width: '100%',
  },
  buildingIcon: {
    marginBottom: {
      mobile: '10px',
      tablet: 0,
    },
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default styles;
