import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  base: {
    width: '100%',
    maxWidth: 770,
    border: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
    backgroundColor: 'common.white',
    padding: {
      mobile: '16px 16px 16px 0',
      tablet: '16px 16px 0 0',
      desktop: '4px 4px 0 0',
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
    mb: 4,
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    width: '100%',
    maxWidth: 770,
    flexWrap: {
      mobile: 'wrap',
      desktop: 'nowrap',
    },
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    marginLeft: {
      mobile: '16px',
      desktop: 0,
    },
    display: {
      tablet: 'flex',
    },
    flexDirection: 'column',
    justifyContent: {
      mobile: 'center',
      desktop: 'space-between',
    },
    padding: {
      desktop: '20px 20px 10px 20px',
    },
  },
  addressAndPrice: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    justifyContent: 'space-between',
    flexWrap: {
      desktop: 'wrap',
    },
  },
  spacesMathes: {
    display: 'flex',
    alignItems: {
      mobile: 'flex-start',
      tablet: 'center',
      desktop: 'flex-end',
    },
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    justifyContent: 'space-between',
    flexWrap: {
      desktop: 'wrap',
    },
  },
  divider: {
    marginBottom: '10px',
  },
  hiddenBox: {
    position: 'relative',
    top: '-200px',
  },
};

export default styles;
