import PaymentTokensRepository from 'repositories/landlord/PaymentTokensRepository';
import { PaymentTokensInterface } from 'types/resources/PaymentTokens';

const usePaymentTokens = (): PaymentTokensInterface => {
  const { createCustomer, plaidLinkToken, getSource } = PaymentTokensRepository;

  return {
    createCustomer,
    plaidLinkToken,
    getSource,
  };
};

export default usePaymentTokens;
