import { loadLandlord, updateLandlord } from 'store/landlord/landlordSlice';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';
import { LandlordInSettings } from 'types/resources/Landlord';
import { CompanyDetailsFormDataToSubmit } from 'forms/landlord/Settings/CompanyDetailsForm';

type UseLandlordType = {
  isLoadingLandlordFinished: boolean;
  isUpdateLandlordPending: boolean;
  landlord: LandlordInSettings;
  loadLandlord: () => void;
  updateLandlord: (params: CompanyDetailsFormDataToSubmit) => void;
};

const useLandlord = (): UseLandlordType => {
  const dispatch = useAppDispatch();
  const {
    landlord,
    index: { fetchStatus: indexFetchStatus },
    update: { fetchStatus: updateFetchStatus },
  } = useAppSelector(globalState => globalState.landlord);

  const { isFinished: isLoadingLandlordFinished } = getFetchStatus(indexFetchStatus);

  const { isPending: isUpdateLandlordPending } = getFetchStatus(updateFetchStatus);

  return {
    isLoadingLandlordFinished,
    isUpdateLandlordPending,
    landlord,
    loadLandlord: () => dispatch(loadLandlord()),
    updateLandlord: (params: CompanyDetailsFormDataToSubmit) => dispatch(updateLandlord(params)),
  };
};

export default useLandlord;
