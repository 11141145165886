import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  chip: {
    pt: 0.5,
    borderRadius: 1,
    backgroundColor: 'navy.main',
    color: 'common.white',

    '&.MuiChip-colorSuccess': {
      backgroundColor: 'common.white',
      color: 'success.main',
      border: theme => `1px solid ${theme.palette.success.main}`,
    },

    '& svg': {
      marginTop: '-4px !important',
      color: 'white !important',
      width: 13,
      height: 13,
    },
  },
};

export default styles;
