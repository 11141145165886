import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  accordeon: {
    '&.MuiAccordion-root': {
      backgroundColor: 'navy.background',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 'auto !important',
      paddingLeft: '25px',
      margin: '16px 0',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  accordeonBlock: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '17px',
      marginRight: '12px',
    },
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
  },
  navItemAccordeon: {
    padding: '16px 40px 16px 55px',
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;
