import { takeLast } from 'ramda';

import email from './images/emailIcon.svg';
import elevator from './images/elevator.svg';
import password from './images/passwordIcon.svg';
import warning from './images/warning.svg';
import watch from './images/watch.svg';
import watchTimer from './images/watch_timer.svg';
import arrow from './images/arrow.svg';
import logo from './images/logo.svg';
import cities from './images/cities.svg';
import lock from './images/lock.svg';
import twitter from './images/twitter.svg';
import facebook from './images/facebook.svg';
import towns from './images/towns.svg';
import onePerson from './images/onePerson.svg';
import twoPerson from './images/twoPerson.svg';
import building from './images/building.svg';
import retail from './images/retail.svg';
import label from './images/label.svg';
import success from './images/success.svg';
import close from './images/close.svg';
import calendar from './images/calendar.svg';
import controlButtons from './images/controlButtons.svg';
import cube from './images/cube.svg';
import armchair from './images/armchair.svg';
import mark from './images/mark.svg';
import daw from './images/daw.svg';
import air from './images/air.svg';
import subway from './images/subway.svg';
import bus from './images/bus.svg';
import dashboard from './images/dashboard.svg';
import message from './images/message.svg';
import logout from './images/logout.svg';
import suitcase from './images/suitcase.svg';
import eye from './images/eye.svg';
import points from './images/points.svg';
import pen from './images/pen.svg';
import qr from './images/qr.svg';
import loupe from './images/loupe.svg';
import vector from './images/vector.svg';
import checked from './images/checked.svg';
import search from './images/search.svg';
import checkbox from './images/checkbox.svg';
import checkboxChecked from './images/checkbox_checked.svg';
import upload from './images/upload.svg';
import plus from './images/plus.svg';
import dollar from './images/dollar.svg';
import quilPen from './images/quilPen.svg';
import image from './images/image.svg';
import impersonate from './images/impersonate.svg';
import area from './images/area.svg';
import bell from './images/bell.svg';
import phone from './images/phone.svg';
import radio from './images/radio.svg';
import radioChecked from './images/radio_checked.svg';
import trash from './images/trash.svg';
import triangle from './images/triangle.svg';
import arrowDown from './images/arrowDown.svg';
import emailSend from './images/emailSend.svg';
import download from './images/download.svg';
import closeCircle from './images/close_circle.svg';
import dislike from './images/dislike.svg';
import heart from './images/heart.svg';
import qa from './images/qa.svg';
import communication from './images/communication.svg';
import help from './images/help.svg';
import playCircle from './images/playCircle.svg';
import linkedin from './images/linkedin.svg';
import instagram from './images/instagram.svg';
import security from './images/security.svg';
import securityDisabled from './images/securityDisabled.svg';
import menu from './images/menu.svg';
import info from './images/info.svg';
import helpCircle from './images/helpCircle.svg';
import helpCircleFilled from './images/helpCircleFilled.svg';
import choose from './images/howItWorks/forTrotters/choose.svg';
import equip from './images/howItWorks/forTrotters/equip.svg';
import license from './images/howItWorks/forTrotters/license.svg';
import listings from './images/howItWorks/forTrotters/listings.svg';
import work from './images/howItWorks/forTrotters/work.svg';
import comission from './images/howItWorks/forBuildingOwners/comission.svg';
import listProperty from './images/howItWorks/forBuildingOwners/listProperty.svg';
import pullListing from './images/howItWorks/forBuildingOwners/pullListing.svg';
import tailorConnectivity from './images/homePage/tailorConnectivity.svg';
import tailorFurniture from './images/homePage/tailorFurniture.svg';
import tailorInsurance from './images/homePage/tailorInsurance.svg';
import rotateLeft from './images/rotateLeft.svg';
import messengerRightArrow from './images/messengerRightArrow.svg';
import messengerLeftArrow from './images/messengerLeftArrow.svg';
import link from './images/link.svg';
import wind from './images/wind.svg';
import thermometer from './images/thermometer.svg';
import floor from './images/floor.svg';
import window from './images/window.svg';
import arrowUpRight from './images/arrowUpRight.svg';
import arrowRight from './images/arrowRight.svg';
import matterport from './images/matterport.svg';
import buildingClass from './images/buildingClass.svg';
import thePowerOfFlex from './images/thePowerOfFlex.svg';
import archivedTrash from './images/archivedTrash.svg';
import lockSpace from './images/lockSpace.svg';
import archivedSpace from './images/archivedSpace.svg';
import share from './images/share.svg';
import shareFacebook from './images/shareFacebook.svg';
import shareEmail from './images/shareEmail.svg';
import shareMessages from './images/shareMessages.svg';
import shareMessenger from './images/shareMessenger.svg';
import shareWhatsapp from './images/shareWhatsapp.svg';
import shareTwitter from './images/shareTwitter.svg';
import stop from './images/stop.svg';
import google from './images/google.svg';

type IconDimensions = {
  width: string;
  height: string;
};

const getIconDimensions = (viewbox: string): IconDimensions => {
  const [width, height] = takeLast(2, viewbox.split(' '));

  return {
    width,
    height,
  };
};

interface BrowserSpriteSymbol {
  id: string;
  viewBox: string;
  content: string;
  node: SVGSymbolElement;
}

type IconAttributes = {
  src: BrowserSpriteSymbol;
  viewBox: string;
  id: string;
  width: string;
  height: string;
};

const getIconAttributes = (name: BrowserSpriteSymbol): IconAttributes => {
  const { width, height } = getIconDimensions(name.viewBox);

  return {
    src: name,
    viewBox: name.viewBox,
    id: `#${name.id}`,
    width,
    height,
  };
};

export default {
  email: getIconAttributes(email),
  elevator: getIconAttributes(elevator),
  password: getIconAttributes(password),
  warning: getIconAttributes(warning),
  watch: getIconAttributes(watch),
  watchTimer: getIconAttributes(watchTimer),
  arrow: getIconAttributes(arrow),
  logo: getIconAttributes(logo),
  cities: getIconAttributes(cities),
  lock: getIconAttributes(lock),
  twitter: getIconAttributes(twitter),
  facebook: getIconAttributes(facebook),
  towns: getIconAttributes(towns),
  onePerson: getIconAttributes(onePerson),
  twoPerson: getIconAttributes(twoPerson),
  building: getIconAttributes(building),
  retail: getIconAttributes(retail),
  label: getIconAttributes(label),
  success: getIconAttributes(success),
  close: getIconAttributes(close),
  calendar: getIconAttributes(calendar),
  controlButtons: getIconAttributes(controlButtons),
  cube: getIconAttributes(cube),
  armchair: getIconAttributes(armchair),
  mark: getIconAttributes(mark),
  daw: getIconAttributes(daw),
  air: getIconAttributes(air),
  subway: getIconAttributes(subway),
  bus: getIconAttributes(bus),
  dashboard: getIconAttributes(dashboard),
  message: getIconAttributes(message),
  logout: getIconAttributes(logout),
  suitcase: getIconAttributes(suitcase),
  eye: getIconAttributes(eye),
  points: getIconAttributes(points),
  pen: getIconAttributes(pen),
  qr: getIconAttributes(qr),
  loupe: getIconAttributes(loupe),
  vector: getIconAttributes(vector),
  checked: getIconAttributes(checked),
  search: getIconAttributes(search),
  checkbox: getIconAttributes(checkbox),
  checkboxChecked: getIconAttributes(checkboxChecked),
  upload: getIconAttributes(upload),
  plus: getIconAttributes(plus),
  dollar: getIconAttributes(dollar),
  quilPen: getIconAttributes(quilPen),
  image: getIconAttributes(image),
  impersonate: getIconAttributes(impersonate),
  area: getIconAttributes(area),
  bell: getIconAttributes(bell),
  phone: getIconAttributes(phone),
  radio: getIconAttributes(radio),
  radioChecked: getIconAttributes(radioChecked),
  trash: getIconAttributes(trash),
  triangle: getIconAttributes(triangle),
  arrowDown: getIconAttributes(arrowDown),
  emailSend: getIconAttributes(emailSend),
  download: getIconAttributes(download),
  closeCircle: getIconAttributes(closeCircle),
  dislike: getIconAttributes(dislike),
  heart: getIconAttributes(heart),
  qa: getIconAttributes(qa),
  communication: getIconAttributes(communication),
  help: getIconAttributes(help),
  playCircle: getIconAttributes(playCircle),
  linkedin: getIconAttributes(linkedin),
  instagram: getIconAttributes(instagram),
  security: getIconAttributes(security),
  menu: getIconAttributes(menu),
  info: getIconAttributes(info),
  helpCircle: getIconAttributes(helpCircle),
  choose: getIconAttributes(choose),
  equip: getIconAttributes(equip),
  license: getIconAttributes(license),
  listings: getIconAttributes(listings),
  work: getIconAttributes(work),
  comission: getIconAttributes(comission),
  listProperty: getIconAttributes(listProperty),
  pullListing: getIconAttributes(pullListing),
  tailorConnectivity: getIconAttributes(tailorConnectivity),
  tailorFurniture: getIconAttributes(tailorFurniture),
  tailorInsurance: getIconAttributes(tailorInsurance),
  rotateLeft: getIconAttributes(rotateLeft),
  messengerRightArrow: getIconAttributes(messengerRightArrow),
  messengerLeftArrow: getIconAttributes(messengerLeftArrow),
  link: getIconAttributes(link),
  wind: getIconAttributes(wind),
  thermometer: getIconAttributes(thermometer),
  floor: getIconAttributes(floor),
  window: getIconAttributes(window),
  arrowUpRight: getIconAttributes(arrowUpRight),
  arrowRight: getIconAttributes(arrowRight),
  matterport: getIconAttributes(matterport),
  buildingClass: getIconAttributes(buildingClass),
  thePowerOfFlex: getIconAttributes(thePowerOfFlex),
  archivedTrash: getIconAttributes(archivedTrash),
  lockSpace: getIconAttributes(lockSpace),
  archivedSpace: getIconAttributes(archivedSpace),
  securityDisabled: getIconAttributes(securityDisabled),
  helpCircleFilled: getIconAttributes(helpCircleFilled),
  share: getIconAttributes(share),
  shareFacebook: getIconAttributes(shareFacebook),
  shareEmail: getIconAttributes(shareEmail),
  shareMessages: getIconAttributes(shareMessages),
  shareMessenger: getIconAttributes(shareMessenger),
  shareWhatsapp: getIconAttributes(shareWhatsapp),
  shareTwitter: getIconAttributes(shareTwitter),
  stop: getIconAttributes(stop),
  google: getIconAttributes(google),
};
