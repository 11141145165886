import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  menuItem: {
    alignItems: 'flex-start !important',
    flexDirection: 'column',
    padding: 0,
    paddingLeft: '20px',
    paddingRight: '20px',
    minHeight: 'auto',
  },
  menuItemText: {
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingRight: '22px',
  },
};

export default styles;
