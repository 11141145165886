import { ComponentsOverrides } from '@material-ui/core';

import palette from '../palette';

type MuiTooltipType = {
  styleOverrides: ComponentsOverrides['MuiTooltip'];
};

const MuiTooltip: MuiTooltipType = {
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.blue.tealish,
      fontFamily: 'Heebo',
      fontSize: 15,
      maxWidth: 400,
    },
    arrow: {
      color: palette.blue.tealish,
    },
  },
};

export default MuiTooltip;
