import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  form: {
    backgroundColor: '#F5F6FA',
    position: 'relative',
  },
  textarea: {
    position: 'relative',
    margin: {
      mobile: '0 16px 75px',
      tablet: '0 75px 60px',
    },
    '& textarea': {
      display: 'flex',
      width: '100%',
      paddingRight: '110px',
      paddingLeft: '24px',
      paddingTop: '13px',
      paddingBottom: '15px',
      borderColor: 'rgba(5, 12, 29, 0.1)',
      borderRadius: '4px',
      resize: 'none',
      fontSize: '15px',
    },
    '& textarea::placeholder': {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      opacity: 0.5,
    },
    '& textarea:hover': {
      borderColor: 'black.main',
    },
  },
  adminTextarea: {
    margin: {
      mobile: '0 16px 150px',
      tablet: '0 60px 110px',
      desktop: '0 60px 90px',
    },
  },
  landlordTextarea: {
    margin: '0 16px 20px',
  },
  actions: {
    position: 'absolute',
    top: '50%',
    right: '25px',
    transform: 'translateY(-50%)',
  },
  sendButton: {
    width: 'auto',
    borderRadius: '3px',
    padding: '4px 8px',
    height: '28px',
    textTransform: 'initial',
  },
};

export default styles;
