import { configureStore } from '@reduxjs/toolkit';

import sessionsReducer from './sessionsSlice';
import usersReducer from './usersSlice';
import spacesReducer from './spacesSlice';
import modalsReducer from './modalsSlice';
import notificationsReducer from './notificationsSlice';
import searchReducer from './searchSlice';
import amenitiesReducer from './amenitiesSlice';
import googleServiceReducer from './googleServiceSlice';
import landlordsReducer from './admin/landlordsSlice';
import landlordBuildingsReducer from './landlord/buildingsSlice';
import landlordDraftBuildingsReducer from './landlord/draft/buildingsSlice';
import landlordEmployeesReducer from './landlord/employeesSlice';
import landlordLegalEntitiesReducer from './landlord/legalEntitiesSlice';
import landlordSpacesReducer from './landlord/spacesSlice';
import landlordDraftSpacesReducer from './landlord/draft/spacesSlice';
import landlordReducer from './landlord/landlordSlice';
import adminSpacesReducer from './admin/spacesSlice';
import adminBuildingsReducer from './admin/buildingsSlice';
import adminTrottersReducer from './admin/trottersSlice';
import trotterFavoriteSpacesReducer from './trotter/favoriteSpacesSlice';
import trotterSpaceRentsReducer from './trotter/spaceRentsSlice';
import ordersReducer from './trotter/ordersSlice';
import googleAuthReducer from './googleAuthSlice';

export const store = configureStore({
  reducer: {
    sessions: sessionsReducer,
    users: usersReducer,
    spaces: spacesReducer,
    modals: modalsReducer,
    notifications: notificationsReducer,
    search: searchReducer,
    amenities: amenitiesReducer,
    googleService: googleServiceReducer,
    landlords: landlordsReducer,
    landlordBuildings: landlordBuildingsReducer,
    landlordDraftBuildings: landlordDraftBuildingsReducer,
    landlordSpaces: landlordSpacesReducer,
    landlordDraftSpaces: landlordDraftSpacesReducer,
    landlordEmployees: landlordEmployeesReducer,
    landlordLegalEntities: landlordLegalEntitiesReducer,
    landlord: landlordReducer,
    adminSpaces: adminSpacesReducer,
    adminBuildings: adminBuildingsReducer,
    adminTrotters: adminTrottersReducer,
    trotterFavoriteSpaces: trotterFavoriteSpacesReducer,
    trotterSpaceRents: trotterSpaceRentsReducer,
    trotterOrders: ordersReducer,
    googleAuth: googleAuthReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
