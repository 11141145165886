import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    alignItems: {
      mobile: 'stretch',
      desktop: 'flex-start',
    },
    width: {
      mobile: '100%',
      largeScreen: 'calc(100% - 120px)',
    },
    margin: {
      mobile: 0,
      largeScreen: '40px 60px',
    },
    paddingTop: '6px',
  },
  densed: {
    margin: {
      mobile: 0,
      largeScreen: '40px 0',
    },
    width: '100%',
  },
  contentWithAnnotation: {
    paddingTop: '8px',
  },
  label: {
    width: {
      mobile: '100%',
      desktop: '240px',
    },
  },
  title: {
    paddingTop: '16px',
    lineHeight: '160%',
  },
  content: {
    flex: 1,
  },
};

export default styles;
