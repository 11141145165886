import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import humps from 'humps';
import { is } from 'ramda';

export const camelizeKeys = (object: Params): Params => camelcaseKeys(object, { deep: true });
export const decamelizeKeys = <T>(object: T): T => snakecaseKeys(object, { deep: true });

const isObject = (obj: unknown): boolean => is(Object, obj);

const isFile = (obj: unknown): boolean => obj instanceof File;

const isArray = (obj: unknown): obj is [] => obj instanceof Array;

export const camelize = (obj: string): string => humps.camelize(obj);

export const decamelize = (obj: Params | Params[]): Params | Params[] => {
  if (isArray(obj)) {
    return obj.map(item => decamelize(item));
  }
  if (isObject(obj) && !isFile(obj)) {
    return Object.keys(obj).reduce(
      (acc, next) => ({
        ...acc,
        [humps.decamelize(next)]: decamelize(obj[next]),
      }),
      {},
    );
  }
  return obj;
};
