/* eslint-disable no-param-reassign */
import SpacesRepository from 'repositories/admin/SpacesRepository';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import Space from 'types/resources/Space';

export type SpacesSliceStateType = {
  space: Space;
  spaces: Space[];
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  decline: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  approve: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadSpaces = createAsyncThunk('admin/spaces/load', SpacesRepository.index);
export const loadSpace = createAsyncThunk('admin/spaces/show', async (spaceId: ID, { rejectWithValue }) => {
  try {
    return await SpacesRepository.show(spaceId);
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateSpace = createAsyncThunk('admin/spaces/update', SpacesRepository.update);
export const deleteSpace = createAsyncThunk('admin/spaces/deleteSpace', SpacesRepository.delete);
export const approveSpace = createAsyncThunk('admin/spaces/approveSpace', SpacesRepository.approve);
export const declineSpace = createAsyncThunk('admin/spaces/declineSpace', SpacesRepository.decline);

const initialState: SpacesSliceStateType = {
  space: {} as Space,
  spaces: [] as Space[],
  meta: { perPage: 100 } as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  decline: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  approve: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'admin/spaces',
  initialState,
  reducers: {
    resetSpace(state) {
      state.space = initialState.space;
      state.show = initialState.show;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSpaces.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpaces.fulfilled, (state, { payload }) => {
      state.spaces = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpaces.rejected, state => {
      state.spaces = [];
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadSpace.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpace.fulfilled, (state, { payload }) => {
      state.space = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpace.rejected, (state, { payload }) => {
      state.show.error = payload;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateSpace.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateSpace.fulfilled, (state, { payload }) => {
      state.space = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateSpace.rejected, (state, { payload }) => {
      state.show.error = payload;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(deleteSpace.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteSpace.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteSpace.rejected, (state, { error }) => {
      state.delete.fetchStatus = FetchStatus.failed;
      state.delete.error = error;
    });
    builder.addCase(declineSpace.pending, state => {
      state.decline.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(declineSpace.fulfilled, state => {
      state.decline.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(declineSpace.rejected, (state, { error }) => {
      state.decline.fetchStatus = FetchStatus.failed;
      state.decline.error = error;
    });
    builder.addCase(approveSpace.pending, state => {
      state.approve.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(approveSpace.fulfilled, (state, { payload }) => {
      state.space = payload;
      state.approve.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(approveSpace.rejected, (state, { error }) => {
      state.approve.fetchStatus = FetchStatus.failed;
      state.approve.error = error;
    });
  },
});

export default slice.reducer;

const {
  actions: { resetSpace },
} = slice;

export { resetSpace };
