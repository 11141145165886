import { FC, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@material-ui/core';
import Box from 'components/Box';
import Loader from 'components/Loader';

import styles from './styles';

type CardPaymentFormProps = {
  onPaymentMethodLoad: () => void;
};

const CardPaymentForm: FC<CardPaymentFormProps> = props => {
  const { onPaymentMethodLoad } = props;
  const [isElementsReady, setIsElementsReady] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [cardError, setCardError] = useState<string | null>(null);

  const stripeElements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const result = await stripe.confirmSetup({
      elements: stripeElements,
      redirect: 'if_required',
    });
    setIsSubmitting(false);

    if (result.error) {
      setCardError(result.error.message);
    } else {
      onPaymentMethodLoad();
    }
  };

  const handleElementsReady = () => {
    setIsElementsReady(true);
  };

  return (
    <>
      {isSubmitting && <Loader open />}
      <Box sx={styles.root}>
        <form onSubmit={handleSubmit}>
          <PaymentElement onReady={handleElementsReady} />
          {isElementsReady && (
            <Button sx={styles.submit} size="medium" type="submit">
              Save Changes
            </Button>
          )}
        </form>
      </Box>
      {cardError && <Box sx={styles.error}>{cardError}</Box>}
    </>
  );
};

export default CardPaymentForm;
