import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  backdrop: {
    color: 'common.white',
    zIndex: 200,
  },
};

export default styles;
