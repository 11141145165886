import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  select: {
    p: 0,
    border: 'none',
  },
};

export default styles;
