import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@material-ui/core';
import { useScreen } from 'hooks';
import AccountMenu from 'components/AccountMenu';
import AccountMenuMobile from 'components/AccountMenuMobile';
import DropDownMenu from 'components/DropDownMenu';
import DropDownMenuProfilePlaceholder from 'components/DropDownMenuProfilePlaceholder';
import MessagesBadge from 'components/MessagesBadge';
import { HeaderLayout } from 'enums/HeaderLayout';
import DropDownMenuPlaceholder from 'components/Header/components/DropDownMenuPlaceholder';
import DropDownMenuList from 'components/Header/components/DropDownMenuList';
import { marketingRoutes } from 'routes';
import clsx from 'utils/clsx';
import PhoneNumber from 'components/PhoneNumber';

import styles from './styles';

type AuthUserProps = {
  onLogout: () => void;
  type: HeaderLayout;
};

const AuthUser: FC<AuthUserProps> = props => {
  const { onLogout, type } = props;

  const { t } = useTranslation(['resources', 'publicLayouts', 'layouts']);
  const { lessThanDesktop } = useScreen();

  if (lessThanDesktop) {
    return <AccountMenuMobile onLogout={onLogout} />;
  }

  const isLightLayout = type === HeaderLayout.light;
  const isBaseLayout = type === HeaderLayout.base;
  const isHomeLayout = type === HeaderLayout.home;

  const linkStyles = clsx(styles.link, [
    [styles.linkLight, isLightLayout],
    [styles.linkBase, isBaseLayout],
    [styles.linkHome, isHomeLayout],
  ]);

  return (
    <Box sx={styles.root}>
      <Stack sx={styles.links} direction={{ mobile: 'column', tablet: 'row' }}>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:Locations.title')} type={type} />}
          >
            <DropDownMenuList placeholder="locations" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:ForOwners.title')} type={type} />}
          >
            <DropDownMenuList placeholder="forOwners" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:ForTrotters.title')} type={type} />}
          >
            <DropDownMenuList placeholder="forTrotters" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:AboutTrot.title')} type={type} />}
          >
            <DropDownMenuList placeholder="aboutTrot" />
          </DropDownMenu>
        </Box>
        <Box sx={styles.link}>
          <DropDownMenu
            openOnHover
            placeholder={<DropDownMenuPlaceholder placeholder={t('publicLayouts:ContactUs.title')} type={type} />}
          >
            <DropDownMenuList placeholder="contactUs" />
          </DropDownMenu>
        </Box>
        <Typography sx={linkStyles} variant="body3" href={marketingRoutes.rootPath()} component="a">
          {t('publicLayouts:ThePowerOfFlex')}
        </Typography>
      </Stack>
      <Stack sx={styles.user} spacing={0} direction="row">
        <PhoneNumber layoutType={type} />
        <MessagesBadge type={type} />
        <Box sx={styles.link}>
          <DropDownMenu placeholder={<DropDownMenuProfilePlaceholder type={type} />}>
            <AccountMenu />
          </DropDownMenu>
        </Box>
      </Stack>
    </Box>
  );
};

export default AuthUser;
