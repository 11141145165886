import { FC, useState, useEffect } from 'react';
import { Avatar } from '@material-ui/core';
import { useChatContext } from 'stream-chat-react';
import { Channel } from 'stream-chat';
import clsx from 'utils/clsx';
import { head, sum } from 'ramda';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Box from 'components/Box';
import ChannelItemCard from 'components/Chat/components/ChannelItemCard';
import ChannelItemText from 'components/Chat/components/ChannelItemText';
import ChannelItemUnread from 'components/Chat/components/ChannelItemUnread';
import {
  getTrotterNameFromChannel,
  getTrotterImageFromChannel,
  getTrotterChannelBuildingName,
  getTrotterChannelSpaceName,
  groupLoadedChannelsByTrotter,
} from 'utils/chat';
import { ChatChannel } from 'types/chat';

import styles from './styles';

type DropdownCardProps = {
  trotterChannels: ChatChannel[];
};

const DropdownCard: FC<DropdownCardProps> = props => {
  const { trotterChannels } = props;
  const { setActiveChannel, channel: activeChannel } = useChatContext();
  const { t } = useTranslation('chatComponents');

  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    const channelIds = trotterChannels.map(trotterChannel => trotterChannel.id);

    if (channelIds.includes(activeChannel?.id)) {
      setOpened(true);
    }
  }, []);

  const currentChannel = head(trotterChannels);
  const trotterName = getTrotterNameFromChannel(currentChannel);
  const trotterImage = getTrotterImageFromChannel(currentChannel);
  const conversations = t('conversationCount', { count: trotterChannels.length });
  const totalUnreadCount = sum(trotterChannels.map(trotterChannel => trotterChannel.countUnread()));
  const shouldShowTotal = !opened && totalUnreadCount > 0;

  const handleTrotterClick = () => {
    setOpened(prev => !prev);
  };

  const handleTrotterChannelClick = (channel: Channel) => {
    setActiveChannel(channel);
  };

  return (
    <>
      <ChannelItemCard isActive={false} handleCardClick={handleTrotterClick}>
        <Avatar sx={styles.avatar} alt={trotterName} src={trotterImage} />
        <ChannelItemText title={trotterName} subtitle={conversations} />
        {shouldShowTotal ? (
          <ChannelItemUnread unreadMessages={totalUnreadCount} />
        ) : (
          <Box sx={clsx(styles.arrow, [[styles.arrowOpened, opened]])}>
            <Icon name="arrowDown" />
          </Box>
        )}
      </ChannelItemCard>
      {opened && (
        <Box>
          {trotterChannels.map(trotterChannel => {
            const active = activeChannel?.id === trotterChannel.id;
            const unreadMessages = trotterChannel.state.unreadCount;
            const spaceName = getTrotterChannelBuildingName(trotterChannel);
            const buildingName = getTrotterChannelSpaceName(trotterChannel);

            return (
              <ChannelItemCard
                key={trotterChannel.id}
                isActive={active}
                handleCardClick={() => handleTrotterChannelClick(trotterChannel)}
              >
                <ChannelItemText title={buildingName} subtitle={spaceName} />
                <ChannelItemUnread unreadMessages={unreadMessages} />
              </ChannelItemCard>
            );
          })}
        </Box>
      )}
    </>
  );
};

type ChannelListItemLandlordProps = {
  loadedChannels: ChatChannel[];
};

const ChannelListItemLandlord: FC<ChannelListItemLandlordProps> = props => {
  const { loadedChannels } = props;
  const trotterGroups = groupLoadedChannelsByTrotter(loadedChannels);

  return (
    <>
      {trotterGroups.map(trotterChannels => {
        const key = getTrotterNameFromChannel(head(trotterChannels));
        return <DropdownCard key={key} trotterChannels={trotterChannels} />;
      })}
    </>
  );
};

export default ChannelListItemLandlord;
