import i18n from 'locales/i18n';
import Landlord from 'types/resources/Landlord';
import format from 'date-fns/format';
import { parseDate } from 'utils/dateTimeUtils';
import { LandlordState, LandlordStatePresentation, LandlordAction } from 'enums/resources/Landlord';

export const prettyRegisteredAt = (landlord: Landlord): string => {
  const date = parseDate(landlord.createdAt);
  return format(date, 'MMMM d, y');
};

export const messagingId = (landlord: Landlord): string => {
  return landlord && `${Settings.env}-landlords-${landlord.id}`;
};

type LandlordStatePredicates = {
  isInitial: boolean;
  isVerified: boolean;
  isUnferified: boolean;
  isArchived: boolean;
};

export const state = (landlord: Landlord): string => {
  const statePresentation = LandlordStatePresentation[landlord.state];

  return i18n.t(`resources:landlord.states.${statePresentation}`).toUpperCase();
};

export const getLandlordState = (landlord: Landlord): LandlordStatePredicates => {
  const isInitial = landlord.state === LandlordState.initial;
  const isVerified = landlord.state === LandlordState.verified;
  const isUnferified = landlord.state === LandlordState.unverified;
  const isArchived = landlord.state === LandlordState.archived;

  return {
    isInitial,
    isVerified,
    isUnferified,
    isArchived,
  };
};

export const availableActions = (landlord: Landlord): LandlordAction[] => {
  const { isInitial, isVerified, isUnferified, isArchived } = getLandlordState(landlord);

  if (isInitial) {
    return [LandlordAction.verify, LandlordAction.unverify, LandlordAction.archive];
  }

  if (isVerified) {
    return [LandlordAction.unverify, LandlordAction.archive];
  }

  if (isUnferified) {
    return [LandlordAction.verify, LandlordAction.archive];
  }

  if (isArchived) {
    return [LandlordAction.verify, LandlordAction.unverify];
  }

  return [];
};
