import { SxStyles, Theme } from 'types/theme';
import theme from 'theme';

const styles: SxStyles = {
  root: {
    height: '100%',
    width: '100%',
    '& .str-chat-channel-list.messaging': {
      height: '100%',
      backgroundColor: 'white.main',
      borderRight: (trotTheme: Theme) => `1px solid ${trotTheme.palette.navy.secondary}`,
    },
    '& .str-chat-channel.messaging .str-chat__main-panel': {
      padding: 0,
    },
    '& .str-chat-channel': {
      height: '100%',
    },
    '& .messaging.str-chat .str-chat__list': {
      backgroundColor: '#F5F6FA',
      paddingLeft: '60px',
      paddingRight: '75px',
    },
    '.str-chat-channel-list.messaging': {
      [theme.breakpoints.down('chat')]: {
        position: 'static',
        width: '100%',
      },
    },
    '.messaging.str-chat .str-chat__list': {
      [theme.breakpoints.down('chat')]: {
        paddingLeft: '30px',
        paddingRight: '60px',
      },
    },
    '.str-chat__date-separator': {
      [theme.breakpoints.down('chat')]: {
        padding: '20px',
      },
    },
  },
  rootNavClosed: {
    [theme.breakpoints.down('chat')]: {
      '& .str-chat-channel-list.messaging': {
        display: 'none',
      },
    },
  },
};

export default styles;
