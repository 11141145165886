import { Select, MenuItem, Typography, Box } from '@material-ui/core';
import Icon from 'components/Icon';
import clsx from 'utils/clsx';
import { capitalize } from 'utils/string';
import { DEFAULT_SELECT_VALUE } from 'utils/constants';

import styles from './styles';

type RenderValue = {
  value: string;
  label?: string;
};

type SelectProps = {
  onChange: (val: string | typeof DEFAULT_SELECT_VALUE) => void;
  selectedValue: string;
  values: RenderValue[];
  filterName: string;
  transformValue?: (value: string) => string;
};

type SelectEvent = React.ChangeEvent<{
  name?: string;
  value: string;
  event: Event | React.SyntheticEvent<Element, Event>;
}>;

function StateFilter(props: SelectProps): JSX.Element {
  const { onChange, selectedValue, values, filterName, transformValue = string => string } = props;
  const allValues: RenderValue[] = [{ value: DEFAULT_SELECT_VALUE, label: DEFAULT_SELECT_VALUE }, ...values];

  const handleSelectChange = (e: SelectEvent) => {
    onChange(e.target.value);
  };

  const shouldFill = selectedValue.toLowerCase() !== DEFAULT_SELECT_VALUE.toLowerCase();

  const ArrowDown = ({ className }: { className: string }) => {
    return (
      <Box className={className} sx={styles.arrowWrapper}>
        <Icon name="arrowDown" />
      </Box>
    );
  };

  const labelByValue = (currentValue: string) => {
    if (currentValue === DEFAULT_SELECT_VALUE) {
      return DEFAULT_SELECT_VALUE;
    }

    return allValues.find(({ label }) => (label === currentValue ? label : false)).label;
  };

  return (
    <Select
      sx={clsx(styles.select, [[styles.selectFilled, shouldFill]])}
      onChange={handleSelectChange}
      value={selectedValue}
      renderValue={(value: string) => {
        return (
          <>
            <Typography variant="body5">{filterName}</Typography>
            <Typography fontWeight={500} variant="body5">
              {capitalize(transformValue(labelByValue(value)))}
            </Typography>
          </>
        );
      }}
      IconComponent={ArrowDown}
    >
      {allValues.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {capitalize(transformValue(label || value))}
        </MenuItem>
      ))}
    </Select>
  );
}

export default StateFilter;
