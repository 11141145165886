import { SxStyles } from 'types/theme';
import palette from 'theme/palette';

const styles: SxStyles = {
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    position: 'relative',
    '& form': {
      height: '44px',
      padding: '3.5px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${palette.brightBlue.main}`,
      '& svg': {
        width: '17px',
        height: '17px',
      },
    },
  },
  inputField: {
    width: '300px',
    height: '42px',
    backgroundColor: 'white.main',
    '& input': {
      padding: '8px 40px 8px 0',
    },
    '& svg': {
      width: 17,
      height: 17,
    },
  },
  searchButton: {
    padding: '5px',
    minWidth: '35px',
    width: '35px',
    height: '35px',
    '& svg': {
      width: 10,
      height: 10,
    },
  },
  closeButton: {
    position: 'absolute',
    right: '40px',
    backgroundColor: 'white.main',
    marginRight: '5px',
    padding: '0px',
    minWidth: '35px',
    width: '35px',
    height: '35px',
    '&:hover': {
      backgroundColor: 'white.main',
    },
    '& svg': {
      color: 'brightBlue.main',
      width: 20,
      height: 20,
    },
  },
  loupe: {
    '& svg': {
      width: 15,
      height: 15,
    },
  },
  loupeOpened: {
    marginLeft: '10px',
    marginRight: '10px',
  },
};

export default styles;
