import { AmenitiesSliceStateType, AmenitiesSliceActionsType, useAmenitiesActions } from 'store/amenitiesSlice';
import { useAppSelector } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AmenityType } from 'enums/resources/Amenity';
import Amenity from 'types/resources/Amenity';

type UseAmenitiesType = AmenitiesSliceStateType &
  AmenitiesSliceActionsType & {
    isLoadAmenitiesFinished: boolean;
    buildingAmenities: Amenity[];
    spaceAmenities: Amenity[];
  };

const useAmenities = (): UseAmenitiesType => {
  const amenitiesSelector = (globalState: RootState) => globalState.amenities.amenities;

  const { amenities, fetchStatus } = useAppSelector(globalState => globalState.amenities);
  const { loadAmenities } = useAmenitiesActions();

  const buildingAmenitiesSelector = createSelector(amenitiesSelector, items =>
    items.filter(amenity => amenity.type === AmenityType.building),
  );

  const spaceAmenitiesSelector = createSelector(amenitiesSelector, items =>
    items.filter(amenity => amenity.type === AmenityType.space),
  );

  const { isFinished: isLoadAmenitiesFinished } = getFetchStatus(fetchStatus);

  return {
    fetchStatus,
    loadAmenities,
    amenities,
    buildingAmenities: useAppSelector(buildingAmenitiesSelector),
    spaceAmenities: useAppSelector(spaceAmenitiesSelector),
    isLoadAmenitiesFinished,
  };
};

export default useAmenities;
