import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import EditLink from 'components/EditLink';
import Building from 'types/resources/Building';
import clsx from 'utils/clsx';

import Listings from './components/Listings';
import PlainTextItem from './components/PlainTextItem';
import StackColumn from './components/StackColumn';
import StackRow from './components/StackRow';
import styles from './styles';

type BuildingContentProps = {
  building: Building;
  decreased?: boolean;
  pathToEditBuilding?: (buildingId: ID) => string | null;
  pathToViewListing: (buildingId: ID, spaceId: ID) => string;
  isAdminPage?: boolean;
};

const BuildingContent: FC<BuildingContentProps> = props => {
  const { building, decreased = false, pathToEditBuilding, pathToViewListing, isAdminPage = false } = props;
  const { t } = useTranslation(['landlordComponents', 'common', 'resources']);
  const {
    description,
    amenities,
    freightElevator,
    onSiteManagement,
    ownerOccupied,
    bicycleParkingAccess,
    onSiteGym,
    buildingClass,
    buildingType,
    elevatorType,
    numberOfElevators,
    lobbyAttendantHours,
    accessHours,
    onSiteFood,
    onSiteEntertainment,
    commonAreaBathrooms,
    id,
  } = building;

  const pathToEdit: string = pathToEditBuilding && pathToEditBuilding(id);

  const isBuildingSpecExists = buildingClass || buildingType;
  const isElevatorsExists = elevatorType || numberOfElevators;
  const isOpeningInfoExists = lobbyAttendantHours || accessHours;

  return (
    <>
      <PlainTextItem
        title={t('landlordComponents:BuildingContent.aboutTheLocation')}
        info={description}
        path={pathToEdit}
      />

      <Box sx={styles.itemContainer}>
        <Typography sx={styles.contentTitle} variant="bold3" component="h6">
          {t('landlordComponents:BuildingContent.whyIsThisBuilding')}
          <EditLink path={pathToEdit} />
        </Typography>
        <Box sx={clsx(styles.amenities, [[styles.amenitiesDecreased, decreased]])}>
          {amenities &&
            amenities.map(amenity => (
              <Typography key={amenity.id} variant="body5" component="p">
                {amenity.name}
              </Typography>
            ))}
          {freightElevator && (
            <Typography variant="body5" component="p">
              {t('resources:building.freightElevator')}
            </Typography>
          )}
          {onSiteManagement && (
            <Typography variant="body5" component="p">
              {t('resources:building.onSiteManagement')}
            </Typography>
          )}{' '}
          {ownerOccupied && (
            <Typography variant="body5" component="p">
              {t('resources:building.ownerOccupied')}
            </Typography>
          )}
          {bicycleParkingAccess && (
            <Typography variant="body5" component="p">
              {t('resources:building.bicycleParkingAccess')}
            </Typography>
          )}
          {onSiteGym && (
            <Typography variant="body5" component="p">
              {t('resources:building.onSiteGym')}
            </Typography>
          )}
        </Box>
      </Box>

      {/* todo transport
      t('landlordComponents:BuildingContent.distanceToPublicTransport')
      "subway"
      t('common:subway')
      "bus" />
      t('common:bus')
      */}

      {isBuildingSpecExists && (
        <StackRow
          building={building}
          title={t('resources:building.name')}
          path={pathToEdit}
          items={[
            {
              data: buildingClass,
              itemTitle: t('landlordComponents:BuildingContent.class'),
              iconName: 'building',
            },
            {
              data: buildingType,
              itemTitle: t('landlordComponents:BuildingContent.type'),
              iconName: 'building',
            },
          ]}
        />
      )}

      {isElevatorsExists && (
        <StackRow
          title={t('landlordComponents:BuildingContent.elevator')}
          path={pathToEdit}
          items={[
            {
              data: elevatorType,
              itemTitle: t('landlordComponents:BuildingContent.type'),
              iconName: 'elevator',
            },
            {
              data: numberOfElevators,
              itemTitle: t('landlordComponents:BuildingContent.elevatorNumber'),
              iconName: 'elevator',
            },
          ]}
        />
      )}

      {isOpeningInfoExists && (
        <StackColumn
          title={t('landlordComponents:BuildingContent.openingHours')}
          path={pathToEdit}
          items={[
            {
              data: lobbyAttendantHours,
              itemTitle: t('resources:building.lobbyAttendantHours'),
              iconName: 'watch',
            },
            {
              data: accessHours,
              itemTitle: t('resources:building.accessHours'),
              iconName: 'watchTimer',
            },
          ]}
        />
      )}

      {onSiteFood && <PlainTextItem title={t('resources:building.onSiteFood')} info={onSiteFood} path={pathToEdit} />}

      {onSiteEntertainment && (
        <PlainTextItem
          title={t('resources:building.onSiteEntertainment')}
          info={onSiteEntertainment}
          path={pathToEdit}
        />
      )}

      {commonAreaBathrooms && (
        <PlainTextItem
          title={t('resources:building.commonAreaBathrooms')}
          info={commonAreaBathrooms}
          path={pathToEdit}
        />
      )}

      {!decreased && (
        <>
          <Listings pathToViewListing={pathToViewListing} building={building} isAdminPage={isAdminPage} />
        </>
      )}
    </>
  );
};

export default BuildingContent;
