import { FC } from 'react';
import { Stack, Typography, StackProps } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import Icon from 'components/Icon';
import Box from 'components/Box';
import Space from 'types/resources/Space';
import { SpacePresenter } from 'presenters';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import styles from './styles';

type SpaceInfoProps = {
  space: Space;
};

const SpaceInfo: FC<SpaceInfoProps & StackProps> = props => {
  const { space, ...rest } = props;
  const { t } = useTranslation(['common', 'resources']);

  const squareTooltip = (
    <Box sx={styles.tooltip}>
      <Typography variant="body4" component="p">
        {t('resources:listing.rentableSquareTooltipText')}
      </Typography>
    </Box>
  );

  return (
    <Stack direction="row" alignItems="center" sx={styles.base} {...rest}>
      {!isNil(space.square) && (
        <>
          <Icon name="cube" />
          <Tooltip
            title={squareTooltip}
            arrow
            placement="top"
            componentsProps={{ tooltip: { style: { maxWidth: '367px' } } }}
            enterTouchDelay={0}
            leaveTouchDelay={2500}
          >
            <Box sx={styles.squareBlock}>
              <Typography sx={styles.row} variant="body3" component="p">
                {SpacePresenter.rentableSquare(space)}
              </Typography>
            </Box>
          </Tooltip>
        </>
      )}
      <Icon name="armchair" />
      <Typography sx={styles.row} variant="body3">
        {t('common:capacity', { min: space.minCapacity, max: space.maxCapacity })}
      </Typography>
    </Stack>
  );
};

export default SpaceInfo;
