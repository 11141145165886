import { MediaType } from 'enums/MediaType';
import R from 'ramda';
import Media from 'types/resources/Media';
import Image from 'types/resources/Image';
import Video from 'types/resources/Video';
import Matterport from 'types/resources/Matterport';

export const LIMIT_OF_IMAGES_IN_SECTION = 10;

const photoFileTypes = ['.jpg', '.jpeg', '.png'];
const videoFileTypes = ['.mp4', '.mpv', '.flv', '.mov', '.avi', '.wmv'];

export const GALLERY_UPLOAD_CONFIG = {
  config: {
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: LIMIT_OF_IMAGES_IN_SECTION * 3,
      allowedFileTypes: [...photoFileTypes, ...videoFileTypes],
      maxFileSize: 256 * 1024 * 1024, // 256 Megabytes
    },
  },
  thumbnails: { height: 210 },
};

export const isPhoto = (media: Media): media is Image => {
  return media.type === MediaType.photo;
};

export const isVideo = (media: Media): media is Video => {
  return media.type === MediaType.video;
};

export const isMatterport = (media: Media): media is Matterport => {
  return media.type === MediaType.matterport;
};

export const isVideoFormat = (url: string): boolean => {
  if (!url) {
    return false;
  }

  const extensionWithoutDot = R.last(url.split('.'));
  const extension = `.${extensionWithoutDot}`;

  return videoFileTypes.includes(extension.toLowerCase());
};

export const findPreviewByMediaId = (previews: { id: ID; preview: string }[], id: ID): string | undefined =>
  (previews.find(preview => preview.id === id) || {}).preview;

export const isNewMedia = (media: Media): boolean => R.has('isNew', media);

export const isDestroyedMedia = (media: Media): boolean => R.has('_destroy', media);

export const isMediaHasTag = (media: Media, tag: string): boolean => media.tag === tag;

export const sortMediasByOrder = (medias: Media[]): Media[] => R.sortBy(R.prop('order'))(medias);

export const splitMediasByDestroy = (medias: Media[]): { activeMedias: Media[]; destroyedMedias: Media[] } => {
  return medias.reduce(
    (acc, media) => {
      if (isDestroyedMedia(media)) {
        acc.destroyedMedias.push(media);
      } else {
        acc.activeMedias.push(media);
      }
      return acc;
    },
    { activeMedias: [] as Media[], destroyedMedias: [] as Media[] },
  );
};

export const splitMediasByTag = (medias: Media[], tag: string): { taggedMedias: Media[]; otherMedias: Media[] } => {
  return medias.reduce(
    (acc, media) => {
      if (isMediaHasTag(media, tag)) {
        acc.taggedMedias.push(media);
      } else {
        acc.otherMedias.push(media);
      }
      return acc;
    },
    { taggedMedias: [] as Media[], otherMedias: [] as Media[] },
  );
};

export const getMediasByTag = (medias: Media[], tag: string): Media[] => {
  return medias.filter(media => media.tag === tag);
};

export const getOrderedMediasByTag = (medias: Media[], tag: string): Media[] => {
  const taggedMedias = getMediasByTag(medias, tag);
  return sortMediasByOrder(taggedMedias);
};

export const getNotDestroyableMediasByTag = (medias: Media[], tag: string): Media[] => {
  return medias.filter(media => media.tag === tag && !isDestroyedMedia(media));
};

export const getLastAvailableOrderForTag = (medias: Media[], tag: string): number => {
  const taggedMedias = getNotDestroyableMediasByTag(medias, tag);
  return taggedMedias.length;
};

export const removeFromList = (medias: Media[], media: Media): Media[] => {
  return R.reject(R.propEq('id', media.id), medias);
};

export const reorderMedias = (medias: Media[], tag: string): Media[] => {
  const { taggedMedias, otherMedias } = splitMediasByTag(medias, tag);
  const { activeMedias, destroyedMedias } = splitMediasByDestroy(taggedMedias);

  const reoderedActiveTaggedMedias = activeMedias.map((media, index) => {
    return index < media.order ? { ...media, order: index } : media;
  });

  const reoderedDestroyedTaggedMedias = destroyedMedias.map(media => {
    return { ...media, order: -1 };
  });

  return [...reoderedActiveTaggedMedias, ...reoderedDestroyedTaggedMedias, ...otherMedias];
};

export const prepareMediasToStore = (medias: Media[]): Partial<Media>[] => {
  return medias.map(media => {
    return isNewMedia(media) ? R.omit(['id'], media) : R.omit(['url', 'isNew'], media);
  });
};

export const prepareMatterportsToStore = (links: Matterport[]): Partial<Matterport>[] => {
  return links.map((link, index) => {
    const linkToStore = isNewMedia(link) ? R.omit(['id', 'isNew'], link) : R.omit(['isNew'], link);
    return {
      ...linkToStore,
      order: index,
    };
  });
};

export const sortMediasByTags = (medias: Media[], tags: string[]): Media[] => {
  return tags.reduce((acc, tag) => [...acc, ...getOrderedMediasByTag(medias, tag)], []);
};
