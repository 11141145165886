import { AxiosResponse } from 'axios';
import { SuggestedSpacesParams } from 'types/resources/Space';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const SuggestedSpacesRepository = {
  create(params: SuggestedSpacesParams): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1LandlordSuggestedSpacesPath();
    return Fetcher.post(url, params);
  },
};

export default SuggestedSpacesRepository;
