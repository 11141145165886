/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { createSlice } from '@reduxjs/toolkit';
import AmenitiesRepository from 'repositories/AmenitiesRepository';
import { useAppDispatch } from 'hooks/useRedux';
import Amenity from 'types/resources/Amenity';

export type AmenitiesSliceStateType = {
  amenities: Amenity[];
  fetchStatus: FetchStatus;
};

export type AmenitiesSliceActionsType = {
  loadAmenities: () => Promise<void>;
};

const initialState: AmenitiesSliceStateType = {
  amenities: [],
  fetchStatus: FetchStatus.idle,
};

const slice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {
    loadAmenitiesStart(state) {
      state.fetchStatus = FetchStatus.pending;
    },
    loadAmenitiesSuccess(state, { payload }) {
      state.fetchStatus = FetchStatus.fulfilled;
      state.amenities = payload.items;
    },
    loadAmenitiesFail(state) {
      state.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = slice;

export const useAmenitiesActions = (): AmenitiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAmenities = async () => {
    dispatch(actions.loadAmenitiesStart());
    try {
      const result = await AmenitiesRepository.index();
      dispatch(actions.loadAmenitiesSuccess(result));
    } catch (e) {
      dispatch(actions.loadAmenitiesFail());
      throw e;
    }
  };

  return {
    loadAmenities,
  };
};

export default slice.reducer;
