import { Button, Dialog, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import Box from 'components/Box';
import { ConfirmationDialogProps } from 'types/confirmationDialog';
import clsx from 'utils/clsx';

import styles from './styles';

const ConfirmationDialog: FC<ConfirmationDialogProps> = props => {
  const { t } = useTranslation('common');
  const {
    open,
    title,
    subtitle,
    onConfirm,
    onDismiss,
    confirmButtonText,
    cancelButtonText = t('cancel'),
    type = 'negative',
    size = 'medium',
  } = props;

  const isMediumSize = size === 'medium';
  const isBigSize = size === 'big';

  const renderButtons = () => {
    switch (type) {
      case 'positive':
        return (
          <>
            <Button sx={styles.positiveConfirmButton} color="primary" onClick={() => onConfirm()}>
              <Typography variant="tabsTextBold" component="p">
                {confirmButtonText}
              </Typography>
            </Button>
            <Button sx={styles.positiveCancelButton} variant="text" onClick={() => onDismiss()}>
              <Typography variant="tabsTextBold" component="p">
                {cancelButtonText}
              </Typography>
            </Button>
          </>
        );
      case 'negative':
        return (
          <>
            <Button sx={styles.negativeCancelButton} color="primary" onClick={() => onDismiss()}>
              <Typography variant="tabsTextBold" component="p">
                {cancelButtonText}
              </Typography>
            </Button>
            <Button sx={styles.negativeConfirmButton} variant="text" color="error" onClick={() => onConfirm()}>
              <Typography variant="tabsTextBold" component="p">
                {confirmButtonText}
              </Typography>
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onDismiss} aria-describedby="alert-dialog-slide-description">
      <Box
        sx={clsx(styles.dialog, [
          [styles.mediumSize, isMediumSize],
          [styles.bigSize, isBigSize],
        ])}
      >
        <Box sx={styles.closeButton} component="button" onClick={onDismiss}>
          <Icon name="close" />
        </Box>
        <Typography sx={styles.title} variant="heading2" component="h2">
          {title}
        </Typography>
        {subtitle && (
          <Typography component="p" variant="body4">
            {subtitle}
          </Typography>
        )}
        <Box sx={styles.actions}>{renderButtons()}</Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
