import { Stack, Typography } from '@material-ui/core';
import Box from 'components/Box';
import R from 'ramda';
import { FC, ReactNode } from 'react';
import clsx from 'utils/clsx';

import styles from './styles';

type FormRowType = {
  label: string;
  annotation?: JSX.Element | string | null;
  children?: ReactNode;
  required?: boolean;
  densed?: boolean;
  column?: boolean;
};

const FormRow: FC<FormRowType> = (props): JSX.Element => {
  const { children, label, annotation = null, required = false, densed = false, column = false } = props;

  return (
    <Stack
      direction={{ mobile: 'column', desktop: column ? 'column' : 'row' }}
      sx={clsx(styles.root, [[styles.densed, densed]])}
      spacing={{ mobile: 0, desktop: 2.5 }}
    >
      <Box sx={styles.label}>
        <Typography
          sx={clsx(styles.title, [[styles.withoutAnnotation, !annotation]])}
          component="p"
          variant="subtitle3"
        >
          {label}
          {required && (
            <Typography component="span" color="red.main">
              {' '}
              *
            </Typography>
          )}
        </Typography>
        {!R.isNil(annotation) && (
          <Typography variant="body5" component="p" color="black.darker">
            {annotation}
          </Typography>
        )}
      </Box>
      <Box sx={clsx(styles.content, [[styles.contentWithAnnotation, Boolean(annotation)]])}>{children}</Box>
    </Stack>
  );
};

export default FormRow;
