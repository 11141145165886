import { FC } from 'react';
import ReactPlayer from 'react-player';
import Box from 'components/Box';

import styles from './styles';

type VideoPlayerProps = {
  url: string;
};

const VideoPlayer: FC<VideoPlayerProps> = props => {
  const { url } = props;

  return (
    <Box sx={styles.player}>
      <ReactPlayer url={url} controls width="100%" height="100%" className="react-player" />
    </Box>
  );
};

export default VideoPlayer;
