import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  wrapperMobile: {
    marginRight: '38px',
  },
  messages: {
    color: 'black.main',
    width: 24,
    height: 24,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  messagesWhite: {
    color: 'white.main',
  },
};

export default styles;
