/* eslint-disable no-param-reassign */
import BuildingsRepository from 'repositories/landlord/draft/BuildingsRepository';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { FetchStatus } from 'enums/FetchStatus';

export type BuildingsSliceStateType = {
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const createDraftBuilding = createAsyncThunk('landlords/draft/buildings/create', BuildingsRepository.create);
export const updateDraftBuilding = createAsyncThunk('landlords/draft/buildings/update', BuildingsRepository.update);

const initialState: BuildingsSliceStateType = {
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords/draft/buildings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createDraftBuilding.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createDraftBuilding.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createDraftBuilding.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });
    builder.addCase(updateDraftBuilding.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateDraftBuilding.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateDraftBuilding.rejected, (state, { error }) => {
      state.update.fetchStatus = FetchStatus.failed;
      state.update.error = error;
    });
  },
});

export default slice.reducer;
