/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { FetchStatus } from 'enums/FetchStatus';
import SpaceRentsRepository from 'repositories/trotter/SpaceRentsRepository';
import Space, { SpaceRent } from 'types/resources/Space';

export type SpaceRentsSliceStateType = {
  spaceRentsInProgress: Space[];
  spaceRentsRented: Space[];
  spaceRent: SpaceRent;
  indexInProgress: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  indexRented: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  pay: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  failPayment: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  cancelSubscription: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  cancelPayment: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadSpaceRentsInProgress = createAsyncThunk(
  'trotter/spaceRents/loadInProgress',
  SpaceRentsRepository.index,
);
export const loadSpaceRentsRented = createAsyncThunk('trotter/spaceRents/loadRented', SpaceRentsRepository.index);
export const loadSpaceRent = createAsyncThunk('trotter/spaceRent/load', SpaceRentsRepository.show);
export const createSpaceRent = createAsyncThunk('trotter/spaceRents/create', SpaceRentsRepository.create);
export const failPaymentSpaceRent = createAsyncThunk(
  'trotter/spaceRents/failPayment',
  SpaceRentsRepository.failPayment,
);
export const cancelSubscriptionSpaceRent = createAsyncThunk(
  'trotter/spaceRents/cancelSubscription',
  SpaceRentsRepository.cancelSubscription,
);
export const cancelPaymentSpaceRent = createAsyncThunk(
  'trotter/spaceRents/cancelPayment',
  SpaceRentsRepository.cancelPayment,
);

const initialState: SpaceRentsSliceStateType = {
  spaceRentsInProgress: [],
  spaceRentsRented: [],
  spaceRent: {} as SpaceRent,
  indexInProgress: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  indexRented: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  pay: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  failPayment: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  cancelSubscription: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  cancelPayment: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'trotter/spaceRents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // index in-progress
    builder.addCase(loadSpaceRentsInProgress.pending, state => {
      state.indexInProgress.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpaceRentsInProgress.fulfilled, (state, { payload }) => {
      state.spaceRentsInProgress = payload.items.map(({ space, ...spaceRent }) => ({
        ...space,
        orderId: spaceRent.orderId,
        spaceRentId: spaceRent.id,
        spaceRent,
      }));
      state.indexInProgress.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpaceRentsInProgress.rejected, (state, { error }) => {
      state.indexInProgress.fetchStatus = FetchStatus.failed;
      state.indexInProgress.error = error;
    });

    // index rented
    builder.addCase(loadSpaceRentsRented.pending, state => {
      state.indexRented.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpaceRentsRented.fulfilled, (state, { payload }) => {
      state.spaceRentsRented = payload.items.map(({ space, ...spaceRent }) => ({
        ...space,
        orderId: spaceRent.orderId,
        spaceRentId: spaceRent.id,
        spaceRent,
      }));
      state.indexRented.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpaceRentsRented.rejected, (state, { error }) => {
      state.indexRented.fetchStatus = FetchStatus.failed;
      state.indexRented.error = error;
    });

    // create
    builder.addCase(createSpaceRent.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createSpaceRent.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createSpaceRent.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });

    // fail payment
    builder.addCase(failPaymentSpaceRent.pending, state => {
      state.failPayment.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(failPaymentSpaceRent.fulfilled, state => {
      state.failPayment.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(failPaymentSpaceRent.rejected, (state, { error }) => {
      state.failPayment.fetchStatus = FetchStatus.failed;
      state.failPayment.error = error;
    });

    // cancel subscription
    builder.addCase(cancelSubscriptionSpaceRent.pending, state => {
      state.cancelSubscription.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(cancelSubscriptionSpaceRent.fulfilled, state => {
      state.cancelSubscription.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(cancelSubscriptionSpaceRent.rejected, (state, { error }) => {
      state.cancelSubscription.fetchStatus = FetchStatus.failed;
      state.cancelSubscription.error = error;
    });

    // cancel payment
    builder.addCase(cancelPaymentSpaceRent.pending, state => {
      state.cancelPayment.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(cancelPaymentSpaceRent.fulfilled, state => {
      state.cancelPayment.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(cancelPaymentSpaceRent.rejected, (state, { error }) => {
      state.cancelPayment.fetchStatus = FetchStatus.failed;
      state.cancelPayment.error = error;
    });

    // show
    builder.addCase(loadSpaceRent.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpaceRent.fulfilled, (state, { payload }) => {
      state.spaceRent = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpaceRent.rejected, (state, { error }) => {
      state.show.fetchStatus = FetchStatus.failed;
      state.show.error = error;
    });
  },
});

export default slice.reducer;
