import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { CompanyDetailsFormDataToSubmit } from 'forms/landlord/Settings/CompanyDetailsForm';
import { LandlordInSettings } from 'types/resources/Landlord';

const LandlordRepository = {
  index(): Promise<LandlordInSettings> {
    const url = apiRoutes.apiSiteV1LandlordPath();
    return Fetcher.get(url);
  },
  update(params: CompanyDetailsFormDataToSubmit): Promise<LandlordInSettings> {
    const url = apiRoutes.apiSiteV1LandlordPath();
    return Fetcher.put(url, params);
  },
};

export default LandlordRepository;
