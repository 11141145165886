import { FC } from 'react';
import Box from 'components/Box';
import User, { EmployeeUser } from 'types/resources/User';
import { UsersPresenter } from 'presenters';
import { toCamelCase } from 'utils/string';

import styles from './styles';

type UserStatusBadgeProps = {
  user: User | EmployeeUser;
};

const UserStatusBadge: FC<UserStatusBadgeProps> = props => {
  const { user } = props;
  const style = `state_${toCamelCase(user.state)}`;
  const statusText = UsersPresenter.state(user);

  return <Box sx={styles[style]}>{statusText}</Box>;
};

export default UserStatusBadge;
