import { FC } from 'react';
import { Button, Stack, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks';
import Icon from 'components/Icon';

import styles from './styles';

const TrotRules: FC = () => {
  const { t } = useTranslation('publicPages');
  const { closeModal } = useModals();

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={() => closeModal()}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('Rules.title')}
      </Typography>
      <Box sx={styles.content}>
        <Typography variant="h3" component="p" sx={styles.contentTitle}>
          {t('Rules.rules')}
        </Typography>
        <Box sx={styles.text}>
          <Typography variant="body4">{t('Rules.text')}</Typography>
        </Box>
      </Box>
      <Button onClick={() => closeModal()} sx={styles.okButton}>
        Ok
      </Button>
    </Stack>
  );
};

export default TrotRules;
