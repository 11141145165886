import { FC } from 'react';
import Building from 'types/resources/Building';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { CardMedia } from '@material-ui/core';
import { BuildingPresenter } from 'presenters';
import { isNil } from 'ramda';

import styles from './styles';

type ImageProps = {
  building: Building;
  onClick: (building: Building) => void;
};

const Image: FC<ImageProps> = props => {
  const { building, onClick } = props;

  const imageUrl = BuildingPresenter.coverUrl(building);
  return isNil(imageUrl) ? (
    <ImagePlaceholder sx={styles.image} name="building" preserveAspectRatio="none" onClick={() => onClick(building)} />
  ) : (
    <CardMedia sx={styles.image} image={imageUrl} onClick={() => onClick(building)} />
  );
};

export default Image;
