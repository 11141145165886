import { PaletteOptions, alpha } from '@material-ui/core/styles';

const navy = '#0B2050';
const brightBlue = '#416AF1';
const pale = '#D9E9EF';
const athensGray = '#E7E9ED';
const lightBlue = '#91CEDD';
const red = '#D92546';
const white = '#fff';
const black = '#050C1D';
const gray = '#959595';
const cadetBlue = '#A5AEBF';
const lightGray = '#CED2DC';
const blue = '#00205C';
const lighterGray = '#DFDFDF';
const blueBlack = '#24396B';
const orange = '#FF971D';
const blueAttention = '#7A97F4';

interface Colors {
  navy: {
    main: string;
    background: string;
    secondary: string;
    light: string;
    lighter: string;
  };
  pale: {
    main: string;
    light: string;
    lighter: string;
  };
  lightBlue: {
    main: string;
    light: string;
  };
  brightBlue: {
    main: string;
    light: string;
  };
  blue: {
    main: string;
    cadet: string;
    tealish: string;
    black: string;
    attention: string;
  };
  red: {
    main: string;
    light: string;
    attention: string;
  };
  success: {
    main: string;
  };
  black: {
    main: string;
    light: string;
    disabled: string;
    darker: string;
  };
  white: {
    main: string;
    light: string;
  };
  gray: {
    main: string;
    athens: string;
    light: string;
    lighter: string;
  };
  orange: {
    main: string;
  };
}

const COLORS: Colors = {
  navy: {
    main: navy,
    background: '#F5F6FA',
    secondary: alpha(navy, 0.1),
    light: alpha(navy, 0.15),
    lighter: alpha(navy, 0.2),
  },
  pale: {
    main: pale,
    light: alpha(pale, 0.2),
    lighter: alpha(pale, 0.4),
  },
  lightBlue: {
    main: lightBlue,
    light: alpha(lightBlue, 0.2),
  },
  brightBlue: {
    main: brightBlue,
    light: alpha(brightBlue, 0.2),
  },
  blue: {
    main: blue,
    cadet: cadetBlue,
    tealish: alpha(blue, 0.8),
    black: blueBlack,
    attention: blueAttention,
  },
  red: {
    main: red,
    light: alpha(red, 0.4),
    attention: '#DB123D',
  },
  success: {
    main: '#8DCB3B',
  },
  black: {
    disabled: '#D3D3D3',
    main: black,
    light: '#D6E7EC',
    darker: alpha('#050C1D', 0.5),
  },
  white: {
    main: white,
    light: alpha(white, 0.8),
  },
  gray: {
    main: gray,
    athens: athensGray,
    light: lightGray,
    lighter: lighterGray,
  },
  orange: {
    main: orange,
  },
};

const palette: PaletteOptions & Colors = {
  primary: {
    main: brightBlue,
    contrastText: '#fff',
  },
  background: {
    default: '#fff',
  },
  ...COLORS,
};

export default palette;
