/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';
import { ModalType } from 'enums/ModalType';

export type ModalsSliceStateType = {
  isOpened: boolean;
  type: ModalType | null;
  extra: null;
};

export type ModalsSliceActionsType = {
  closeModal: () => void;
  openModal: (props) => void;
};

const initialState: ModalsSliceStateType = {
  isOpened: false,
  type: null,
  extra: null,
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, { payload }): void => {
      const { type, extra } = payload;
      state.isOpened = true;
      state.type = type;
      state.extra = extra || null;
    },
    closeModal: (state): void => {
      state.isOpened = false;
      state.type = null;
      state.extra = null;
    },
  },
});

const { actions } = slice;

export const useModalsActions = (): ModalsSliceActionsType => {
  const dispatch = useAppDispatch();

  const closeModal = async () => {
    dispatch(actions.closeModal());
  };

  const openModal = props => {
    dispatch(actions.openModal(props));
  };

  return {
    closeModal,
    openModal,
  };
};

export default slice.reducer;
