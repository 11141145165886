/* eslint-disable no-param-reassign */
import BuildingsRepository from 'repositories/landlord/BuildingsRepository';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import Building from 'types/resources/Building';

export type BuildingsSliceStateType = {
  buildings: Building[];
  building: Building;
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadBuildings = createAsyncThunk('landlords/buildings/load', BuildingsRepository.index);
export const loadBuilding = createAsyncThunk(
  'landlords/buildings/loadBuilding',
  async (buildingId: ID, { rejectWithValue }) => {
    try {
      return await BuildingsRepository.show(buildingId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const createBuilding = createAsyncThunk('landlords/buildings/create', BuildingsRepository.create);
export const updateBuilding = createAsyncThunk('landlords/buildings/update', BuildingsRepository.update);
export const deleteBuilding = createAsyncThunk('landlords/buildings/delete', BuildingsRepository.delete);

const initialState: BuildingsSliceStateType = {
  buildings: [] as Building[],
  building: {} as Building,
  meta: { perPage: 10 } as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords/buildings',
  initialState,
  reducers: {
    resetBuildings(state) {
      state.buildings = initialState.buildings;
      state.index = initialState.index;
      state.meta = initialState.meta;
    },
    resetBuilding(state) {
      state.building = initialState.building;
      state.show = initialState.show;
      state.create = initialState.create;
      state.update = initialState.update;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadBuildings.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadBuildings.fulfilled, (state, { payload }) => {
      state.buildings = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadBuildings.rejected, (state, { error }) => {
      state.index.fetchStatus = FetchStatus.failed;
      state.index.error = error;
    });
    builder.addCase(createBuilding.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createBuilding.fulfilled, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.fulfilled;
      state.building = payload;
    });
    builder.addCase(createBuilding.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });
    builder.addCase(loadBuilding.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadBuilding.fulfilled, (state, { payload }) => {
      state.building = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadBuilding.rejected, (state, { error }) => {
      state.show.error = error;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateBuilding.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateBuilding.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.building = payload;
    });
    builder.addCase(updateBuilding.rejected, (state, { error }) => {
      state.show.fetchStatus = FetchStatus.failed;
      state.show.error = error;
    });
    builder.addCase(deleteBuilding.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteBuilding.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteBuilding.rejected, (state, { error }) => {
      state.delete.fetchStatus = FetchStatus.failed;
      state.delete.error = error;
    });
  },
});

export default slice.reducer;

const {
  actions: { resetBuilding, resetBuildings },
} = slice;

export { resetBuilding, resetBuildings };
