import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  itemContainer: {
    marginBottom: 5,
  },
  contentTitle: {
    marginBottom: 1,
  },
  description: {
    lineHeight: '19.5px',
  },
};

export default styles;
