import common from './common';
import forms from './forms';
import resources from './resources';
import adminPages from './pages/admin';
import landlordPages from './pages/landlord';
import publicPages from './pages/public';
import layouts from './layouts/common';
import adminLayouts from './layouts/admin';
import landlordLayouts from './layouts/landlord';
import publicLayouts from './layouts/public';
import components from './components/common';
import publicComponents from './components/public';
import adminComponents from './components/admin';
import landlordComponents from './components/landlord';
import chatComponents from './components/chat';

const en = {
  forms,
  common,
  layouts,
  resources,
  components,
  adminPages,
  landlordPages,
  publicPages,
  adminLayouts,
  landlordLayouts,
  publicLayouts,
  publicComponents,
  adminComponents,
  landlordComponents,
  chatComponents,
};

export default en;

export const ns = Object.keys(en);
