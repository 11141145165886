import { FC } from 'react';
import { Stack } from '@material-ui/core';
import Icon from 'components/Icon';
import { externalRoutes } from 'routes';

import styles from './styles';

const socialLinks = [
  {
    iconName: 'facebook',
    href: externalRoutes.facebookPath(),
  },
  {
    iconName: 'twitter',
    href: externalRoutes.twitterPath(),
  },
  {
    iconName: 'linkedin',
    href: externalRoutes.linkedInPath(),
  },
  {
    iconName: 'instagram',
    href: externalRoutes.instagramPath(),
  },
];

const SocialLinks: FC = () => {
  return (
    <Stack sx={styles.socialLinks} spacing={2} direction="row">
      {socialLinks.map(social => {
        return (
          <a key={social.iconName} rel="noopener noreferrer" href={social.href} target="_blank">
            <Icon name={social.iconName} />
          </a>
        );
      })}
    </Stack>
  );
};

export default SocialLinks;
