import * as yup from 'yup';
import i18n from 'locales/i18n';
import { ListingUpdateStatusData } from 'types/resources/Space';

export type DeclineFormData = {
  declineReason: string;
};

const declineReasonFieldName = i18n.t('adminPages:Listing.declineReason');

export const validationFields = {
  declineReason: yup
    .string()
    .default('')
    .required(() => i18n.t('forms:validations.required', { field: declineReasonFieldName })),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues: DeclineFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: DeclineFormData): ListingUpdateStatusData => {
  const { declineReason } = values;

  return {
    declineReason,
  };
};
