import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  searchButton: {
    m: 0.5,
    width: 115,
    height: 50,
  },
};

export default styles;
