import { FC } from 'react';
import { Typography, ButtonBase, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from 'components/Icon';
import { useScreen } from 'hooks';

import styles from './styles';

type ToggleFiltersButtonProps = {
  onClick: () => void;
  isAllFiltersOpened: boolean;
};

const ToggleFiltersButton: FC<ToggleFiltersButtonProps> = props => {
  const { onClick, isAllFiltersOpened } = props;
  const { t } = useTranslation(['components', 'common']);
  const { lessThanDesktop } = useScreen();

  if (lessThanDesktop) {
    return (
      <Box onClick={onClick} sx={styles.filtersMobile}>
        <Icon name="controlButtons" className="controls" />
      </Box>
    );
  }

  return (
    <ButtonBase onClick={onClick} sx={styles.toggleOpenAllFilters}>
      <Icon name="controlButtons" className="controls" />
      <Typography sx={styles.showAllFiltersText} variant="body3" component="p">
        {!isAllFiltersOpened ? t('components:Filter.showAllFilters') : t('components:Filter.hideAllFilters')}
      </Typography>
      <Box sx={styles.filtersExpandIcon}>
        <ExpandMoreIcon />
      </Box>
    </ButtonBase>
  );
};

export default ToggleFiltersButton;
