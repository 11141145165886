import * as yup from 'yup';
import i18n from 'locales/i18n';

export type SignUpOwnerFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type SignUpOwnerFormDataToSubmit = {
  user: SignUpOwnerFormData;
};

const firstNameFieldName = i18n.t('common:firstName');
const lastNameFieldName = i18n.t('common:lastName');
const emailFieldName = i18n.t('common:yourEmail');
const phoneFieldName = i18n.t('common:contactPhone');

export const validationFields = {
  email: yup
    .string()
    .email(i18n.t('forms:validations.email'))
    .required(i18n.t('forms:validations.required', { field: emailFieldName }))
    .default(''),
  firstName: yup
    .string()
    .required(i18n.t('forms:validations.required', { field: firstNameFieldName }))
    .default(''),
  lastName: yup
    .string()
    .required(i18n.t('forms:validations.required', { field: lastNameFieldName }))
    .default(''),
  phone: yup
    .string()
    .required(i18n.t('forms:validations.required', { field: phoneFieldName }))
    .default(''),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues: SignUpOwnerFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: SignUpOwnerFormData): SignUpOwnerFormDataToSubmit => {
  return {
    user: {
      ...values,
    },
  };
};
