export const rollbarConfig = {
  accessToken: Settings.rollbarAccessToken,
  enabled: ['staging', 'production', 'uat', 'qa'].includes(Settings.env),
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: Settings.env,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: Settings.version,
        guess_uncaught_frames: true,
      },
    },
  },
  ignoredMessages: [
    'Request failed with status code 401',
    'Request failed with status code 403',
    'Request failed with status code 404',
  ],
};
