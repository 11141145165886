import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  outlinedAdornment: {
    marginLeft: '16px',
    marginRight: '6px',
    color: 'brightBlue.main',
    '& > svg': {
      width: '17px',
      height: '17px',
      transform: 'translateY(3px)',
    },
  },
};

export default styles;
