import Image, { ImageWithoutMediaType, ImageWithoutLabelType } from 'types/resources/Image';
import Video, { VideoWithoutMediaType, VideoWithoutLabelType } from 'types/resources/Video';
import Matterport, { MatterportWithoutMediaType } from 'types/resources/Matterport';
import { MediaType } from 'enums/MediaType';
import { GalleryFileState } from 'enums/GalleryFileState';
import Media from 'types/resources/Media';
import { sortMediasByTags } from 'utils/galleryAlbum';
import { isEmpty, head } from 'ramda';

export const addPhotoLabel = (object: ImageWithoutLabelType, label: string): Image => {
  return { ...object, label };
};

export const addVideoLabel = (object: VideoWithoutLabelType, label: string): Video => {
  return { ...object, label };
};

export const addPhotoMediaType = (object: ImageWithoutMediaType): Image => {
  return { ...object, type: MediaType.photo };
};

export const addVideoMediaType = (object: VideoWithoutMediaType): Video => {
  return { ...object, type: MediaType.video };
};

export const addMatterportMediaType = (object: MatterportWithoutMediaType): Matterport => {
  return { ...object, type: MediaType.matterport };
};

export const isMediaUploading = (media: Media): boolean => {
  return media.state === GalleryFileState.inProgress;
};

export const getMediaPreviewUrl = (media: Media, options?: { full: boolean }): string => {
  if (media.type === MediaType.video) {
    return media.previewUrl;
  }
  if (media.type === MediaType.photo && options?.full) {
    return media.url;
  }
  if (media.type === MediaType.photo) {
    return media.previewUrl || media.url;
  }
  return media.url;
};

export const getHeadImageUrl = (photos: Image[], tags: string[]): string | null => {
  const sortedImages = sortMediasByTags(photos, tags) as Image[];
  if (isEmpty(sortedImages)) {
    return null;
  }

  return head(sortedImages).previewUrl || head(sortedImages).url;
};

export const isOverTheLimitOfImages = (medias: Media[], tag: string, limit: number): boolean =>
  medias.filter(media => media.tag === tag).length < limit;
