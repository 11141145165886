import { AxiosResponse } from 'axios';
import { SignUpOwnerFormDataToSubmit } from 'forms/public/signUpOwnerForm';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const OwnerRequestsRepository = {
  create(params: SignUpOwnerFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1OwnerRequestsPath();
    return Fetcher.post(url, params);
  },
};

export default OwnerRequestsRepository;
