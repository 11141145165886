import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikHelpers } from 'formik';
import { SignUpOwnerFormData, initialValues, validationSchema, attributesToSubmit } from 'forms/public/signUpOwnerForm';
import Icon from 'components/Icon';
import InputField from 'components/InputField';
import SignInFormsToggler from 'components/SignInFormsToggler';
import OwnerRequestsRepository from 'repositories/OwnerRequestsRepository';
import Loader from 'components/Loader';
import { handleErrors } from 'utils/errors';
import { appRoutes } from 'routes';
import { SignUpFormTab } from 'enums/SignUpFormTab';
import clsx from 'utils/clsx';

import styles from './styles';

const EMAIL_HAS_BEEN_SENT_STATUS = 'EMAIL_HAS_BEEN_SENT_STATUS';

type SignUpOwnerProps = {
  onTabChange?: (newActiveTab: SignUpFormTab) => void;
  isInHeroBlock?: boolean;
};

const SignUpOwner: FC<SignUpOwnerProps> = props => {
  const { onTabChange, isInHeroBlock } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);

  const onSubmit = async (
    formData: SignUpOwnerFormData,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<SignUpOwnerFormData>,
  ) => {
    const params = attributesToSubmit(formData);

    try {
      await OwnerRequestsRepository.create(params);
      setStatus(EMAIL_HAS_BEEN_SENT_STATUS);
    } catch (error: unknown) {
      handleErrors(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });
  const { values, handleChange, handleBlur, handleSubmit, touched, errors, isSubmitting, status } = formik;

  if (status === EMAIL_HAS_BEEN_SENT_STATUS) {
    return (
      <Box sx={styles.hint}>
        <Typography mb="auto" textAlign="center" variant="body1">
          {t('components:SignUpOwner.success')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={clsx(styles.wrapper, [[styles.wrapperHero, isInHeroBlock]])}>
      <Typography variant={isInHeroBlock ? 'h2' : 'mainPageHeading1'} sx={styles.titleWithMarker} component="h1">
        {t('components:SignUp.createAccount')}
      </Typography>
      <SignInFormsToggler activeTab={SignUpFormTab.owners} onTabChange={onTabChange} isInHeroBlock={isInHeroBlock} />
      <Box sx={styles.base}>
        <Box sx={styles.container}>
          <Box sx={styles.form} noValidate onSubmit={handleSubmit} component="form">
            <InputField
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              fullWidth
              id="firstName"
              name="firstName"
              autoComplete="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
              placeholder={t('common:firstName')}
              size={isInHeroBlock ? 'small' : 'medium'}
            />
            <InputField
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              fullWidth
              id="lastName"
              name="lastName"
              autoComplete="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
              placeholder={t('common:lastName')}
              size={isInHeroBlock ? 'small' : 'medium'}
            />
            <InputField
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
              placeholder={t('common:yourEmail')}
              startAdornment={<Icon name="email" sx={styles.icon} />}
              size={isInHeroBlock ? 'small' : 'medium'}
            />
            <InputField
              error={touched.phone && !!errors.phone}
              helperText={touched.phone && errors.phone}
              fullWidth
              id="phone"
              name="phone"
              autoComplete="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              sx={clsx(styles.field, [[styles.fieldHero, isInHeroBlock]])}
              placeholder={t('common:contactPhone')}
              size={isInHeroBlock ? 'small' : 'medium'}
            />
            <Button sx={styles.submit} type="submit" disabled={isSubmitting} size={isInHeroBlock ? 'small' : 'medium'}>
              <Typography>{t('components:SignUpOwner.request')}</Typography>
            </Button>
            <Typography sx={styles.signInTypo} variant="body3">
              {t('components:SignUp.alreadyHaveAccount')}{' '}
              <Typography variant="body3" component={Link} to={appRoutes.public.signInPath()}>
                {t('components:SignUp.signIn')}
              </Typography>
            </Typography>
          </Box>
          <Loader open={isSubmitting} />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpOwner;
