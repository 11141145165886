import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import { Typography } from '@material-ui/core';

import styles from './styles';

type SpacesMatchesProps = {
  spacesCount: number;
};

const SpacesMatches: FC<SpacesMatchesProps> = props => {
  const { spacesCount } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);

  return (
    <Box sx={styles.root}>
      <Typography variant="subtitle2">
        {t('components:BuildingCard.spacesInBuilding', { count: spacesCount })}
      </Typography>
      <Typography variant="body3" color="black.darker">
        {t('components:BuildingCard.matchYourCriteria', { count: spacesCount })}
      </Typography>
    </Box>
  );
};

export default SpacesMatches;
