import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    '& .Mui-selected': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    '& .MuiTypography-root': {
      fontFamily: 'Heebo, sans-serif !important',
    },
    '& .MuiTypography-caption': {
      fontWeight: '700',
      fontSize: '13px',
      lineHeight: '24px',
      color: 'black.main',
    },
    '& .MuiPickersDay-root': {
      color: 'brightBlue.main',
      '&:hover': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },
    '& .MuiPickersDay-today': {
      color: 'black.main',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
  trashButton: {
    left: '105px',
    '&[disabled] svg': {
      color: 'black.main',
      opacity: 0.5,
    },
    '&[disabled] p': {
      color: 'black.main',
      opacity: 0.5,
    },
    '&:hover': {
      background: 'none',
    },
  },
  trashIcon: {
    width: '15px',
    height: '15px',
    color: 'red.main',
    marginBottom: '2px',
  },
  trashButtonText: {
    color: 'red.main',
    marginLeft: '10px',
  },
  day: {
    borderRadius: '0 !important',
    '&:hover': {
      backgroundColor: 'navy.background',
    },
  },
  dayCurrent: {
    border: 'none !important',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '4px',
      left: 'calc(50% - 2px)',
      width: '4px',
      height: '4px',
      backgroundColor: 'brightBlue.main',
      borderRadius: '100%',
    },
  },
  dayNotAllowed: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
};

export default styles;
