import { FC, useState, useEffect, Dispatch, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Stack, Toolbar, Drawer, ButtonBase, Button, Typography } from '@material-ui/core';
import Icon from 'components/Icon';
import Container from 'components/Container';
import MessagesBadge from 'components/MessagesBadge';
import PhoneNumber from 'components/PhoneNumber';
import { appRoutes } from 'routes';
import { useScreen, useUsers, useSessions, useRouter } from 'hooks';
import { UsersPresenter } from 'presenters';
import { HeaderLayout } from 'enums/HeaderLayout';
import { ChatContext } from 'contexts/ChatContext';
import clsx from 'utils/clsx';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import NonAuthUser from './components/NonAuthUser';
import AuthUser from './components/AuthUser';

type HeaderProps = {
  type: HeaderLayout;
  filterRef?: Dispatch<unknown>;
};

const Header: FC<HeaderProps> = props => {
  const { filterRef, type } = props;
  const { lessThanTablet, lessThanDesktop } = useScreen();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { currentUser } = useUsers();
  const { signOut } = useSessions();
  const { push, pathname, location } = useRouter();
  const { chatClient } = useContext(ChatContext);

  const { t } = useTranslation(['publicLayouts', 'common']);

  const isSearchPage = pathname === appRoutes.public.searchPath();
  const isRootPath = pathname === appRoutes.public.rootPath();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleLogout = async () => {
    await signOut();
    chatClient?.disconnectUser();
    push(appRoutes.public.rootPath());
  };

  const handleMenuButtonClick = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const isGuest = UsersPresenter.isGuest(currentUser);

  const renderLogin = () => {
    if (isRootPath) {
      return (
        <Box sx={styles.loginButtons}>
          <PhoneNumber layoutType={type} />
          <Typography component={Link} to={appRoutes.public.signUpPath()} variant="body3" sx={styles.createAccount}>
            {t('common:createAccount')}
          </Typography>
          <Button
            component={Link}
            to={appRoutes.public.signInPath()}
            size="small"
            variant="outlined"
            sx={styles.signInButton}
          >
            <Icon name="lock" />
            <Typography variant="body3">{t('publicLayouts:BaseLayout.Header.login')}</Typography>
          </Button>
        </Box>
      );
    }
    return (
      <Box sx={styles.loginToAccountBlock}>
        <PhoneNumber layoutType={type} />
        <ButtonBase component={Link} to={appRoutes.public.signInPath()} sx={styles.loginToAccount}>
          <Icon name="lock" />
          <Typography variant="body3">{t('publicLayouts:BaseLayout.Header.loginToAccount')}</Typography>
        </ButtonBase>
      </Box>
    );
  };

  const renderContent = () => {
    if (lessThanTablet) {
      return <PhoneNumber layoutType={type} />;
    }
    if (lessThanDesktop) {
      return isGuest ? renderLogin() : <PhoneNumber layoutType={type} />;
    }

    return isGuest ? (
      <NonAuthUser type={type} isRootPath={isRootPath} />
    ) : (
      <AuthUser type={type} onLogout={handleLogout} />
    );
  };

  const isLightLayout = type === HeaderLayout.light;
  const isBaseLayout = type === HeaderLayout.base;
  const isHomeLayout = type === HeaderLayout.home;

  const appBarStyles = clsx(styles.appBar, [
    [styles.appBarLight, isLightLayout],
    [styles.appBarBase, isBaseLayout],
    [styles.appBarHome, isHomeLayout],
    [styles.appBarFixed, isSearchPage],
    [styles.appBarRoot, isRootPath],
  ]);

  return (
    <>
      <AppBar
        color="transparent"
        position="relative"
        sx={clsx(appBarStyles, [
          [styles.appBarGuest, isGuest],
          [styles.appBarGuestSearch, isGuest && isSearchPage],
        ])}
        elevation={0}
      >
        <Container small={isSearchPage}>
          <Toolbar disableGutters sx={styles.toolbar}>
            <Box
              sx={clsx(styles.headerContentWrapper, [
                [styles.headerContentWrapperSearch, isSearchPage],
                [styles.headerContentWrapperSearchGuest, isSearchPage && isGuest],
                [styles.headerContentLessThanDesktopWrapper, lessThanDesktop],
              ])}
            >
              <Box sx={styles.logo}>
                <Link to={appRoutes.public.rootPath()}>
                  <Icon className="logo" name="logo" />
                </Link>
              </Box>
              {lessThanDesktop ? (
                <Box sx={styles.rightContent}>
                  {renderContent()}
                  <Stack direction="row" spacing={0} sx={styles.menuStack}>
                    {!isGuest && <MessagesBadge type={type} />}
                    {drawerOpen ? (
                      <ButtonBase onClick={handleDrawerClose} sx={styles.menuButton}>
                        <Icon name="close" />
                      </ButtonBase>
                    ) : (
                      <ButtonBase onClick={handleMenuButtonClick} sx={styles.menuButton} aria-label="menu-button">
                        <Icon name="menu" />
                      </ButtonBase>
                    )}
                  </Stack>
                </Box>
              ) : (
                renderContent()
              )}
            </Box>

            {filterRef && <div ref={filterRef} />}
          </Toolbar>
        </Container>
        {isLightLayout && <Box sx={clsx(styles.divider, [[styles.dividerLight, isLightLayout]])} />}
      </AppBar>

      <Drawer
        ModalProps={{
          sx: styles.drawerModal,
          BackdropProps: {
            sx: styles.drawerBackdrop,
          },
        }}
        PaperProps={{ sx: styles.drawerPaper }}
        anchor="right"
        open={drawerOpen && lessThanDesktop}
        onClose={handleDrawerClose}
      >
        {isGuest ? (
          <NonAuthUser type={type} isRootPath={isRootPath} />
        ) : (
          <AuthUser type={type} onLogout={handleLogout} />
        )}
      </Drawer>
    </>
  );
};

export default Header;
