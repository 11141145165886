import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import { FC } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTranslation } from 'react-i18next';
import { HeaderLayout } from 'enums/HeaderLayout';
import clsx from 'utils/clsx';

import styles from './styles';

type PhoneInputProps = {
  layoutType: HeaderLayout;
};

const PhoneInput: FC<PhoneInputProps> = props => {
  const { layoutType } = props;
  const { t } = useTranslation(['common']);

  const isLightLayout = layoutType === HeaderLayout.light;
  const isBaseLayout = layoutType === HeaderLayout.base;
  const isHomeLayout = layoutType === HeaderLayout.home;

  const linkStyles = clsx(styles.link, [
    [styles.linkLight, isLightLayout],
    [styles.linkBase, isBaseLayout],
    [styles.linkHome, isHomeLayout],
  ]);

  const iconWrapperStyles = clsx(styles.phoneIconWrapper, [
    [styles.phoneIconWrapperLight, isLightLayout],
    [styles.phoneIconWrapperBase, isBaseLayout],
    [styles.phoneIconWrapperHome, isHomeLayout],
  ]);

  return (
    <Typography component="a" href={`tel:${Settings.trotPhoneNumber}`} variant="subtitle2" sx={linkStyles}>
      <Box sx={iconWrapperStyles}>
        <PhoneIcon sx={styles.phoneIcon} />
      </Box>
      {t('common:trotPhoneNumberLabel')}
    </Typography>
  );
};

export default PhoneInput;
