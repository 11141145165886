import { FC } from 'react';
import Box from 'components/Box';
import useResizeObserver from 'use-resize-observer';

type MatterportProps = {
  url: string;
};

const Matterport: FC<MatterportProps> = props => {
  const { url } = props;
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  return (
    <Box ref={ref} sx={{ height: '100%' }}>
      <iframe
        src={url}
        width={width}
        height={height}
        frameBorder={0}
        allowFullScreen
        title="matterport"
        allow="xr-spatial-tracking"
      />
    </Box>
  );
};

export default Matterport;
