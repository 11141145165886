import { FC } from 'react';
import Box from 'components/Box';

import styles from './styles';

const Separator: FC = props => {
  const { children } = props;

  return <Box sx={styles.separator}>{children}</Box>;
};

export default Separator;
