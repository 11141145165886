/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';

export type NotificationsSliceStateType = {
  isOpened: boolean;
  title: string;
  description: string;
};

export type NotificationsSliceActionsType = {
  closeNotification: () => void;
  openNotification: (props: { title: string; description: string }) => void;
};

const initialState: NotificationsSliceStateType = {
  isOpened: false,
  title: null,
  description: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openNotification: (state, { payload }): void => {
      const { title, description } = payload;
      state.isOpened = true;
      state.title = title;
      state.description = description;
    },
    closeNotification: (state): void => {
      state.isOpened = false;
      state.title = null;
      state.description = null;
    },
  },
});

const { actions } = slice;

export const useNotificationsActions = (): NotificationsSliceActionsType => {
  const dispatch = useAppDispatch();

  const closeNotification = async () => {
    dispatch(actions.closeNotification());
  };

  const openNotification = (props: { title: string; description: string }) => {
    dispatch(actions.openNotification(props));
  };

  return {
    closeNotification,
    openNotification,
  };
};

export default slice.reducer;
