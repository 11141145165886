import { AxiosResponse } from 'axios';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { LandlordFormDataToSubmit } from 'forms/admin/landlordForm';
import { LoadingParams } from 'utils/loadingParams';

const LandlordsRepository = {
  index(params: LoadingParams): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1AdminLandlordsPath();
    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1AdminLandlordPath(id);
    return Fetcher.get(url);
  },
  create(params: LandlordFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1AdminLandlordsPath();
    return Fetcher.post(url, params);
  },
  update({ id, params }: { id: ID; params: RecursivePartial<LandlordFormDataToSubmit> }): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1AdminLandlordPath(id);
    return Fetcher.put(url, params);
  },
  resendInvitation(id: ID): Promise<AxiosResponse> {
    const url = apiRoutes.resendInvitationApiSiteV1AdminLandlordPath(id);
    return Fetcher.put(url, {});
  },
};

export default LandlordsRepository;
