import { FC } from 'react';
import Box from 'components/Box';
import clsx from 'utils/clsx';

import styles from './styles';

type ContainerProps = {
  children: React.ReactNode;
  small?: boolean;
};

const Container: FC<ContainerProps> = props => {
  const { children, small = false } = props;

  const containerStyles = clsx(styles.container, [[styles.containerSmall, small]]);

  return <Box sx={containerStyles}>{children}</Box>;
};

export default Container;
