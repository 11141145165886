import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  item: {
    backgroundColor: 'white.main',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 30px',
    cursor: 'pointer',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
  },
  itemActive: {
    backgroundColor: '#EEF5F7',
  },
};

export default styles;
