import { FC, useState, useEffect } from 'react';
import { Stack, Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals, useRouter } from 'hooks';
import { useSpaces } from 'hooks/landlords';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import SpacesWithCheckbox from 'components/SpacesWithCheckbox';
import { landlordSuggestSpacesParams } from 'utils/loadingParams';
import { isEmpty } from 'ramda';
import { appRoutes } from 'routes';
import { ModalType } from 'enums/ModalType';
import SuggestedSpacesRepository from 'repositories/landlord/SuggestedSpacesRepository';

import styles from './styles';

const LandlordSpaceRentCancelSuggestSpaces: FC = () => {
  const { t } = useTranslation(['landlordPages', 'common']);
  const { push } = useRouter();
  const { closeModal, extra, openModal } = useModals();
  const { suggestedSpaces, loadSuggestedSpaces, isLoadSuggestSpacesFinished } = useSpaces();
  const { spaceRent, space, loadSpaces } = extra;
  const [suggestedSpacesIds, setSuggestedSpacesIds] = useState<ID[]>([]);

  const fetchSpaces = () => {
    const params = landlordSuggestSpacesParams();
    loadSuggestedSpaces(params);
  };

  useEffect(() => {
    fetchSpaces();
  }, []);

  if (!isLoadSuggestSpacesFinished) {
    return <Loader open />;
  }

  const handleSuggestSpacesClick = async () => {
    await SuggestedSpacesRepository.create({
      space: {
        ids: suggestedSpacesIds,
        spaceRentId: spaceRent.id,
      },
    });
    closeModal();
    openModal({
      type: ModalType.landlordSpaceRentCancelSuggestSpacesFinish,
      extra: {
        spaceRent,
      },
    });
  };

  const handleUploadListingClick = () => {
    closeModal();
    push(appRoutes.landlord.newListingPath());
  };

  const handleCancelClick = () => {
    openModal({
      type: ModalType.landlordSpaceRentCancelFinish,
      extra: {
        space,
        spaceRent,
        loadSpaces,
      },
    });
  };

  const hasSuggestedSpaces = !isEmpty(suggestedSpaces);

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={closeModal}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {hasSuggestedSpaces
          ? t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.title', { firstName: spaceRent.user.firstName })
          : t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.otherSpaces')}
      </Typography>
      {hasSuggestedSpaces && (
        <>
          <Typography sx={styles.subtitle} variant="body4">
            {t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.text', { firstName: spaceRent.user.firstName })}
          </Typography>
          <SpacesWithCheckbox spaces={suggestedSpaces} onSpaceSelect={ids => setSuggestedSpacesIds(ids)} />
          <Box sx={styles.buttons}>
            <Button disabled={isEmpty(suggestedSpacesIds)} onClick={handleSuggestSpacesClick} sx={styles.button}>
              {t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.suggestSpaces')}
            </Button>
            <Button sx={styles.button} variant="text" onClick={handleCancelClick}>
              {t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.cancel')}
            </Button>
          </Box>
        </>
      )}
      {!hasSuggestedSpaces && (
        <>
          <Typography sx={styles.subtitle} variant="body4">
            {t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.sorry')}
          </Typography>
          <Box>
            <Button onClick={handleUploadListingClick} sx={styles.button}>
              {t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.upload')}
            </Button>
            <Button sx={styles.button} variant="text" onClick={handleCancelClick}>
              {t('landlordPages:Listings.SpaceRentCancelSuggestSpaces.cancel')}
            </Button>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default LandlordSpaceRentCancelSuggestSpaces;
