import { appRoutes } from 'routes';
import { stringify } from 'utils/searchUtils';

type CityCardinalPoints = {
  cardinalPoints: google.maps.LatLngBoundsLiteral;
  place: string;
  onlyAvailable: boolean;
};

export const NewYork: CityCardinalPoints = {
  cardinalPoints: {
    north: 40.9175771,
    east: -73.70027209999999,
    south: 40.4773991,
    west: -74.25908989999999,
  },
  place: 'New York, NY, USA',
  onlyAvailable: true,
};

export const NewJersey: CityCardinalPoints = {
  cardinalPoints: {
    north: 41.357423,
    east: -73.88506,
    south: 38.788657,
    west: -75.5635861,
  },
  place: 'New Jersey, USA',
  onlyAvailable: true,
};

export const makeSearchPathWithCoordinates = (filters: {
  cardinalPoints: google.maps.LatLngBoundsLiteral;
  place: string;
}): string => {
  const searchPath = appRoutes.public.searchPath();
  const locationPath = stringify(filters);

  return `${searchPath}${locationPath}`;
};
