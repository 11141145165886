import { merge } from 'ramda';
import { Theme } from '@material-ui/core/styles';
import { SxProps } from '@material-ui/system';

type ClsxPair = [styles: SxProps<Theme>, applicable: boolean];

const clsx = (baseSx: SxProps<Theme>, conditionalSx: ClsxPair[]): SxProps<Theme> => {
  return conditionalSx.reduce((acc, item) => {
    const [styles, applicable] = item;
    return applicable ? merge(acc, styles) : acc;
  }, baseSx);
};

export default clsx;
