import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  itemContainer: {
    marginBottom: 5,
  },
  contentTitle: {
    marginBottom: 1,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: '14px',
      width: '14px',
      marginRight: '10px',
    },
  },
  list: {
    marginTop: '20px',
    gap: '20px',
  },
};

export default styles;
