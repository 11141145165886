import { FC } from 'react';
import { includes, uniq, without, filter } from 'ramda';
import { Box, Typography, FormControlLabel, Grid, FormGroup, Stack, TextField, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/Checkbox';
import Amenity from 'types/resources/Amenity';
import { useScreen } from 'hooks';

import styles from './styles';

type SettersType = {
  setCurrentAmenities: (amenities: Amenity[] | []) => void;
  setElevator: (elevator: boolean | null) => void;
  setOnlyAvailable: (onlyAvailable: boolean | null) => void;
  setPricePerMonthMin: (pricePerMonthMin: string | null) => void;
  setPricePerMonthMax: (pricePerMonthMax: string | null) => void;
  setSquareMin: (squareMin: string | null) => void;
  setSquareMax: (squareMax: string | null) => void;
};

type ValuesType = {
  currentAmenities: Amenity[];
  elevator: boolean | null;
  onlyAvailable: boolean | null;
  pricePerMonthMin: string | null;
  pricePerMonthMax: string | null;
  squareMin: string | null;
  squareMax: string | null;
};

type Props = {
  amenities: Amenity[];
  setters: SettersType;
  values: ValuesType;
  onSubmit?: (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
};

const PreApplyForm: FC<Props> = props => {
  const { amenities, setters, values, onSubmit } = props;

  const {
    setCurrentAmenities,
    setElevator,
    setOnlyAvailable,
    setPricePerMonthMin,
    setPricePerMonthMax,
    setSquareMin,
    setSquareMax,
  } = setters;

  const { t } = useTranslation(['components', 'common']);

  const { lessThanDesktop } = useScreen();

  const onChange = (amenity: Amenity) => () => {
    const amenityId = Number(amenity.id);
    const amenitiesIds = values.currentAmenities.map(item => Number(item.id));
    const newAmenitiesIds = includes(amenity.id, amenitiesIds)
      ? without([amenityId], amenitiesIds)
      : uniq([...amenitiesIds, amenityId]);

    const newAmenities = filter(a => includes(a.id, newAmenitiesIds), amenities);
    setCurrentAmenities(newAmenities);
  };

  const handleElevatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const elevatorValue = isChecked ? true : null;
    setElevator(elevatorValue);
  };

  const handleOnlyAvailableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const value = isChecked ? true : null;
    setOnlyAvailable(value);
  };

  const renderAmenties = () => {
    const amenitiesIds = values.currentAmenities.map(item => Number(item.id));
    return amenities.map(amenity => {
      const checked = includes(amenity.id, amenitiesIds);
      return (
        <FormGroup sx={styles.checkboxWrapper} key={amenity.id}>
          <FormControlLabel
            control={<Checkbox sx={styles.checkbox} />}
            label={<Typography variant="body3">{amenity.name}</Typography>}
            checked={checked}
            onChange={onChange(amenity)}
          />
        </FormGroup>
      );
    });
  };

  return (
    <Box sx={styles.allFilters} component="form" noValidate>
      <Grid container spacing={{ mobile: 1, desktop: 10 }} sx={styles.root}>
        <Grid item>
          <Typography variant="subtitle2" sx={styles.mainFiltersText}>
            {t('components:Filter.mainFilters')}
          </Typography>
          <FormGroup row sx={styles.formGroup}>
            <Typography sx={styles.formGroupTitle} color="black.main" variant="body3">
              {t('components:Filter.pricePerMonth')}
            </Typography>
            <Stack spacing={2} direction="row">
              <TextField
                size="small"
                value={values.pricePerMonthMin}
                sx={styles.formGroupInput}
                placeholder={t('components:Filter.min$')}
                name="pricePerMonthMin"
                id="pricePerMonthMin"
                onChange={e => setPricePerMonthMin(e.currentTarget.value)}
              />
              <TextField
                size="small"
                value={values.pricePerMonthMax}
                sx={styles.formGroupInput}
                placeholder={t('components:Filter.max$')}
                name="pricePerMonthMax"
                id="pricePerMonthMax"
                onChange={e => setPricePerMonthMax(e.currentTarget.value)}
              />
            </Stack>
          </FormGroup>
          <FormGroup row sx={styles.formGroup}>
            <Typography sx={styles.formGroupTitle} color="black.main" variant="body3">
              {t('components:Filter.squareFeet')}
            </Typography>
            <Stack spacing={2} direction="row">
              <TextField
                size="small"
                value={values.squareMin}
                sx={styles.formGroupInput}
                placeholder={t('components:Filter.minft')}
                name="squareMin"
                id="squareMin"
                onChange={e => setSquareMin(e.currentTarget.value)}
              />
              <TextField
                size="small"
                value={values.squareMax}
                sx={styles.formGroupInput}
                placeholder={t('components:Filter.maxft')}
                name="squareMax"
                id="squareMax"
                onChange={e => setSquareMax(e.currentTarget.value)}
              />
            </Stack>
          </FormGroup>
          <FormGroup sx={styles.checkboxWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.onlyAvailable}
                  onChange={e => handleOnlyAvailableChange(e)}
                  sx={styles.checkbox}
                />
              }
              label={<Typography variant="body3">{t('components:Filter.onlyAvailable')}</Typography>}
            />
          </FormGroup>
        </Grid>
        <Grid item sx={styles.amenitiesGrid}>
          <Typography sx={styles.amenitiesTitle} variant="subtitle2">
            {t('components:Filter.amenities')}
          </Typography>
          <Stack direction="column" flexWrap="wrap" height="100%">
            {renderAmenties()}
            <FormGroup sx={styles.checkboxWrapper}>
              <FormControlLabel
                control={
                  <Checkbox checked={values.elevator} onChange={e => handleElevatorChange(e)} sx={styles.checkbox} />
                }
                label={<Typography variant="body3">{t('components:Filter.elevator')}</Typography>}
              />
            </FormGroup>
          </Stack>
        </Grid>
      </Grid>
      {lessThanDesktop && (
        <Button sx={styles.applyFiltersButton} onClick={onSubmit}>
          <Typography variant="subtitle2">{t('components:Filter.applyFilters')}</Typography>
        </Button>
      )}
    </Box>
  );
};

export default PreApplyForm;
