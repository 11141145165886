import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  content: {
    position: 'relative',
    marginX: 0,
    padding: 0,
    zIndex: 0,
  },
  paper: {
    overflowY: 'initial',
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    right: {
      mobile: 8,
      fablet: -72,
    },
    top: {
      mobile: 8,
      fablet: -16,
    },
    color: {
      mobile: 'common.black',
      fablet: 'common.white',
    },
  },
};

export default styles;
