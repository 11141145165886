import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  autocompleteWrapper: {
    '& svg': {
      color: 'brightBlue.main',
    },
    border: (theme: Theme) => ({
      mobile: `1px solid ${theme.palette.navy.secondary}`,
      desktop: 'none',
    }),
    marginBottom: {
      mobile: 0,
    },
    width: {
      mobile: '100%',
      desktop: '10%',
    },
    alignItems: 'center',
    flexShrink: {
      mobile: 1,
      desktop: 0,
    },
    flexGrow: 1,
  },
};

export default styles;
