import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: {
      mobile: 'column',
      desktop: 'row',
    },
    marginTop: 0,
    marginBottom: {
      mobile: 0,
      desktop: '16px',
    },
    marginLeft: {
      mobile: 0,
      desktop: 1,
    },
    marginRight: {
      mobile: 0,
      desktop: 1,
    },
  },
  title: {
    marginRight: {
      mobile: 1,
      desktop: 0,
    },
    marginTop: 1,
  },
  badges: {
    '& .MuiChip-root': {
      mt: 1,
      marginLeft: {
        mobile: 0,
        desktop: 2,
      },
    },
  },
  badge: {
    marginRight: {
      mobile: '8px !important',
      desktop: 0,
    },
  },
  clearAllFilters: {
    marginBottom: {
      mobile: 1,
      desktop: 0,
    },
    marginTop: {
      mobile: 1,
      desktop: 0,
    },
    justifyContent: {
      mobile: 'flex-start',
      desktop: 'center',
    },
    ml: {
      mobile: 0,
      desktop: 'auto',
    },
    color: 'red.main',
    flexShrink: 0,
    '& svg': {
      width: 15,
      height: 15,
    },
  },
};

export default styles;
