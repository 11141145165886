import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: '100%',
    height: '100%',
  },
  mainImage: {
    width: '100%',
    height: 'calc(100% - 100px)',
    '.swiper': {
      width: '100%',
      height: '100%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  thumbnails: {
    width: '100%',
    height: '100px',
    marginTop: '20px',
    paddingBottom: '8px',
    paddingLeft: {
      mobile: '8px',
      desktop: 0,
    },
    paddingRight: {
      mobile: '8px',
      desktop: 0,
    },
    '.swiper': {
      width: '100%',
      height: '100%',
    },
    '.swiper-slide': {
      height: 70,
      width: 106,
      position: 'relative',
      padding: '3px',
      opacity: 0.5,
      border: '1px solid transparent',
    },
    '.swiper-slide-thumb-active': {
      opacity: 1,
      border: (theme: Theme) => `1px solid ${theme.palette.brightBlue.main}`,
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      cursor: 'pointer',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  thumbnail: {
    height: '100%',
  },
  arrow: {
    zIndex: 11,

    position: 'absolute',
    top: '50%',

    backgroundColor: 'white.main',
    opacity: 0.7,
    width: 36,
    height: 36,
    borderRadius: '0 !important',
    '& svg': {
      width: 16,
      height: 16,
      color: 'brightBlue.main',
    },
    '&:hover': {
      backgroundColor: 'gray.athens',
      opacity: 0.9,
    },
    '&:disabled': {
      opacity: 0.5,
    },
  },
  arrowPrev: {
    left: '18px',
  },
  arrowNext: {
    right: '18px',
    transform: 'rotate(180deg)',
  },
  placeholder: {
    width: '100%',
    height: '100%',
  },
  label: {
    backgroundColor: 'white.main',
    color: 'brightBlue.main',
    position: 'absolute',
    top: '20px',
    left: '18px',
    padding: '3px',
    width: 'auto',
    height: '20px',
    lineHeight: '17px',
    opacity: 0.5,
    textAlign: 'center',
  },
};

export default styles;
