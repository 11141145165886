import { FC } from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import Icon from 'components/Icon';

import styles from './styles';

const Badge: FC<ChipProps> = props => {
  const { sx, ...rest } = props;

  return <Chip sx={{ ...styles.chip, ...sx }} {...rest} deleteIcon={<Icon name="mark" />} />;
};

export default Badge;
