import Box from 'components/Box';
import { useGoogleService } from 'hooks';
import { FC, useEffect, useRef, useState, DependencyList, EffectCallback } from 'react';
import { styledMapType } from 'utils/googleHelper';

import styles from './styles';

type SimpleMapProps = {
  longitude: number;
  latitude: number;
  controlsAtLeftSide?: boolean;
};

const SimpleMap: FC<SimpleMapProps> = (props): JSX.Element => {
  const { latitude, longitude, controlsAtLeftSide = false } = props;
  const mapRef = useRef();
  const { ready, google } = useGoogleService();
  const [map, setMap]: [google.maps.Map, (map: google.maps.Map) => void] = useState(null);

  const useMapEffect = (func: EffectCallback, deps: DependencyList = []): void => {
    const effectFunc = () => {
      if (!map) return null;
      return func();
    };
    useEffect(effectFunc, [!!map, ...deps]);
  };

  const initMap = () => {
    const controlsAtLeftSideOptions = {
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
    };
    const newMap = new google.maps.Map(mapRef.current, {
      center: { lat: latitude, lng: longitude },
      zoom: 16,
      streetViewControl: false,
      draggable: false,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
      ...(controlsAtLeftSide ? controlsAtLeftSideOptions : {}),
    });
    newMap.mapTypes.set('styled_map', styledMapType(google));
    newMap.setMapTypeId('styled_map');

    setMap(newMap);
  };

  useMapEffect(() => {
    map.setCenter({ lng: longitude, lat: latitude });
    const marker = new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [latitude, longitude]);

  useEffect(() => {
    if (!ready) return;
    initMap();
  }, [ready]);

  return <Box sx={styles.map} ref={mapRef} />;
};

export default SimpleMap;
