import Building from 'types/resources/Building';
import { useRef, useEffect, useState, DependencyList, EffectCallback, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { boundsToCardinalPoints, makeMarkerSymbol, styledMapType } from 'utils/googleHelper';
import { SearchFormData } from 'forms/public/searchForm';

import useGoogleService from './useGoogleService';

type UseMapProps = {
  buildings?: Building[];
  onCardinalPointsChange?: (bounds: google.maps.LatLngBoundsLiteral, zoom: number) => void;
  filters?: SearchFormData;
  hoveredBuildingId?: ID | null;
};

type UseMapTypes = {
  ref: React.MutableRefObject<undefined>;
  map: google.maps.Map;
  buildings?: Building[];
  ready: boolean;
};

type Marker = google.maps.Marker & {
  id: ID;
  index: number;
};

const useMap = (props: UseMapProps): UseMapTypes => {
  const { buildings = [], onCardinalPointsChange, filters, hoveredBuildingId } = props;
  const { cardinalPoints: initialCardinalPoints, zoom } = filters;
  const mapRef = useRef();
  const { ready, google } = useGoogleService();
  const [map, setMap]: [google.maps.Map, (map: google.maps.Map) => void] = useState(null);
  const [zoomed, setZoomed] = useState(false);

  const useMapEffect = (func: EffectCallback, deps: DependencyList = []): void => {
    const effectFunc = () => {
      if (!map) return null;
      return func();
    };
    useEffect(effectFunc, [!!map, ...deps]);
  };

  const debouncedChangeCardinalPoints = debounce(onCardinalPointsChange, 1000);

  const initMap = () => {
    const newMap = new google.maps.Map(mapRef.current, {
      streetViewControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
    });
    newMap.mapTypes.set('styled_map', styledMapType(google));
    newMap.setMapTypeId('styled_map');

    setMap(newMap);
  };

  useEffect(() => {
    if (!ready) return;
    initMap();
  }, [ready]);

  useMapEffect(() => {
    const boundsChangedListener = map.addListener('bounds_changed', () => {
      const newBounds = map.getBounds();
      if (!newBounds) return;
      if (!zoomed) {
        setZoomed(true);
        return;
      }

      const newCardinalPoints = boundsToCardinalPoints(newBounds);
      debouncedChangeCardinalPoints(newCardinalPoints, map.getZoom());
    });

    return () => {
      google.maps.event.removeListener(boundsChangedListener);
    };
  }, [zoomed, filters]);

  const zoomMap = () => {
    const positions = [
      new google.maps.LatLng(initialCardinalPoints.south, initialCardinalPoints.west),
      new google.maps.LatLng(initialCardinalPoints.north, initialCardinalPoints.east),
    ];
    const bounds = new google.maps.LatLngBounds(...positions);
    map.setCenter(bounds.getCenter());
    if (zoom) {
      map.setZoom(zoom);
    } else {
      map.fitBounds(bounds);
    }
  };

  useMapEffect(() => {
    zoomMap();
  });

  const [markers, setMarkers] = useState<Marker[] | null>(null);
  const [lastHoveredId, setLastHoveredId] = useState(null);

  const getBuilding = (id: ID) => buildings.find(building => building.id === id);
  const getMarker = (id: ID) => markers.find((marker: Marker) => marker.id === id);
  const destroyMarker = (marker: Marker) => marker.setMap(null);

  const renderMarker = useCallback(
    (building: Building, index: number, hovered = false) => {
      const position = new google.maps.LatLng(building.location.lat, building.location.lon);

      return new google.maps.Marker({
        id: building.id,
        index,
        position,
        label: { text: `${index + 1}`, color: 'white' },
        map,
        icon: hovered ? makeMarkerSymbol('#416AF1') : makeMarkerSymbol('#D92546'),
      });
    },
    [map],
  );

  useMapEffect(() => {
    if (markers) {
      markers.map(destroyMarker);
    }
    setMarkers(buildings.map((building, index) => renderMarker(building, index)));

    return () => {
      if (markers) markers.map(destroyMarker);
    };
  }, [buildings.map(({ id }) => id).join(',')]);

  useMapEffect(() => {
    if (!markers) {
      return;
    }

    if ((lastHoveredId && !hoveredBuildingId) || (lastHoveredId && hoveredBuildingId)) {
      const hoveredMarker = getMarker(lastHoveredId);
      const { id, index } = hoveredMarker;
      const building = getBuilding(id);

      destroyMarker(hoveredMarker);
      setMarkers(prev => prev.filter(marker => marker.id !== id).concat(renderMarker(building, index)));
      setLastHoveredId(null);
    }

    if (hoveredBuildingId) {
      const markerToHover = getMarker(hoveredBuildingId);
      const { id, index } = markerToHover;
      const building = getBuilding(id);

      destroyMarker(markerToHover);
      setMarkers(prev => prev.filter(marker => marker.id !== id).concat(renderMarker(building, index, true)));
      setLastHoveredId(id);
    }
  }, [hoveredBuildingId]);

  return {
    ref: mapRef,
    map,
    ready,
  };
};

export default useMap;
