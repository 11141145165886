export enum SpaceRentSubscriptionState {
  subscriptionInactive = 'subscription_inactive',
  subscriptionActive = 'subscription_active',
}

export enum SpaceRentState {
  initial = 'initial',
  waitingForApproval = 'waiting_for_approval',
  approved = 'approved',
  declined = 'declined',
  active = 'active',
  completed = 'completed',
}

export enum SpaceRentCancellationReason {
  noLonger = 'noLonger',
  tenantExpanding = 'tenantExpanding',
  tenantLongTerm = 'tenantLongTerm',
  issues = 'issues',
  complicated = 'complicated',
  expensive = 'expensive',
  other = 'other',
}
