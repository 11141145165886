import { FC } from 'react';
import { Snackbar, IconButton, Typography } from '@material-ui/core';
import { useNotifications } from 'hooks';
import Box from 'components/Box';
import Icon from 'components/Icon';

import styles from './styles';

const AUTO_HIDE_DURATION = 4000;

const Notification: FC = (): JSX.Element => {
  const { isOpened, closeNotification, description, title } = useNotifications();

  const handleClose = () => {
    closeNotification();
  };

  return (
    <Snackbar
      open={isOpened}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
      message={description}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Box sx={styles.root}>
        <Icon name="warning" sx={styles.warning} />
        <Box sx={styles.content}>
          <Box sx={styles.head}>
            <Typography variant="subtitle1" sx={styles.title}>
              {title}
            </Typography>
            <IconButton sx={styles.closeButton} onClick={handleClose}>
              <Icon name="close" />
            </IconButton>
          </Box>
          <Typography variant="body3">{description}</Typography>
        </Box>
      </Box>
    </Snackbar>
  );
};

export default Notification;
