import { FC } from 'react';
import { Chat, Channel, ChannelList, MessageInput, MessageList, Window, Streami18n } from 'stream-chat-react';
import { ChannelFilters, StreamChat } from 'stream-chat';
import { ChatType } from 'enums/ChatType';
import CustomMessage from 'components/Chat/components/CustomMessage';
import CustomMessageInput from 'components/Chat/components/CustomMessageInput';
import EmptyMessageListCover from 'components/Chat/components/EmptyMessageListCover';
import ChatWrapper from 'components/Chat/components/ChatWrapper';
import CustomChannelListTrotter from 'components/Chat/components/CustomChannelListTrotter';
import CustomChannelListLandlord from 'components/Chat/components/CustomChannelListLandlord';
import CustomChannelListAdmin from 'components/Chat/components/CustomChannelListAdmin';
import CustomMessageAdmin from 'components/Chat/components/CustomMessageAdmin';

type ChatProps = {
  client: StreamChat;
  filters: ChannelFilters;
  activeChannelId: string | null;
  type: ChatType;
};

const ChatCustom: FC<ChatProps> = props => {
  const { filters, client, activeChannelId, type } = props;

  const i18nInstance = new Streami18n({ language: 'en' });

  const channelListMapping = {
    trotter: CustomChannelListTrotter,
    landlord: CustomChannelListLandlord,
    admin: CustomChannelListAdmin,
  };

  const messageMapping = {
    trotter: CustomMessage,
    landlord: CustomMessage,
    admin: CustomMessageAdmin,
  };

  return (
    <Chat client={client} i18nInstance={i18nInstance}>
      <ChatWrapper>
        <ChannelList
          filters={filters}
          List={channelListMapping[type]}
          setActiveChannelOnMount={false}
          customActiveChannel={activeChannelId}
          sendChannelsToList
        />
        <Channel>
          <Window>
            <MessageList Message={messageMapping[type]} />
            <MessageInput Input={CustomMessageInput} />
          </Window>
        </Channel>
        <EmptyMessageListCover type={type} />
      </ChatWrapper>
    </Chat>
  );
};

export default ChatCustom;
