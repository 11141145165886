import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'inline-flex',
    padding: '4px 12px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  verifiedIcon: {
    width: 20,
    height: 20,
    marginRight: '4px',
  },
};

export default styles;
