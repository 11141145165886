import { FC } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';
import Icon from 'components/Icon';

import styles from './styles';

const Checkbox: FC<CheckboxProps> = props => {
  return (
    <MuiCheckbox
      sx={styles.select}
      {...props}
      icon={<Icon name="checkbox" />}
      checkedIcon={<Icon name="checkboxChecked" />}
    />
  );
};

export default Checkbox;
