import { apiRoutes } from 'routes';
import Space, { ListingUpdateStatusData, SpaceResources } from 'types/resources/Space';
import Fetcher from 'utils/fetcher';
import { LoadingParams } from 'utils/loadingParams';

const SpacesRepository = {
  index(params: LoadingParams): Promise<SpaceResources> {
    const url = apiRoutes.apiSiteV1AdminSpacesPath();
    return Fetcher.get(url, params);
  },
  show(spaceId: ID): Promise<Space> {
    const url = apiRoutes.apiSiteV1AdminSpacePath(spaceId);
    return Fetcher.get(url);
  },
  update(params: { spaceId: ID; space: ListingUpdateStatusData }): Promise<Space> {
    const { spaceId, space } = params;
    const url = apiRoutes.apiSiteV1AdminSpacePath(spaceId);
    return Fetcher.put(url, { space });
  },
  delete(spaceId: ID): Promise<void> {
    const url = apiRoutes.apiSiteV1AdminSpacePath(spaceId);
    return Fetcher.delete(url);
  },
  approve(spaceId: ID): Promise<Space> {
    const url = apiRoutes.approveApiSiteV1AdminSpacePath(spaceId);
    return Fetcher.put(url, {});
  },
  decline(params: { spaceId: ID; space: ListingUpdateStatusData }): Promise<Space> {
    const { spaceId, space } = params;
    const url = apiRoutes.declineApiSiteV1AdminSpacePath(spaceId);
    return Fetcher.put(url, { space });
  },
};

export default SpacesRepository;
