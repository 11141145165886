import React, { FC } from 'react';
import { useMessageInputContext } from 'stream-chat-react';
import { Button, Box, Typography, TextareaAutosize } from '@material-ui/core';
import { isEmpty } from 'ramda';
import clsx from 'utils/clsx';
import { useTranslation } from 'react-i18next';
import { UsersPresenter } from 'presenters';
import { useUsers } from 'hooks';

import PromptForTrotter from './components/PromptForTrotter';
import styles from './styles';

const CustomMessageInput: FC = () => {
  const { handleSubmit, setText, text } = useMessageInputContext();
  const { currentUser } = useUsers();
  const isAdmin = UsersPresenter.hasAdminAccess(currentUser);
  const isLandlord = UsersPresenter.hasLandlordAccess(currentUser);
  const isTrotter = UsersPresenter.hasTrotterAccess(currentUser);

  const { t } = useTranslation(['chatComponents']);

  const emptyText = isEmpty(text);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSubmit(event);
    }
  };

  return (
    <Box sx={styles.form} component="form" onKeyDown={handleKeyDown}>
      <Box
        sx={clsx(styles.textarea, [
          [styles.adminTextarea, isAdmin],
          [styles.landlordTextarea, isLandlord],
        ])}
      >
        <TextareaAutosize
          name="message"
          id="message"
          aria-label={t('chatComponents:MessageInput.ariaLabel')}
          placeholder={t('chatComponents:MessageInput.placeholder')}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <Box sx={styles.actions}>
          <Button sx={styles.sendButton} onClick={handleSubmit} disabled={emptyText}>
            <Typography variant="subtitle3">{t('chatComponents:MessageInput.sendButtonText')}</Typography>
          </Button>
        </Box>
      </Box>
      {isTrotter && <PromptForTrotter />}
    </Box>
  );
};

export default CustomMessageInput;
