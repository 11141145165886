import { FC } from 'react';
import Box from 'components/Box';
import { Typography } from '@material-ui/core';
import Building from 'types/resources/Building';
import { getAddressAndCity } from 'utils/buildingUtils';

import styles from './styles';

type AddressProps = {
  building: Building;
};

const Address: FC<AddressProps> = props => {
  const { building } = props;

  const { address, city } = getAddressAndCity(building.address);

  return (
    <Box sx={styles.root}>
      <Typography variant="h3">{address}</Typography>
      <Typography variant="body2">{city}</Typography>
    </Box>
  );
};

export default Address;
