/* eslint-disable no-param-reassign */
import LandlordsRepository from 'repositories/admin/LandlordsRepository';
import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';
import Landlord from 'types/resources/Landlord';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import { LandlordFormDataToSubmit } from 'forms/admin/landlordForm';
import { LoadingParams } from 'utils/loadingParams';
import { createAsyncThunk } from 'utils/createAsyncThunk';

export type LandlordsSliceStateType = {
  landlords: Landlord[];
  meta: Meta;
  state: FetchStatus;
  landlordState: FetchStatus;
  landlord: Landlord | null;
  resendInvitation: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export type LandlordsSliceActionsType = {
  loadLandlords: (params: LoadingParams) => Promise<void>;
  loadLandlord: (id: ID) => Promise<void>;
  updateLandlord: (id: ID, params: RecursivePartial<LandlordFormDataToSubmit>) => Promise<void>;
  createLandlord: (params: LandlordFormDataToSubmit) => Promise<void>;
  resendInvitation: (id: ID) => Promise<void>;
};

export const resendInvitationLandlord = createAsyncThunk(
  'admin/landlords/resendInvitation',
  LandlordsRepository.resendInvitation,
);

export const updateLandlord = createAsyncThunk('admin/landlords/update', LandlordsRepository.update);

const initialState: LandlordsSliceStateType = {
  state: FetchStatus.idle,
  landlordState: FetchStatus.idle,
  landlord: null,
  landlords: [],
  meta: {
    count: null,
    totalCount: null,
    perPage: 10,
    currentPage: 1,
    totalPages: null,
    nextPage: null,
  },
  resendInvitation: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords',
  initialState,
  reducers: {
    loadLandlordsStart(state) {
      state.state = FetchStatus.pending;
    },
    loadLandlordsSuccess(state, { payload }) {
      state.landlords = payload.items;
      state.meta = payload.meta;
      state.state = FetchStatus.fulfilled;
    },
    loadLandlordsFail(state) {
      state.state = FetchStatus.failed;
    },
    loadLandlordSuccess(state, { payload }) {
      state.landlord = payload.landlord;
      state.landlordState = FetchStatus.fulfilled;
    },
    loadLandlordStart(state) {
      state.landlordState = FetchStatus.pending;
    },
    loadLandlordFail(state) {
      state.landlordState = FetchStatus.failed;
    },
  },
  extraReducers: builder => {
    builder.addCase(resendInvitationLandlord.pending, state => {
      state.resendInvitation.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(resendInvitationLandlord.fulfilled, state => {
      state.resendInvitation.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(resendInvitationLandlord.rejected, (state, { payload }) => {
      state.resendInvitation.error = payload;
      state.resendInvitation.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateLandlord.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateLandlord.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateLandlord.rejected, (state, { payload }) => {
      state.update.error = payload;
      state.update.fetchStatus = FetchStatus.failed;
    });
  },
});

const { actions } = slice;

export const useLandlordsActions = (): LandlordsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadLandlords = async (params: LoadingParams) => {
    dispatch(actions.loadLandlordsStart());
    try {
      const data = await LandlordsRepository.index(params);
      dispatch(actions.loadLandlordsSuccess(data));
    } catch (e) {
      dispatch(actions.loadLandlordsFail());
      throw e;
    }
  };

  const loadLandlord = async (id: ID) => {
    dispatch(actions.loadLandlordStart());
    try {
      const data = await LandlordsRepository.show(id);
      dispatch(actions.loadLandlordSuccess({ landlord: data }));
    } catch (e) {
      dispatch(actions.loadLandlordFail());
      throw e;
    }
  };

  const createLandlord = async (params: LandlordFormDataToSubmit) => {
    await LandlordsRepository.create(params);
  };

  return {
    loadLandlord,
    loadLandlords,
    createLandlord,
  };
};

export default slice.reducer;
