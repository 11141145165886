import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiPaginationType = {
  defaultProps: ComponentsProps['MuiPagination'];
  styleOverrides: ComponentsOverrides['MuiPagination'];
};

const MuiPagination: MuiPaginationType = {
  styleOverrides: {
    root: {
      marginTop: 24,
    },
    ul: {
      border: '1px solid rgba(11, 32, 80, 0.1)',
      borderRadius: 4,
      overflow: 'hidden',
      display: 'inline-flex',
      '& li': {
        marginRight: 2,
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  },
  defaultProps: {
    size: 'large',
    shape: 'rounded',
    hidePrevButton: true,
    hideNextButton: true,
  },
};

export default MuiPagination;
