import { FC, useState, useEffect, createContext, useContext } from 'react';
import { StreamChat, ChannelFilters } from 'stream-chat';
import { useUsers } from 'hooks';
import { UsersPresenter } from 'presenters';
import ChannelsRepository from 'repositories/ChannelsRepository';
import User from 'types/resources/User';
import { ChatChannel, ChatState } from 'types/chat';
import useRouter from 'hooks/useRouter';
import { findChannelByQueryParams } from 'utils/chat';
import { isNil } from 'ramda';

const ChatContext = createContext(null);
ChatContext.displayName = 'ChatContext';

type ChatContextProviderProps = {
  children: JSX.Element;
};

const ChatContextProvider: FC<ChatContextProviderProps> = props => {
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [isChatReady, setChatReady] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [isOpenedPromptForTrotter, setIsOpenedPromptForTrotter] = useState<boolean>(true);

  const { currentUser, isLoadCurrentUserFinished } = useUsers();
  const { chatToken } = currentUser;

  const eventTypes = ['message.new', 'notification.message_new', 'notification.mark_read'];

  useEffect(() => {
    const initChat = async () => {
      const messagingId = UsersPresenter.messagingId(currentUser);
      const client = StreamChat.getInstance(Settings.chatKey);
      await client.disconnectUser();
      const { me } = await client.connectUser({ id: messagingId }, chatToken);

      setChatClient(client);
      setUnreadCount(me.total_unread_count);
      setChatReady(true);

      client.on(event => {
        if (eventTypes.includes(event.type)) {
          setUnreadCount(event.total_unread_count);
        }
      });
    };

    if (isLoadCurrentUserFinished) {
      initChat();
    }
  }, [currentUser.id, isLoadCurrentUserFinished]);

  return (
    <ChatContext.Provider
      value={{ chatClient, isChatReady, unreadCount, isOpenedPromptForTrotter, setIsOpenedPromptForTrotter }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

type UseChatType = {
  client: StreamChat;
  activeChannelId: string | null;
  isChatLoading: boolean;
};

type UserChatProps = {
  filters: ChannelFilters;
  currentUser: User;
};

const useChat = (props: UserChatProps): UseChatType => {
  const { filters, currentUser } = props;
  const createChannel = ({ spaceId, userId }: { spaceId: ID; userId?: ID }) =>
    ChannelsRepository.create(spaceId, userId);

  const { chatClient, isChatReady } = useContext(ChatContext);
  const [chatState, setChatState] = useState<ChatState>('loading');
  const [activeChannelId, setActiveChannelId] = useState<string | null>(null);

  const { camelizedQuery } = useRouter();
  const { spaceId, userId } = camelizedQuery as { spaceId: ID; userId: ID };

  const isTrotter = UsersPresenter.hasTrotterAccess(currentUser);
  const isLandlord = UsersPresenter.hasLandlordAccess(currentUser);
  const isEmployee = UsersPresenter.hasEmployeeMessagingFullAccess(currentUser);

  const findOrCreateChannel = async () => {
    const channels = (await chatClient.queryChannels(filters)) as ChatChannel[];
    const channelWithSpace = findChannelByQueryParams(channels, { spaceId, userId });

    if (isNil(channelWithSpace)) {
      try {
        if (isTrotter) {
          const createdChannel = await createChannel({ spaceId });
          setActiveChannelId(createdChannel.id);
        }
        if (isLandlord || isEmployee) {
          const createdChannel = await createChannel({ spaceId, userId });
          setActiveChannelId(createdChannel.id);
        }

        setChatState('ready');
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        setChatState('ready');
      }
    } else {
      setActiveChannelId(channelWithSpace?.id);
      setChatState('ready');
    }
  };

  useEffect(() => {
    if (!isChatReady) {
      return;
    }

    if (spaceId) {
      findOrCreateChannel();
    } else {
      setChatState('ready');
    }
  }, [isChatReady]);

  return {
    client: chatClient,
    activeChannelId,
    isChatLoading: chatState === 'loading',
  };
};

export default ChatContextProvider;
export { ChatContext, useChat };
