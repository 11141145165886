export default {
  DataTable: {
    noResourcesText: 'Oops! There are no results that match the selected criteria.',
  },
  LandlordForm: {
    companyName: 'Company name',
    companyAddress: 'Company address',
    enterLandlordCompanyName: 'Enter landlord’s company name',
    enterLandlordCompanyAddress: 'Enter landlord’s company address',
    addressPlaceholder: '1270 Broadway, New York, NY, USA',
    acmeCorporation: 'Acme Corporation',
    status: 'Status',
    state: 'State',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    selectStatus: 'Select status...',
    uploadCompanyLogo: 'Upload company logo',
    maxMb: 'Max. 20mb',
    chooseImage: 'Choose the image...',
    companyAdmin: 'Company admin',
    enterCompanyAdmin: 'Enter the admin first and last names',
    john: 'John',
    applessed: 'Appleseed',
    sendInvationEmail: 'We will send invitation to this email',
    placeholderEmail: 'john.appleseed@email.com',
    revenue: 'TROT revenue share',
    enterRevenue: 'Enter revenue share',
    enterPercent: 'Enter percent',
    fillAllRequired: 'Please fill all required fields to continue',
    create: {
      submit: 'Send invitation',
    },
    resendInvitation: 'resend an invitation',
  },
  BuildingsAutocomplete: {
    building: 'Building:',
    selectBuilding: 'Select building',
  },
};
