import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    zIndex: 2,
    pointerEvents: 'none',
  },
  playIconSmall: {
    width: '48px !important',
    height: '48px !important',
  },
  playIconMedium: {
    width: '72px !important',
    height: '72px !important',
  },
  playIconLarge: {
    width: '104px !important',
    height: '104px !important',
  },
};

export default styles;
