import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    flexGrow: 1,
    marginBottom: {
      mobile: 2,
      desktop: 0,
    },
  },
};

export default styles;
