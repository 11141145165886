/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { createSlice } from '@reduxjs/toolkit';
import UsersRepository from 'repositories/UsersRepository';
import User from 'types/resources/User';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { UsersPresenter } from 'presenters';

export type UsersSliceStateType = {
  currentUser: User;
  loadCurrentUser: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

const initialState: UsersSliceStateType = {
  currentUser: UsersPresenter.guestUser(),
  loadCurrentUser: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const loadCurrentUser = createAsyncThunk('users/loadCurrent', UsersRepository.current);

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetCurrentUser: state => {
      state.currentUser = initialState.currentUser;
      state.loadCurrentUser = initialState.loadCurrentUser;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadCurrentUser.pending, state => {
      state.loadCurrentUser.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadCurrentUser.fulfilled, (state, { payload }) => {
      state.loadCurrentUser.fetchStatus = FetchStatus.fulfilled;
      state.currentUser = payload;
    });
    builder.addCase(loadCurrentUser.rejected, (state, { error }) => {
      state.currentUser = initialState.currentUser;
      state.loadCurrentUser.fetchStatus = FetchStatus.failed;
      state.loadCurrentUser.error = error;
    });
  },
});

export default slice.reducer;

const {
  actions: { resetCurrentUser },
} = slice;

export { resetCurrentUser };
