import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, ButtonBase, Divider } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { isNil } from 'ramda';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Building from 'types/resources/Building';
import Space from 'types/resources/Space';
import { SpacePresenter } from 'presenters';
import { useRouter, useScreen } from 'hooks';
import clsx from 'utils/clsx';
import { appRoutes } from 'routes';
import { rentableSquare, pricePerMonthFormatted } from 'presenters/SpacePresenter';

import styles from './styles';

type SpacesProps = {
  building: Building;
  selectedSpaceId: ID;
  onSpaceClick: (space: Space) => void;
  isSpacesOpened: boolean;
};

const Spaces: FC<SpacesProps> = props => {
  const { building, selectedSpaceId, onSpaceClick, isSpacesOpened } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);
  const { lessThanTablet, lessThanDesktop } = useScreen();
  const { push } = useRouter();

  const handleSpaceClick = (space: Space) => {
    // on mobile/tablets we go directly to detail page without extra step
    if (lessThanDesktop) {
      push(appRoutes.public.buildingSpacePath(space.buildingId, space.id));
    }

    // on desktop we replace map with space description
    onSpaceClick(space);
  };

  const squareTooltip = (
    <Box sx={styles.tooltip}>
      <Typography variant="body4" component="p">
        {t('resources:listing.rentableSquareTooltipText')}
      </Typography>
    </Box>
  );

  return (
    <Box sx={clsx(styles.spaces, [[styles.spacesOpened, isSpacesOpened]])}>
      {building.spaces.map((space, index, spaces) => {
        const isLast = index === spaces.length - 1;
        const isSelected = space.id === selectedSpaceId;

        const getAvailableAt = () => {
          if (!space.availableAt) {
            return (
              <Typography variant="body3" color="black.darker" component="p">
                {t('resources:space.notAvailable')}
              </Typography>
            );
          }
          if (SpacePresenter.isAvailableAtInPastOrToday(space)) {
            return (
              <Typography variant="body3" color="black.darker" component="p">
                {t('resources:space.availableNow')}
              </Typography>
            );
          }

          return (
            <>
              <Typography variant="body3" color="black.darker" component="p">
                {t('resources:space.availableFrom')}
              </Typography>
              {space.availableAt && <Typography variant="body3">{SpacePresenter.availableAtDate(space)}</Typography>}
            </>
          );
        };

        const isOpacitySpaceRow = !SpacePresenter.canBeBooked(space);

        return (
          <React.Fragment key={space.id}>
            <Box
              onClick={() => handleSpaceClick(space)}
              sx={clsx(styles.spaceCard, [[styles.spaceCardSelected, isSelected]])}
            >
              <Box sx={clsx(styles.content, [[styles.contentWithRentedSpace, isOpacitySpaceRow]])}>
                <Box sx={styles.topContent}>
                  <Icon name="building" className="building" sx={styles.buildingIcon} />
                  <Box sx={styles.leftContent}>
                    <Box sx={styles.spaceTitleBox}>
                      <Typography variant="subtitle2">{space.title}</Typography>
                      {SpacePresenter.isVerified(space) && (
                        <Tooltip
                          title={t('resources:listing.verifiedDescription')}
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <Box sx={styles.verifiedBox}>
                            <Icon name="security" sx={styles.verifiedIcon} />
                          </Box>
                        </Tooltip>
                      )}
                      {lessThanTablet && (
                        <ButtonBase sx={styles.arrow}>
                          <Icon name="arrow" />
                        </ButtonBase>
                      )}
                    </Box>
                    <Box sx={styles.squareBlock}>
                      {!isNil(space.square) && (
                        <Tooltip
                          title={squareTooltip}
                          arrow
                          placement="top"
                          componentsProps={{ tooltip: { style: { maxWidth: '367px' } } }}
                          enterTouchDelay={0}
                          leaveTouchDelay={2500}
                        >
                          <Box>
                            <Typography sx={styles.square} variant="body3" color="black.darker" component="p">
                              {rentableSquare(space)}
                            </Typography>
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ ...styles.contentColumn, ...styles.capacity }}>
                  <Typography variant="body3" color="black.darker" component="p">
                    {t('components:BuildingCard.capacity')}
                  </Typography>
                  <Typography variant="body3">
                    {t('common:capacity', { min: space.minCapacity, max: space.maxCapacity })}
                  </Typography>
                </Box>
                <Box sx={{ ...styles.contentColumn, ...styles.availableAt }}>{getAvailableAt()}</Box>
                <Box sx={{ ...styles.contentColumn, ...styles.price }}>
                  <Typography variant="body3" color="black.darker" component="p">
                    {t('components:BuildingCard.from')}
                  </Typography>
                  <Typography variant="body3">{pricePerMonthFormatted(space, 'common:inMonth')}</Typography>
                </Box>
              </Box>
              {!lessThanTablet && (
                <ButtonBase sx={styles.arrow} onClick={() => onSpaceClick(space)}>
                  <Icon name="arrow" />
                </ButtonBase>
              )}
            </Box>
            {!isLast && <Divider sx={styles.divider} />}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
export default Spaces;
