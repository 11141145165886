import { FC } from 'react';
import { Table, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import Box from 'components/Box';
import clsx from 'utils/clsx';

import styles from './styles';

type Row = {
  label: string;
  data: string;
  description?: string;
  big?: boolean;
};

type InfoTableProps = {
  title?: string;
  rows: Row[];
  theme?: 'white' | 'navy';
  bottomTitle?: string;
  bottomRows?: Row[];
  withDivider?: boolean;
  withBorder?: boolean;
};

const InfoTable: FC<InfoTableProps> = props => {
  const { title, rows, theme = 'white', withBorder = true } = props;

  const isNavyTheme = theme === 'navy';

  const renderTable = (tableRows: Row[]) => (
    <Table sx={styles.table}>
      <TableBody>
        {tableRows.map(row => (
          <TableRow key={row.label} sx={styles.row}>
            <TableCell sx={clsx(styles.cell, [[styles.cellLeft, true]])}>
              <Typography component="p" variant="body4" sx={styles.rowLabel}>
                {row.label}
              </Typography>
            </TableCell>
            <TableCell sx={styles.cell}>
              <Typography sx={clsx(styles.rowData, [[styles.rowDataBig, row.big]])}>{row.data}</Typography>
              {row.description && (
                <Typography variant="body4" sx={styles.description}>
                  {row.description}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Box
      sx={clsx(styles.root, [
        [styles.rootNavy, isNavyTheme],
        [styles.rootWithoutBorder, !withBorder],
      ])}
    >
      {title && (
        <Typography variant="h3" sx={styles.title}>
          {title}
        </Typography>
      )}
      {renderTable(rows)}
    </Box>
  );
};

export default InfoTable;
