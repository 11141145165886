/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';
import { FetchStatuses } from 'types/fetchStatus';

export type GoogleServiceSliceStateType = {
  state: FetchStatuses;
};

export type GoogleServiceSliceActionsType = {
  initGoogle: () => void;
};

const initialState: GoogleServiceSliceStateType = {
  state: FetchStatuses.idle,
};

const slice = createSlice({
  name: 'googleService',
  initialState,
  reducers: {
    initGoogle(state) {
      state.state = FetchStatuses.fulfilled;
    },
  },
});

const { actions } = slice;

export const useGoogleServiceActions = (): GoogleServiceSliceActionsType => {
  const dispatch = useAppDispatch();

  const initGoogle = () => {
    dispatch(actions.initGoogle());
  };

  return {
    initGoogle,
  };
};

export default slice.reducer;
