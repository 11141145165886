type ExternalRoutes = {
  [key: string]: () => string;
};

const appRoutes: ExternalRoutes = {
  twitterPath: () => 'https://twitter.com/trotflex',
  facebookPath: () => 'https://www.facebook.com/trotflex',
  instagramPath: () => 'https://www.instagram.com/trotflex/',
  linkedInPath: () => 'https://www.linkedin.com/company/trotflex',
  insurancePath: () => 'https://www.join.trotflex.com/insurance-application',
  intercomHelp: () => 'https://help.trotflex.com/en/',
  intercomHelpMakeListing: () => 'https://help.trotflex.com/en/articles/6061816-how-to-make-the-most-of-your-listing',
};

export default appRoutes;
