import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useChatContext } from 'stream-chat-react';
import { Box, Typography } from '@material-ui/core';
import { useScreen } from 'hooks';
import { ChatType } from 'enums/ChatType';
import Icon from 'components/Icon';

import styles from './styles';

type EmptyMessageListCoverProps = {
  type: ChatType;
};

const EmptyMessageListCover: FC<EmptyMessageListCoverProps> = props => {
  const { type } = props;
  const { channel, navOpen } = useChatContext();
  const { lessThanDesktop } = useScreen();

  const { t } = useTranslation(['common']);

  const getMessage = (): string => {
    switch (type) {
      case ChatType.trotter:
        return t('common:chatPlaceholderTrotter');
      case ChatType.landlord:
        return t('common:chatPlaceholderLandlord');
      case ChatType.admin:
        return t('common:chatPlaceholderAdmin');
      default:
        return t('common:chatPlaceholderAdmin');
    }
  };

  if (channel || (navOpen && lessThanDesktop)) {
    return null;
  }

  return (
    <Box sx={styles.preview}>
      <Box sx={styles.text}>
        <Icon name="message" />
        <Typography variant="subtitle1">{getMessage()}</Typography>
      </Box>
    </Box>
  );
};

export default EmptyMessageListCover;
