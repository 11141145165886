import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';

import styles from './styles';

interface EditLinkProps {
  path: string | null;
}

const EditLink: FC<EditLinkProps> = props => {
  const { t } = useTranslation(['common']);
  const editTitle = t('common:edit');
  const { path } = props;

  if (!path) {
    return null;
  }

  return (
    <Typography sx={styles.editLink} variant="subtitle4">
      <Link to={path}>
        <Icon name="pen" />
        {editTitle}
      </Link>
    </Typography>
  );
};

export default EditLink;
