import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  dialog: {
    padding: '52px 50px 40px',
    textAlign: 'center',
  },
  mediumSize: {
    maxWidth: '420px',
  },
  bigSize: {
    maxWidth: '640px',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '14px',
  },
  title: {
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '24px',
  },
  positiveCancelButton: {
    color: 'navy.main',
    width: 'auto',
  },
  positiveConfirmButton: {
    width: 'auto',
    marginRight: '24px',
  },
  negativeCancelButton: {
    width: 'auto',
    marginRight: '24px',
  },
  negativeConfirmButton: {
    width: 'auto',
  },
};

export default styles;
