import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  wrapper: {
    width: '100%',
    paddingTop: '24px',
  },
  wrapperHero: {
    padding: '40px',
    backgroundColor: 'navy.main',
    marginTop: '-40px',
  },
  base: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '600px',
  },
  row: {
    display: 'flex',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
  },
  signInTypo: {
    marginTop: '20px',
    '& a': {
      color: 'white.main',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  firstName: {
    marginBottom: '24px',
    marginRight: '24px',
    color: 'brightBlue.main',
    width: '100%',
    flexGrow: 1,
  },
  field: {
    marginBottom: '24px',
    color: 'brightBlue.main',
    width: '100%',
    flexGrow: 1,
  },
  fieldHero: {
    marginBottom: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    width: '100%',
  },
  icon: {
    color: 'brightBlue.main',
  },

  titleWithMarker: {
    marginBottom: '24px',
    display: 'inline-block',
    textDecorationLine: 'underline',
    textDecorationSkipInk: 'none',
    textUnderlineOffset: '-7px',
    textDecorationColor: 'rgb(66, 106, 241)',
    textDecorationThickness: '8px',
  },
};

export default styles;
