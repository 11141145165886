/* eslint-disable no-param-reassign */

import { FC, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, FreeMode, Thumbs } from 'swiper';
import { IconButton, Typography } from '@material-ui/core';
import Media from 'types/resources/Media';
import Icon from 'components/Icon';
import Box from 'components/Box';
import ImagePlaceholder from 'components/ImagePlaceholder';
import MediaComponent from 'components/MediaComponent';
import { useScreen } from 'hooks';
import { isEmpty } from 'ramda';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';

import styles from './styles';

type SliderProps = {
  medias: Media[];
  onSlideClick?: (slideIndex: number) => void;
  clickedIndex?: number | null;
  preventPlayVideo?: boolean;
  showFull?: boolean;
};

const Slider: FC<SliderProps> = props => {
  const { medias, clickedIndex, onSlideClick, preventPlayVideo = false, showFull = false } = props;
  const { lessThanDesktop } = useScreen();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  if (isEmpty(medias)) {
    return <ImagePlaceholder sx={styles.placeholder} name="space" preserveAspectRatio="none" />;
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.mainImage}>
        <Swiper
          initialSlide={clickedIndex || 0}
          navigation={{
            nextEl: navigationPrevRef.current,
            prevEl: navigationNextRef.current,
          }}
          onClick={swiper => {
            if (!lessThanDesktop && onSlideClick) {
              onSlideClick(swiper.clickedIndex);
            }
          }}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {medias.map(media => (
            <SwiperSlide key={`main-${media.id}`}>
              <MediaComponent
                showFull={showFull}
                type="interactive"
                media={media}
                preventPlayVideo={preventPlayVideo}
              />
              {media.label && (
                <Box sx={styles.label}>
                  <Typography variant="subtitle4">{media.label}</Typography>
                </Box>
              )}
            </SwiperSlide>
          ))}
          <IconButton ref={navigationPrevRef} sx={{ ...styles.arrow, ...styles.arrowPrev }}>
            <Icon name="arrow" />
          </IconButton>
          <IconButton ref={navigationNextRef} sx={{ ...styles.arrow, ...styles.arrowNext }}>
            <Icon name="arrow" />
          </IconButton>
        </Swiper>
      </Box>
      <Box sx={styles.thumbnails}>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          slidesPerView="auto"
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {medias.map(media => (
            <SwiperSlide key={`thumbnail-${media.id}`}>
              <Box sx={styles.thumbnail}>
                <MediaComponent type="preview" media={media} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Slider;
