/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "sidekiq" + $buildOptions(options, []);
}

/** /letter_opener */
export function letterOpenerWebPath(options?: object): string {
  return "/" + "letter_opener" + $buildOptions(options, []);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /letter_opener/:id(.:format) */
export function letterOpenerWebDeleteLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /letter_opener/ */
export function letterOpenerWebLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + $buildOptions(options, []);
}

/** /letter_opener/:id(/:style)(.:format) */
export function letterOpenerWebLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "style") ? "/" + (options as any).style : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "style", "format"]);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/buildings(.:format) */
export function apiSiteV1AdminBuildingsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "buildings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/buildings/:id(.:format) */
export function apiSiteV1AdminBuildingPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "buildings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/spaces/:id/approve(.:format) */
export function approveApiSiteV1AdminSpacePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "spaces" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/spaces/:id/decline(.:format) */
export function declineApiSiteV1AdminSpacePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "spaces" + "/" + id + "/" + "decline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/spaces(.:format) */
export function apiSiteV1AdminSpacesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "spaces" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/spaces/:id(.:format) */
export function apiSiteV1AdminSpacePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "spaces" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/landlords/:id/resend_invitation(.:format) */
export function resendInvitationApiSiteV1AdminLandlordPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "landlords" + "/" + id + "/" + "resend_invitation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/landlords(.:format) */
export function apiSiteV1AdminLandlordsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "landlords" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/landlords/:id(.:format) */
export function apiSiteV1AdminLandlordPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "landlords" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/users/:id/impersonate(.:format) */
export function impersonateApiSiteV1AdminUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "users" + "/" + id + "/" + "impersonate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/users/stop_impersonate(.:format) */
export function stopImpersonateApiSiteV1AdminUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "users" + "/" + "stop_impersonate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/users(.:format) */
export function apiSiteV1AdminUsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/users/:id(.:format) */
export function apiSiteV1AdminUserPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "users" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/admin/trotters(.:format) */
export function apiSiteV1AdminTrottersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "trotters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/admin/trotters/:id(.:format) */
export function apiSiteV1AdminTrotterPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "admin" + "/" + "trotters" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/favorite_spaces(.:format) */
export function apiSiteV1TrotterFavoriteSpacesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "favorite_spaces" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/trotter/favorite_spaces/:id(.:format) */
export function apiSiteV1TrotterFavoriteSpacePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "favorite_spaces" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/orders(.:format) */
export function apiSiteV1TrotterOrdersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "orders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/trotter/orders/:id(.:format) */
export function apiSiteV1TrotterOrderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "orders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/space_rents/:id/pay(.:format) */
export function payApiSiteV1TrotterSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "space_rents" + "/" + id + "/" + "pay" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/space_rents/:id/fail_payment(.:format) */
export function failPaymentApiSiteV1TrotterSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "space_rents" + "/" + id + "/" + "fail_payment" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/space_rents/:id/cancel_subscription(.:format) */
export function cancelSubscriptionApiSiteV1TrotterSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "space_rents" + "/" + id + "/" + "cancel_subscription" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/space_rents/:id/cancel_payment(.:format) */
export function cancelPaymentApiSiteV1TrotterSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "space_rents" + "/" + id + "/" + "cancel_payment" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/trotter/space_rents(.:format) */
export function apiSiteV1TrotterSpaceRentsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "space_rents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/trotter/space_rents/:id(.:format) */
export function apiSiteV1TrotterSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "trotter" + "/" + "space_rents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/draft/buildings/:building_id/spaces(.:format) */
export function apiSiteV1LandlordDraftBuildingSpacesPath(building_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "draft" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "format"]);
}

/** /api/site/v1/landlord/draft/buildings/:building_id/spaces/:id(.:format) */
export function apiSiteV1LandlordDraftBuildingSpacePath(building_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "draft" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "id", "format"]);
}

/** /api/site/v1/landlord/draft/buildings(.:format) */
export function apiSiteV1LandlordDraftBuildingsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "draft" + "/" + "buildings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/draft/buildings/:id(.:format) */
export function apiSiteV1LandlordDraftBuildingPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "draft" + "/" + "buildings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/buildings/:building_id/spaces/:id/review(.:format) */
export function reviewApiSiteV1LandlordBuildingSpacePath(building_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + "/" + id + "/" + "review" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "id", "format"]);
}

/** /api/site/v1/landlord/buildings/:building_id/spaces/:id/make_bookable(.:format) */
export function makeBookableApiSiteV1LandlordBuildingSpacePath(building_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + "/" + id + "/" + "make_bookable" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "id", "format"]);
}

/** /api/site/v1/landlord/buildings/:building_id/spaces/:id/make_unbookable(.:format) */
export function makeUnbookableApiSiteV1LandlordBuildingSpacePath(building_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + "/" + id + "/" + "make_unbookable" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "id", "format"]);
}

/** /api/site/v1/landlord/buildings/:building_id/spaces/:id/unpublish(.:format) */
export function unpublishApiSiteV1LandlordBuildingSpacePath(building_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + "/" + id + "/" + "unpublish" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "id", "format"]);
}

/** /api/site/v1/landlord/buildings/:building_id/spaces(.:format) */
export function apiSiteV1LandlordBuildingSpacesPath(building_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "format"]);
}

/** /api/site/v1/landlord/buildings/:building_id/spaces/:id(.:format) */
export function apiSiteV1LandlordBuildingSpacePath(building_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + building_id + "/" + "spaces" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["building_id", "id", "format"]);
}

/** /api/site/v1/landlord/buildings(.:format) */
export function apiSiteV1LandlordBuildingsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/buildings/:id(.:format) */
export function apiSiteV1LandlordBuildingPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "buildings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/spaces(.:format) */
export function apiSiteV1LandlordSpacesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "spaces" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/suggested_spaces(.:format) */
export function apiSiteV1LandlordSuggestedSpacesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "suggested_spaces" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/employees(.:format) */
export function apiSiteV1LandlordEmployeesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "employees" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/employees/:id(.:format) */
export function apiSiteV1LandlordEmployeePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "employees" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/space_rents/:id/approve(.:format) */
export function approveApiSiteV1LandlordSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "space_rents" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/space_rents/:id/decline(.:format) */
export function declineApiSiteV1LandlordSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "space_rents" + "/" + id + "/" + "decline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/space_rents/:id/cancel(.:format) */
export function cancelApiSiteV1LandlordSpaceRentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "space_rents" + "/" + id + "/" + "cancel" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord/payment_token/link_token(.:format) */
export function linkTokenApiSiteV1LandlordPaymentTokenPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "payment_token" + "/" + "link_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/payment_token(.:format) */
export function apiSiteV1LandlordPaymentTokenPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "payment_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/legal_entities(.:format) */
export function apiSiteV1LandlordLegalEntitiesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "legal_entities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/landlord/legal_entities/:id(.:format) */
export function apiSiteV1LandlordLegalEntityPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + "/" + "legal_entities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/landlord(.:format) */
export function apiSiteV1LandlordPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "landlord" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/user/password_reset(.:format) */
export function apiSiteV1UserPasswordResetPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "user" + "/" + "password_reset" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/user/password_set(.:format) */
export function apiSiteV1UserPasswordSetPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "user" + "/" + "password_set" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/user/channels(.:format) */
export function apiSiteV1UserChannelsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "user" + "/" + "channels" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/google/auth(.:format) */
export function apiSiteV1GoogleAuthPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "google" + "/" + "auth" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/current_user/payment_token/link_token(.:format) */
export function linkTokenApiSiteV1CurrentUserPaymentTokenPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "current_user" + "/" + "payment_token" + "/" + "link_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/current_user/payment_token(.:format) */
export function apiSiteV1CurrentUserPaymentTokenPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "current_user" + "/" + "payment_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/current_user/payment_methods(.:format) */
export function apiSiteV1CurrentUserPaymentMethodsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "current_user" + "/" + "payment_methods" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/current_user/setup_intent(.:format) */
export function apiSiteV1CurrentUserSetupIntentPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "current_user" + "/" + "setup_intent" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/users(.:format) */
export function apiSiteV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/amenities(.:format) */
export function apiSiteV1AmenitiesPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "amenities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/users/current(.:format) */
export function currentApiSiteV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "users" + "/" + "current" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/users/confirm(.:format) */
export function confirmApiSiteV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "users" + "/" + "confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/users/resend_confirmation(.:format) */
export function resendConfirmationApiSiteV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "users" + "/" + "resend_confirmation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/users/invitation_confirm(.:format) */
export function invitationConfirmApiSiteV1UsersPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "users" + "/" + "invitation_confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/search(.:format) */
export function apiSiteV1SearchIndexPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/session(.:format) */
export function apiSiteV1SessionPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "session" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/site/v1/buildings/:id(.:format) */
export function apiSiteV1BuildingPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "buildings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/spaces/:id(.:format) */
export function apiSiteV1SpacePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "spaces" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /api/site/v1/owner_requests(.:format) */
export function apiSiteV1OwnerRequestsPath(options?: object): string {
  return "/" + "api" + "/" + "site" + "/" + "v1" + "/" + "owner_requests" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /webhooks/v1/stripe/events(.:format) */
export function webhooksV1StripeEventsPath(options?: object): string {
  return "/" + "webhooks" + "/" + "v1" + "/" + "stripe" + "/" + "events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /webhooks/v1/getstream/events(.:format) */
export function webhooksV1GetstreamEventsPath(options?: object): string {
  return "/" + "webhooks" + "/" + "v1" + "/" + "getstream" + "/" + "events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /s/:id(.:format) */
export function shortenedUrlPath(id: ScalarType, options?: object): string {
  return "/" + "s" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /stripe */
export function stripePath(options?: object): string {
  return "/" + "stripe" + $buildOptions(options, []);
}

/** /stripe/events(.:format) */
export function stripeEventsPath(options?: object): string {
  return "/" + "stripe" + "/" + "events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id", "format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id", "format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id", "filename", "format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id", "filename", "format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id", "variation_key", "filename", "format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id", "variation_key", "filename", "format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key", "filename", "format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token", "format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
