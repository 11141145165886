import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: ({ hasAdornment, hasBoxShadow }) => ({
    width: '100%',
    padding: 0,
    position: 'relative',
    borderRadius: 1,
    boxSizing: 'border-box',

    '& .MuiInputBase-input': {
      padding: hasAdornment ? '10px 20px 10px 50px' : '10px 20px 10px 25px',
      width: '100%',
      height: {
        mobile: 50,
        desktop: 60,
      },
      position: 'relative',
      borderRadius: 1,
      border: (theme: Theme) => `1px solid ${theme.palette.navy.light}`,
      boxSizing: 'border-box',
      transition: (theme: Theme) => theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
      '&:focus': {
        border: (theme: Theme) => `1px solid ${theme.palette.brightBlue.main}`,
        boxShadow: hasBoxShadow ? (theme: Theme) => `${theme.palette.brightBlue.light} 0 0 0 0.2rem` : 'none',
      },
    },
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-Error': {
      border: (theme: Theme) => `1px solid ${theme.palette.red.main}`,
      display: 'none',
    },
    '& input::placeholder': (theme: Theme) => {
      return theme.typography.body3;
    },
  }),
  adornment: {
    position: 'absolute',
    left: 20,
  },
  textField: ({ width, height }) => {
    return {
      width,
      height,
      minWidth: 'auto',
      '& .Mui-error': {
        border: (theme: Theme) => `1px solid ${theme.palette.red.main}`,
        '& input:focus': {
          border: (theme: Theme) => `1px solid ${theme.palette.red.main}`,
          boxShadow: 'none',
        },
        '& svg': {
          color: 'red.main',
        },
      },
      '& p.Mui-error': {
        border: 'none',
      },
    };
  },
};

export default styles;
