import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { SetupIntentResponse } from 'types/resources/SetupIntents';

export default {
  show(): Promise<SetupIntentResponse> {
    const path = apiRoutes.apiSiteV1CurrentUserSetupIntentPath();
    return Fetcher.get(path);
  },
  create(): Promise<SetupIntentResponse> {
    const path = apiRoutes.apiSiteV1CurrentUserSetupIntentPath();
    return Fetcher.post(path);
  },
  destroy(): Promise<void> {
    const path = apiRoutes.apiSiteV1CurrentUserSetupIntentPath();
    return Fetcher.delete(path);
  },
};
