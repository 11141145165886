import { PasswordMeter } from 'password-meter';
import { path, head, isEmpty } from 'ramda';

export const defaultPasswordSecurityParams = {
  minLength: 8,
};

export const isSecurePassword = (password: string): boolean => {
  const passwordMeter = new PasswordMeter(defaultPasswordSecurityParams);

  const errors = path(['errors'], head(passwordMeter.getResults([password])));
  const errorsCount = path(['length'], errors);

  return !isEmpty(password) && !errorsCount;
};
