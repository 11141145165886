import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';

import styles from './styles';

type ChannelItemUnreadProps = {
  unreadMessages: number;
};

const ChannelItemUnread: FC<ChannelItemUnreadProps> = props => {
  const { unreadMessages } = props;

  if (unreadMessages === 0) {
    return null;
  }

  return (
    <Box sx={styles.unread}>
      <Typography variant="bold4">{unreadMessages}</Typography>
    </Box>
  );
};

export default ChannelItemUnread;
