import i18n from 'locales/i18n';
import { nanoid } from 'nanoid';
import Space, { SpaceRent } from 'types/resources/Space';
import { toCamelCase } from 'utils/string';
import { isNil, head } from 'ramda';
import { isBlank } from 'utils/data';
import {
  ListingMediaTag,
  RestrictedUseType,
  SpaceAction,
  SpaceState,
  SpaceStatePresentation,
  SpaceVerificationState,
} from 'enums/resources/Space';
import { sortMediasByTags } from 'utils/galleryAlbum';
import { fullValueWithComma } from 'utils/currency';
import { getSquareWithComma } from 'utils/square';
import { getNotDestroyableItems } from 'utils/storeUtils';
import Media from 'types/resources/Media';
import Amenity from 'types/resources/Amenity';
import BooleanAmenity from 'types/resources/BooleanAmenity';
import { addPhotoMediaType, addVideoMediaType, addMatterportMediaType, getHeadImageUrl } from 'utils/mediaUtils';
import { formatDateWithSlashes, parseDate, startOfToday, isDayInPast, isSameDay } from 'utils/dateTimeUtils';
import { SpaceRentState } from 'enums/resources/SpaceRent';

const OPERABLE_WINDOWS = {
  id: nanoid(),
  name: i18n.t('resources:amenity.operableWindows'),
};

const CLEANING_INCLUDED = {
  id: nanoid(),
  name: i18n.t('resources:amenity.cleaningIncluded'),
};

export const coverUrl = (space: Space): string | null => getHeadImageUrl(space.photos, Object.keys(ListingMediaTag));

export const state = (space: Space): string => {
  const statePresentation = SpaceStatePresentation[space.state];

  return i18n.t(`resources:space.states.${statePresentation}`).toUpperCase();
};

export const capacity = (space: Space): string => {
  return i18n.t('common:capacity', { min: space.minCapacity, max: space.maxCapacity });
};

export const square = (space: Space): string => {
  return i18n.t('common:sqft', { square: getSquareWithComma(space.square) });
};

export const rentableSquare = (space: Space): string => {
  return i18n.t('common:rentableSquare', { square: getSquareWithComma(space.square) });
};

export const images = (space: Space): string => {
  const notDestroyablePhotos = getNotDestroyableItems(space.photos);
  return i18n.t('common:imagesCountUploaded', { count: notDestroyablePhotos.length });
};

export const amenitiesNames = (space: Space): string[] => {
  const result = getNotDestroyableItems(space.amenities).map(i => i.name);

  if (space.cleaningIncluded) {
    result.push(i18n.t('resources:space.cleaningIncluded'));
  }

  if (space.operableWindows) {
    result.push(i18n.t('resources:space.operableWindows'));
  }

  return result;
};

export const sortedMedias = (space: Space): Media[] => {
  const photos = space.photos.map(addPhotoMediaType);
  const videos = space.videos.map(addVideoMediaType);
  const matterports = (space.matterports || []).map(addMatterportMediaType);
  return sortMediasByTags([...photos, ...videos, ...matterports], Object.keys(ListingMediaTag));
};

export const priceFormatted = (space: Space): string => {
  return fullValueWithComma(space.pricePerMonth);
};

export const squareFormatted = (space: Space): string => {
  return `${getSquareWithComma(space.square)} ${i18n.t('common:squareMetric')}`;
};

export const pricePerMonthFormatted = (space: Space, i18nextTemplate: string): string => {
  return i18n.t(i18nextTemplate, { price: fullValueWithComma(space.pricePerMonth) });
};

export const rentalPeriod = (space: Space): string => {
  return i18n.t('common:monthsCount', { count: space.minimalRentalPeriod });
};

type SpaceStatePredicates = {
  isInitial: boolean;
  isWaitingForApproval: boolean;
  isNeedsRevision: boolean;
  isRentRequested: boolean;
  isRented: boolean;
  isActive: boolean;
  isDisabled: boolean;
  isArchived: boolean;
  isDraft: boolean;
};

export const getSpaceState = (space: Space): SpaceStatePredicates => {
  const isInitial = space.state === SpaceState.initial || isNil(space.state);
  const isWaitingForApproval = space.state === SpaceState.waitingForApproval;
  const isNeedsRevision = space.state === SpaceState.needsRevision;
  const isRentRequested = space.state === SpaceState.rentRequested;
  const isRented = space.state === SpaceState.rented;
  const isActive = space.state === SpaceState.active;
  const isDisabled = space.state === SpaceState.disabled;
  const isArchived = space.state === SpaceState.archived;
  const isDraft = space.state === SpaceState.draft;

  return {
    isInitial,
    isWaitingForApproval,
    isNeedsRevision,
    isRentRequested,
    isRented,
    isActive,
    isDisabled,
    isArchived,
    isDraft,
  };
};

export const isRentedSpace = (space: Space): boolean => {
  const { isRented } = getSpaceState(space);
  return isRented;
};

export const isActiveSpace = (space: Space): boolean => {
  const { isActive } = getSpaceState(space);
  return isActive;
};

export const isInitialSpace = (space: Space): boolean => {
  const { isInitial } = getSpaceState(space);
  return isInitial;
};

export const isRentRequestedSpace = (space: Space): boolean => {
  const { isRentRequested } = getSpaceState(space);
  return isRentRequested;
};

export const isArchivedSpace = (space: Space): boolean => {
  const { isArchived } = getSpaceState(space);
  return isArchived;
};

export const canBeReviewed = (space: Space): boolean => {
  const { isInitial, isNeedsRevision, isActive, isDraft } = getSpaceState(space);
  return isInitial || isNeedsRevision || isActive || isDraft;
};

export const declineReason = (space: Space): string | null => {
  const { isNeedsRevision } = getSpaceState(space);

  if (!isNeedsRevision) {
    return null;
  }

  return space.declineReason;
};

export const landlordAvailableActions = (space: Space): SpaceAction[] | [] => {
  const { isInitial, isNeedsRevision, isActive, isDraft } = getSpaceState(space);

  if (isInitial || isNeedsRevision) {
    return [SpaceAction.toReview, SpaceAction.delete];
  }

  if (isActive) {
    return [SpaceAction.delete, SpaceAction.unpublish];
  }

  if (isDraft) {
    return [SpaceAction.delete];
  }

  return [];
};

export const adminAvailableActions = (currentSpace: Space): SpaceAction[] | [] => {
  const { isInitial, isNeedsRevision, isActive } = getSpaceState(currentSpace);

  if (isInitial || isNeedsRevision || isActive) {
    return [SpaceAction.delete];
  }

  return [];
};

export const parkingSpaces = (space: Space): string | null => {
  if (isNil(space.parkingSpacesIncluded)) {
    return null;
  }

  return i18n.t(`resources:space.parkingSpacesIncludedPc`, { value: String(space.parkingSpacesIncluded) });
};

export const ceilingHeight = (space: Space): string | null => {
  const value = space.ceilingHeight;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.ceilingHeightFt`, { value: String(space.ceilingHeight) });
};

export const isUnknownRestrictedValue = (space: Space): boolean => {
  if ((space.restrictedUses || []).length === 1) {
    const knownRawValues = Object.keys(RestrictedUseType).filter(v => v !== RestrictedUseType.other);

    return !knownRawValues.includes(head(space.restrictedUses));
  }

  return false;
};

export const isRestrictedUsesOtherSelected = (space: Space): boolean => {
  if ((space.restrictedUses || []).length === 1) {
    return head(space.restrictedUses) === RestrictedUseType.other;
  }

  return false;
};

export const restrictedUses = (space: Space): string | null => {
  const values = space.restrictedUses;
  if (isBlank(values)) {
    return null;
  }

  if (isUnknownRestrictedValue(space)) {
    return space.restrictedUsesOther || head(space.restrictedUses);
  }

  return values.map(value => i18n.t(`resources:space.restrictedUsesValues.${value}`)).join(', ');
};

export const airConditioningType = (space: Space): string | null => {
  const value = space.airConditioningType;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.airConditioningTypeValues.${toCamelCase(value)}`);
};

export const heatingType = (space: Space): string | null => {
  const value = space.heatingType;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.heatingTypeValues.${toCamelCase(value)}`);
};

export const flooringType = (space: Space): string | null => {
  const value = space.flooringType;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.flooringTypeValues.${toCamelCase(value)}`);
};

export const floor = (space: Space): string | null => {
  const value = space.floor;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.floorValues.${toCamelCase(value)}`);
};

export const spaceViewType = (space: Space): string | null => {
  const value = space.spaceView;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.viewTypeValues.${toCamelCase(value)}`);
};

export const internetProvider = (space: Space): string | null => {
  const value = space.internetProvider;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.internetProviderValues.${toCamelCase(value)}`);
};

export const spaceType = (space: Space): string | null => {
  const value = space.spaceType;
  if (isBlank(value)) {
    return null;
  }
  return i18n.t(`resources:space.spaceTypeValues.${toCamelCase(value)}`);
};

export const isVerified = (space: Space): boolean => {
  return space.verificationState === SpaceVerificationState.verified;
};

export const isWaitingForApproval = (space: Space): boolean => {
  return space.state === SpaceState.waitingForApproval;
};

export const allAmenities = (space: Space): (Amenity | BooleanAmenity)[] | [] => {
  const booleanAmenities = [
    space.operableWindows && OPERABLE_WINDOWS,
    space.cleaningIncluded && CLEANING_INCLUDED,
  ].filter(amenity => amenity);
  return [...booleanAmenities, ...space.amenities];
};

export const getSpaceRent = (space: Space): SpaceRent | null => {
  const { spaceRents } = space;

  const correctStates = ['prepaid', 'waiting_for_approval', 'approved', 'active', 'initial'];

  return spaceRents.find(spaceRent => correctStates.includes(spaceRent.state)) || null;
};

export const availableAtDate = (space: Space): string => {
  if (space.availableAt) {
    return formatDateWithSlashes(space.availableAt);
  }

  return '-';
};

export const availableAt = (space: Space): string => {
  const date = availableAtDate(space);

  return i18n.t(`resources:space.availableFromWithDate`, { date });
};

export const isAvailableAtInPastOrToday = (space: Space): boolean => {
  const date = parseDate(space.availableAt);

  const inPast = isDayInPast(date, startOfToday());
  const isToday = isSameDay(date, startOfToday());

  return inPast || isToday;
};

export const canBeBooked = (space: Space): boolean => {
  const { isActive, isRented } = getSpaceState(space);

  return (isActive || isRented) && !isNil(space.availableAt);
};

export const filterVisibleSpaceRents = (space: Space): SpaceRent[] => {
  return space.spaceRents.filter(spaceRent => {
    if (
      spaceRent.state === SpaceRentState.approved ||
      spaceRent.state === SpaceRentState.active ||
      spaceRent.state === SpaceRentState.waitingForApproval ||
      spaceRent.state === SpaceRentState.initial
    ) {
      return true;
    }
    return false;
  });
};

export const isSavingDraftAvailable = (space?: Space): boolean => {
  if (isBlank(space)) {
    return true;
  }

  const { isInitial, isDraft } = getSpaceState(space as Space);

  return isInitial || isDraft;
};
