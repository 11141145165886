import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  itemContainer: {
    marginBottom: 5,
  },
  contentTitle: {
    marginBottom: 1,
  },
  list: {
    marginTop: '20px',
    gap: '0px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: '18px',
      width: '18px',
      marginRight: '10px',
    },
  },
  itemWidth: {
    width: '150px',
  },
  helpBuildingClassBlock: {
    marginLeft: '8px',
    marginTop: '7px',
  },
  helpBuildingClassIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  tooltip: {
    padding: '10px',
  },
};

export default styles;
