import * as yup from 'yup';
import i18n from 'locales/i18n';
import { NO_SPACES_RULE, IS_STRONG_PASSWORD } from 'forms/public/customRules';
import { CompanySignIp } from 'types/resources/Company';
import R from 'ramda';

export type SignUpFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  company: CompanySignIp;
};

export type SignUpFormDataToSubmit = {
  user: Partial<SignUpFormData> & { company_attributes: CompanySignIp };
};

const firstNameFieldName = i18n.t('common:firstName');
const lastNameFieldName = i18n.t('common:lastName');
const emailFieldName = i18n.t('common:email');
const passwordFieldName = i18n.t('common:password');

export const validationFields = {
  firstName: yup
    .string()
    .required(i18n.t('forms:validations.required', { field: firstNameFieldName }))
    .default(''),
  lastName: yup
    .string()
    .required(i18n.t('forms:validations.required', { field: lastNameFieldName }))
    .default(''),
  company: yup.object({
    name: yup.string().nullable().default(''),
  }),
  email: yup
    .string()
    .email(i18n.t('forms:validations.email'))
    .required(i18n.t('forms:validations.required', { field: emailFieldName }))
    .default(''),
  password: yup
    .string()
    .test(NO_SPACES_RULE)
    .test(IS_STRONG_PASSWORD)
    .required(i18n.t('forms:validations.required', { field: passwordFieldName }))
    .default(''),
};

export const validationSchema = yup.object().shape({ ...validationFields });

export const initialValues: SignUpFormData = validationSchema.getDefault();

export const attributesToSubmit = (values: SignUpFormData): SignUpFormDataToSubmit => {
  return {
    user: {
      ...R.omit(['company'], values),
      company_attributes: values.company,
    },
  };
};
