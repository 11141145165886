import { FC } from 'react';
import theme from 'theme';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { store } from 'store';
import { Provider as RoolbarProvider, ErrorBoundary } from '@rollbar/react';
import { IntercomProvider } from 'react-use-intercom';
import { rollbarConfig } from 'utils/errorTracking';
import locales from 'locales/i18n';
import Router from 'routers/Router';
import { I18nextProvider, useTranslation } from 'react-i18next';
import ConfirmationDialogProvider from 'contexts/ConfirmationDialogContext';
import ChatProvider from 'contexts/ChatContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Helmet } from 'react-helmet';

const App: FC = () => {
  const { t } = useTranslation(['common']);

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoolbarProvider config={rollbarConfig}>
            <ErrorBoundary>
              <I18nextProvider i18n={locales}>
                <ConfirmationDialogProvider>
                  <IntercomProvider appId={Settings.intercomAppId}>
                    <ChatProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Helmet>
                          <title>{t('common:trot')}</title>
                        </Helmet>
                        <Router />
                      </LocalizationProvider>
                    </ChatProvider>
                  </IntercomProvider>
                </ConfirmationDialogProvider>
              </I18nextProvider>
            </ErrorBoundary>
          </RoolbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default hot(App);
