import { AxiosResponse } from 'axios';
import { SearchFormDataToSubmit } from 'forms/public/searchForm';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const SearchRepository = {
  index(params: SearchFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiSiteV1SearchIndexPath();
    return Fetcher.get(url, params);
  },
};

export default SearchRepository;
