import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, FormHelperText, Stack, Typography } from '@material-ui/core';
import { head, isEmpty, isNil } from 'ramda';
import { useUpload } from 'hooks';
import Image from 'types/resources/Image';
import ImageCropRotateZoom from 'components/ImageCropRotateZoom';
import Box from 'components/Box';
import UploadWrapper from 'components/UploadWrapper';
import Loader from 'components/Loader';
import FormRow from 'components/FormRow';
import Icon from 'components/Icon';

import styles from './styles';

import type { UploadResult } from '@uppy/core';

type AvatarUploadProps = {
  isLoading: boolean;
  avatarUrl: string | null;
  onDelete: () => void;
  onDone: (avatar: Image) => void;
  label?: string;
  column?: boolean;
  type?: 'circle' | 'square';
  buttonText?: string;
  uploadHint: JSX.Element;
  isPlaceholderVisible?: boolean;
};

const upload = {
  config: {
    autoProceed: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ['image/*'],
      maxFileSize: 10 * 1024 * 1024,
    },
  },
  thumbnails: { height: 1000 },
};

const AvatarUpload: FC<AvatarUploadProps> = props => {
  const {
    isLoading,
    avatarUrl,
    onDelete,
    onDone,
    label,
    column = false,
    type = 'circle',
    isPlaceholderVisible,
    buttonText,
    uploadHint,
  } = props;

  const { t } = useTranslation(['resources', 'common', 'publicPages', 'components']);

  const [avatarUppyError, setAvatarUppyError] = useState<string>('');
  const [showCropImageModal, setShowCropImageModal] = useState<boolean>(false);
  const wasCropDone = useRef(false);
  const setWasCropDone = (value: boolean) => {
    wasCropDone.current = value;
  };

  const {
    uppy,
    files: [avatarFile],
    uploadFiles,
    reset,
    fileData,
  } = useUpload({
    config: upload.config,
    thumbnails: upload.thumbnails,
    onRestrictionFailed: (_file, error) => setAvatarUppyError(error.message),
    onAdd: () => {
      if (!wasCropDone.current) {
        setShowCropImageModal(true);
      }
    },
  });

  const uploadImages = (): Promise<Image> => {
    const imageToUpload = head(uppy.getFiles());
    if (!imageToUpload) return Promise.resolve(null);
    return uploadFiles().then((result: UploadResult): Image => {
      const { successful, failed } = result;
      if (head(failed)) {
        setAvatarUppyError(head(failed).error);
        reset();
      }

      if (head(successful)) {
        const file = fileData(head(successful));
        const avatar = { file, tag: 'profile_avatar', order: 0 } as Image;
        return avatar;
      }

      return null;
    });
  };

  const imageLink = avatarFile ? avatarFile.preview : avatarUrl;

  const handleAvatarDelete = async () => {
    await onDelete();
    if (avatarFile) {
      uppy.cancelAll();
    }
    setWasCropDone(false);
  };

  const handleCropDone = async (blob: Blob) => {
    setWasCropDone(true);
    setShowCropImageModal(false);
    uppy.cancelAll();
    uppy.addFile({
      name: 'avatar.jpg',
      type: 'image/jpeg',
      data: blob,
      source: 'Local',
      isRemote: false,
    });
    const result = await uploadImages();
    onDone(result);
  };

  const handleCancel = () => {
    setWasCropDone(false);
    setShowCropImageModal(false);
    uppy.cancelAll();
  };

  const handleAvatarEdit = () => {
    setShowCropImageModal(true);
  };

  const emptyImage = isEmpty(imageLink) || isNil(imageLink);

  return (
    <Box>
      <Loader open={isLoading} />
      <ImageCropRotateZoom
        imageUrl={imageLink}
        onCropDone={handleCropDone}
        onClose={() => setShowCropImageModal(false)}
        onCancel={handleCancel}
        isOpened={showCropImageModal}
      />
      <FormRow column={column} label={label || t('publicPages:ProfileEdit.publicAvatar')}>
        <Stack direction="column" spacing={{ mobile: 1, desktop: 0 }} sx={styles.upload}>
          {!isPlaceholderVisible && <Avatar sx={styles.avatar} src={imageLink} />}
          {type === 'square' && !emptyImage && <Avatar sx={styles.avatarSquare} src={imageLink} variant="square" />}
          <Box sx={styles.uploadButtons}>
            {emptyImage && (
              <Box sx={styles.buttonAndHint}>
                <UploadWrapper
                  uppy={uppy}
                  id="avatar"
                  handlerKey="onClick"
                  render={innerProps => (
                    <Button
                      {...innerProps}
                      sx={styles.uploadButton}
                      startIcon={<Icon name="upload" />}
                      disabled={isLoading}
                    >
                      <Typography sx={styles.uploadButtonText} variant="subtitle2">
                        {buttonText || t('common:uploadTheImage')}
                      </Typography>
                    </Button>
                  )}
                />
                {uploadHint}
              </Box>
            )}
            {(avatarFile || avatarUrl) && (
              <Box sx={styles.editDeleteButtons}>
                <Button onClick={handleAvatarEdit} sx={styles.editButton} variant="text">
                  <Typography variant="tabsTextBold">{t('common:edit')}</Typography>
                </Button>
                <Button disabled={isLoading} onClick={handleAvatarDelete} sx={styles.deleteButton} variant="text">
                  <Typography variant="tabsTextBold">{t('common:delete')}</Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
        {!isEmpty(avatarUppyError) && <FormHelperText error>{avatarUppyError}</FormHelperText>}
      </FormRow>
    </Box>
  );
};

export default AvatarUpload;
