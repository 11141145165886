import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    marginBottom: '16px',
    paddingBottom: '12.5px',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.gray.main}`,
    maxWidth: '600px',
    cursor: 'pointer',
  },
  link: {
    color: 'white.main',
    textDecoration: 'none',
    opacity: 0.5,
    marginRight: '32px',
    textTransform: 'uppercase',
  },
  linkActive: {
    opacity: 1,
    textDecorationLine: 'underline',
    textDecorationSkipInk: 'none',
    textUnderlineOffset: '14px',
    textDecorationColor: 'rgb(66, 106, 241)',
    textDecorationThickness: '3px',
  },
  column: {
    maxWidth: '310px',
  },
  textLink: {
    color: 'white.main',
  },
  heading: {
    marginBottom: '16px',
  },
};

export default styles;
