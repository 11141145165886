import { FC, useState, useMemo, useEffect } from 'react';
import { Typography, Autocomplete, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { useGoogleService } from 'hooks';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import { isEmpty } from 'ramda';
import Box from 'components/Box';

import styles from './styles';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

type PlacesAutocompleteProps = {
  onChange: (result: google.maps.GeocoderResult, place: string) => void;
  initialValue?: string;
  variant?: 'filled' | 'outlined';
  error?: string;
};

const PlacesAutocomplete: FC<PlacesAutocompleteProps> = props => {
  const { onChange, initialValue = '', variant = 'filled', error } = props;
  const { t } = useTranslation('components');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [value, setValue] = useState<PlaceType | null>(null);
  const { autocomplete, geocoder, ready } = useGoogleService();

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        if (ready) {
          autocomplete.getPlacePredictions(request, callback);
        }
      }, 200),
    [autocomplete],
  );

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleAutocompleteInputChange = (event: React.SyntheticEvent, newInputValue: string, reason: string) => {
    const changedValue = reason === 'reset' && isEmpty(newInputValue) ? initialValue : newInputValue;
    if (changedValue === '') {
      onChange(null, null);
    }
    setInputValue(changedValue);
    fetch({ input: newInputValue }, (results: readonly PlaceType[] = []) => {
      if (results) {
        setOptions(results);
      }
    });
  };

  const handleAutocompleteValueChange = (event: React.SyntheticEvent, newValue: PlaceType | string) => {
    if (typeof newValue === 'string') {
      onChange(null, null);
    } else {
      setValue(newValue);

      const address = newValue.description;
      geocoder.geocode({ address }).then(({ results }) => {
        onChange(results[0], address);
      });
    }
  };

  const renderPlaceOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: PlaceType) => {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match: MainTextMatchedSubstrings): [number, number] => [match.offset, match.offset + match.length]),
    );

    return (
      <li {...optionProps}>
        <Grid container alignItems="center">
          <Grid item xs>
            {parts.map((part, i) => (
              <span
                key={`${i + 0}`}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
            <Typography variant="body2" color="text.secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  };

  return (
    <>
      <Autocomplete
        getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
        filterOptions={x => x}
        options={options}
        isOptionEqualToValue={(option: PlaceType, selected: PlaceType) => option.place_id === selected.place_id}
        autoComplete
        fullWidth
        includeInputInList
        filterSelectedOptions
        value={value}
        inputValue={inputValue}
        forcePopupIcon={false}
        disableClearable
        onChange={handleAutocompleteValueChange}
        onInputChange={handleAutocompleteInputChange}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={t('PlacesAutocomplete.placeholder')}
            fullWidth
            variant={variant}
            error={Boolean(error)}
            helperText={Boolean(error) && error}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                variant === 'filled' ? (
                  <Icon name="label" />
                ) : (
                  <Box sx={styles.outlinedAdornment}>
                    <Icon name="search" />
                  </Box>
                ),
            }}
          />
        )}
        renderOption={(optionProps, option) => {
          if (typeof option !== 'string') {
            return renderPlaceOption(optionProps, option);
          }
          return null;
        }}
      />
    </>
  );
};

export default PlacesAutocomplete;
