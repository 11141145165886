export default {
  trot: 'Trot',
  back: 'Back',
  cancel: 'Cancel',
  capacity: '{{min}}-{{max}} people',
  contactPhone: 'Contact phone',
  createAccount: 'Create Account',
  trotPhoneNumberLabel: '+1 (866) TROTFLEX',
  email: 'Email',
  inMonth: '{{price}} / month',
  edit: 'Edit',
  forgotPassword: 'Forgot password?',
  yes: 'Yes',
  no: 'No',
  or: 'or',
  newPassword: 'New Password',
  resetPassword: 'Reset Password',
  saveAndContinue: 'Save and continue',
  saveAsDraft: 'Save and continue later',
  saveChanges: 'Save changes',
  sendForApproval: 'Send for approval',
  search: 'Search',
  sqft: '{{square}} sqft.',
  termsAndConditions: 'Terms & Conditions',
  yourEmail: 'Email Address',
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  companyName: 'Company Name',
  referralDescription: 'Tell us how you found out about TROT',
  selectImageFromYourComputer: 'Select image from your computer',
  selectVideoFromYourComputer: 'Select video from your computer',
  selectImagesFromYourComputer: 'Select images from your computer',
  jpgPng10MB: 'JPG or PNG, up to 10MB',
  jpgPng10MBeach: 'JPG or PNG, up to 10MB each',
  selectImagesOrVideosFromYourComputer: 'Select images or videos from your computer',
  jpgPngMP4MOV256MB: 'JPG, PNG, MP4 or MOV up to 256MB each',
  mp4Mov256MB: 'MP4 or MOV up to 256MB',
  uploadTheFiles: 'Upload files',
  uploadTheImage: 'Upload image',
  uploadTheFile: 'Upload the file',
  addMoreImages: 'Add more',
  dragOrSelectFromComputer: 'Drag or select from computer',
  selectTheImages: 'Select the images',
  addTheCaption: 'Add the caption',
  subway: 'Subway',
  bus: 'Bus',
  squareMetric: 'sqft.',
  monthsCount: '{{count}} month',
  monthsCount_plural: '{{count}} months',
  heightMetric: 'ft.',
  piecesMetric: 'pc.',
  priceMetric: '$ / month',
  capacityMetric: 'people',
  price: 'Price',
  imagesCountUploaded: '{{count}} image uploaded',
  imagesCountUploaded_plural: '{{count}} images uploaded',
  uploaded: 'uploaded',
  approve: 'approve',
  decline: 'decline',
  john: 'John',
  appleseed: 'Appleseed',
  ok: 'ok',
  delete: 'delete',
  connectAccount: 'Add bank account',
  percentUploaded: '{{percent}}% uploaded',
  backToGallery: 'back to gallery',
  stopImpersonateButton: 'Stop impersonate',
  profile: 'Profile',
  dashboard: 'Dashboard',
  rentableSquare: '{{square}} sqft. (rentable)',
  space: {
    filters: {
      minMax: '{{name}}: {{min}}-{{max}}{{label}}',
      onlyMin: '{{name}}: {{min}}{{label}}+',
      onlyMax: '{{name}}: < {{max}}{{label}}',
      pricePerMonth: 'Price',
      square: 'Size',
      elevator: 'Elevator',
      onlyAvailable: 'Only Available',
      namesToLabels: {
        pricePerMonth: '$',
        square: 'ft2',
      },
    },
  },
  paymentSchedule: {
    Date: 'Date',
    Amount: 'Amount',
    Period: 'Period',
    Total: 'Total',
  },
  goBack: 'Go Back',
  chatPlaceholderTrotter: 'Select the space to see the messages',
  chatPlaceholderLandlord: 'Select the trotter and conversation to see the messages',
  chatPlaceholderAdmin: 'Select conversation to see the messages',
  mainPhotoLabel: 'Main Photo of the gallery',
  removeDate: 'Remove date',
  checkDates: 'Check dates',
  dateOfStart: 'Date of start',
  errorTitle: 'An error has occurred',
  errorDefaultMessage:
    "Please don't worry, everything will work soon. But you need to send the data again a little later.",
};
