import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: {
    maxWidth: '588px',
    height: '616px',
    padding: '76px 40px 48px',
    position: 'relative',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '14px',
  },
  title: {
    marginBottom: '32px',
    textAlign: 'center',
  },
  content: {
    padding: '20px',
    border: (theme: Theme) => `1px solid ${theme.palette.brightBlue.light}`,
    height: 'calc(100% - 20px)',
    marginBottom: '32px',
    overflowY: 'scroll',
  },
  text: {
    opacity: 0.9,
  },
  contentTitle: {
    marginBottom: '12px',
  },
  okButton: {
    width: '132px',
  },
};

export default styles;
