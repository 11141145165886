import { useEffect } from 'react';
import { useIntercom as useReactIntercom } from 'react-use-intercom';
import { UsersPresenter } from 'presenters';

import useUsers from './useUsers';

const useIntercom = (): void => {
  const { boot, shutdown } = useReactIntercom();
  const { currentUser } = useUsers();

  const userData = () => {
    if (UsersPresenter.isGuest(currentUser)) {
      return {
        customAttributes: {
          'User Type': 'guest',
        },
      };
    }

    return {
      name: UsersPresenter.fullName(currentUser),
      email: currentUser.email,
      userId: String(currentUser.id),
      createdAt: currentUser.createdAt,
      customAttributes: {
        'User Type': currentUser.intercomMeta.userType,
        'User Status': currentUser.intercomMeta.userStatus,
        'Company Name': currentUser.intercomMeta.companyName,
        'Company Address': currentUser.intercomMeta.companyAddress,
        'Building Totals': currentUser.intercomMeta.buildingTotals,
        'Listing Totals': currentUser.intercomMeta.listingTotals,
        'Total Listing Size Sq Ft': currentUser.intercomMeta.totalListingSizeSqFt,
        'Total Revenue': currentUser.intercomMeta.totalRevenueToDate,
        'Total License Number': currentUser.intercomMeta.totalLicenseNumber,
      },
    };
  };

  useEffect(() => {
    boot(userData());
  }, []);

  useEffect(() => {
    shutdown();
    boot(userData());
  }, [currentUser.id]);
};

export default useIntercom;
