import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import Space from 'types/resources/Space';
import { SpacePresenter } from 'presenters';

import styles from './styles';

type SpaceStatusBadgeProps = {
  space: Space;
};

const SpaceStatusBadge: FC<SpaceStatusBadgeProps> = props => {
  const { space } = props;
  const style = `state_${space.state}`;
  const statusText = SpacePresenter.state(space);

  return (
    <Box sx={styles[style]}>
      <Typography variant="bold7">{statusText}</Typography>
    </Box>
  );
};

export default SpaceStatusBadge;
