import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { useGoogleAuthActions, createGoogleAuthAthorizationURL } from 'store/googleAuthSlice';
import getFetchStatus from 'utils/fetchStatus';

type UseGoogleAuthType = {
  isFinished: boolean;
  authorizationUrl: string;
};

const useGoogleAuth = (): UseGoogleAuthType => {
  const dispatch = useAppDispatch();

  const { resetGoogleAuth } = useGoogleAuthActions();
  const { authorizationUrl, fetchStatus } = useAppSelector(state => state.googleAuth);
  const { isFinished } = getFetchStatus(fetchStatus);

  useEffect(() => {
    dispatch(createGoogleAuthAthorizationURL());

    return resetGoogleAuth;
  }, []);

  return {
    authorizationUrl,
    isFinished,
  };
};

export default useGoogleAuth;
