import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  player: {
    width: '100%',
    height: '100%',
  },
};

export default styles;
