import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  base: {
    marginTop: '24px',
    marginBottom: '40px',
    '& svg': {
      width: 16,
      height: 16,
    },
    cursor: 'default',
  },
  row: {
    marginLeft: '8px',
    marginRight: '32px',
  },
  squareBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: {
    padding: '10px',
  },
};

export default styles;
