import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiFilledInputType = {
  defaultProps: ComponentsProps['MuiFilledInput'];
  styleOverrides: ComponentsOverrides['MuiFilledInput'];
};

const MuiFilledInput: MuiFilledInputType = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        fontSize: 15,
        height: 50,
        background: '#fff',
        borderRadius: 0,
        padding: '0 0 0 27px !important',
        '&:hover': {
          background: '#fff',
        },
        '& .MuiInputBase-inputAdornedStart': {
          paddingLeft: '10px !important',
        },
        [theme.breakpoints.up('desktop')]: {
          height: 60,
        },
      };
    },
  },
  defaultProps: {
    disableUnderline: true,
  },
};

export default MuiFilledInput;
