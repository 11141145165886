import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiPaginationItemType = {
  defaultProps: ComponentsProps['MuiPaginationItem'];
  styleOverrides: ComponentsOverrides['MuiPaginationItem'];
};

const MuiPaginationItem: MuiPaginationItemType = {
  styleOverrides: {
    root: {
      color: '#050C1D',
      backgroundColor: '#F5F6FA',
      marginRight: 0,
      marginLeft: 0,
      width: 40,
      height: 40,
      borderRadius: 0,
      '&.Mui-selected': {
        color: '#fff',
        backgroundColor: '#0B2050',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#0B2050',
        },
      },
    },
  },
  defaultProps: {},
};

export default MuiPaginationItem;
