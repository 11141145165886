import { apiRoutes } from 'routes';
import Space, { ListingFormSubmitData, ListingUpdateAvailableDateData } from 'types/resources/Space';
import Fetcher from 'utils/fetcher';

const SpacesRepository = {
  create(newListing: ListingFormSubmitData): Promise<Space> {
    const url = apiRoutes.apiSiteV1LandlordDraftBuildingSpacesPath(newListing.buildingId);
    return Fetcher.post(url, newListing);
  },
  update({
    buildingId,
    spaceId,
    space,
  }: {
    buildingId: ID;
    spaceId: ID;
    space: ListingFormSubmitData | ListingUpdateAvailableDateData;
  }): Promise<Space> {
    const url = apiRoutes.apiSiteV1LandlordDraftBuildingSpacePath(buildingId, spaceId);
    return Fetcher.put(url, space);
  },
};

export default SpacesRepository;
