import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTabsType = {
  defaultProps: ComponentsProps['MuiTabs'];
  styleOverrides: ComponentsOverrides['MuiTabs'];
};

const MuiTabs: MuiTabsType = {
  styleOverrides: {
    indicator: {},
  },
  defaultProps: {},
};

export default MuiTabs;
