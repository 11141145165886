import { apiRoutes } from 'routes';
import Building, { BuildingResources, BuildingFormSubmitData } from 'types/resources/Building';
import Fetcher from 'utils/fetcher';

const BuildingsRepository = {
  index(params: QueryParams): Promise<BuildingResources> {
    const url = apiRoutes.apiSiteV1LandlordBuildingsPath();
    return Fetcher.get(url, params);
  },
  create(newBuilding: BuildingFormSubmitData): Promise<Building> {
    const url = apiRoutes.apiSiteV1LandlordBuildingsPath();
    return Fetcher.post(url, newBuilding);
  },
  show(buildingId: ID): Promise<Building> {
    const url = apiRoutes.apiSiteV1LandlordBuildingPath(buildingId);
    return Fetcher.get(url);
  },
  update({ buildingId, building }: { buildingId: ID; building: BuildingFormSubmitData }): Promise<Building> {
    const url = apiRoutes.apiSiteV1LandlordBuildingPath(buildingId);
    return Fetcher.put(url, building);
  },
  delete(buildingId: ID): Promise<void> {
    const url = apiRoutes.apiSiteV1LandlordBuildingPath(buildingId);
    return Fetcher.delete(url);
  },
};

export default BuildingsRepository;
