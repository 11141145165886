type MarketingRoutes = {
  [key: string]: () => string;
};

const appRoutes: MarketingRoutes = {
  rootPath: () => 'https://powerofflex.trotflex.com/the-trot-blog/',
  whyTrotForOwnersPath: () => 'https://powerofflex.trotflex.com/why-trot-2/',
  whyTrotForTrottersPath: () => 'https://powerofflex.trotflex.com/why-trot/',
  spaceRevolutionPath: () => 'https://powerofflex.trotflex.com/flex-space-revolution/',
  exploreResourcesForOwnersPath: () => 'https://powerofflex.trotflex.com/explore-resources-2/',
  exploreResourcesForTrottersPath: () => 'https://powerofflex.trotflex.com/explore-resources/',
  howItWorksForOwnersPath: () => 'https://powerofflex.trotflex.com/how-it-works-2/',
  howItWorksForTrottersPath: () => 'https://powerofflex.trotflex.com/how-it-works/',
  meetTheTeamPath: () => 'https://powerofflex.trotflex.com/meet-the-team/',
  contactUsPath: () => 'https://powerofflex.trotflex.com/contact/',
  ourServicesPath: () => 'https://powerofflex.trotflex.com/home/#os',
  scheduleDemoPath: () => 'https://powerofflex.trotflex.com/schedule-a-demo/',
};

export default appRoutes;
