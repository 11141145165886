import { FC } from 'react';
import { Button, Stack, Typography, TextField, FormHelperText, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { initialValues, validationSchema, attributesToSubmit, DeclineFormData } from 'forms/admin/DeclineForm';
import { FormikHelpers, useFormik } from 'formik';
import { handleErrors } from 'utils/errors';
import { useModals, useRouter } from 'hooks';
import SpacesRepository from 'repositories/admin/SpacesRepository';
import { appRoutes } from 'routes';
import Icon from 'components/Icon';

import styles from './styles';

const MAX_DESCRIPTION_LENGTH = 80;

const DeclineWithReason: FC = () => {
  const { t } = useTranslation(['adminPages', 'resources']);
  const { closeModal, extra } = useModals();
  const { push } = useRouter();

  const { title, space, declineType, onDecline, onCancel } = extra;

  const onSubmit = async (formData: DeclineFormData, { setSubmitting, setErrors }: FormikHelpers<DeclineFormData>) => {
    const params = attributesToSubmit(formData);
    try {
      if (declineType === 'listing') {
        await SpacesRepository.decline({ spaceId: space.id, space: params });
        push(appRoutes.admin.rootPath());
      } else if (declineType === 'request') {
        onDecline(params);
      }
      closeModal();
    } catch (error: unknown) {
      handleErrors(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const { values, handleChange, handleBlur, handleSubmit, touched, errors, isSubmitting, isValid } = formik;

  const handleCancelClick = () => (onCancel ? onCancel() : closeModal());

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={handleCancelClick}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {title}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4">
        {t('adminPages:Listing.declineSubtitle')}
      </Typography>
      <Box onSubmit={handleSubmit} component="form">
        <Box sx={styles.textarea}>
          <TextField
            inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
            error={touched.declineReason && Boolean(errors.declineReason)}
            helperText={touched.declineReason && errors.declineReason}
            name="declineReason"
            id="declineReason"
            fullWidth
            value={values.declineReason}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('adminPages:Listing.typeText')}
          />
          <FormHelperText variant="outlined" sx={styles.symbolsCounter}>
            {values.declineReason.length} / {MAX_DESCRIPTION_LENGTH}
          </FormHelperText>
        </Box>
        <Box sx={styles.actions}>
          {declineType === 'request' ? (
            <>
              <Button sx={styles.negativeCancelButton} color="primary" onClick={handleCancelClick}>
                <Typography variant="tabsTextBold" component="p">
                  {t('adminPages:Listing.back')}
                </Typography>
              </Button>
              <Button
                sx={styles.negativeConfirmButton}
                variant="text"
                color="error"
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                <Typography variant="tabsTextBold" component="p">
                  {t('adminPages:Listing.decline')}
                </Typography>
              </Button>
            </>
          ) : (
            <>
              <Button sx={styles.okButton} type="submit" disabled={!isValid || isSubmitting}>
                {t('adminPages:Listing.submitButton')}
              </Button>
              <Button sx={styles.cancelButton} variant="text" onClick={handleCancelClick}>
                {t('adminPages:Listing.cancelButton')}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default DeclineWithReason;
