import { FC } from 'react';
import { Stack, Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals, useRouter } from 'hooks';
import Icon from 'components/Icon';
import { makePathWithQueryString } from 'utils/routes';
import { appRoutes } from 'routes';
import { ModalType } from 'enums/ModalType';

import styles from './styles';

const LandlordSpaceRentCancelFinish: FC = () => {
  const { t } = useTranslation(['landlordPages', 'common']);
  const { push } = useRouter();
  const { closeModal, extra, openModal } = useModals();
  const { space, spaceRent, loadSpaces } = extra;

  const handleSuggestSpacesButtonClick = () => {
    closeModal();
    openModal({
      type: ModalType.landlordSpaceRentCancelSuggestSpaces,
      extra: {
        space,
        spaceRent,
        loadSpaces,
      },
    });
  };

  const handleStartChatClick = async () => {
    closeModal();
    const path = makePathWithQueryString(appRoutes.landlord.chatPath(), {
      spaceId: space.id,
      userId: spaceRent.user.id,
    });
    push(path);
  };

  const handleClose = () => {
    loadSpaces();
    closeModal();
  };

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={handleClose}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('landlordPages:Listings.SpaceRentCancelFinish.title')}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4">
        {t('landlordPages:Listings.SpaceRentCancelFinish.text', { firstName: spaceRent.user.firstName })}
      </Typography>
      <Box>
        <Button sx={styles.button} onClick={handleSuggestSpacesButtonClick}>
          <Typography variant="tabsTextBold">
            {t('landlordPages:Listings.SpaceRentCancelFinish.suggestSpaces')}
          </Typography>
        </Button>
        <Button sx={styles.button} variant="outlined" onClick={handleStartChatClick}>
          <Typography variant="tabsTextBold">{t('landlordPages:Listings.SpaceRentCancelFinish.chat')}</Typography>
        </Button>
        <Button sx={styles.button} variant="text" onClick={handleClose}>
          <Typography variant="tabsTextBold">{t('landlordPages:Listings.SpaceRentCancelFinish.finish')}</Typography>
        </Button>
      </Box>
    </Stack>
  );
};

export default LandlordSpaceRentCancelFinish;
