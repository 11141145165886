import { apiRoutes } from 'routes';
import { Employee, EmployeeFormSubmitData, EmployeeResources } from 'types/resources/Employee';
import Fetcher from 'utils/fetcher';

const EmployeesRepository = {
  index(params: QueryParams): Promise<EmployeeResources> {
    const url = apiRoutes.apiSiteV1LandlordEmployeesPath();
    return Fetcher.get(url, params);
  },
  create(employee: EmployeeFormSubmitData): Promise<Employee> {
    const url = apiRoutes.apiSiteV1LandlordEmployeesPath();
    return Fetcher.post(url, employee);
  },
  show(employeeId: ID): Promise<Employee> {
    const url = apiRoutes.apiSiteV1LandlordEmployeePath(employeeId);
    return Fetcher.get(url);
  },
  update({ employeeId, employee }: { employeeId: ID; employee: EmployeeFormSubmitData }): Promise<Employee> {
    const url = apiRoutes.apiSiteV1LandlordEmployeePath(employeeId);
    return Fetcher.put(url, employee);
  },
  delete(employeeId: ID): Promise<void> {
    const url = apiRoutes.apiSiteV1LandlordEmployeePath(employeeId);
    return Fetcher.delete(url);
  },
};

export default EmployeesRepository;
