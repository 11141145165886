import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  avatars: {
    width: 48,
    height: 48,
    position: 'relative',
    marginRight: '12px',
  },
  avatar: {
    position: 'absolute',
    height: 34,
    width: 34,
    border: '2px solid white',
  },
  avatarLeft: {
    top: 0,
    left: 0,
  },
  avatarRight: {
    bottom: 0,
    right: 0,
  },
  channel: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  textBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  iconRightArrow: {
    display: {
      mobile: 'block',
      desktop: 'none',
    },
  },
};

export default styles;
