import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    padding: '64px 58px 48px',
    position: 'relative',
    maxWidth: '600px',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '14px',
  },
  title: {
    marginBottom: '16px',
    textAlign: 'center',
    maxWidth: '500px',
  },
  subtitle: {
    marginBottom: '40px',
    textAlign: 'center',
  },
  button: {
    width: 'auto',
    marginRight: '20px',
  },
};

export default styles;
