import { FC } from 'react';
import { isNil } from 'ramda';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { TextField } from '@mui/material';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import clsx from 'utils/clsx';

import styles from './styles';

type DatePickerPropsType = {
  date: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChangeDate: (newDate: Date) => void;
  onResetDate?: () => void;
  setOpen?: (opened: boolean) => void;
  filter?: (day: Date) => boolean;
};

const DatePicker: FC<DatePickerPropsType> = props => {
  const { date, onChangeDate, onResetDate, setOpen, filter = () => false, minDate = null, maxDate = null } = props;
  const { t } = useTranslation(['common']);

  const handleDateChange = (newDate: Date) => {
    onChangeDate(newDate);
    setOpen(false);
  };

  const renderDay = (day: Date, _: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
    return (
      <PickersDay
        {...pickersDayProps}
        sx={clsx(styles.day, [
          [styles.dayCurrent, pickersDayProps.today],
          [styles.dayNotAllowed, filter(day)],
        ])}
      />
    );
  };

  return (
    <Box sx={styles.root}>
      <StaticDatePicker
        allowSameDateSelection
        displayStaticWrapperAs="desktop"
        value={date || new Date()}
        onChange={() => null}
        onAccept={handleDateChange}
        renderInput={params => <TextField {...params} />}
        mask=""
        inputFormat="MMMM dd, yyyy"
        renderDay={renderDay}
        minDate={minDate}
        maxDate={maxDate}
      />
      {onResetDate && (
        <Button sx={styles.trashButton} variant="text" onClick={onResetDate} disabled={isNil(date)}>
          <Icon sx={styles.trashIcon} name="trash" />
          <Typography sx={styles.trashButtonText} variant="tabsTextBold" component="p">
            {t('common:removeDate')}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default DatePicker;
