import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import { FC } from 'react';
import PhoneInput2 from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import clsx from 'utils/clsx';

import styles from './styles';

type PhoneInputProps = {
  disabled: boolean;
  value: string;
  error: string | null;
  onChange: (phone: string) => void;
  placeholder: string;
};

const PhoneInput: FC<PhoneInputProps> = props => {
  const { value, onChange, placeholder, disabled, error } = props;

  return (
    <Box sx={clsx(styles.root, [[styles.rootError, Boolean(error)]])}>
      <PhoneInput2
        country="us"
        enableAreaCodes
        disabled={disabled}
        value={value}
        onChange={phone => onChange(phone)}
        placeholder={placeholder}
      />
      {error && (
        <Typography sx={styles.error} component="p" variant="body5">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default PhoneInput;
