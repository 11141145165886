import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks';
import { Stack, Typography, Box, Button } from '@material-ui/core';
import Icon from 'components/Icon';

import styles from './styles';

const LandlordSpaceRentCancelSuggestSpacesFinish: FC = () => {
  const { t } = useTranslation(['landlordPages', 'common']);
  const { closeModal, extra } = useModals();
  const { spaceRent } = extra;
  const { firstName } = spaceRent.user;

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={closeModal}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('landlordPages:Listings.SpaceRentCancelSuggestSpacesFinish.title', { firstName })}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4">
        {t('landlordPages:Listings.SpaceRentCancelSuggestSpacesFinish.text', { firstName })}
      </Typography>
      <Box>
        <Button sx={styles.button} onClick={closeModal}>
          <Typography variant="tabsTextBold">
            {t('landlordPages:Listings.SpaceRentCancelSuggestSpacesFinish.ok')}
          </Typography>
        </Button>
      </Box>
    </Stack>
  );
};

export default LandlordSpaceRentCancelSuggestSpacesFinish;
