/* eslint-disable no-param-reassign */
import SpacesRepository from 'repositories/landlord/SpacesRepository';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import Space, { ListingFormSubmitData } from 'types/resources/Space';

export type SpacesSliceStateType = {
  space: Space;
  spaces: Space[];
  suggestedSpaces: Space[];
  rentedSpaces: Space[];
  rentRequestedSpaces: Space[];
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  indexSuggestedSpaces: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  indexRented: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  indexRentRequested: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  review: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  unpublish: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  makeBookable: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  makeUnbookable: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadSpaces = createAsyncThunk('landlords/spaces/load', SpacesRepository.index);
export const loadSuggestedSpaces = createAsyncThunk('landlords/suggestedSpaces/load', SpacesRepository.index);
export const loadRentRequestedSpaces = createAsyncThunk('landlords/spacesRentRequested/load', SpacesRepository.index);
export const loadRentedSpaces = createAsyncThunk('landlords/spacesRented/load', SpacesRepository.index);

export const createSpace = createAsyncThunk(
  'landlords/spaces/create',
  async (space: ListingFormSubmitData, { rejectWithValue }) => {
    try {
      return await SpacesRepository.create(space);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const reviewSpace = createAsyncThunk(
  'landlords/spaces/review',
  async ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }, { rejectWithValue }) => {
    try {
      return await SpacesRepository.review(buildingId, spaceId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSpace = createAsyncThunk(
  'landlords/spaces/delete',
  async ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }, { rejectWithValue }) => {
    try {
      return await SpacesRepository.delete(buildingId, spaceId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const loadSpace = createAsyncThunk(
  'landlords/spaces/loadSpace',
  async ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }, { rejectWithValue }) => {
    try {
      return await SpacesRepository.show(buildingId, spaceId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSpace = createAsyncThunk('landlords/spaces/updateSpace', SpacesRepository.update);
export const unpublishSpace = createAsyncThunk('landlords/spaces/unpublishSpace', SpacesRepository.unpublish);
export const makeUnbookableSpace = createAsyncThunk(
  'landlords/spaces/makeUnbookableSpace',
  SpacesRepository.makeUnbookable,
);
export const makeBookableSpace = createAsyncThunk(
  'landlords/spaces/makeBookableSpace',
  async (
    { buildingId, spaceId, availableAt }: { buildingId: ID; spaceId: ID; availableAt: string },
    { rejectWithValue },
  ) => {
    try {
      return await SpacesRepository.makeBookable({ buildingId, spaceId, availableAt });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: SpacesSliceStateType = {
  space: {} as Space,
  spaces: [] as Space[],
  suggestedSpaces: [] as Space[],
  rentedSpaces: [] as Space[],
  rentRequestedSpaces: [] as Space[],
  meta: { perPage: 10 } as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  indexRented: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  indexRentRequested: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  indexSuggestedSpaces: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  review: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  unpublish: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  makeBookable: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  makeUnbookable: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords/spaces',
  initialState,
  reducers: {
    resetSpace(state) {
      state.space = initialState.space;
      state.create = initialState.create;
      state.show = initialState.show;
      state.update = initialState.update;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSpaces.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpaces.fulfilled, (state, { payload }) => {
      state.spaces = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpaces.rejected, state => {
      state.spaces = [];
      state.index.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadSuggestedSpaces.pending, state => {
      state.indexSuggestedSpaces.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSuggestedSpaces.fulfilled, (state, { payload }) => {
      state.suggestedSpaces = payload.items;
      state.indexSuggestedSpaces.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSuggestedSpaces.rejected, state => {
      state.indexSuggestedSpaces.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadRentRequestedSpaces.pending, state => {
      state.indexRentRequested.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadRentRequestedSpaces.fulfilled, (state, { payload }) => {
      state.rentRequestedSpaces = payload.items;
      state.indexRentRequested.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadRentRequestedSpaces.rejected, state => {
      state.rentRequestedSpaces = [];
      state.indexRentRequested.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadRentedSpaces.pending, state => {
      state.indexRented.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadRentedSpaces.fulfilled, (state, { payload }) => {
      state.rentedSpaces = payload.items;
      state.indexRented.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadRentedSpaces.rejected, state => {
      state.rentedSpaces = [];
      state.indexRented.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(loadSpace.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpace.fulfilled, (state, { payload }) => {
      state.space = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpace.rejected, (state, { payload }) => {
      state.show.error = payload;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(createSpace.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createSpace.fulfilled, (state, { payload }) => {
      state.space = payload;
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createSpace.rejected, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = payload;
    });
    builder.addCase(reviewSpace.pending, state => {
      state.review.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(reviewSpace.fulfilled, state => {
      state.review.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(reviewSpace.rejected, (state, { error }) => {
      state.review.error = error;
      state.review.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateSpace.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateSpace.fulfilled, state => {
      state.update.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(updateSpace.rejected, state => {
      state.update.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(unpublishSpace.pending, state => {
      state.unpublish.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(unpublishSpace.fulfilled, state => {
      state.unpublish.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(unpublishSpace.rejected, state => {
      state.unpublish.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(makeUnbookableSpace.pending, state => {
      state.makeUnbookable.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(makeUnbookableSpace.fulfilled, state => {
      state.makeUnbookable.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(makeUnbookableSpace.rejected, state => {
      state.makeUnbookable.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(makeBookableSpace.pending, state => {
      state.makeBookable.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(makeBookableSpace.fulfilled, state => {
      state.makeBookable.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(makeBookableSpace.rejected, state => {
      state.makeBookable.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(deleteSpace.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteSpace.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteSpace.rejected, (state, { error }) => {
      state.delete.error = error;
      state.delete.fetchStatus = FetchStatus.failed;
    });
  },
});

export default slice.reducer;

const {
  actions: { resetSpace },
} = slice;

export { resetSpace };
