import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100% - 64px)',
    backgroundColor: 'navy.background',
    overflowY: 'auto',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
  },
  navItem: {
    padding: '20px 25px 20px 25px',
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'flex-start',
    '& svg': {
      width: '17px',
      marginRight: '12px',
    },
  },
  bottom: {
    marginTop: 'auto',
  },
  logout: {
    cursor: 'pointer',
    padding: '16px 40px',
  },
  buttonWrapper: {
    marginLeft: '24px',
    marginRight: '24px',
  },
  button: {
    width: '100%',
    marginBottom: '12px',
  },
};

export default styles;
