import { loadCurrentUser } from 'store/usersSlice';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/User';
import getFetchStatus from 'utils/fetchStatus';

type UseUsersType = {
  currentUser: User;
  loadCurrentUser: () => void;
  isLoadCurrentUserFinished: boolean;
};

const useUsers = (): UseUsersType => {
  const {
    currentUser,
    loadCurrentUser: { fetchStatus },
  } = useAppSelector(state => state.users);
  const { isFinished: isLoadCurrentUserFinished } = getFetchStatus(fetchStatus);
  const dispatch = useAppDispatch();

  return {
    currentUser,
    loadCurrentUser: () => dispatch(loadCurrentUser()),
    isLoadCurrentUserFinished,
  };
};

export default useUsers;
