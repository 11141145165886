import { FC, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Dialog, DialogContent, Slider, IconButton } from '@material-ui/core';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { getBlobFromUrl } from 'utils/download';
import { useScreen } from 'hooks';

import { getCroppedImg } from './utils';
import styles from './styles';

type ImageCropRotateZoomProps = {
  imageUrl: string;
  onCropDone: (blob: Blob) => void;
  onClose: () => void;
  onCancel: () => void;
  isOpened: boolean;
};

type Crop = {
  x: number;
  y: number;
};

type CroppedAreaPixels = {
  width: number;
  height: number;
  x: number;
  y: number;
} | null;

const CONFIG = {
  cropXY: { x: 0, y: 0 },
  cropSize: { width: 220, height: 220 },
  zoomStep: 1,
  minZoom: 1,
  maxZoom: 7,
  defaultZoom: 2,
  rotationDegrees: 30,
  defaultRotation: 0,
};

const ImageCropRotateZoom: FC<ImageCropRotateZoomProps> = props => {
  const { imageUrl, onCropDone, onClose, onCancel, isOpened } = props;

  const { isFablet } = useScreen();
  const { t } = useTranslation(['components']);

  const [crop, setCrop] = useState<Crop>(CONFIG.cropXY);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedAreaPixels>(null);
  const [zoom, setZoom] = useState<number>(CONFIG.defaultZoom);
  const [rotation, setRotation] = useState<number>(CONFIG.defaultRotation);

  const handleCropComplete = useCallback((_, croppedAreaPixelsResult) => {
    setCroppedAreaPixels(croppedAreaPixelsResult);
  }, []);

  const reset = () => {
    setCrop(CONFIG.cropXY);
    setZoom(CONFIG.defaultZoom);
    setRotation(CONFIG.defaultRotation);
  };

  const handleApplyCropClick = async () => {
    const blobImageUrl = await getCroppedImg(imageUrl, croppedAreaPixels, rotation);
    const blob = await getBlobFromUrl(blobImageUrl);
    onCropDone(blob);
    reset();
  };

  const handleCancelCropClick = () => {
    onCancel();
    reset();
  };

  return (
    <Dialog open={isOpened} onClose={onClose} fullScreen={isFablet} PaperProps={{ sx: styles.paper }}>
      <IconButton sx={styles.close} onClick={onClose}>
        <Icon name="close" />
      </IconButton>
      <DialogContent sx={styles.content}>
        <Typography sx={styles.modalTitle} variant="h2">
          {t('components:ImageUpload.positionAndSizeYourLogo')}
        </Typography>
        <Box sx={styles.cropperWrapper}>
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            minZoom={CONFIG.minZoom}
            maxZoom={CONFIG.maxZoom}
            cropSize={CONFIG.cropSize}
            rotation={rotation}
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box sx={styles.rotateControls}>
          <Button
            sx={styles.rotateLeftbutton}
            variant="text"
            onClick={() => setRotation(prev => prev - CONFIG.rotationDegrees)}
          >
            <Icon name="rotateLeft" />
            <Typography variant="subtitle2">{t('components:ImageUpload.left')}</Typography>
          </Button>
          <Button
            sx={styles.rotateRightbutton}
            variant="text"
            onClick={() => setRotation(prev => prev + CONFIG.rotationDegrees)}
          >
            <Icon name="rotateLeft" />
            <Typography variant="subtitle2">{t('components:ImageUpload.right')}</Typography>
          </Button>
        </Box>
        <Slider
          size="small"
          aria-label="zoom"
          value={zoom}
          step={CONFIG.zoomStep}
          min={CONFIG.minZoom}
          max={CONFIG.maxZoom}
          onChange={(_: Event, newValue: number) => setZoom(newValue)}
        />
        <Box sx={styles.actions}>
          <Button sx={styles.saveButton} onClick={handleApplyCropClick}>
            <Typography variant="tabsTextBold">{t('components:ImageUpload.apply')}</Typography>
          </Button>
          <Button sx={styles.deleteButton} variant="text" onClick={handleCancelCropClick}>
            <Typography variant="tabsTextBold">{t('components:ImageUpload.cancel')}</Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropRotateZoom;
