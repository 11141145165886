import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import { Typography, ButtonBase } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'utils/clsx';

import styles from './styles';

type ShowHideSpacesProps = {
  isSpacesOpened: boolean;
  onSpacesToggle: () => void;
  spacesCount: number;
};

const ShowHideSpaces: FC<ShowHideSpacesProps> = props => {
  const { isSpacesOpened, onSpacesToggle, spacesCount } = props;
  const { t } = useTranslation(['components', 'common', 'resources']);

  return (
    <Box sx={styles.root}>
      <ButtonBase onClick={onSpacesToggle} sx={styles.spacesButton}>
        <Typography variant="subtitle2">
          {isSpacesOpened
            ? t('components:BuildingCard.hideSpaces', { count: spacesCount })
            : t('components:BuildingCard.showSpaces', { count: spacesCount })}
        </Typography>
        <ExpandMoreIcon sx={clsx(styles.icon, [[styles.iconRotated, isSpacesOpened]])} />
      </ButtonBase>
    </Box>
  );
};

export default ShowHideSpaces;
