import axios, { AxiosError } from 'axios';
import { set } from 'utils/storeUtils';
import {
  values,
  path,
  is,
  lensPath,
  view,
  isEmpty,
  isNil,
  pathOr,
  Path,
  assocPath,
  split,
  pipe,
  map,
  mergeAll,
  apply,
  toPairs,
} from 'ramda';
import { capitalize } from 'utils/string';
import { HttpStatusCode } from 'enums/HttpStatusCode';
import { ValidationError } from 'yup';

import { camelize } from './keysConverter';

const pathPairToObj = (key: string, val: unknown) => assocPath(split('.', key), val, {});
const unflattenObj = pipe(toPairs, map(apply(pathPairToObj)), mergeAll);

export const isAxiosError = (error: unknown | AxiosError): error is AxiosError => axios.isAxiosError(error);

export const isNotFound = (error: unknown): boolean => {
  return isAxiosError(error) && path(['response', 'status'], error) === HttpStatusCode.notFound;
};

export const isUnauthorized = (error: AxiosError): boolean => {
  return path(['response', 'status'], error) === HttpStatusCode.unauthorized;
};

export const isUnprocessableEntity = (error: AxiosError): boolean => {
  return path(['response', 'status'], error) === HttpStatusCode.unprocessedEntity;
};

export const isLocked = (error: AxiosError): boolean => {
  return path(['response', 'status'], error) === HttpStatusCode.locked;
};

const isFormikError = (error: AxiosError | ValidationError): error is ValidationError =>
  is(Error, error) && error.name === 'ValidationError';

export const hasErrorAt = (errors: unknown, lens: Path | string): boolean => {
  const newLens: Path = lens instanceof String ? lens.split('.') : (lens as Path);
  return !!view(lensPath(newLens), errors);
};

export const errorValueAt = (errors: unknown, lens: Path | string): unknown => {
  const newLens: Path = lens instanceof String ? lens.split('.') : (lens as Path);
  return view(lensPath(newLens), errors);
};

export const yupToFormErrors = (yupErrors: ValidationError): Params => {
  let errors = {};

  const formatError = (err: ValidationError): void => {
    if (!errors[err.path]) {
      const fieldName = err.path;
      const formattedFieldName = camelize(fieldName);
      const error = err.message.replace(fieldName, formattedFieldName);

      errors = set(errors, err.path.split('.'), error);
    }
  };

  if (isEmpty(yupErrors.inner)) {
    formatError(yupErrors);
  } else {
    const yupErrorsValues: ValidationError[] = values(yupErrors.inner) as ValidationError[];
    yupErrorsValues.map((yupErrorsValue: ValidationError) => formatError(yupErrorsValue));
  }

  return errors;
};

export const apiErrorsToFormik = (error: AxiosError): Params => {
  const errors = pathOr({}, ['response', 'data', 'errors'], error);

  if (isNil(errors) || isEmpty(errors)) return '';
  const flatErrors = Object.keys(errors).reduce(
    (acc, key) => ({ ...acc, [camelize(key)]: errors[key].map(capitalize).join(', ') }),
    {},
  );
  return unflattenObj(flatErrors);
};

export const toCommonErrorInterface = (error: CommonError): Params => {
  if (isFormikError(error)) {
    return yupToFormErrors(error);
  }
  return apiErrorsToFormik(error);
};

export const handleErrors = (err: CommonError, handler: (func: unknown) => unknown): void => {
  const commonErrors = toCommonErrorInterface(err);
  handler(commonErrors);
};
