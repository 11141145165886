import { FC, useEffect } from 'react';
import { Stack, Typography, Box, Avatar, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals, useRouter } from 'hooks';
import Icon from 'components/Icon';
import ImagePlaceholder from 'components/ImagePlaceholder';
import Loader from 'components/Loader';
import { isNil, isEmpty } from 'ramda';
import { SpacePresenter, UsersPresenter } from 'presenters';
import { formatDateWithSlashes } from 'utils/dateTimeUtils';
import { makePathWithQueryString } from 'utils/routes';
import { appRoutes } from 'routes';
import { ModalType } from 'enums/ModalType';
import { SpaceRentDeclineAndSuggestSpacesParams, SpaceRentDeclineParams } from 'types/resources/Space';
import { useSpaceRents, useSpaces } from 'hooks/landlords';
import { landlordSuggestSpacesParams } from 'utils/loadingParams';

import styles from './styles';

const LandlordBookingRequest: FC = () => {
  const { t } = useTranslation(['landlordPages', 'common']);
  const { push } = useRouter();
  const { openModal, closeModal, extra } = useModals();
  const { isApprovePending, isDeclinePending, approve, decline } = useSpaceRents();
  const { suggestedSpaces, loadSuggestedSpaces, isLoadSuggestSpacesFinished } = useSpaces();

  const fetchSuggestedSpaces = () => {
    const params = landlordSuggestSpacesParams();
    loadSuggestedSpaces(params);
  };

  useEffect(() => {
    fetchSuggestedSpaces();
  }, []);

  const isRentActionSubmitted = isApprovePending || isDeclinePending;

  const { space, spaceRent, loadSpaces } = extra;

  const handleCancelClick = () => closeModal();

  const imageUrl = SpacePresenter.coverUrl(space);

  const handleStartChatClick = () => {
    closeModal();
    const path = makePathWithQueryString(appRoutes.landlord.chatPath(), {
      spaceId: space.id,
      userId: spaceRent.user.id,
    });
    push(path);
  };

  const handleApproveClick = async () => {
    await approve(spaceRent.id);
    loadSpaces();
    closeModal();
  };

  const handleDeclineClick = () => {
    const declineSpaceRent = async (params: SpaceRentDeclineParams) => {
      await decline(spaceRent.id, params);
      loadSpaces();
      closeModal();
    };

    const handleCancelDeclineClick = () => {
      openModal({
        type: ModalType.landlordBookingRequest,
        extra: {
          space,
          spaceRent,
          loadSpaces,
        },
      });
    };

    openModal({
      type: ModalType.declineWithReason,
      extra: {
        title: t('landlordPages:Listing.UserRental.popup.title'),
        spaceRent,
        declineType: 'request',
        onDecline: declineSpaceRent,
        onCancel: handleCancelDeclineClick,
      },
    });
  };

  const handleDeclineAndOfferSpacesClick = () => {
    const declineSpaceRent = async (params: SpaceRentDeclineAndSuggestSpacesParams) => {
      await decline(spaceRent.id, params);
      loadSpaces();
      closeModal();
    };

    const handleCancelDeclineClick = () => {
      openModal({
        type: ModalType.landlordBookingRequest,
        extra: {
          space,
          spaceRent,
          loadSpaces,
        },
      });
    };

    openModal({
      type: ModalType.bookingRequestDeclineAndSuggestSpaces,
      extra: {
        onDecline: declineSpaceRent,
        onCancel: handleCancelDeclineClick,
      },
    });
  };

  const trotterCompanyName = spaceRent?.user?.company?.name;
  const trotterCompanyAddress = spaceRent?.user?.company?.address;
  const { rentalPeriod } = spaceRent;

  if (!isLoadSuggestSpacesFinished) {
    return <Loader open />;
  }

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={handleCancelClick}>
        <Icon name="close" />
      </Box>
      <Typography sx={styles.title} variant="h2">
        {t('landlordPages:Listings.bookingRequest')}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4">
        {t('landlordPages:Listings.bookingRequestSubtitle')}
      </Typography>
      <Box sx={styles.spaceCard}>
        <Box sx={styles.info}>
          <Box sx={styles.preview}>
            {isNil(imageUrl) ? <ImagePlaceholder name="space" /> : <img src={imageUrl} alt={space.title} />}
          </Box>
          <Box sx={styles.text}>
            <Typography sx={styles.spaceTitle} variant="body3">
              {space.title}
            </Typography>
            <Typography sx={styles.address} variant="body5">
              {space.building.address}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.price}>
          <Typography sx={styles.priceText} variant="body3">
            {SpacePresenter.pricePerMonthFormatted(spaceRent, 'common:inMonth')}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.trotterCard}>
        <Box sx={styles.avatar}>
          <Avatar alt="avatar" src={spaceRent.user.avatarUrl} />
        </Box>
        <Box sx={styles.trotterInfo}>
          <Typography sx={styles.trotterName} variant="h6" component="p">
            {UsersPresenter.fullName(spaceRent.user)}
          </Typography>
          {trotterCompanyName && (
            <Typography sx={styles.companyText} variant="body4" component="span">
              <Icon sx={styles.textIcon} name="suitcase" />
              {trotterCompanyName}
            </Typography>
          )}
          {trotterCompanyAddress && (
            <Typography sx={styles.companyText} variant="body4" component="p">
              <Icon sx={styles.textIcon} name="label" />
              {trotterCompanyAddress}
            </Typography>
          )}
          <Box sx={styles.startDate}>
            <Icon sx={styles.textIcon} name="calendar" />
            <Typography variant="subtitle3" whiteSpace="pre-wrap">
              {t('landlordPages:Listings.startDate')}
            </Typography>
            <Typography variant="body4">{formatDateWithSlashes(spaceRent.projectedActivatedAt)}</Typography>
          </Box>
          <Box sx={styles.rentalPeriod}>
            <Icon sx={styles.textIcon} name="calendar" />
            <Typography variant="subtitle3" whiteSpace="pre-wrap">
              {t('landlordPages:Listings.rentalPeriod')}
            </Typography>
            <Typography variant="body4">{t('landlordPages:Listings.month', { count: rentalPeriod })}</Typography>
          </Box>
          <Button variant="text" onClick={handleStartChatClick} sx={styles.itemButton}>
            <Typography sx={styles.itemIconBlock} variant="tabsTextBold">
              {t('landlordPages:Listings.chatWith', { name: UsersPresenter.fullName(spaceRent.user) })}
              <Icon sx={styles.itemIcon} name="arrowRight" />
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box>
        <Button onClick={handleApproveClick} sx={styles.button} disabled={isRentActionSubmitted}>
          <Typography variant="tabsTextBold">{t('landlordPages:Listing.UserRental.approveButton')}</Typography>
        </Button>
        {!isEmpty(suggestedSpaces) && (
          <Button
            onClick={handleDeclineAndOfferSpacesClick}
            sx={styles.declineAndOfferSpacesButton}
            variant="text"
            disabled={isRentActionSubmitted}
          >
            <Typography variant="tabsTextBold">
              {t('landlordPages:Listing.UserRental.declineAndOfferSpacesButton')}
            </Typography>
          </Button>
        )}
        <Button
          onClick={handleDeclineClick}
          sx={{ ...styles.button, ...styles.declineButton }}
          variant="text"
          disabled={isRentActionSubmitted}
        >
          <Typography variant="tabsTextBold">{t('landlordPages:Listing.UserRental.declineButton')}</Typography>
        </Button>
      </Box>
    </Stack>
  );
};

export default LandlordBookingRequest;
