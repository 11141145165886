export default {
  conversationCount: '{{count}} conversation opened',
  conversationCount_plural: '{{count}} conversations opened',
  adminMessageLabel: 'Admin',
  landlordMessageLabel: 'Landlord',
  clientMessageLabel: 'Client',
  timeAgo: '{{ time }} ago',
  MessageInput: {
    ariaLabel: 'empty textarea',
    placeholder: 'Enter new message',
    sendButtonText: 'Send',
  },
  PromptForTrotter: {
    youAreNowChatting: "You're now chatting with the owner in a private message",
    feelFreeToReach: 'Feel free to reach out to ',
    arrangeABooking: 'arrange a booking.',
  },
};
