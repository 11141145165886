import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  link: {
    textDecoration: 'none',
  },
  linkLight: {
    color: 'black.main',
  },
  linkBase: {
    color: 'black.main',
  },
  linkHome: {
    color: 'white.main',
  },
  navLink: {
    fontSize: 15,
  },
};

export default styles;
