import { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { useChatContext } from 'stream-chat-react';
import {
  getUsernamesFromChannelMembers,
  getLastMessageWithName,
  getImagesFromChannelMembers,
  CHAT_TROT_TYPE,
} from 'utils/chat';
import { ChatChannel } from 'types/chat';
import clsx from 'utils/clsx';
import Box from 'components/Box';
import Icon from 'components/Icon';
import ChannelItemText from 'components/Chat/components/ChannelItemText';
import ChannelItemUnread from 'components/Chat/components/ChannelItemUnread';
import ChannelItemCard from 'components/Chat/components/ChannelItemCard';

import styles from './styles';

type ChannelListItemAdminProps = {
  loadedChannels: ChatChannel[];
};

const ChannelListItemAdmin: FC<ChannelListItemAdminProps> = props => {
  const { loadedChannels } = props;
  const { setActiveChannel, channel: activeChannel } = useChatContext();

  return (
    <Box>
      {loadedChannels.map(channel => {
        const unreadMessages = channel.countUnread();

        if (channel.type !== CHAT_TROT_TYPE) {
          return null;
        }

        const channelMemberNames = getUsernamesFromChannelMembers(channel);
        const chatTitle = channelMemberNames.join(' & ');
        const lastMessageInChannel = getLastMessageWithName(channel.state.messages);
        const images = getImagesFromChannelMembers(channel);
        const isActive = activeChannel?.cid === channel.cid;

        const handleCardClick = () => {
          setActiveChannel(channel);
        };

        return (
          <ChannelItemCard key={channel.id} isActive={isActive} handleCardClick={handleCardClick}>
            <Box sx={styles.channel}>
              <Box sx={styles.avatars}>
                <Avatar sx={clsx(styles.avatar, [[styles.avatarLeft, true]])} alt={chatTitle} src={images[0]} />
                <Avatar sx={clsx(styles.avatar, [[styles.avatarRight, true]])} alt={chatTitle} src={images[1]} />
              </Box>
              <Box sx={styles.textBlock}>
                <ChannelItemText title={chatTitle} subtitle={lastMessageInChannel} />
                <ChannelItemUnread unreadMessages={unreadMessages} />
              </Box>
            </Box>
            <Box>
              <Icon sx={styles.iconRightArrow} name="messengerRightArrow" />
            </Box>
          </ChannelItemCard>
        );
      })}
    </Box>
  );
};

export default ChannelListItemAdmin;
