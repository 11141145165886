import { FC, useState, useContext, useEffect } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChatContext } from 'stream-chat-react';
import { appRoutes } from 'routes';
import clsx from 'utils/clsx';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { ChatContext } from 'contexts/ChatContext';

import styles from './styles';

const PromptForTrotter: FC = () => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const { t } = useTranslation(['chatComponents']);
  const { isOpenedPromptForTrotter, setIsOpenedPromptForTrotter } = useContext(ChatContext);
  const { channel } = useChatContext();
  const {
    data: { building, space },
    state,
  } = channel;

  const messagesCount = state.messages.length;

  const handlePromptClose = () => {
    setIsClosed(true);
    setIsOpenedPromptForTrotter(false);
  };

  useEffect(() => {
    if (messagesCount === 1) {
      setIsOpenedPromptForTrotter(true);
    } else {
      setIsOpenedPromptForTrotter(false);
    }
  }, [messagesCount]);

  return (
    <Box sx={clsx(styles.root, [[styles.rootClosed, isClosed || !isOpenedPromptForTrotter]])}>
      <Box sx={styles.iconBlock}>
        <Icon name="message" />
      </Box>
      <Box sx={styles.descriptionBlock}>
        <Typography variant="body1" component="p">
          {t('chatComponents:PromptForTrotter.youAreNowChatting')}
        </Typography>
        <Typography variant="body1">
          {t('chatComponents:PromptForTrotter.feelFreeToReach')}
          <Typography
            variant="body1"
            to={appRoutes.public.buildingSpacePath(building.id, space.id)}
            component={Link}
            sx={styles.link}
          >
            {t('chatComponents:PromptForTrotter.arrangeABooking')}
          </Typography>
        </Typography>
      </Box>
      <IconButton sx={styles.closeButton} onClick={handlePromptClose}>
        <Icon name="close" />
      </IconButton>
    </Box>
  );
};

export default PromptForTrotter;
