export default {
  validations: {
    required: '{{field}} is required',
    email: 'Not a valid Email address',
    passwordsDontMatch: "Passwords don't match",
    noSpace: 'Spaces are not allowed for passwords.',
    passwordNotStrong: "Password isn't strong enough",
    maxLength: 'Maximum {{number}} sybmols are allowed',
    atLeastOne: 'Choose at least one value',
    integer: 'Should be an integer',
    number: 'Should be a number',
    url: 'Should contain http or https and be a valid URL. Example: https://example.com',
    wrongPercent: 'Should be between 0 and 100',
    bookingWindow: 'Booking window is required',
    minimalRentalPeriod: 'Should be between 1 and 12 months',
    auth: {
      title: 'Sign In Error',
      description: 'Your account has been disabled',
    },
  },
};
