import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  avatar: {
    width: '30px',
    height: '30px',
  },
  username: {
    textDecoration: 'none',
    marginLeft: 1,
  },
  usernameLight: {
    color: 'black.main',
  },
  usernameBase: {
    color: 'black.main',
  },
  usernameHome: {
    color: 'white.main',
  },
};

export default styles;
