import { Dialog, IconButton, Typography } from '@material-ui/core';
import { isNil } from 'ramda';
import { FC } from 'react';
import Media from 'types/resources/Media';
import Icon from 'components/Icon';
import Box from 'components/Box';
import Slider from 'components/Slider';
import MediaComponent from 'components/MediaComponent';
import { useTranslation } from 'react-i18next';

import styles from './styles';

type PopupGalleryProps = {
  open: boolean;
  onClose: () => void;
  medias: Media[];
  header: JSX.Element;
  skipGrid?: boolean;
  clickedIndex?: number | null;
  onSlideClick?: (slideIndex: number | null) => void;
};

const PopupGallery: FC<PopupGalleryProps> = props => {
  const { open, onClose, medias, header, skipGrid = false, clickedIndex, onSlideClick = () => {} } = props;

  const { t } = useTranslation('common');

  const handleClose = () => {
    onClose();
    onSlideClick(null);
  };

  const handleBackToGallery = () => {
    onSlideClick(null);
  };

  const renderBaseGallery = () => (
    <Box sx={styles.simpleGallery}>
      {medias.map((media, index) => {
        return (
          <Box key={media.id} sx={styles.simpleGalleryFigure}>
            <MediaComponent type="preview" media={media} onClick={() => onSlideClick(index)} />
          </Box>
        );
      })}
    </Box>
  );

  const showBaseGallery = isNil(clickedIndex);

  const BackToGallery = () => {
    if (skipGrid) {
      return null;
    }
    return (
      <Typography onClick={handleBackToGallery} variant="subtitle3" component="p" sx={styles.backLink}>
        <Icon name="arrow" />
        {t('backToGallery')}
      </Typography>
    );
  };

  const renderContent = () => {
    if (skipGrid) {
      return <Slider showFull medias={medias} clickedIndex={clickedIndex} />;
    }
    if (showBaseGallery) {
      return renderBaseGallery();
    }
    return <Slider medias={medias} clickedIndex={clickedIndex} />;
  };

  return (
    <Dialog onClose={handleClose} open={open} onBackdropClick={handleClose}>
      <IconButton sx={styles.close} onClick={handleClose}>
        <Icon name="close" />
      </IconButton>
      <Box sx={styles.root}>
        {showBaseGallery ? header : <BackToGallery />}

        <Box sx={styles.content}>{renderContent()}</Box>
      </Box>
    </Dialog>
  );
};

export default PopupGallery;
