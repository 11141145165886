import { isMobile } from 'react-device-detect';

function objectToGetParams(object: { [key: string]: string | number | undefined | null }): string {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export const emailLink = (
  url: string,
  {
    subject,
    body,
    separator,
  }: {
    body?: string;
    separator?: string;
    subject?: string;
  },
): string => {
  const params = objectToGetParams({ subject, body: body ? body + separator + url : url });

  return `mailto:${params}`;
};

export const iMessageLink = (
  url: string,
  {
    body,
    separator,
  }: {
    body?: string;
    separator?: string;
  },
): string => {
  const text = body + separator + url;

  return `imessage://?&body=${text}`;
};

export const whatsappLink = (url: string, { title, separator }: { title?: string; separator?: string }): string => {
  const params = objectToGetParams({
    text: title ? title + separator + url : url,
  });
  return `https://${isMobile ? 'api' : 'web'}.whatsapp.com/send${params}`;
};

export const facebookLink = (url: string, { quote, hashtag }: { quote?: string; hashtag?: string }): string => {
  const params = objectToGetParams({
    u: url,
    quote,
    hashtag,
  });
  return `https://www.facebook.com/sharer/sharer.php${params}`;
};

export const facebookMessengerLink = (
  url: string,
  {
    appId,
    redirectUri,
    to,
  }: {
    appId: string;
    redirectUri?: string;
    to?: string;
  },
): string => {
  const params = objectToGetParams({
    link: url,
    redirect_uri: redirectUri || url,
    app_id: appId,
    to,
  });
  return `https://www.facebook.com/dialog/send${params}`;
};

export const twitterLink = (
  url: string,
  {
    title,
    via,
    hashtags = [],
    related = [],
  }: { title?: string; via?: string; hashtags?: string[]; related?: string[] },
): string => {
  const params = objectToGetParams({
    url,
    text: title,
    via,
    hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
    related: related.length > 0 ? related.join(',') : undefined,
  });
  return `https://twitter.com/share${params}`;
};
