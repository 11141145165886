import { createDraftSpace, updateDraftSpace } from 'store/landlord/draft/spacesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { ListingFormSubmitData, ListingUpdateAvailableDateData } from 'types/resources/Space';
import getFetchStatus from 'utils/fetchStatus';

type UseSpacesType = {
  isCreateDraftSpacePending: boolean;
  isUpdateDraftSpacePending: boolean;
  createDraftSpace: (newSpace: ListingFormSubmitData) => void;
  updateDraftSpace: ({
    buildingId,
    spaceId,
    updatedSpace,
  }: {
    buildingId: ID;
    spaceId: ID;
    updatedSpace: ListingFormSubmitData | ListingUpdateAvailableDateData;
  }) => void;
};

const useSpaces = (): UseSpacesType => {
  const dispatch = useAppDispatch();
  const {
    create: { fetchStatus: createFetchStatus },
    update: { fetchStatus: updateFetchStatus },
  } = useAppSelector(globalState => globalState.landlordSpaces);

  const { isPending: isCreateDraftSpacePending } = getFetchStatus(createFetchStatus);
  const { isPending: isUpdateDraftSpacePending } = getFetchStatus(updateFetchStatus);

  return {
    isCreateDraftSpacePending,
    isUpdateDraftSpacePending,
    createDraftSpace: (newSpace: ListingFormSubmitData) => dispatch(createDraftSpace(newSpace)),
    updateDraftSpace: ({
      buildingId,
      spaceId,
      updatedSpace,
    }: {
      buildingId: ID;
      spaceId: ID;
      updatedSpace: ListingFormSubmitData | ListingUpdateAvailableDateData;
    }) => dispatch(updateDraftSpace({ buildingId, spaceId, space: updatedSpace })),
  };
};

export default useSpaces;
