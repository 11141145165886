/* eslint-disable no-param-reassign */
import EmployeesRepository from 'repositories/landlord/EmployeesRepository';
import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';
import { Employee } from 'types/resources/Employee';
import { createAsyncThunk } from 'utils/createAsyncThunk';

export type EmployeesSliceStateType = {
  employees: Employee[];
  employee: Employee;
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadEmployees = createAsyncThunk('landlords/employees/load', EmployeesRepository.index);
export const loadEmployee = createAsyncThunk('landlords/employees/loadEmployee', EmployeesRepository.show);
export const createEmployee = createAsyncThunk('landlords/employees/create', EmployeesRepository.create);
export const updateEmployee = createAsyncThunk('landlords/employees/update', EmployeesRepository.update);
export const deleteEmployee = createAsyncThunk('landlords/employees/delete', EmployeesRepository.delete);

const initialState: EmployeesSliceStateType = {
  employees: [] as Employee[],
  employee: {} as Employee,
  meta: { perPage: 10 } as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords/employees',
  initialState,
  reducers: {
    resetEmployees(state) {
      state.employees = initialState.employees;
      state.index = initialState.index;
      state.meta = initialState.meta;
    },
    resetEmployee(state) {
      state.employee = initialState.employee;
      state.show = initialState.show;
      state.create = initialState.create;
      state.update = initialState.update;
      state.delete = initialState.delete;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadEmployees.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEmployees.fulfilled, (state, { payload }) => {
      state.employees = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadEmployees.rejected, (state, { error }) => {
      state.index.fetchStatus = FetchStatus.failed;
      state.index.error = error;
    });
    builder.addCase(createEmployee.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createEmployee.fulfilled, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.fulfilled;
      state.employee = payload;
    });
    builder.addCase(createEmployee.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });
    builder.addCase(loadEmployee.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadEmployee.fulfilled, (state, { payload }) => {
      state.employee = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadEmployee.rejected, (state, { error }) => {
      state.show.error = error;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateEmployee.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateEmployee.fulfilled, (state, { payload }) => {
      state.update.fetchStatus = FetchStatus.fulfilled;
      state.employee = payload;
    });
    builder.addCase(updateEmployee.rejected, (state, { error }) => {
      state.update.fetchStatus = FetchStatus.failed;
      state.update.error = error;
    });
    builder.addCase(deleteEmployee.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteEmployee.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteEmployee.rejected, (state, { error }) => {
      state.delete.fetchStatus = FetchStatus.failed;
      state.delete.error = error;
    });
  },
});

export default slice.reducer;

const {
  actions: { resetEmployee, resetEmployees },
} = slice;

export { resetEmployee, resetEmployees };
