import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import styles from './styles';

type UploadMediaHintProps = {
  title?: string;
  subtitle?: string;
};

const UploadMediaHint: FC<UploadMediaHintProps> = props => {
  const { t } = useTranslation('common');
  const { title = t('selectImagesFromYourComputer'), subtitle = t('jpgPng10MBeach') } = props;

  return (
    <Box>
      <Typography sx={styles.startUploadTitle} variant="subtitle1">
        {title}
      </Typography>
      <Typography component="p" sx={styles.startUploadSubtitle} variant="body4">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default UploadMediaHint;
