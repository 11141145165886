import { FC } from 'react';
import { useChatContext } from 'stream-chat-react';
import Icon from 'components/Icon';
import ImagePlaceholder from 'components/ImagePlaceholder';
import Box from 'components/Box';
import ChannelItemText from 'components/Chat/components/ChannelItemText';
import { ChatChannel } from 'types/chat';

import styles from './styles';

const RenderGoBack: FC = () => {
  const { channel: activeChannel, navOpen, openMobileNav, setActiveChannel } = useChatContext();

  const handleGoBackClick = () => {
    openMobileNav();
    setActiveChannel(null);
  };

  if (!activeChannel || navOpen) {
    return null;
  }

  const channel = activeChannel as ChatChannel;
  const title = channel?.data?.building?.name;
  const subtitle = channel?.data?.space?.name;
  const img = channel?.data?.space?.image || channel?.data?.building?.image;

  return (
    <Box sx={styles.goBackCard} onClick={handleGoBackClick}>
      <Box>
        <Icon sx={styles.icon} name="messengerLeftArrow" />
      </Box>
      <Box sx={styles.card}>
        <Box sx={styles.image}>{img ? <img src={img} alt={subtitle} /> : <ImagePlaceholder name="building" />}</Box>
        <ChannelItemText title={title} subtitle={subtitle} />
      </Box>
    </Box>
  );
};

export default RenderGoBack;
