import { Avatar, Typography } from '@material-ui/core';
import { useUsers } from 'hooks';
import { UsersPresenter } from 'presenters';
import clsx from 'utils/clsx';
import { HeaderLayout } from 'enums/HeaderLayout';

import styles from './styles';

type DropDownMenuProfilePlaceholderProps = {
  type: HeaderLayout;
};

const DropDownMenuProfilePlaceholder = (props: DropDownMenuProfilePlaceholderProps): JSX.Element => {
  const { type } = props;
  const { currentUser } = useUsers();
  const currentUserFullName = UsersPresenter.fullName(currentUser);

  const isLightLayout = type === HeaderLayout.light;
  const isBaseLayout = type === HeaderLayout.base;
  const isHomeLayout = type === HeaderLayout.home;

  const userNameStyles = clsx(styles.username, [
    [styles.usernameLight, isLightLayout],
    [styles.usernameBase, isBaseLayout],
    [styles.usernameHome, isHomeLayout],
  ]);

  return (
    <>
      <Avatar sx={styles.avatar} alt={currentUserFullName} src={currentUser.avatarUrl} />
      <Typography variant="body2" sx={userNameStyles}>
        {currentUserFullName}
      </Typography>
    </>
  );
};
export default DropDownMenuProfilePlaceholder;
