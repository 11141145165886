import publicRoutes from './public/Routes';
import adminRoutes from './admin/Routes';
import landlordRoutes from './landlord/Routes';
import trotterRoutes from './trotter/Routes';
import * as apiRoutes from './apiRoutes';
import externalRoutes from './externalRoutes';
import marketingRoutes from './marketingRoutes';
import documentRoutes from './documentRoutes';

const appRoutes = {
  public: publicRoutes,
  admin: adminRoutes,
  landlord: landlordRoutes,
  trotter: trotterRoutes,
};

export { apiRoutes, externalRoutes, appRoutes, marketingRoutes, documentRoutes };
