import { FC } from 'react';
import { Typography, Stack } from '@material-ui/core';
import Icon from 'components/Icon';
import Box from 'components/Box';
import EditLink from 'components/EditLink/';
import { capitalize } from 'utils/string';

import styles from './styles';

type ComponentData = {
  data: string | undefined;
  itemTitle: string;
  iconName: string;
};

interface StackColumnProps {
  title: string;
  items: ComponentData[];
  path: string;
}

const StackColumn: FC<StackColumnProps> = props => {
  const { title, items, path } = props;
  return (
    <Box sx={styles.itemContainer}>
      <Typography sx={styles.contentTitle} variant="bold3" component="h6">
        {title}
        <EditLink path={path} />
      </Typography>
      <Stack direction="column" sx={styles.list}>
        {items.map(({ data, itemTitle, iconName }) => {
          if (!data) return null;
          return (
            <Box sx={styles.listItem} key={iconName}>
              <Icon name={iconName} />
              <Typography variant="body5">
                {itemTitle}: {capitalize(data)}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default StackColumn;
