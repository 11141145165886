import { SxStyles } from 'types/theme';

const statusBadge = {
  display: 'inline-flex',
  padding: '6.5px 8.5px',
  letterSpacing: '0.1em',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'transparent',
  fontSize: 11,
  lineHeight: '11px',
  fontWeight: 500,
  fontFamily: 'Heebo',
};

const styles: SxStyles = {
  state_active: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'success.main',
  },
  state_initial: {
    ...statusBadge,
    color: 'success.main',
    borderColor: 'success.main',
  },
  state_disabled: {
    ...statusBadge,
    color: 'gray.main',
    borderColor: 'gray.main',
  },
};

export default styles;
