import { groupBy } from 'lodash';
import { isEmpty, last, uniq, take, takeLast, flatten } from 'ramda';
import { StreamMessage } from 'stream-chat-react';
import { ChatChannel } from 'types/chat';

export const CHAT_TROT_TYPE = 'trot';

export const groupLoadedChannelsByTrotter = (channelList: ChatChannel[]): ChatChannel[][] => {
  const filtered = channelList.filter(channel => channel.type === CHAT_TROT_TYPE);
  const channelsByTrotter = groupBy(filtered, 'data.user.id');
  return Object.values(channelsByTrotter);
};

export const getLastMessageWithName = (messages: StreamMessage[]): string => {
  if (isEmpty(messages)) {
    return 'No messages';
  }

  const lastMessage = last(messages);
  const { name } = lastMessage.user;
  const { text } = lastMessage;

  return `${name}: ${text}`;
};

type QueryParamsForChannel = {
  spaceId: ID;
  userId?: ID;
};
export const findChannelByQueryParams = (
  channels: ChatChannel[],
  params: QueryParamsForChannel,
): ChatChannel | null => {
  const { spaceId, userId } = params;

  const spacePredicate = (channel: ChatChannel) => Number(channel?.data?.space?.id) === Number(spaceId);
  const userPredicate = (channel: ChatChannel) => channel?.data?.user?.id === `development-trotters-${userId}`;

  // search by space AND user (landlord and super admin case)
  if (spaceId && userId) {
    return channels.find(channel => spacePredicate(channel) && userPredicate(channel)) || null;
  }

  // search ONLY by space (trotter case)
  return channels.find(channel => spacePredicate(channel)) || null;
};

export const getTrotterNameFromChannel = (channel: ChatChannel): string => {
  return channel?.data?.user?.name || '';
};

export const getTrotterImageFromChannel = (channel: ChatChannel): string => {
  return channel?.data?.user?.image || null;
};

export const getTrotterChannelBuildingName = (channel: ChatChannel): string => {
  return channel?.data?.building?.name || '';
};

export const getTrotterChannelSpaceName = (channel: ChatChannel): string => {
  return channel?.data?.space?.name || '';
};

export const getUsernamesFromChannelMembers = (channel: ChatChannel): string[] => {
  const users = uniq(channel.state.messages.map(message => message.user.name));
  return take(2, users);
};

export const getImagesFromChannelMembers = (channel: ChatChannel): string[] => {
  const images = uniq(channel.state.messages.map(message => message.user.image)) as string[];
  return take(2, images);
};

export const getUnreadMessages = (channels: ChatChannel[]): StreamMessage[] => {
  const unreadMessages: StreamMessage[] = [];

  channels.forEach(channel => {
    const unreadCount = channel?.state?.unreadCount;

    if (unreadCount > 0) {
      const messages = takeLast(unreadCount, channel?.state?.messages || []).filter(message => !message.first);
      unreadMessages.push(messages as unknown as StreamMessage);
    }
  });

  return flatten(unreadMessages);
};
