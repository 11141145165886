/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { createSlice } from '@reduxjs/toolkit';
import SessionRepository from 'repositories/SessionRepository';
import { createAsyncThunk } from 'utils/createAsyncThunk';

export type UsersSliceStateType = {
  signout: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

const initialState: UsersSliceStateType = {
  signout: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

export const signOut = createAsyncThunk('sessions/signout', SessionRepository.delete);

const slice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signOut.pending, state => {
      state.signout.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(signOut.fulfilled, state => {
      state.signout.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(signOut.rejected, (state, { error }) => {
      state.signout.fetchStatus = FetchStatus.failed;
      state.signout.error = error;
    });
  },
});

export default slice.reducer;
