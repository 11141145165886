import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTabType = {
  defaultProps: ComponentsProps['MuiTab'];
  styleOverrides: ComponentsOverrides['MuiTab'];
};

const MuiTab: MuiTabType = {
  styleOverrides: {
    root: {
      fontFamily: 'Heebo',
      letterSpacing: '0.15em',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '13px',
      textTransform: 'uppercase',
      background: '#fff',
      paddingTop: '10px',
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 0,
      marginRight: '30px',
      color: '#050C1D',
      minWidth: 'auto',
      '&.Mui-selected': {
        color: '#050C1D',
        background: '#fff',
        fontSize: 13,
        lineHeight: '13px',
        fontWeight: 700,
      },
    },
  },
  defaultProps: {},
};

export default MuiTab;
