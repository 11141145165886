import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    maxWidth: 400,
  },
  submit: {
    marginTop: '10px',
  },
  error: {
    color: 'red.main',
    marginTop: '12px',
  },
};

export default styles;
