import { SearchSliceStateType, SearchSliceActionsType, useSearchActions, initialFilters } from 'store/searchSlice';
import { useAppSelector } from 'hooks/useRedux';
import { SearchFormData, attributesToSubmit } from 'forms/public/searchForm';
import useRouter from 'hooks/useRouter';
import useLoading from 'hooks/useLoading';
import { stringify, parse } from 'utils/searchUtils';

type UseSearchCustom = {
  setFilters: (params: SearchFormData) => void;
  search: (params: SearchFormData, route?: string, options?: { resetPage?: boolean }) => void;
  loadMore: () => void;
  initFromQuery: () => SearchFormData;
  isFinished: boolean;
  isLoadMorePending: boolean;
  initialFilters: SearchFormData;
};
type UseSearchType = SearchSliceStateType &
  UseSearchCustom &
  Pick<
    SearchSliceActionsType,
    'resetSearch' | 'setSpaceLiked' | 'deleteSpaceLike' | 'setLastSelectedBuildingId' | 'setLastSelectedPage'
  >;

const useSearch = (): UseSearchType => {
  const { camelizedQuery, push } = useRouter();
  const { buildings, meta, filters, lastSelectedBuildingId, lastSelectedPage } = useAppSelector(
    globalState => globalState.search,
  );
  const {
    search: searchFunction,
    setFilters,
    loadMore: loadMoreFunction,
    resetSearch,
    setSpaceLiked,
    deleteSpaceLike,
    setLastSelectedBuildingId,
    setLastSelectedPage,
  } = useSearchActions();

  const pushToQuery = (route: string, queryString: string): void => push(`${route}${queryString}`);

  const search = (
    appliedFilters: SearchFormData = {},
    route: string,
    options: { resetPage?: boolean } = {},
  ): Promise<void> => {
    const newFilters = { ...filters, ...appliedFilters };
    const params = attributesToSubmit(newFilters, meta);
    if (route) {
      const queryString = stringify(newFilters);
      pushToQuery(route, queryString);
    }
    setFilters(newFilters);

    if (options.resetPage) {
      return searchFunction({ ...params, page: 1 });
    }
    return searchFunction(params);
  };

  const { funcWithLoading, isFinished } = useLoading(search);

  const initFromQuery = () => {
    const filtersFromQuery = parse(camelizedQuery);
    const newFilters = { ...initialFilters, ...filtersFromQuery };
    setFilters(newFilters);
    return newFilters;
  };

  const loadMore = (): Promise<void> => {
    const params = attributesToSubmit(filters, { ...meta, currentPage: meta.currentPage + 1 });
    return loadMoreFunction(params);
  };

  const { funcWithLoading: loadMoreWithLoading, isPending: isLoadMorePending } = useLoading(loadMore);

  return {
    buildings,
    meta,
    filters,
    search: funcWithLoading,
    isFinished,
    loadMore: loadMoreWithLoading,
    isLoadMorePending,
    resetSearch,
    setFilters,
    initFromQuery,
    initialFilters,
    setSpaceLiked,
    deleteSpaceLike,
    lastSelectedBuildingId,
    setLastSelectedBuildingId,
    lastSelectedPage,
    setLastSelectedPage,
  };
};

export default useSearch;
