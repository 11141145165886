import { makeRoutes } from 'utils/routes';

const publicRoutes = makeRoutes(
  {
    rootPath: () => '/',
    resetPasswordPath: () => '/password-reset',
    setPasswordPath: () => '/password-set',
    emailConfirmationPath: () => '/confirm',
    signInPath: () => '/sign-in',
    signUpOwnerPath: () => '/sign-up-owner',
    signUpPath: () => '/sign-up',
    signUpFinishPath: () => '/sign-up-finish',
    signDocumentPath: () => '/sign-document',
    passwordForgotPath: () => '/password-forgot',
    searchPath: () => '/search',
    buildingSpacePath: (buildingId, spaceId) => `/buildings/${buildingId}/spaces/${spaceId}`,
    requestQuotePath: orderId => `/order/${orderId}`,
    thanksPath: orderId => `/thanks/${orderId}`,
    profilePath: () => '/profile',
    profileEditPath: () => '/profile/edit',
    profileSpacesAndContractsPath: () => '/profile/spaces-and-contracts',
    profileBillingAndBankAccountPath: () => '/profile/billing-and-bank-account',
    profileNotifilcationsPath: () => '/profile/notifications',
    page404Path: () => '/page404',
    termsOfServicePdfPath: () => '/trot-terms-of-service.pdf',
    licenseAgreementPath: () => '/trot-license-agreement.pdf',
    privacyPolicyPdfPath: () => '/trot-privacy-policy.pdf',
    rulesPdfPath: () => '/trot-rules.pdf',
    forwardPath: () => '/forward',
  },
  '/',
);

export default publicRoutes;
