import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {},
  matterport: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  pointerEventsNone: {
    pointerEvents: {
      desktop: 'none',
    },
  },
  video: {
    width: '100%',
    height: '100%',
  },
};

export default styles;
