import {
  loadLegalEntities,
  loadLegalEntity,
  createLegalEntity,
  updateLegalEntity,
  resetLegalEntity,
  resetLegalEntities,
  deleteLegalEntity,
} from 'store/landlord/legalEntitiesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { LegalEntity, LegalEntityFormSubmitData } from 'types/resources/LegalEntity';
import { Meta } from 'types/meta';
import { LoadingParams } from 'utils/loadingParams';
import getFetchStatus from 'utils/fetchStatus';

type UseLegalEntitiesType = {
  isLoadLegalEntitiesFinished: boolean;
  isLoadLegalEntityFinished: boolean;
  isCreateLegalEntityPending: boolean;
  isUpdateLegalEntityPending: boolean;
  resetLegalEntity: () => void;
  resetLegalEntities: () => void;
  loadLegalEntities: (params: LoadingParams) => { unwrap: () => void };
  loadLegalEntity: (legalEntityId: ID) => { unwrap: () => void };
  createLegalEntity: (newLegalEntity: LegalEntityFormSubmitData) => { unwrap: () => void };
  updateLegalEntity: (legalEntityId: ID, legalEntity: LegalEntityFormSubmitData) => { unwrap: () => void };
  deleteLegalEntity: (legalEntityId: ID) => { unwrap: () => void };
  legalEntities: LegalEntity[];
  legalEntity: LegalEntity;
  meta: Meta;
  showError: unknown;
};

const useLegalEntities = (): UseLegalEntitiesType => {
  const dispatch = useAppDispatch();
  const {
    legalEntity,
    legalEntities,
    meta,
    index: { fetchStatus: indexFetchStatus },
    create: { fetchStatus: createFetchStatus },
    show: { fetchStatus: showFetchStatus, error: showError },
    update: { fetchStatus: updateFetchStatus },
  } = useAppSelector(globalState => globalState.landlordLegalEntities);

  const { isFinished: isLoadLegalEntitiesFinished } = getFetchStatus(indexFetchStatus);
  const { isPending: isCreateLegalEntityPending } = getFetchStatus(createFetchStatus);
  const { isFinished: isLoadLegalEntityFinished } = getFetchStatus(showFetchStatus);
  const { isPending: isUpdateLegalEntityPending } = getFetchStatus(updateFetchStatus);

  return {
    isLoadLegalEntitiesFinished,
    isLoadLegalEntityFinished,
    isCreateLegalEntityPending,
    isUpdateLegalEntityPending,
    loadLegalEntities: (params: LoadingParams) => dispatch(loadLegalEntities(params)),
    loadLegalEntity: (legalEntityId: ID) => dispatch(loadLegalEntity(legalEntityId)),
    createLegalEntity: (newLegalEntity: LegalEntityFormSubmitData) => dispatch(createLegalEntity(newLegalEntity)),
    updateLegalEntity: (legalEntityId: ID, updatableLegalEntity: LegalEntityFormSubmitData) =>
      dispatch(updateLegalEntity({ legalEntityId, legalEntity: updatableLegalEntity })),
    resetLegalEntity: () => dispatch(resetLegalEntity()),
    resetLegalEntities: () => dispatch(resetLegalEntities()),
    deleteLegalEntity: (legalEntityId: ID) => dispatch(deleteLegalEntity(legalEntityId)),
    legalEntities,
    legalEntity,
    meta,
    showError,
  };
};

export default useLegalEntities;
