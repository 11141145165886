import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  startUploadTitle: {
    lineHeight: '16px',
    marginBottom: '10px',
  },
  startUploadSubtitle: {
    lineHeight: '13px',
    opacity: 0.6,
  },
};

export default styles;
