import Space from 'types/resources/Space';
import Building from 'types/resources/Building';
import { loadSpace, setSpaceLiked, setSpaceNotLiked, resetSpace } from 'store/spacesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';

type UseSpaceType = {
  space: Space;
  building: Building;
  loadSpace: (id: ID) => { unwrap: () => void };
  isLoadSpaceFulfilled: boolean;
  setSpaceLiked: () => void;
  setSpaceNotLiked: () => void;
  resetSpace: () => void;
};

const useSpace = (): UseSpaceType => {
  const dispatch = useAppDispatch();
  const {
    space,
    building,
    show: { fetchStatus: showFetchStatus },
  } = useAppSelector(globalState => globalState.spaces);

  const { isFulfilled: isLoadSpaceFulfilled } = getFetchStatus(showFetchStatus);

  return {
    building,
    space,
    loadSpace: (spaceId: ID) => dispatch(loadSpace(spaceId)),
    isLoadSpaceFulfilled,
    setSpaceLiked: () => dispatch(setSpaceLiked()),
    setSpaceNotLiked: () => dispatch(setSpaceNotLiked()),
    resetSpace: () => dispatch(resetSpace()),
  };
};

export default useSpace;
