import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';

import styles from './styles';

type MessageHeaderProps = {
  leftContent: string;
  rightContent?: string;
};

const MessageHeader: FC<MessageHeaderProps> = props => {
  const { leftContent, rightContent } = props;

  return (
    <Box sx={styles.header}>
      <Typography component="p" variant="body6">
        {leftContent}
      </Typography>
      {rightContent && (
        <Typography component="p" sx={styles.role} variant="bold6">
          {rightContent}
        </Typography>
      )}
    </Box>
  );
};

export default MessageHeader;
