import { SxStyles, Theme } from 'types/theme';

const baseStyles = {
  width: '100%',
  padding: '18.5px 14px 18.5px 68px',
};

const styles: SxStyles = {
  root: {
    '& .react-tel-input .special-label': {
      display: 'none',
    },
    '& .react-tel-input .form-control': baseStyles,
    '& .react-tel-input .selected-flag': {
      width: '100%',
      padding: '0 0 0 25px',
    },
  },
  rootError: {
    '& .react-tel-input .form-control': {
      ...baseStyles,
      border: (theme: Theme) => `1px solid ${theme.palette.red.main}`,
    },
  },
  error: {
    color: 'red.main',
    marginTop: '10px',
  },
};

export default styles;
