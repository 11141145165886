import building from 'assets/svg/default_building.svg';
import space from 'assets/svg/default_space.svg';
import user from 'assets/svg/default_user.svg';
import keys from 'assets/svg/thanks_page_keys.svg';
import insurance from 'assets/svg/thanks_page_insurance.svg';
import nextStep from 'assets/svg/thanks_page_next_step.svg';
import homeBrandsFurniture from 'assets/svg/home_brands_furniture.svg';
import { takeLast } from 'ramda';

type ImagePlaceholderDimensions = {
  width: string;
  height: string;
};

const getImagePlaceholderDimensions = (viewbox: string): ImagePlaceholderDimensions => {
  const [width, height] = takeLast(2, viewbox.split(' '));

  return {
    width,
    height,
  };
};

interface BrowserSpriteSymbol {
  id: string;
  viewBox: string;
  content: string;
  node: SVGSymbolElement;
}

type ImagePlaceholderAttributes = {
  src: BrowserSpriteSymbol;
  viewBox: string;
  id: string;
  width: string;
  height: string;
};

const getImagePlaceholderAttributes = (name: BrowserSpriteSymbol): ImagePlaceholderAttributes => {
  const { width, height } = getImagePlaceholderDimensions(name.viewBox);

  return {
    src: name,
    viewBox: name.viewBox,
    id: `#${name.id}`,
    width,
    height,
  };
};

export default {
  building: getImagePlaceholderAttributes(building),
  space: getImagePlaceholderAttributes(space),
  user: getImagePlaceholderAttributes(user),
  thanksPageKeys: getImagePlaceholderAttributes(keys),
  thanksPageInsurance: getImagePlaceholderAttributes(insurance),
  nextStep: getImagePlaceholderAttributes(nextStep),
  homeBrandsFurniture: getImagePlaceholderAttributes(homeBrandsFurniture),
};
