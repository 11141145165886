import React, { FC } from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@material-ui/core';

import styles from './styles';

type InputFieldProps = {
  startAdornment?: React.ReactElement;
  hasBoxShadow?: boolean;
  width?: number | string;
  height?: number;
  value?: number | string | null;
};

const InputField: FC<InputFieldProps & TextFieldProps> = props => {
  const {
    InputProps = {},
    startAdornment,
    hasBoxShadow = true,
    sx = {},
    width = 320,
    height = 60,
    value,
    ...rest
  } = props;

  const adornment = startAdornment && (
    <InputAdornment position="start" sx={styles.adornment}>
      {startAdornment}
    </InputAdornment>
  );

  const inputSx = styles.root({ hasAdornment: !!startAdornment, hasBoxShadow });

  return (
    <TextField
      sx={{ ...styles.textField({ width, height }), ...sx }}
      InputProps={{ sx: inputSx, ...InputProps, startAdornment: adornment }}
      {...rest}
      value={value === null ? '' : value}
    />
  );
};

export default InputField;
