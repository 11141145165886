import { apiRoutes } from 'routes';
import Space, { ListingFormSubmitData, SpaceResources, ListingUpdateAvailableDateData } from 'types/resources/Space';
import Fetcher from 'utils/fetcher';

const SpacesRepository = {
  index(params: QueryParams): Promise<SpaceResources> {
    const url = apiRoutes.apiSiteV1LandlordSpacesPath();
    return Fetcher.get(url, params);
  },
  create(newListing: ListingFormSubmitData): Promise<Space> {
    const url = apiRoutes.apiSiteV1LandlordBuildingSpacesPath(newListing.buildingId);
    return Fetcher.post(url, newListing);
  },
  review(buildingId: ID, spaceId: ID): Promise<Space> {
    const url = apiRoutes.reviewApiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.put(url, {});
  },
  unpublish({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }): Promise<Space> {
    const url = apiRoutes.unpublishApiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.put(url, {});
  },
  makeBookable({
    buildingId,
    spaceId,
    availableAt,
  }: {
    buildingId: ID;
    spaceId: ID;
    availableAt: string;
  }): Promise<Space> {
    const url = apiRoutes.makeBookableApiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.put(url, { availableAt });
  },
  makeUnbookable({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }): Promise<Space> {
    const url = apiRoutes.makeUnbookableApiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.put(url, {});
  },
  show(buildingId: ID, spaceId: ID): Promise<Space> {
    const url = apiRoutes.apiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.get(url);
  },
  update({
    buildingId,
    spaceId,
    space,
  }: {
    buildingId: ID;
    spaceId: ID;
    space: ListingFormSubmitData | ListingUpdateAvailableDateData;
  }): Promise<Space> {
    const url = apiRoutes.apiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.put(url, space);
  },
  delete(buildingId: ID, spaceId: ID): Promise<Space> {
    const url = apiRoutes.apiSiteV1LandlordBuildingSpacePath(buildingId, spaceId);
    return Fetcher.delete(url);
  },
};

export default SpacesRepository;
