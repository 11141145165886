import { FC } from 'react';
import Select from 'components/Select';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { SpaceType } from 'enums/resources/Space';
import clsx from 'utils/clsx';
import { isOfficeSpaceType } from 'utils/spaceUtils';

import styles from './styles';

type OfficeTypeSelectProps = {
  spaceType: SpaceType;
  onSpaceTypeChange: (spaceType: SpaceType) => void;
};

const ICON_MAPPING = {
  office: 'building',
  retail: 'retail',
};

const OfficeTypeSelect: FC<OfficeTypeSelectProps> = props => {
  const { spaceType, onSpaceTypeChange } = props;
  const { t } = useTranslation(['components', 'common']);

  const values = Object.keys(SpaceType)
    .filter(isOfficeSpaceType)
    .map(value => ({
      value,
      label: t(`resources:space.spaceTypeValues.${SpaceType[value]}`) as string,
    }));

  const handleSpaceTypeChange = (event: React.ChangeEvent<{ value: SpaceType }>) => {
    onSpaceTypeChange(event.target.value);
  };

  const iconName = ICON_MAPPING[spaceType];
  const isRetailIcon = iconName === 'retail';

  return (
    <Select
      className="office"
      startAdornment={<Icon sx={clsx(null, [[styles.iconRetail, isRetailIcon]])} name={iconName} />}
      sx={clsx(styles.select, [[styles.selectWithRetail, isRetailIcon]])}
      value={spaceType}
      values={values}
      onChange={handleSpaceTypeChange}
    />
  );
};

export default OfficeTypeSelect;
