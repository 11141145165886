import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: {
      desktop: '10px',
      largeScreen: 0,
    },
    marginBottom: {
      mobile: 0,
      tablet: '10px',
    },
  },
  buildingIconBlock: {
    marginTop: '7px',
    marginRight: '5px',
    marginLeft: '-4px',
  },
  buildingIcon: {
    width: '24px',
    height: '24px',
    opacity: 0.7,
  },
  buildingClassText: {
    opacity: 0.7,
  },
  class: {
    marginLeft: '4px',
  },
  helpBuildingClassBlock: {
    marginLeft: '8px',
    marginTop: '7px',
  },
  helpBuildingClassIcon: {
    width: '20px',
    height: '20px',
  },
  tooltip: {
    padding: '10px',
  },
};

export default styles;
