export default {
  Home: {
    title: {
      yourNext: 'Search, Browse, and Rent short-term office space direct from owners',
      getStarted: 'Get Started',
    },
    longTermLeases:
      'Ditch Co-Working and Long Term Leases and rent on the industry’s ONLY true Direct to Owner Marketplace for short term office spaces.',
    trotIsNotACoworkingCompany: 'TROT IS NOT A CO-WORKING COMPANY',
    yourTeam: 'your team deserves',
    trotIsNotACoworkingCompanyText:
      "TROT is not a co-working company. We're a dedicated marketplace that connects individuals and businesses directly with building owners to find and rent short-term office space. TROT makes it easy to search for and book the space you need, without any long-term commitments. With TROT, you'll enjoy the privacy and flexibility of your own office space, without the distractions and noise (or crazy pricing) of co-working",
    readyToUse: 'Ready to use',
    flexSpaceReimagined: 'Flex Space Reimagined ',
    redefinesPricingAndAccess: 'TROT redefines Pricing and Access',
    weAreUnlockingSpaces:
      "We are unlocking spaces from owners that were only available through long term traditional leases!  Our direct-to-owner marketplace means you'll NEVER have to deal with middlemen or inflated prices EVER again.  By reformatting the entire process TROT gives you the freedom to create a space that truly fits your needs. Say goodbye to paying exorbitant rates for limited choices in cookie-cutter spaces at your nearby coworking operators and say hello to TROT! Where hundreds of choices exist at prices up to 70% cheaper than your typical coworking spaces.",
    findAndRentOffice: 'Find and Rent Office Space on TROT directly from Owners.',
    foundYourSpace:
      'Once you’ve found space, browse our partner’s selection of chairs, reception desks, and other furniture to make it move-in ready before you start work. Check back for connectivity options, which are coming soon.',
    furniture: 'Furniture',
    connectivity: 'Connectivity',
    insurance: 'Insurance',
    comingSoon: 'Coming soon',
    search: 'Search',
    'NY-US': 'New York, United States',
    thisWeeksFeaturedSpace: "This week's featured space",
    thisWeeksFeaturedSpaceSubtitle:
      'We are always adding more spaces to our inventory so check back often and see below for some of this week’s hottest listings',
    featuredSpaces: 'Click below to get started or call ',
    personalService: ` for personal service`,
    trotNumber: '866-TROTFLEX',
    rentDirectShortTerm:
      'Rent Direct Short-Term Office Spaces with No Long-Term Commitments, at Rates of up to 70% less than coworking… your next office is just a few clicks away…',
    weAreAdding: 'We’re adding new spaces weekly. Check back often for additional buildings and offices.',
  },
  NewPassword: {
    resetPassword: 'Reset password',
    enterToContinuePassword: 'Please, enter the new password to continue',
    saveNewPassword: 'Save new password',
    newPassword: 'New password',
    enterPassword: 'Enter password',
    repeatPassword: 'Repeat password',
  },
  SetPassword: {
    setPassword: 'Set your password',
    enterToContinuePassword: 'Please, enter the password to continue',
    saveNewPassword: 'Save your password',
    newPassword: 'Your password',
    enterPassword: 'Enter password',
    repeatPassword: 'Repeat password',
  },
  Search: {
    sortBy: 'Sort by: ',
    spacesFound: '{{count}} office space found',
    spacesFound_plural: '{{count}} office spaces found',
    buildingsFound: 'in {{count}} building',
    buildingsFound_plural: 'in {{count}} buildings',
    detailsAndBooking: 'Details and booking',
    backToSearch: 'Back to search results',
    noSpacesFound: 'No spaces found',
    loadMore: 'Load more',
    sort: {
      updated_at_desc: 'Recently added',
      price_desc: 'Price: High to low',
      price_asc: 'Price: Low to high',
    },
  },
  BuildingSpace: {
    detailsAndAmenities: 'Details & Amenities',
    addressAndMap: 'Address & Map',
    pricing: 'Pricing',
    leasingTerms: 'Leasing terms',
    neighborhood: 'Neighborhood',
    backToSearch: 'Back to search results',
    requestQuote: 'Book now',
    startMessage: 'Chat with owner',
    startingFrom: 'Starting from ${{price}}', // eslint-disable-line no-template-curly-in-string
    perMonth: 'per month',
    fromPrice: 'From {{price}} / month', // eslint-disable-line no-template-curly-in-string
    price: 'From {{price}}', // eslint-disable-line no-template-curly-in-string
    sqft: '{{square}} sqft.',
    teammates: 'Up to {{count}} teammates',
    aboutSpace: 'About the space',
    amenities: 'Amenities',
    totalArea: 'Total area',
    minCapacity: 'Minimum capacity',
    maxCapacity: 'Maximum capacity',
    ft: '{{count}} square feet',
    people: '{{count}} people',
    otherSpaces: 'Other spaces in this building',
    buildingAndLocation: 'Building & location',
    whatsNearOffice: 'What’s near the office?',
    vendingMachines: 'Vending machines',
    restaurant: 'Restaurant',
    spa: 'Spa and welness center',
    bar: 'Bar',
    publicTransport: 'Public transport',
    buildingAddress: 'Building address',
    buildingType: 'Building type',
    buildingClass: 'Building class',
    elevatorType: 'Elevator type',
    numberOfElevators: 'Number of elevators',
    freightElevator: 'Freight elevator',
    onSiteManagement: 'On site management',
    ownerOccupied: 'Owner occupied',
    bicycleParkingAccess: 'Bicycle parking access',
    onSiteGym: 'On site gym',
    label: 'Building Photo',
    dateOfStart: 'Date of start',
    shareSpace: {
      share: 'Share',
      copy: 'copy',
      copied: 'Copied to clipboard!',
      cta: 'I’ve just discovered this flex space on TROT! Check it out',
      email: {
        label: 'Email',
        subject: 'TROT',
        separator: ' ',
      },
      twitter: {
        label: 'Twitter',
        via: 'trotflex',
        hashtags: {
          trot: 'TROT',
          revolution: 'FlexSpaceRevolution',
          future: 'futureofwork',
          real: 'realestate',
          cre: 'cre',
        },
      },
      facebook: {
        label: 'Facebook',
      },
      messenger: {
        label: 'Messenger',
      },
      whatsapp: {
        label: 'WhatsApp',
        separator: ' ',
      },
      messages: {
        label: 'Messages',
        separator: ' ',
      },
    },
  },
  Profile: {
    manageYourProfile: 'Manage your profile',
    navigation: {
      spacesAndContracts: 'Spaces & contracts',
      editProfile: 'Edit profile',
      billingAndBankAccount: 'Billing & card details',
      notifications: 'Notifications',
      dashboard: 'Dashboard',
      logout: 'Logout',
    },
  },
  ProfileEdit: {
    editYourProfile: 'Edit your profile',
    subtitle: 'Manage your contact details or password below',
    publicAvatar: 'Public Avatar',
    setUpAndRepeatNewPassword: 'Set up and repeat a new password if needed',
    passwordPlaceholder: '********',
    saveChanges: 'Save changes',
  },
  ProfileBilling: {
    billing: 'Billing & card details',
    subtitle:
      'This is the account from which your three-month payment will be deducted. Please ensure your balance will cover the full payment before you confirm a booking',
    bankName: 'Bank name',
    bankNameDescription: 'Name of the bank associated with the routing number',
    lastDigits: 'Last 4 digits',
    lastDigitsDescription: 'The last four digits of the bank account number',
    routingNumber: 'Routing number',
    routingNumberDescription: 'The routing transit number for the bank account',
  },
  RequestQuote: {
    title: 'Review the quote',
    subtitle:
      'You will be able track the status of your request and modify the details later in your personal account section. ',
    orderConfirmation: 'Order confirmation',
    orderConfirmationNumber: 'Order confirmation number',
    dateAndTimeOfTheOrder: 'Date and time of the order',
    listingInfo: 'Listing Info',
    initialTerm: 'Initial term',
    fullAddress: 'Full address',
    monthlyPayment: 'Monthly payment',
    rentalPeriod: 'Rental period',
    daysPriorToNextRenewal: '30 days prior to next renewal term start',
    accept: 'I accept the quote',
    trotRules: 'TROT Rules',
    and: 'and',
    trotTermsAndConditions: 'TROT License agreement',
    chargedImmediately: 'I realize, that I will be charged immediately.',
    acceptAndPay: 'Accept & pay',
    paymentError: 'Payment unsuccessful. Transaction was declined',
    HaveAnyQuestions: {
      title: 'Have any questions? We can help!',
      subtitle:
        'You can always contact listing manager or TROT support team to get the answers and additional information on your quote.',
      list: {
        chat: {
          linkText: 'Send a message',
          altAvatar: 'Manager’s avatar',
        },
        intercom: {
          title: 'TROT Support Center',
          subtitle: 'Frequently Asked Questions & Live Support',
          linkText: 'Go to support center',
          altAvatar: 'TROT logo',
        },
      },
    },
  },
  Terms: {
    title: 'Trot Terms and Conditions',
    terms: 'Terms',
    text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  Rules: {
    title: 'Trot Rules',
    rules: 'Rules',
    text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  SpacesAndContracts: {
    spacesAndContracts: 'Spaces & contracts',
    subtitle: 'See the listings you’ve loved, the spaces you’ve booked, and download your contracts',
    inProgress: 'in progress',
    rented: 'rented',
    saved: 'saved',
    toComplete: 'To complete checkout',
    billingInfo: 'billing info',
    sendMessage: 'Send message',
    addBillingDetails: 'Add billing details',
    cancelAgreement: 'Cancel agreement',
    cancelAgreementConfirmationTitle: 'You are about to cancel the agreement',
    cancelAgreementConfirmationDescription: 'Are you sure?',
    rentedUntil: 'Space is rented until',
    spaceInProgressHint: 'Your request is awaiting approval from the owner, we’ll be in touch soon',
    deleteFromSaved: 'Delete from saved',
    visitRequestAQuotePage: 'visit request a quote page',
    youSaved: 'You saved this listing for later.',
    visitListingPage: 'Visit listing page',
    getDetails: 'to get details',
    viewOrderInformation: 'View order information',
    cancelAgreementError: 'Cancel agreement was unsuccessful, please contact support',
    declineRentError: 'Decline rent was unsuccessful, please contact support',
    archived: 'archived',
    youSavedButArchived: 'You have saved this listing but it has been archived.',
    youSavedButUnpublished: 'You have saved this listing but it has been unpublished.',
    youSavedButRented: 'You have saved the listing but it has already been rented.',
    youCanSee: 'You can see the ',
    detailsOfTheListing: 'details of the listing here.',
    rentalPeriod: 'Rental period {{dateOfStart}} - {{dateOfEnd}}',
  },
  Thanks: {
    thanksForChoosing: 'Thanks for choosing',
    trot: 'TROT.',
    inTouch: 'We’ll be in touch soon.',
    whatHappensNext: 'Here’s what happens next:',
    emailTitle: 'Email',
    emailHint:
      'In the next 3 business days, you’ll receive an email from <b>{{landlordName}}</b> with detailed instructions for gaining access to and moving into <b>{{spaceName}}</b>.',
    qaTitle: 'Q&A',
    qaHint: 'We’ve created a Q&A with additional information about what to expect, which you can view here.',
    qaAction: 'see details',
    communicationTitle: 'Communication with the Landlord',
    communicationHint:
      'If you have any questions for your landlord, you can chat with them directly in your TROT account. ',
    communicationAction: 'start a chat',
    helpTitle: 'Help',
    helpHint:
      'If you have any problems, concerns or feedback you’d like to share with TROT, you can contact us directly here.',
    helpAction: 'Chat to the team',
    otherAddOnServices: 'TROT preferred partners for additional services',
    insurance: 'Need insurance?',
    insuranceHint: 'We can help with that.',
    insuranceLink: 'Learn more',
    thereIsJust: 'There’s just',
    oneStep: 'one step',
    left: 'left',
    nextStep:
      'The next step is for the building owner to confirm your reservation, which typically happens within a few business days. If the owner declines your booking for any reason, all held funds will be immediately released back to your card with no action needed from you.',
    backToSearch: 'Back to Search',
    goToMyBookings: 'Go to my bookings',
    doNotWorry: "Don't worry, in case of refusal, the money will be returned to you automatically within 3 days.",
  },
};
