import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { isEmpty } from 'ramda';
import { camelizeKeys, decamelize } from 'utils/keysConverter';

export const axiosInstance = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

const authenticityToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
};

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['X-CSRF-Token'] = authenticityToken();

const handleResponse = ({ data }: AxiosResponse) => {
  return isEmpty(data) ? {} : camelizeKeys(data);
};

const Fetcher = {
  get: <R>(url: string, params?: QueryParams): Promise<R> =>
    axiosInstance.get(url, { params: params ? decamelize(params) : null }).then(handleResponse),
  post: <D, R>(url: string, data?: D): Promise<R> => axiosInstance.post(url, decamelize(data)).then(handleResponse),
  put: <D, R>(url: string, data: D): Promise<R> => axiosInstance.put(url, decamelize(data)).then(handleResponse),
  patch: <D, R>(url: string, data: D): Promise<R> => axiosInstance.patch(url, decamelize(data)).then(handleResponse),
  delete: (url: string): Promise<null> => axiosInstance.delete(url).then(handleResponse),
};

export default Fetcher;
