export const downloadFile = (content: string, name: string): void => {
  const downloadLink = document.createElement('a');

  downloadLink.download = name;
  downloadLink.href = content;

  downloadLink.click();
};

export const getBlobFromUrl = async (url: string): Promise<Blob> => {
  try {
    const response = await fetch(url);
    return await response.blob();
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return Promise.resolve(null);
  }
};
