/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import SearchRepository from 'repositories/SearchRepository';
import { useAppDispatch } from 'hooks/useRedux';
import Building from 'types/resources/Building';
import { MetaWithSpacesCount } from 'types/meta';
import { SearchFormData, SearchFormDataToSubmit } from 'forms/public/searchForm';
import { decamelizeKeys } from 'utils/keysConverter';
import { findNestedItemByParentAndChildIds } from 'utils/storeUtils';
import Space from 'types/resources/Space';
import { NewYork } from 'utils/locations';
import { SpaceType } from 'enums/resources/Space';
import { defaultSort } from 'utils/sortUtils';

export const DEFAULT_PER_PAGE = 10;

export type SearchSliceStateType = {
  buildings: Building[];
  meta: MetaWithSpacesCount;
  filters: SearchFormData;
  lastSelectedBuildingId: ID | null;
  lastSelectedPage: number;
};

export type SearchSliceActionsType = {
  search: (params: SearchFormDataToSubmit) => Promise<void>;
  setFilters?: (params: SearchFormData) => void;
  loadMore: (params: SearchFormDataToSubmit) => Promise<void>;
  resetSearch: () => Promise<void>;
  setSpaceLiked: (buildingId: ID, spaceId: ID) => void;
  deleteSpaceLike: (buildingId: ID, spaceId: ID) => void;
  setLastSelectedBuildingId: (id: ID | null) => void;
  setLastSelectedPage: (page: number) => void;
};

export const initialFilters: SearchFormData = {
  pricePerMonthMin: null,
  pricePerMonthMax: null,
  squareMin: null,
  squareMax: null,
  capacity: {
    min: null,
    max: null,
  },
  place: null,
  amenities: [],
  cardinalPoints: NewYork.cardinalPoints,
  verificationState: null,
  showcase: null,
  elevator: null,
  onlyAvailable: true,
  spaceType: SpaceType.office,
  s: defaultSort,
};

const initialState: SearchSliceStateType = {
  buildings: [],
  meta: {
    count: null,
    totalCount: null,
    perPage: DEFAULT_PER_PAGE,
    currentPage: 1,
    totalPages: null,
    nextPage: null,
    spacesCount: null,
  },
  filters: initialFilters,
  lastSelectedBuildingId: null,
  lastSelectedPage: 1,
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    loadSearch(state, { payload }) {
      state.buildings = payload.items;
      state.meta = payload.meta;
    },
    setFilters(state, { payload }) {
      state.filters = payload.filters;
    },
    loadMore(state, { payload }) {
      state.buildings = [...state.buildings, ...payload.items];
      state.meta = payload.meta;
    },
    resetSearch(state) {
      state.buildings = initialState.buildings;
      state.meta = initialState.meta;
      state.filters = initialState.filters;
    },
    setSpaceLiked(state, { payload }) {
      const space = findNestedItemByParentAndChildIds<Building, Space>(
        state.buildings,
        'spaces',
        payload.buildingId,
        payload.spaceId,
      );
      if (space) {
        space.isFavorite = true;
      }
    },
    deleteSpaceLike(state, { payload }) {
      const space = findNestedItemByParentAndChildIds<Building, Space>(
        state.buildings,
        'spaces',
        payload.buildingId,
        payload.spaceId,
      );
      if (space) {
        space.isFavorite = false;
      }
    },
    setLastSelectedBuildingId(state, { payload }) {
      state.lastSelectedBuildingId = payload;
    },
    setLastSelectedPage(state, { payload }) {
      state.lastSelectedPage = payload;
    },
  },
});

const { actions } = slice;

export const useSearchActions = (): SearchSliceActionsType => {
  const dispatch = useAppDispatch();

  const search = (params: SearchFormDataToSubmit) => {
    return SearchRepository.index(decamelizeKeys(params)).then(result => {
      dispatch(actions.loadSearch(result));
    });
  };

  const setFilters = (filters: SearchFormData) => {
    dispatch(actions.setFilters({ filters }));
  };

  const loadMore = async (params: SearchFormDataToSubmit) => {
    const result = await SearchRepository.index(decamelizeKeys(params));
    dispatch(actions.loadMore(result));
  };

  const resetSearch = async () => {
    dispatch(actions.resetSearch());
  };

  const setSpaceLiked = (buildingId: ID, spaceId: ID) => {
    dispatch(actions.setSpaceLiked({ buildingId, spaceId }));
  };

  const deleteSpaceLike = (buildingId: ID, spaceId: ID) => {
    dispatch(actions.deleteSpaceLike({ buildingId, spaceId }));
  };

  const setLastSelectedBuildingId = (id: ID | null) => {
    dispatch(actions.setLastSelectedBuildingId(id));
  };

  const setLastSelectedPage = (page: number) => {
    dispatch(actions.setLastSelectedPage(page));
  };

  return {
    search,
    setFilters,
    loadMore,
    resetSearch,
    setSpaceLiked,
    deleteSpaceLike,
    setLastSelectedBuildingId,
    setLastSelectedPage,
  };
};

export default slice.reducer;
