import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  appBar: {
    zIndex: 10,
    padding: {
      mobile: '20px 0',
      tablet: '22.5px 0 20px 0',
      desktop: '31px 0 0 0',
    },
  },
  appBarGuest: {
    padding: {
      mobile: '20px 0',
      tablet: '20px 0 17.5px 0',
      desktop: '23.5px 0 0 0',
      largeScreen: '35px 0 0 0',
    },
  },
  appBarGuestSearch: {
    padding: {
      mobile: '20px 0 19px 0',
      tablet: '20px 0 17.5px 0',
      desktop: '37.5px 0 0 0',
      largeScreen: '35px 0 0 0',
    },
  },
  appBarLight: {
    color: 'black.main',
    backgroundColor: 'common.white',
    '& .logo': {
      color: 'navy.main',
    },
  },
  appBarBase: {
    color: 'black.main',
    backgroundColor: 'navy.background',
    boxShadow: '0px 4px 20px rgba(14, 20, 33, 0.15)',
    '& .logo': {
      color: 'navy.main',
    },
  },
  appBarHome: {
    position: 'absolute',
    color: 'white.main',
    backgroundColor: 'navy.main',
    '& .logo': {
      color: 'white.main',
    },
  },
  appBarFixed: {
    position: 'fixed',
  },
  appBarRoot: {
    backgroundColor: 'transparent',
  },
  toolbar: {
    minHeight: 'auto',
    display: 'block',
  },
  headerContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContentLessThanDesktopWrapper: {
    justifyContent: 'space-between',
  },
  headerContentWrapperSearch: {
    marginBottom: '20px',
  },
  headerContentWrapperSearchGuest: {
    marginBottom: {
      mobile: '20px',
      tablet: '17.5px',
      desktop: '27px',
      largeScreen: '24px',
    },
  },
  logo: {
    marginRight: 3.5,
    '& a': {
      display: 'flex',
    },
    '& svg': {
      width: {
        mobile: 60,
        tablet: 70,
        desktop: 90,
      },
      height: {
        mobile: 22,
        tablet: 25,
        desktop: 32,
      },
    },
  },
  stack: {
    display: {
      mobile: 'none',
    },
  },
  menuStack: {
    flex: 1,
    justifyContent: 'flex-end',
    marginLeft: 2,
  },
  menuButton: {
    '& svg': {
      width: '20px',
      height: '20px',
      color: 'inherit',
    },
  },
  drawerBackdrop: {
    top: '62px',
  },
  drawerModal: {
    top: '62px',
  },
  drawerPaper: {
    top: '62px',
    width: '265px',
  },
  divider: {
    position: {
      mobile: 'absolute',
      desktop: 'static',
    },
    left: 0,
    right: 0,
    bottom: 0,
    marginTop: {
      tablet: '12px',
      desktop: '23.5px',
    },
    height: '1px',
    backgroundColor: 'white.main',
    opacity: 0.2,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dividerLight: {
    backgroundColor: 'gray.main',
  },
  dividerGuest: {
    marginTop: {
      desktop: '16px',
      largeScreen: '27.5px',
    },
  },
  signInButton: {
    display: 'flex',
    border: 'none',
    height: '30px',
    padding: 0,
    width: 107,
    '&:hover': {
      border: 'none',
    },
    '& svg': {
      marginBottom: '3px',
      marginRight: 1,
      width: 16,
      height: 16,
    },
  },
  loginToAccount: {
    display: 'flex',
    height: '30px',
    border: 'none',
    padding: 0,
    '&:hover': {
      border: 'none',
    },
    '& svg': {
      marginRight: 1,
      width: 16,
      height: 16,
    },
  },
  createAccount: {
    color: 'white.main',
    textDecoration: 'none',
    marginRight: '16px',
    marginTop: {
      mobile: '16px',
      tablet: 0,
    },
    textAlign: 'center',
    cursor: 'pointer',
  },
  loginButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  rightContent: {
    display: 'flex',
  },
  loginToAccountBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
