import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiFormHelperTextType = {
  defaultProps: ComponentsProps['MuiFormHelperText'];
  styleOverrides: ComponentsOverrides['MuiFormHelperText'];
};

const MuiFormHelperText: MuiFormHelperTextType = {
  styleOverrides: {
    root: {
      fontFamily: 'Heebo',
    },
    contained: {
      margin: '10px 0 0 0',
      fontSize: 13,
      lineHeight: '13px',
      fontWeight: 'normal',
    },
  },
  defaultProps: {},
};

export default MuiFormHelperText;
