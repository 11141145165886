import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import EditLink from 'components/EditLink/';

import styles from './styles';

interface PlainTextItemProps {
  title: string;
  info: string;
  path: string | null;
}

const PlainTextItem: FC<PlainTextItemProps> = props => {
  const { title, info, path } = props;
  return (
    <Box sx={styles.itemContainer}>
      <Typography sx={styles.contentTitle} variant="bold3" component="h6">
        {title}
        {path && <EditLink path={path} />}
      </Typography>
      <Typography sx={styles.description} variant="body5" component="p">
        {info}
      </Typography>
    </Box>
  );
};

export default PlainTextItem;
