import { FC } from 'react';
import Box from 'components/Box';
import Icon from 'components/Icon';
import clsx from 'utils/clsx';
import { Avatar } from '@material-ui/core';

import styles from './styles';

type MessageBoxProps = {
  isMyMessage: boolean;
  img?: string | null;
  alt?: string;
  withImage?: boolean;
  large?: boolean;
};

const MessageBox: FC<MessageBoxProps> = props => {
  const { isMyMessage, img, alt, withImage = false, large = false } = props;

  return (
    <Box sx={styles.wrapper}>
      {withImage && <Avatar sx={clsx(styles.image, [[styles.myImage, isMyMessage]])} alt={alt} src={img} />}
      <Box
        sx={clsx(styles.message, [
          [styles.myMessage, isMyMessage],
          [styles.largeMessage, large],
        ])}
      >
        {!large && (
          <Box sx={clsx(styles.triangle, [[styles.triangleForMe, isMyMessage]])}>
            <Icon name="triangle" />
          </Box>
        )}
        {props.children}
      </Box>
    </Box>
  );
};

export default MessageBox;
