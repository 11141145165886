import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Divider, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { marketingRoutes, externalRoutes, appRoutes } from 'routes';
import { useUsers, useScreen } from 'hooks';
import { UsersPresenter } from 'presenters';
import { makeSearchPathWithCoordinates, NewYork } from 'utils/locations';
import { MenuItemType } from 'types/utils';
import { addShowTrue } from 'utils/linkUtils';

import AccordionMenu from './components/AccordionMenu';
import styles from './styles';

type DashboardMenuType = {
  label: string;
  link: string;
  show: boolean;
};

type MobileMenuProps = {
  onLogout?: () => void;
  isLandlordDashboard?: boolean;
  isAdminDashboard?: boolean;
  landlordDashboardMenu?: DashboardMenuType[];
  adminDashboardMenu?: DashboardMenuType[];
  onStopImpersonate?: () => void;
};

type NavigationMenuType = {
  title: string;
  menu: MenuItemType[];
  icon: string;
};

const MobileMenu: FC<MobileMenuProps> = props => {
  const {
    onLogout,
    isLandlordDashboard,
    isAdminDashboard,
    landlordDashboardMenu,
    adminDashboardMenu,
    onStopImpersonate,
  } = props;

  const { t } = useTranslation(['publicLayouts', 'layouts', 'common', 'resources', 'adminLayouts', 'publicPages']);
  const { currentUser } = useUsers();
  const isGuest = UsersPresenter.isGuest(currentUser);
  const isLandlord = UsersPresenter.hasLandlordAccess(currentUser);
  const isAdmin = UsersPresenter.hasAdminAccess(currentUser);
  const menuItemsWithoutLogout = UsersPresenter.menuItems(currentUser);

  const { lessThanTablet } = useScreen();

  const locationMenu = [
    {
      label: t('publicLayouts:Locations.NY'),
      link: makeSearchPathWithCoordinates(NewYork),
    },
  ];

  const forOwnersMenu = [
    {
      label: t('publicLayouts:WhyTrot'),
      href: marketingRoutes.whyTrotForOwnersPath(),
    },
    {
      label: t('publicLayouts:ForOwners.flexSpaceRevolution'),
      href: marketingRoutes.spaceRevolutionPath(),
    },
    // {
    //   label: t('publicLayouts:ExploreResources'),
    //   href: marketingRoutes.exploreResourcesForOwnersPath(),
    // },
    {
      label: t('publicLayouts:HowItWorks'),
      href: marketingRoutes.howItWorksForOwnersPath(),
    },
    {
      label: t('publicLayouts:scheduleDemo'),
      href: marketingRoutes.scheduleDemoPath(),
    },
  ];

  const forTrottersMenu = [
    {
      label: t('publicLayouts:WhyTrot'),
      href: marketingRoutes.whyTrotForTrottersPath(),
    },
    // {
    //   label: t('publicLayouts:ExploreResources'),
    //   href: marketingRoutes.exploreResourcesForTrottersPath(),
    // },
    {
      label: t('publicLayouts:HowItWorks'),
      href: marketingRoutes.howItWorksForTrottersPath(),
    },
  ];

  const aboutTrotMenu = [
    {
      label: t('publicLayouts:AboutTrot.whoWeAre'),
      href: marketingRoutes.rootPath(),
    },
    {
      label: t('publicLayouts:AboutTrot.ourServices'),
      href: marketingRoutes.ourServicesPath(),
    },
    {
      label: t('publicLayouts:AboutTrot.faqS'),
      href: externalRoutes.intercomHelp(),
      isNewTabOpen: true,
    },
    {
      label: t('publicLayouts:AboutTrot.meetTheTeam'),
      href: marketingRoutes.meetTheTeamPath(),
    },
  ];

  const contactUsMenu = [
    {
      label: t('publicLayouts:ContactUs.helpCenter'),
      href: externalRoutes.intercomHelp(),
      isNewTabOpen: true,
    },
    {
      label: t('publicLayouts:ContactUs.contactSupport'),
      href: externalRoutes.intercomHelp(),
      isNewTabOpen: true,
    },
  ];

  const usersMenu = {
    navigation: [
      {
        title: t('publicLayouts:Locations.title'),
        menu: locationMenu.map(addShowTrue),
        icon: 'label',
      },
      {
        title: t('publicLayouts:ForOwners.title'),
        menu: forOwnersMenu.map(addShowTrue),
        icon: 'onePerson',
      },
      {
        title: t('publicLayouts:ForTrotters.title'),
        menu: forTrottersMenu.map(addShowTrue),
        icon: 'onePerson',
      },
      {
        title: t('publicLayouts:AboutTrot.title'),
        menu: aboutTrotMenu.map(addShowTrue),
        icon: 'onePerson',
      },
      {
        title: t('publicLayouts:ContactUs.title'),
        menu: contactUsMenu.map(addShowTrue),
        icon: 'onePerson',
      },
    ],
  };

  const renderNavigationMenu = (navigation: NavigationMenuType[]) =>
    navigation.map(item => (
      <AccordionMenu
        defaultExpanded={false}
        key={item.title}
        menuItems={item.menu}
        title={item.title}
        iconName={item.icon}
      />
    ));

  const navigationMenu = isAdminDashboard ? [] : usersMenu.navigation;

  const isDashboard = (isLandlord && isLandlordDashboard) || (isAdmin && isAdminDashboard);

  const dashboardLabel = t('publicPages:Profile.navigation.dashboard');

  const menuItemsWithouLogoutAndDashboard = isDashboard
    ? menuItemsWithoutLogout.filter(menuItem => menuItem.label !== dashboardLabel)
    : menuItemsWithoutLogout;

  const dashboardMenu = isAdmin ? adminDashboardMenu : landlordDashboardMenu;

  return (
    <Box sx={styles.root}>
      {!isGuest && (
        <AccordionMenu
          menuItems={menuItemsWithouLogoutAndDashboard}
          title={t('common:profile')}
          iconName="onePerson"
          defaultExpanded={!isLandlordDashboard && !isAdminDashboard}
        />
      )}
      {isDashboard && (
        <AccordionMenu menuItems={dashboardMenu} title={t('common:dashboard')} iconName="dashboard" defaultExpanded />
      )}
      {!isGuest && <Divider sx={styles.divider} />}

      <Box sx={styles.nav}>
        {renderNavigationMenu(navigationMenu)} <Divider sx={styles.divider} />
        <Typography
          key={t('publicLayouts:ThePowerOfFlex')}
          sx={styles.navItem}
          component="a"
          href={marketingRoutes.rootPath()}
          variant="subtitle5"
        >
          <Icon name="thePowerOfFlex" />
          {t('publicLayouts:ThePowerOfFlex')}
        </Typography>
      </Box>

      <Box sx={styles.buttonWrapper}>
        {isGuest && lessThanTablet && (
          <>
            <Button sx={styles.button} component={Link} to={appRoutes.public.signUpPath()}>
              <Typography component="p" variant="tabsTextBold">
                {t('common:createAccount')}
              </Typography>
            </Button>
            <Button component={Link} to={appRoutes.public.signInPath()} sx={styles.button} variant="outlined">
              <Typography component="p" variant="tabsTextBold">
                {t('publicLayouts:BaseLayout.Header.login')}
              </Typography>
            </Button>
          </>
        )}
        {isLandlord && isLandlordDashboard && (
          <Button onClick={() => onStopImpersonate()} sx={styles.button}>
            <Typography component="p" variant="tabsTextBold">
              {t('common:stopImpersonateButton')}
            </Typography>
          </Button>
        )}
        {!isGuest && (
          <Button onClick={onLogout} sx={styles.button}>
            <Typography component="p" variant="tabsTextBold">
              {t('layouts:logout')}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MobileMenu;
