import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  backLink: {
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    lineHeight: '12px',
    marginBottom: '30px',
    textDecoration: 'underline',
    color: 'brightBlue.main',
    '& a': {
      color: 'brightBlue.main',
      textDecoration: 'none',
    },
    '& svg': {
      width: '10px',
      height: '10px',
      marginRight: 1,
    },
  },
};

export default styles;
