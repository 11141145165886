import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageContext, useChatContext } from 'stream-chat-react';
import { getDistanceFromNowInWords } from 'utils/dateTimeUtils';
import MessageBox from 'components/Chat/components/MessageBox';
import MessageHeader from 'components/Chat/components/MessageHeader';
import MessageText from 'components/Chat/components/MessageText';
import MessageWithSpaceInfo from 'components/Chat/components/MessageWithSpaceInfo';
import { ChatChannel } from 'types/chat';

const CustomMessage: FC = () => {
  const { t } = useTranslation('chatComponents');

  const { message, isMyMessage } = useMessageContext();
  const { channel } = useChatContext();

  const isMe = isMyMessage();
  const time = t('timeAgo', { time: getDistanceFromNowInWords(new Date(message.created_at)) });

  if (message.first) {
    const spaceId = (channel as ChatChannel)?.data?.space?.id;
    return <MessageWithSpaceInfo isMyMessage={isMe} spaceId={spaceId} />;
  }

  return (
    <MessageBox isMyMessage={isMe}>
      <MessageHeader leftContent={time} />
      <MessageText text={message.text} />
    </MessageBox>
  );
};

export default CustomMessage;
