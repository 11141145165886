import { makeRoutes } from 'utils/routes';

const userRoutes = makeRoutes(
  {
    rootPath: () => '/',
    chatPath: () => '/chat',
  },
  '/trotter',
);

export default userRoutes;
