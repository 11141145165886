import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  separator: {
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.white.main}`,
    },
    '&:not(:empty)::before': {
      marginRight: '10px',
    },
    '&:not(:empty)::after': {
      marginLeft: '10px',
    },
  },
};

export default styles;
