import { ConfirmationDialog } from 'components';
import { createContext, FC, useState, useContext } from 'react';
import {
  ConfirmationDialogDefault,
  ConfirmationDialogConfig,
  ConfirmationDialogProviderProps,
} from 'types/confirmationDialog';

const ConfirmationDialogContext = createContext(null);
ConfirmationDialogContext.displayName = 'ConfirmationDialogContext';

const ConfirmationDialogProvider: FC<ConfirmationDialogProviderProps> = props => {
  const { children } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<ConfirmationDialogConfig>({} as ConfirmationDialogConfig);

  const openDialog = ({
    title,
    subtitle,
    confirmButtonText,
    actionCallback,
    cancelButtonText,
    type,
    size,
  }: ConfirmationDialogConfig) => {
    setDialogOpen(true);
    setDialogConfig({ title, subtitle, confirmButtonText, actionCallback, cancelButtonText, type, size });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({} as ConfirmationDialogConfig);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      {children}
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        subtitle={dialogConfig?.subtitle}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        confirmButtonText={dialogConfig.confirmButtonText}
        cancelButtonText={dialogConfig?.cancelButtonText}
        type={dialogConfig?.type}
        size={dialogConfig?.size}
      />
    </ConfirmationDialogContext.Provider>
  );
};

type UseConfirmationType = {
  getConfirmation: (props: ConfirmationDialogDefault) => Promise<boolean>;
};

const useConfirmationDialog = (): UseConfirmationType => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = ({
    title,
    confirmButtonText,
    subtitle,
    cancelButtonText,
    type,
    size,
  }: ConfirmationDialogDefault) =>
    new Promise<boolean>(resolve => {
      openDialog({ actionCallback: resolve, title, subtitle, confirmButtonText, cancelButtonText, type, size });
    });

  return { getConfirmation };
};

export default ConfirmationDialogProvider;
export { ConfirmationDialogContext, useConfirmationDialog };
