import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    height: '792px',
    width: '876px',
    padding: '30px 30px 30px',
    position: 'relative',
    overflow: 'hidden',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  content: {
    width: '100%',
    height: 'calc(100% - 30px)',
  },
  simpleGallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '16px',
    maxHeight: '614px',
    overflow: 'auto',
  },
  simpleGalleryFigure: {
    position: 'relative',
    height: 193,
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
  extendGalleryActiveFigure: {
    width: 812,
    height: 541,
    marginBottom: '13px',
    position: 'relative',
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  counter: {
    position: 'absolute',
    right: '16px',
    top: '15px',
    color: 'common.white',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    width: 48,
    height: 48,
    color: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(7px)',
    borderRadius: '0 !important',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  arrowPrev: {
    left: 0,
  },
  arrowNext: {
    right: 0,
    transform: 'rotate(180deg)',
  },
  extendGalleryList: {
    paddingY: '5px',
    height: 147,
    width: 817,
    overflow: 'auto',
    transform: 'translateX(-5px)',
  },
  extendGalleryRow: {
    display: 'inline-flex',
    paddingX: '5px',
  },
  extendGalleryFigure: {
    width: 191,
    position: 'relative',
    '& img': {
      display: 'block',
      width: '100%',
      height: 127,
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
  extendGalleryActiveFrame: {
    position: 'absolute',
    left: '-5px',
    top: '-5px',
    borderColor: 'brightBlue.main',
    borderWidth: '3px',
    borderStyle: 'solid',
    width: 201,
    height: 137,
  },
  backLink: {
    textTransform: 'uppercase',
    lineHeight: '12px',
    paddingTop: '6px',
    marginBottom: '22px',
    cursor: 'pointer',
    color: 'brightBlue.main',
    '& svg': {
      width: '10px',
      height: '10px',
      marginRight: 1,
    },
  },
};

export default styles;
