import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Space from 'types/resources/Space';
import Building from 'types/resources/Building';

type MixedSpaceBuilding = {
  space: Space;
  building: Building;
};

const SpacesRepository = {
  show(id: ID): Promise<MixedSpaceBuilding> {
    const url = apiRoutes.apiSiteV1SpacePath(id);
    return Fetcher.get(url);
  },
};

export default SpacesRepository;
