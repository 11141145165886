export default {
  values: {
    mobile: 0,
    fablet: 375,
    tablet: 768,
    chat: 960,
    desktop: 1050,
    searchFiltersLargeScreen: 1250,
    largeScreen: 1440,
  },
};
