/* eslint-disable no-param-reassign */
import LegalEntitiesRepository from 'repositories/landlord/LegalEntitiesRepository';
import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { LegalEntity } from 'types/resources/LegalEntity';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { Meta } from 'types/meta';

export type LegalEntitiesSliceStateType = {
  legalEntities: LegalEntity[];
  legalEntity: LegalEntity;
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  update: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadLegalEntities = createAsyncThunk('landlords/legalEntities/load', LegalEntitiesRepository.index);
export const loadLegalEntity = createAsyncThunk(
  'landlords/legalEntities/loadLegalEntity',
  LegalEntitiesRepository.show,
);
export const createLegalEntity = createAsyncThunk('landlords/legalEntities/create', LegalEntitiesRepository.create);
export const updateLegalEntity = createAsyncThunk('landlords/legalEntities/update', LegalEntitiesRepository.update);
export const deleteLegalEntity = createAsyncThunk('landlords/legalEntities/delete', LegalEntitiesRepository.delete);

const initialState: LegalEntitiesSliceStateType = {
  legalEntities: [] as LegalEntity[],
  legalEntity: {} as LegalEntity,
  meta: { perPage: 10 } as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  update: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'landlords/legalEntities',
  initialState,
  reducers: {
    resetLegalEntities(state) {
      state.legalEntities = initialState.legalEntities;
      state.index = initialState.index;
      state.meta = initialState.meta;
    },
    resetLegalEntity(state) {
      state.legalEntity = initialState.legalEntity;
      state.show = initialState.show;
      state.create = initialState.create;
      state.update = initialState.update;
      state.delete = initialState.delete;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadLegalEntities.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadLegalEntities.fulfilled, (state, { payload }) => {
      state.legalEntities = payload.items;
      state.meta = payload.meta;
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadLegalEntities.rejected, (state, { error }) => {
      state.index.fetchStatus = FetchStatus.failed;
      state.index.error = error;
    });
    builder.addCase(createLegalEntity.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createLegalEntity.fulfilled, (state, { payload }) => {
      state.create.fetchStatus = FetchStatus.fulfilled;
      state.legalEntity = payload;
    });
    builder.addCase(createLegalEntity.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });
    builder.addCase(loadLegalEntity.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadLegalEntity.fulfilled, (state, { payload }) => {
      state.legalEntity = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadLegalEntity.rejected, (state, { error }) => {
      state.show.error = error;
      state.show.fetchStatus = FetchStatus.failed;
    });
    builder.addCase(updateLegalEntity.pending, state => {
      state.update.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(updateLegalEntity.fulfilled, (state, { payload }) => {
      state.update.fetchStatus = FetchStatus.fulfilled;
      state.legalEntity = payload;
    });
    builder.addCase(updateLegalEntity.rejected, (state, { error }) => {
      state.update.fetchStatus = FetchStatus.failed;
      state.update.error = error;
    });
    builder.addCase(deleteLegalEntity.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteLegalEntity.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteLegalEntity.rejected, (state, { error }) => {
      state.delete.fetchStatus = FetchStatus.failed;
      state.delete.error = error;
    });
  },
});

export default slice.reducer;

const {
  actions: { resetLegalEntity, resetLegalEntities },
} = slice;

export { resetLegalEntity, resetLegalEntities };
