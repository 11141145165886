import { useState } from 'react';
import { head } from 'ramda';
import PaymentMethodsRepository from 'repositories/currentUser/PaymentMethodsRepository';
import { PaymentMethod } from 'types/resources/PaymentMethods';

import useLoading from './useLoading';

type UsePaymentMethodsType = {
  getPaymentMethod: () => void;
  isFinished: boolean;
  paymentMethod: PaymentMethod;
};

const usePaymentMethods = (): UsePaymentMethodsType => {
  const { index } = PaymentMethodsRepository;
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(null);
  const { isFinished, funcWithLoading: indexPaymentMethodsWithLoading } = useLoading(index);

  const getPaymentMethod = async () => {
    const result = await indexPaymentMethodsWithLoading();
    setPaymentMethod(result.paymentMethods ? head(result.paymentMethods) : null);
  };

  return {
    getPaymentMethod,
    isFinished,
    paymentMethod,
  };
};

export default usePaymentMethods;
