import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  channelList: {
    width: {
      mobile: '100%',
      desktop: '360px',
    },
    height: '100%',
    overflowY: 'scroll',
  },
};

export default styles;
