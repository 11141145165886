import { FC } from 'react';
import { TextField, InputAdornment, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import Icon from 'components/Icon';
import Box from 'components/Box';

import styles from './styles';

type PeopleInputProps = {
  capacityMin: string;
  capacityMax: string;
  onCapacityMinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCapacityMaxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setCapacityMin: (capacityMin: string) => void;
  setCapacityMax: (capacityMax: string) => void;
};

const PeopleInput: FC<PeopleInputProps> = props => {
  const { capacityMin, capacityMax, onCapacityMinChange, onCapacityMaxChange, setCapacityMin, setCapacityMax } = props;
  const { t } = useTranslation(['components', 'common']);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleCapacityReset = () => {
    setCapacityMin('');
    setCapacityMax('');
  };

  const isShowCapacityResetButton = !isEmpty(capacityMin) || !isEmpty(capacityMax);

  return (
    <Box sx={styles.root}>
      <TextField
        sx={{ ...styles.input, ...styles.capacityMinInput }}
        name="capacityMin"
        id="capacityMin"
        fullWidth
        value={capacityMin}
        onChange={onCapacityMinChange}
        onFocus={handleFocus}
        placeholder={t('components:Filter.peopleFrom')}
        InputProps={{
          startAdornment: (
            <>
              <InputAdornment position="start" sx={styles.adornmentIcon}>
                <Icon name="onePerson" />
              </InputAdornment>
              <Typography sx={styles.adornmentText} variant="body3" component="p">
                {t('components:Filter.peopleLabel')}
              </Typography>
            </>
          ),
        }}
      />
      <p>-</p>
      <TextField
        sx={{ ...styles.input, ...styles.capacityMaxInput }}
        name="capacityMax"
        id="capacityMax"
        fullWidth
        value={capacityMax}
        onFocus={handleFocus}
        onChange={onCapacityMaxChange}
        placeholder={t('components:Filter.peopleTo')}
      />
      {isShowCapacityResetButton && (
        <IconButton sx={styles.closeButton} onClick={handleCapacityReset}>
          <Icon name="close" />
        </IconButton>
      )}
    </Box>
  );
};

export default PeopleInput;
