import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  select: {
    minWidth: 114,
    height: '34px !important',
    paddingLeft: 0,
    paddingRight: 0,

    '& .MuiSelect-select': {
      paddingLeft: '15px !important',
      paddingRight: '40px !important',
    },
  },
  selectFilled: {
    backgroundColor: 'brightBlue.main',
    color: 'white.main',

    '& fieldset': {
      borderColor: 'brightBlue.main',
    },
    '& svg': {
      color: 'white.main',
    },
  },
  arrowWrapper: {
    right: '18px !important',
    top: '7px !important',

    '& svg': {
      width: '10px',
      height: '10px',
      color: 'navy.main',
    },

    '&.MuiSelect-iconOpen svg': {
      transform: 'rotate(-180deg)',
    },
  },
};

export default styles;
