import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  unread: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '23px',
    height: '23px',
    backgroundColor: 'brightBlue.main',
    color: 'white.main',
    borderRadius: '50%',
    marginLeft: 'auto',
    flexShrink: 0,
  },
};

export default styles;
