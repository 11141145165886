import { FC, useRef } from 'react';
import { Button, Stack, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks';
import Icon from 'components/Icon';
import QRCode from 'qrcode.react';
import Building from 'types/resources/Building';
import { downloadFile } from 'utils/download';

import styles from './styles';

type BuildingQRCodeExtra = { building: Building };

const BuildingQRCode: FC = () => {
  const { t } = useTranslation('landlordPages');
  const { closeModal, extra } = useModals();

  const svgParentRef = useRef(null);
  const canvasParentRef = useRef(null);

  const { building }: BuildingQRCodeExtra = extra as BuildingQRCodeExtra;

  const handleSVGDownload = () => {
    const svg = svgParentRef.current.children[0];
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));

    const fileContent = `data:image/svg+xml;base64,${base64doc}`;
    const fileName = building.address;

    downloadFile(fileContent, fileName);
  };

  const handlePNGDownload = () => {
    const canvas = canvasParentRef.current.children[0];
    const pngFile = canvas.toDataURL('image/png');

    const fileContent = `${pngFile}`;
    const fileName = building.address;

    downloadFile(fileContent, fileName);
  };

  const url = building.shortenedUrl;

  /**
   * we render 2 QR codes:
   *  - visible: svg (because it is more readable)
   *  - hidden: canvas (only for generating PNG from it)
   */
  const QRCodeElement = () => {
    return (
      <>
        <Box ref={svgParentRef} sx={styles.qr}>
          <QRCode value={url} size={268} renderAs="svg" />
        </Box>
        <Box ref={canvasParentRef} sx={styles.hidden}>
          <QRCode value={url} size={268} />
        </Box>
        <Box sx={styles.actions}>
          <Button startIcon={<Icon name="download" />} variant="text" onClick={handleSVGDownload} sx={styles.button}>
            SVG
          </Button>
          <Button startIcon={<Icon name="download" />} variant="text" onClick={handlePNGDownload} sx={styles.button}>
            PNG
          </Button>
        </Box>
      </>
    );
  };

  const QRCodeError = () => {
    return (
      <Box sx={styles.error}>
        <Typography component="p" variant="body4">
          {t('Building.QRCodeRenderingError')}
        </Typography>
      </Box>
    );
  };

  return (
    <Stack sx={styles.root}>
      <Box sx={styles.closeButton} component="button" onClick={() => closeModal()}>
        <Icon name="close" />
      </Box>
      <Typography variant="h2">{t('Building.QRCode')}</Typography>
      <Typography sx={styles.title} variant="h2" marginBottom="38px">
        {building.address}
      </Typography>
      {url ? <QRCodeElement /> : <QRCodeError />}
    </Stack>
  );
};

export default BuildingQRCode;
