import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  listingsTitle: {
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    marginBottom: '10px',
  },
  table: {
    overflow: 'auto',
    '& table': {
      width: '100%',
      tableLayout: 'auto',
      borderCollapse: 'collapse',
      '& td': {
        borderWidth: '1px 0px 0px 0px',
        borderStyle: 'solid',
        borderColor: 'black.light',
        padding: '20px 8px',
        verticalAlign: 'middle',
      },
      '& td.preview': {
        width: 40,
      },
      '& td:first-of-type': {
        paddingLeft: 0,
      },
      '& td:last-child': {
        paddingRight: 0,
      },
      '& td:nth-of-type(2)': {
        width: '30%',
      },
    },
  },
  preview: {
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '4px',
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(3)',
    },
  },
  title: {
    lineHeight: '16px',
  },
  square: {
    opacity: 0.7,
    lineHeight: '16px',
  },
  price: {
    opacity: 0.7,
    lineHeight: '16px',
  },
  arrowButton: {
    opacity: 0.4,
    transform: 'rotate(-180deg)',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  showButton: {
    justifyContent: 'start',
    marginLeft: 0,
    height: 'auto',
    padding: '0 0 0 0',
  },
  showButtonText: {
    display: 'inline-block',
    textTransform: 'uppercase',
    color: 'brightBlue.main',
    lineHeight: '15px',
    '& svg': {
      marginRight: '8px',
      verticalAlign: 'middle',
      width: '16px',
      height: '16px',
    },
  },
  buttonsBlock: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButton: {
    width: '146px',
  },
};

export default styles;
