import {
  format,
  formatDistance,
  parseJSON,
  addDays,
  differenceInDays,
  startOfToday,
  isSameDay,
  startOfDay,
  fromUnixTime,
  getUnixTime,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import Space from 'types/resources/Space';
import { isBlank } from 'utils/data';
import { getSpaceRentState } from 'presenters/SpaceRentPresenter';

export const formatDateTime = (dateTime: string): string => {
  const date = utcToZonedTime(dateTime, 'UTC');
  return format(date, 'MMMM d, y');
};

export const formatDateTimeForFilters = (dateTime: string): string => {
  const date = utcToZonedTime(dateTime, 'UTC');
  return format(date, 'MMMM d');
};

export const formatDateTimeShortMonth = (dateTime: string): string => {
  const date = utcToZonedTime(dateTime, 'UTC');
  return format(date, 'MMM d, y');
};

export const getCurrentYear = (): string => format(new Date(), 'yyyy');

export const getDistanceInWords = (date1: Date, date2: Date): string => formatDistance(date1, date2);

export const getDistanceFromNowInWords = (date: string | Date): string =>
  getDistanceInWords(new Date(date), new Date());

export const formatDateWithSlashes = (dateTime: string): string => {
  const date = utcToZonedTime(dateTime, 'UTC');
  return format(date, 'MM/dd/yyyy');
};

export const parseDate = (dateTime: string): Date => {
  return utcToZonedTime(dateTime, 'UTC');
};

export const parseDateFromUnix = (unixTime: number): Date => {
  return startOfDay(fromUnixTime(unixTime));
};

export const convertDateForBackend = (date: Date): string => {
  return zonedTimeToUtc(date, 'UTC').toJSON();
};

export const isDayInPast = (date: Date, today: Date): boolean => {
  return differenceInDays(date, today) <= 0;
};

export const getDateOfStart = ({ today, availableAt }: { today: Date; availableAt: Date }): string => {
  const startDate = isDayInPast(availableAt, today) ? addDays(today, 1) : availableAt;

  return startDate;
};

export const getAvailableFromDate = (space: Space): Date => {
  const relevantSpaceRents = space.spaceRents.filter(spaceRent => {
    const { isActiveSpaceRent, isApprovedSpaceRent } = getSpaceRentState(spaceRent);
    return isActiveSpaceRent || isApprovedSpaceRent;
  });
  if (isBlank(relevantSpaceRents)) {
    return startOfToday();
  }

  const projectedCompletedAtTimes = relevantSpaceRents.map(spaceRent =>
    getUnixTime(parseJSON(spaceRent.projectedCompletedAt)),
  );
  const maxProjectedCompletedAtTime = Math.max(...projectedCompletedAtTimes);
  const spaceRentCompletedDate = parseDateFromUnix(maxProjectedCompletedAtTime);

  return getDateOfStart({
    today: spaceRentCompletedDate,
    availableAt: spaceRentCompletedDate,
  });
};

export { startOfToday, isSameDay, startOfDay };
