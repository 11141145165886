import i18n from 'locales/i18n';
import { omit } from 'ramda';
import { Sort } from 'types/utils';

export const sortOptions: Sort[] = [
  {
    field: 'updated_at',
    order: 'desc',
    default: true,
  },
  {
    field: 'price',
    order: 'desc',
  },
  {
    field: 'price',
    order: 'asc',
  },
];

export const getName = (sort: Sort): string => {
  return i18n.t(`publicPages:Search.sort.${sort.field}_${sort.order}`);
};

export const defaultSort = omit(
  ['default'],
  sortOptions.find(sort => sort.default),
);
