import { FC } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'utils/clsx';
import { HeaderLayout } from 'enums/HeaderLayout';

import styles from './styles';

type DropDownMenuPlaceholderProps = {
  type: HeaderLayout;
  placeholder: string;
};

const DropDownMenuPlaceholder: FC<DropDownMenuPlaceholderProps> = props => {
  const { type, placeholder } = props;

  const isLightLayout = type === HeaderLayout.light;
  const isBaseLayout = type === HeaderLayout.base;
  const isHomeLayout = type === HeaderLayout.home;

  const linkStyles = clsx(styles.link, [
    [styles.linkLight, isLightLayout],
    [styles.linkBase, isBaseLayout],
    [styles.linkHome, isHomeLayout],
  ]);

  return (
    <Typography sx={linkStyles} component="p" variant="body3">
      {placeholder}
    </Typography>
  );
};
export default DropDownMenuPlaceholder;
