import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    color: 'black.main',
    backgroundColor: 'navy.background',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 'auto',
    maxWidth: '100%',
  },
  nav: {
    minHeight: 97,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: 'black.main',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    paddingRight: '30px',
  },
  copyright: {
    display: 'flex',
    paddingBottom: 2,
    width: '100%',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
  },
  copyrightAndText: {
    opacity: 0.56,
  },
  towns: {
    display: 'flex',
    backgroundColor: 'common.white',
    maxWidth: '100%',
    overflow: 'hidden',
    '& svg': {
      width: 534,
      height: 61,
      color: 'navy.background',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: '10px 15px',
    '&:last-of-type': {
      marginBottom: {
        mobile: '23px',
        tablet: '20px',
      },
    },
  },
  privacyLink: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  social: {
    marginRight: '15px',
    marginLeft: {
      mobile: 'initial',
      tablet: 'auto',
    },
    marginTop: {
      mobile: '24px',
      tablet: 'auto',
    },
  },
  about: {
    marginBottom: {
      mobile: '24px',
      tablet: '0',
    },
  },
  locations: {
    marginBottom: {
      mobile: '24px',
      tablet: '0',
    },
  },
  help: {
    marginLeft: {
      mobile: 'initial',
      tablet: 'auto',
    },
    marginBottom: {
      mobile: '24px',
      tablet: '0',
    },
  },
  divider: {
    width: '100%',
    marginTop: '60px',
    marginBottom: '20px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15px',
  },
  withDivider: {
    borderLeft: theme => `1px solid ${theme.palette.gray.athens}`,
  },
  emptyColumn: {
    width: {
      mobile: '65px',
      desktop: '115px',
    },
  },
  columnTitle: {
    padding: '10px 15px',
    textDecoration: 'none',
    color: 'black.main',
  },
};

export default styles;
