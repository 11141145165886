import { FC } from 'react';
import { Typography } from '@material-ui/core';

import styles from './styles';

type MessageTextProps = {
  text: string;
};

const MessageText: FC<MessageTextProps> = props => {
  const { text } = props;

  return (
    <Typography sx={styles.text} component="p" variant="body4">
      {text}
    </Typography>
  );
};

export default MessageText;
