import { makeRoutes } from 'utils/routes';

const landlordRoutes = makeRoutes(
  {
    rootPath: () => '/',
    buildingsPath: () => '/buildings',
    buildingPath: buildingId => `/buildings/${buildingId}`,
    newBuildingPath: () => '/buildings/new',
    editBuildingPath: buildingId => `/buildings/${buildingId}/edit`,
    listingsPath: () => '/listings',
    listingPath: (buildingId, listingId) => `/buildings/${buildingId}/listings/${listingId}`,
    editListingPath: (buildingId, listingId) => `/buildings/${buildingId}/listings/${listingId}/edit`,
    newListingPath: () => '/listings/new',
    newListingWithBuildingIdPath: buildingId => `/listings/new?buildingId=${buildingId}`,
    chatPath: () => '/chat',
    settingsPath: () => '/settings',
    page404Path: () => '/page404',
    dashboardPath: () => '/dashboard',
  },
  '/landlord',
);

export default landlordRoutes;
