import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    padding: '32px',
    position: 'relative',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    marginTop: '-3px',
  },
  title: {
    marginRight: '24px',
  },
  description: {
    alignSelf: 'flex-start',
  },
  actions: {
    marginTop: '24px',
  },
  yesButton: {
    marginRight: '24px',
  },
};

export default styles;
