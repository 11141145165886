import { FC } from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { BuildingPresenter } from 'presenters';
import Building from 'types/resources/Building';

import styles from './styles';

type ClassPropsType = {
  building: Building;
};

const Class: FC<ClassPropsType> = props => {
  const { building } = props;

  const { t } = useTranslation(['landlordComponents']);

  const tooltip = (
    <Box sx={styles.tooltip}>
      <Typography variant="body4" component="p">
        <Typography variant="bold3">
          {t('landlordComponents:BuildingContent.class')} {capitalize(building.buildingClass)}
        </Typography>{' '}
        {BuildingPresenter.getBuildingClassTooltipText(building)}
      </Typography>
    </Box>
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.buildingIconBlock}>
        <Icon sx={styles.buildingIcon} name="buildingClass" />
      </Box>
      <Typography sx={styles.buildingClassText} variant="body3">
        {t('landlordComponents:BuildingContent.buildingClass')}:
      </Typography>
      <Typography sx={styles.class} variant="subtitle1">
        {capitalize(building.buildingClass)}
      </Typography>
      <Tooltip title={tooltip} arrow placement="top" enterTouchDelay={0} leaveTouchDelay={2500}>
        <Box sx={styles.helpBuildingClassBlock}>
          <Icon sx={styles.helpBuildingClassIcon} name="helpCircleFilled" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Class;
