import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  image: {
    width: '48px',
    height: '48px',
    overflow: 'hidden',
    marginRight: '12px',
    flexShrink: 0,
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%',
    },
  },
  card: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    display: {
      mobile: 'block',
      desktop: 'none',
    },
  },
};

export default styles;
