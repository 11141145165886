import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const ChannelsRepository = {
  create(spaceId: ID, userId: ID): Promise<{ id: string }> {
    const url = apiRoutes.apiSiteV1UserChannelsPath();
    const params = { spaceId, userId };
    return Fetcher.post(url, params);
  },
};

export default ChannelsRepository;
