import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  upload: {
    marginBottom: 1,
  },
  avatar: {
    width: 103,
    height: 103,
    marginBottom: {
      desktop: '20px',
    },
  },
  avatarSquare: {
    width: 160,
    height: 160,
    borderRadius: '8px',
    marginBottom: '16px',
  },
  uploadCondition: {
    lineHeight: '14px',
    opacity: 0.6,
    mt: 2,
  },
  input: {
    width: {
      mobile: '100%',
      largeScreen: 286,
    },
  },
  formActions: {
    margin: {
      mobile: '24px 0',
      largeScreen: '50px 60px 0',
    },
  },
  button: {
    width: 'auto',
    '& svg': {
      ml: 1,
      width: 14,
      height: 14,
      transform: 'rotateY(180deg)',
    },
  },
  deleteButton: {
    height: 'auto',
    width: 'auto',
    color: 'red.main',
  },
  editButton: {
    height: 'auto',
    width: 'auto',
    color: 'brightBlue.main',
  },
  uploadButton: {
    marginRight: '20px',
    width: '248px',
  },
  uploadButtonText: {
    letterSpacing: '0.1em',
  },
  editDeleteButtons: {
    display: 'flex',
    paddingTop: '6px',
    marginLeft: {
      mobile: '-16px',
      tablet: 0,
    },
  },
  uploadButtons: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  buttonAndHint: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;
