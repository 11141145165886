import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { PaymentMethodResponse } from 'types/resources/PaymentMethods';

export default {
  index(): Promise<PaymentMethodResponse> {
    const path = apiRoutes.apiSiteV1CurrentUserPaymentMethodsPath();
    return Fetcher.get(path);
  },
};
