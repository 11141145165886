import { FC, useState } from 'react';
import { Box, BoxProps, Drawer, Typography, ClickAwayListener, IconButton } from '@material-ui/core';
import { SearchFormData } from 'forms/public/searchForm';
import { filter, equals, pick, without, isEmpty, includes, isNil } from 'ramda';
import { useRouter, useScreen } from 'hooks';
import Amenity from 'types/resources/Amenity';
import { boundsToCardinalPoints } from 'utils/googleHelper';
import { useTranslation } from 'react-i18next';
import { SpaceType } from 'enums/resources/Space';
import DropDownMenu from 'components/DropDownMenu';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import { convertDateForBackend, parseDate, startOfDay } from 'utils/dateTimeUtils';
import clsx from 'utils/clsx';
import { Sort } from 'types/utils';

import styles from './styles';
import PreApplyForm from './components/PreApplyForm';
import LocationInput from './components/LocationInput';
import PeopleInput from './components/PeopleInput';
import DateOfStartPlaceholder from './components/DateOfStartPlaceholder';
import OfficeTypeSelect from './components/OfficeTypeSelect';
import SearchButton from './components/SearchButton';
import ToggleFiltersButton from './components/ToggleFiltersButton';
import AppliedFilters from './components/AppliedFilters';

type PreApplyKey = 'pricePerMonthMin' | 'pricePerMonthMax' | 'squareMin' | 'squareMax' | 'amenities' | 'elevator';

type FilterProps = {
  onSubmit: (formData?: SearchFormData) => void;
  filters: SearchFormData;
  initialFilters: SearchFormData;
  amenities: Amenity[];
  sort: Sort;
};

const Filter: FC<FilterProps & BoxProps> = (props): JSX.Element => {
  const { onSubmit, filters, amenities, initialFilters, sort } = props;
  const { lessThanDesktop, isSearchFiltersLargeScreen } = useScreen();
  const { t } = useTranslation('components');

  const preApplykeys: PreApplyKey[] = [
    'pricePerMonthMin',
    'pricePerMonthMax',
    'squareMin',
    'squareMax',
    'amenities',
    'elevator',
  ];
  const initialPreApplyFilters = pick(preApplykeys, initialFilters);
  const preApplyFilters = pick(preApplykeys, filters);

  const [resetState, setResetState] = useState<number>(0);
  const [isAllFiltersOpened, setIsAllFiltersOpened] = useState<boolean>(false);
  const {
    camelizedQuery: {
      capacity: initialCapacity,
      place: initialPlace,
      spaceType: initialSpaceType,
      dateOfStart: initialDateOfStart,
      onlyAvailable: initialOnlyAvailable,
      elevator: initialElevator,
      pricePerMonthMin: initialPricePerMonthMin,
      pricePerMonthMax: initialPricePerMonthMax,
      squareMin: initialSquareMin,
      squareMax: initialSquareMax,
      amenities: initialAmenities,
    },
  } = useRouter();

  const [capacityMin, setCapacityMin] = useState(initialCapacity?.min || '');
  const [capacityMax, setCapacityMax] = useState(initialCapacity?.max || '');
  const [spaceType, setSpaceType] = useState<SpaceType>(initialSpaceType || SpaceType.office);
  const [dateOfStart, setDateOfStart] = useState<string | null>(initialDateOfStart || null);
  const [onlyAvailable, setOnlyAvailable] = useState<boolean | null>(initialOnlyAvailable || null);
  const [elevator, setElevator] = useState<boolean | null>(initialElevator || null);
  const [currentAmenities, setCurrentAmenities] = useState<Amenity[] | []>(initialAmenities || []);
  const [pricePerMonthMin, setPricePerMonthMin] = useState<string | null>(initialPricePerMonthMin || null);
  const [pricePerMonthMax, setPricePerMonthMax] = useState<string | null>(initialPricePerMonthMax || null);
  const [squareMin, setSquareMin] = useState<string | null>(initialSquareMin || null);
  const [squareMax, setSquareMax] = useState<string | null>(initialSquareMax || null);

  const [isMobileFiltersOpened, setIsMobileFiltersOpened] = useState<boolean>(false);
  const openMobileFilters = () => setIsMobileFiltersOpened(true);
  const closeMobileFilters = () => setIsMobileFiltersOpened(false);

  const handleSubmit = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit({
      capacity: { min: capacityMin, max: capacityMax },
      spaceType,
      dateOfStart,
      onlyAvailable,
      amenities: currentAmenities,
      elevator,
      pricePerMonthMin,
      pricePerMonthMax,
      squareMin,
      squareMax,
      s: sort,
    });
    closeMobileFilters();
    setIsAllFiltersOpened(false);
  };

  const toggleOpenAllFilters = (): void => setIsAllFiltersOpened(prevState => !prevState);
  const closeAllFilters = (): void => setIsAllFiltersOpened(false);
  const handleAmenityDelete = (amenity: Amenity) => (): void => {
    const amenityId = Number(amenity.id);
    const amenitiesIds = filters.amenities.map(item => Number(item.id));
    const newAmenitiesIds = without([amenityId], amenitiesIds);
    const newAmenities = filter(a => includes(a.id, newAmenitiesIds), amenities);

    closeMobileFilters();
    setCurrentAmenities(newAmenities);
    onSubmit({ amenities: newAmenities });
  };

  const handleFiltersReset = () => {
    closeMobileFilters();
    setOnlyAvailable(null);
    setElevator(null);
    setPricePerMonthMin(null);
    setPricePerMonthMax(null);
    setSquareMin(null);
    setSquareMax(null);
    setCurrentAmenities([]);
    onSubmit({ ...initialPreApplyFilters, onlyAvailable: null });
    setResetState(resetState + 1);
  };

  const hasChangedPreApplyFilters = () => {
    const notEqual = (k: PreApplyKey) => !equals(preApplyFilters[k], initialPreApplyFilters[k]);
    const touchedFiltersKeys = filter(notEqual, preApplykeys);
    return !isEmpty(touchedFiltersKeys) || onlyAvailable;
  };

  const handlePlaceChange = (result: google.maps.GeocoderResult, place: string) => {
    if (!result) return;
    const {
      geometry: { viewport },
    } = result;
    const cardinalPoints = boundsToCardinalPoints(viewport);
    onSubmit({ place, cardinalPoints, zoom: null });
  };

  const handleCapacityMinChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newCapacity = e.target.value;
    setCapacityMin(newCapacity);
  };

  const handleCapacityMaxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newCapacity = e.target.value;
    setCapacityMax(newCapacity);
  };

  const handleSpaceTypeChange = (spaceTypeValue: SpaceType): void => {
    setSpaceType(spaceTypeValue);
  };

  const handleFilterRemove = (name: 'pricePerMonth' | 'square' | 'elevator' | 'onlyAvailable') => () => {
    closeMobileFilters();
    if (name === 'elevator') {
      setElevator(null);
      onSubmit({ elevator: null });
      return;
    }
    if (name === 'onlyAvailable') {
      setOnlyAvailable(null);
      onSubmit({ onlyAvailable: null });
      return;
    }

    if (name === 'pricePerMonth') {
      setPricePerMonthMin(null);
      setPricePerMonthMax(null);
      onSubmit({ pricePerMonthMin: null, pricePerMonthMax: null });
      return;
    }

    if (name === 'square') {
      setSquareMin(null);
      setSquareMax(null);
      onSubmit({ squareMin: null, squareMax: null });
    }
  };

  const handleDateOfStartChange = (date: Date) => {
    if (isNil(date)) {
      setDateOfStart(null);
    } else {
      setDateOfStart(convertDateForBackend(startOfDay(date)));
    }
  };

  const PreApplyFormSetters = {
    setOnlyAvailable,
    setElevator,
    setCurrentAmenities,
    setPricePerMonthMin,
    setPricePerMonthMax,
    setSquareMin,
    setSquareMax,
  };

  const preApplyFormValues = {
    onlyAvailable,
    elevator,
    currentAmenities,
    pricePerMonthMin,
    pricePerMonthMax,
    squareMin,
    squareMax,
  };

  if (lessThanDesktop) {
    return (
      <>
        <Box display="flex" sx={styles.searchMobile} component="form" noValidate>
          <LocationInput onPlaceChange={handlePlaceChange} initialPlace={initialPlace} />
          <ToggleFiltersButton onClick={openMobileFilters} isAllFiltersOpened={isAllFiltersOpened} />
        </Box>
        <Drawer open={isMobileFiltersOpened} onClose={closeMobileFilters} PaperProps={{ sx: styles.drawerPaper }}>
          <Box sx={styles.drawerInnerWrapper}>
            <Typography variant="subtitle2" sx={styles.mainFiltersText}>
              {t('Filter.mainFilters')}
            </Typography>
            <AppliedFilters
              hasChangedPreApplyFilters={hasChangedPreApplyFilters}
              isAllFiltersOpened={isAllFiltersOpened}
              filters={filters}
              onAmenityDelete={handleAmenityDelete}
              onFilterRemove={handleFilterRemove}
              onFiltersReset={handleFiltersReset}
            />
            <PeopleInput
              capacityMin={capacityMin}
              capacityMax={capacityMax}
              onCapacityMinChange={handleCapacityMinChange}
              onCapacityMaxChange={handleCapacityMaxChange}
              setCapacityMin={setCapacityMin}
              setCapacityMax={setCapacityMax}
            />
            <Box sx={styles.dateOfStart}>
              <DropDownMenu
                buttonStyles={styles.dateOfStartButton}
                placeholder={<DateOfStartPlaceholder date={dateOfStart} />}
              >
                <DatePicker date={parseDate(dateOfStart)} onChangeDate={handleDateOfStartChange} />
              </DropDownMenu>
              <IconButton
                onClick={() => handleDateOfStartChange(null)}
                sx={clsx(styles.closeButton, [[styles.closeButtonShow, !isNil(dateOfStart)]])}
              >
                <Icon name="close" />
              </IconButton>
            </Box>
            <OfficeTypeSelect spaceType={spaceType} onSpaceTypeChange={handleSpaceTypeChange} />
            <PreApplyForm
              key={`pre-apply-form-${resetState}`}
              amenities={amenities}
              setters={PreApplyFormSetters}
              values={preApplyFormValues}
              onSubmit={handleSubmit}
            />
          </Box>
        </Drawer>
      </>
    );
  }

  return (
    <ClickAwayListener onClickAway={closeAllFilters}>
      <Box {...props}>
        <Box display="flex" sx={styles.search} component="form" noValidate>
          <LocationInput onPlaceChange={handlePlaceChange} initialPlace={initialPlace} />
          <PeopleInput
            capacityMin={capacityMin}
            onCapacityMinChange={handleCapacityMinChange}
            capacityMax={capacityMax}
            onCapacityMaxChange={handleCapacityMaxChange}
            setCapacityMin={setCapacityMin}
            setCapacityMax={setCapacityMax}
          />
          <Box sx={styles.dateOfStart}>
            <DropDownMenu placeholder={<DateOfStartPlaceholder date={dateOfStart} />}>
              <DatePicker date={parseDate(dateOfStart)} onChangeDate={handleDateOfStartChange} />
            </DropDownMenu>
            <IconButton
              onClick={() => handleDateOfStartChange(null)}
              sx={clsx(styles.closeButton, [[styles.closeButtonShow, !isNil(dateOfStart)]])}
            >
              <Icon name="close" />
            </IconButton>
          </Box>
          {!isSearchFiltersLargeScreen && (
            <OfficeTypeSelect spaceType={spaceType} onSpaceTypeChange={handleSpaceTypeChange} />
          )}
          <Box sx={styles.actions}>
            <ToggleFiltersButton onClick={toggleOpenAllFilters} isAllFiltersOpened={isAllFiltersOpened} />
            <SearchButton onSubmit={handleSubmit} />
          </Box>
        </Box>

        {isAllFiltersOpened && (
          <PreApplyForm
            key={`pre-apply-form-${resetState}`}
            amenities={amenities}
            setters={PreApplyFormSetters}
            values={preApplyFormValues}
          />
        )}
        <AppliedFilters
          hasChangedPreApplyFilters={hasChangedPreApplyFilters}
          isAllFiltersOpened={isAllFiltersOpened}
          filters={filters}
          onAmenityDelete={handleAmenityDelete}
          onFilterRemove={handleFilterRemove}
          onFiltersReset={handleFiltersReset}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default Filter;
