import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    justifyContent: 'space-between',
    display: {
      mobile: 'none',
      fablet: 'flex',
    },
    alignItems: 'center',
    width: '100%',
    '& span': {
      cursor: 'pointer',
    },
  },
  user: {
    alignItems: 'center',
    marginLeft: 'auto',
  },
  avatar: {
    width: '30px',
    height: '30px',
  },
  signInButton: matchRootPath => {
    return {
      display: 'flex',
      marginLeft: 'auto',
      border: 'none',
      padding: 0,
      paddingTop: 0.5,
      color: matchRootPath ? 'brightBlue.main' : 'black.main',
      background: matchRootPath ? 'common.white' : 'inherit',
      width: 107,
      '&:hover': {
        border: 'none',
      },
      '& svg': {
        marginBottom: '3px',
        marginRight: 1,
        width: 16,
        height: 16,
      },
    };
  },
  link: {
    textDecoration: 'none',
    marginLeft: '16px',
  },
  linkLight: {
    color: 'black.main',
  },
  linkBase: {
    color: 'black.main',
  },
  linkHome: {
    color: 'common.white',
  },
  links: {
    alignItems: 'center',
  },
  contactUsLink: {
    marginLeft: 'auto',
    marginRight: '16px',
    textAlign: 'center',
  },
};

export default styles;
