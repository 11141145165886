import { isEmpty, propOr } from 'ramda';
import User, { EmployeeUser } from 'types/resources/User';
import { UserAction, UserState, UserStatePresentation, UserType } from 'enums/resources/User';
import i18n from 'locales/i18n';
import { EmployeeAccessType } from 'enums/resources/Employee';
import { appRoutes } from 'routes';
import { format } from 'utils/phone';

export const guestUser = (): User => {
  return {
    id: null,
    avatarId: null,
    avatarUrl: null,
    firstName: null,
    lastName: null,
    email: null,
    state: UserState.initial,
    updatedAt: '',
    createdAt: '',
    ownLandlord: false,
    employee: null,
    impersonated: false,
    type: UserType.guest,
    intercomMeta: {
      userType: 'guest',
    },
  };
};

export const isGuest = (user: User): boolean => {
  return user.type === UserType.guest;
};

export const fullName = (user: User | EmployeeUser): string => {
  const firstName = propOr('', 'firstName', user);
  const lastName = propOr('', 'lastName', user);

  const hasAnyName = [firstName, lastName].some(name => !isEmpty(name));

  return hasAnyName ? `${firstName} ${lastName}` : user.email;
};

export const hasAdminAccess = (user: User): boolean => {
  return user.type === UserType.superAdmin;
};

export const hasLandlordAccess = (user: User): boolean => {
  return user.ownLandlord;
};

export const hasEmployeeAccess = (user: User): boolean => {
  return !!user.employee;
};

export const hasTrotterAccess = (user: User): boolean => {
  const isAdmin = hasAdminAccess(user);
  const isLandlord = hasLandlordAccess(user);
  const isEmployee = hasEmployeeAccess(user);

  return !isAdmin && !isLandlord && !isEmployee && !isGuest(user);
};

export const impersonated = (user: User): boolean => {
  return user.impersonated;
};

export const state = (user: User | EmployeeUser): string => {
  const statePresentation = UserStatePresentation[user.state];

  return i18n.t(`resources:user.states.${statePresentation}`).toUpperCase();
};

export const messagingId = (user: User): string => {
  if (hasAdminAccess(user)) {
    return `${Settings.env}-super_admins-${user.id}`;
  }
  return user && `${Settings.env}-trotters-${user.id}`;
};

export const hasEmployeeMessagingFullAccess = (user: User): boolean => {
  return hasEmployeeAccess(user) && user.employee.messaging === EmployeeAccessType.fullAccess;
};

export const hasEmployeeMessagingReadOnlyAccess = (user: User): boolean => {
  return hasEmployeeAccess(user) && user.employee.messaging === EmployeeAccessType.readOnlyAccess;
};

export const hasEmployeeMessagingNoAccess = (user: User): boolean => {
  return hasEmployeeAccess(user) && user.employee.messaging === EmployeeAccessType.noAccess;
};

export const hasAccessToBilling = (user: User): boolean => {
  const isTrotter = hasTrotterAccess(user);
  const isEmployee = hasEmployeeAccess(user);

  return isTrotter || isEmployee;
};

export const formattedPhoneNumber = (user: User): string => {
  const phoneNumber = user.company.phone;
  if (!phoneNumber) {
    return null;
  }

  return format(phoneNumber);
};

export const menuItems = (
  user: User,
): {
  label: string;
  show: boolean;
  href: string;
}[] => {
  const isAdmin = hasAdminAccess(user);
  const isTrotter = hasTrotterAccess(user);
  const isLandlord = hasLandlordAccess(user);
  const isEmployee = hasEmployeeAccess(user);
  const isNotGuest = !isGuest(user);

  return [
    {
      label: i18n.t('publicPages:Profile.navigation.spacesAndContracts'),
      show: isTrotter,
      href: appRoutes.public.profileSpacesAndContractsPath(),
    },
    {
      label: i18n.t('publicPages:Profile.navigation.editProfile'),
      show: isNotGuest,
      href: appRoutes.public.profileEditPath(),
    },
    {
      label: i18n.t('publicPages:Profile.navigation.billingAndBankAccount'),
      show: hasAccessToBilling(user),
      href: appRoutes.public.profileBillingAndBankAccountPath(),
    },
    {
      label: i18n.t('publicPages:Profile.navigation.dashboard'),
      show: isAdmin || isLandlord || isEmployee,
      href: isAdmin ? appRoutes.admin.rootPath() : appRoutes.landlord.dashboardPath(),
    },
  ];
};

type UserStatePredicates = {
  isInitial: boolean;
  isActive: boolean;
  isDisabled: boolean;
};

export const getUserState = (user: User): UserStatePredicates => {
  const isInitial = user.state === UserState.initial;
  const isActive = user.state === UserState.active;
  const isDisabled = user.state === UserState.disabled;

  return {
    isInitial,
    isActive,
    isDisabled,
  };
};

export const availableActions = (user: User): UserAction[] => {
  const { isInitial, isActive, isDisabled } = getUserState(user);

  if (isInitial) {
    return [UserAction.activate, UserAction.disable];
  }

  if (isActive) {
    return [UserAction.disable];
  }

  if (isDisabled) {
    return [UserAction.activate];
  }

  return [];
};
