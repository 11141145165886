import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: {
    padding: '64px 58px 48px',
    position: 'relative',
    maxWidth: '600px',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '14px',
  },
  title: {
    marginBottom: '16px',
    textAlign: 'center',
    maxWidth: '500px',
  },
  subtitle: {
    marginBottom: '26px',
    textAlign: 'center',
  },
  okButton: {
    width: '60px',
    marginRight: '24px',
  },
  cancelButton: {
    width: 'auto',
  },
  spaceCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'navy.background',
    padding: '16px',
    marginBottom: '16px',
    maxWidth: '500px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
  },
  spaceTitle: {
    marginBottom: '4px',
  },
  address: {
    opacity: 0.5,
  },
  preview: {
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '4px',
    marginRight: '8px',
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(3)',
    },
  },
  price: {
    flexShrink: 0,
    marginLeft: '16px',
  },
  priceText: {
    fontWeight: 700,
  },
  trotterCard: {
    padding: '24px 24px 6px 24px',
    border: (theme: Theme) => `1px solid ${theme.palette.gray.athens}`,
    display: 'flex',
    marginBottom: '24px',
  },
  avatar: {
    marginRight: '16px',
    '& .MuiAvatar-root': {
      width: '64px',
      height: '64px',
    },
  },
  trotterName: {
    marginBottom: '20px',
  },
  companyText: {
    opacity: 0.7,
    display: 'flex',
    alignItems: 'center',
  },
  trotterInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  dates: {
    maxWidth: '200px',
  },
  startDate: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  endDate: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  itemButton: {
    width: 'max-content',
    justifyContent: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0,
    color: 'brightBlue.main',
    textTransform: 'uppercase',
  },
  itemIconBlock: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  itemIcon: {
    display: 'block',
    width: '14px',
    height: '14px',
    marginLeft: '5px',
  },
  textIcon: {
    width: '14px',
    height: '14px',
    marginRight: '5px',
  },
  button: {
    width: 'auto',
    marginRight: '40px',
  },
  textarea: {
    position: 'relative',
    marginBottom: '48px',
  },
  cancellationReasonTextarea: {
    position: 'relative',
    marginTop: '12px',
  },
  symbolsCounter: {
    position: 'absolute',
    right: 0,
    top: 60,
  },
  cancellationReasonOther: {
    marginTop: '8px',
  },
  cancellationNote: {
    marginTop: '24px',
  },
  cancellationReason: {
    position: 'relative',
  },
  cancellationReasonPlaceholder: {
    position: 'absolute',
    zIndex: 1,
    top: '15px',
    left: '24px',
    opacity: 0.5,
  },
};

export default styles;
