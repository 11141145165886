import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  actions: {
    opacity: 0.4,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  hidden: {
    visibility: 'hidden',
  },
};

export default styles;
