import {
  loadSpaces,
  loadSuggestedSpaces,
  loadRentRequestedSpaces,
  loadRentedSpaces,
  loadSpace,
  createSpace,
  reviewSpace,
  resetSpace,
  updateSpace,
  deleteSpace,
  unpublishSpace,
  makeUnbookableSpace,
  makeBookableSpace,
} from 'store/landlord/spacesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Space, { ListingFormSubmitData, ListingUpdateAvailableDateData } from 'types/resources/Space';
import { Meta } from 'types/meta';
import { LoadingParams } from 'utils/loadingParams';
import getFetchStatus from 'utils/fetchStatus';

type UseSpacesType = {
  isLoadSpacesFinished: boolean;
  isLoadRentRequestedSpacesFinished: boolean;
  isLoadRentedSpacesFinished: boolean;
  isLoadSpaceFulfilled: boolean;
  isCreateSpacePending: boolean;
  isReviewSpacePending: boolean;
  isUpdateSpacePending: boolean;
  isDeleteSpacePending: boolean;
  loadSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) => { unwrap: () => void };
  loadSpaces: (params?: LoadingParams) => void;
  loadRentRequestedSpaces: (params: LoadingParams) => void;
  loadRentedSpaces: (params: LoadingParams) => void;
  createSpace: (newSpace: ListingFormSubmitData) => void;
  reviewSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) => void;
  updateSpace: ({
    buildingId,
    spaceId,
    updatedSpace,
  }: {
    buildingId: ID;
    spaceId: ID;
    updatedSpace: ListingFormSubmitData | ListingUpdateAvailableDateData;
  }) => void;
  unpublishSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) => void;
  makeUnbookableSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) => void;
  makeBookableSpace: ({ buildingId, spaceId, availableAt }: { buildingId: ID; spaceId: ID; availableAt: string }) => {
    unwrap: () => void;
  };
  deleteSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) => void;
  resetSpace: () => void;
  space: Space;
  spaces: Space[];
  rentedSpaces: Space[];
  rentRequestedSpaces: Space[];
  meta: Meta;
  suggestedSpaces: Space[];
  loadSuggestedSpaces: (params: LoadingParams) => void;
  isLoadSuggestSpacesFinished: boolean;
};

const useSpaces = (): UseSpacesType => {
  const dispatch = useAppDispatch();
  const {
    space,
    spaces,
    suggestedSpaces,
    rentRequestedSpaces,
    rentedSpaces,
    meta,
    index: { fetchStatus: indexFetchStatus },
    indexSuggestedSpaces: { fetchStatus: indexSuggestedFetchStatus },
    indexRentRequested: { fetchStatus: indexRentRequestedFetchStatus },
    indexRented: { fetchStatus: indexRentedFetchStatus },
    create: { fetchStatus: createFetchStatus },
    review: { fetchStatus: reviewFetchStatus },
    show: { fetchStatus: showFetchStatus },
    update: { fetchStatus: updateFetchStatus },
    delete: { fetchStatus: deleteFetchStatus },
  } = useAppSelector(globalState => globalState.landlordSpaces);

  const { isFinished: isLoadSpacesFinished } = getFetchStatus(indexFetchStatus);
  const { isFinished: isLoadSuggestSpacesFinished } = getFetchStatus(indexSuggestedFetchStatus);
  const { isFinished: isLoadRentRequestedSpacesFinished } = getFetchStatus(indexRentRequestedFetchStatus);
  const { isFinished: isLoadRentedSpacesFinished } = getFetchStatus(indexRentedFetchStatus);
  const { isPending: isCreateSpacePending } = getFetchStatus(createFetchStatus);
  const { isPending: isReviewSpacePending } = getFetchStatus(reviewFetchStatus);
  const { isFulfilled: isLoadSpaceFulfilled } = getFetchStatus(showFetchStatus);
  const { isPending: isUpdateSpacePending } = getFetchStatus(updateFetchStatus);
  const { isPending: isDeleteSpacePending } = getFetchStatus(deleteFetchStatus);

  return {
    isLoadSpacesFinished,
    isLoadRentRequestedSpacesFinished,
    isLoadRentedSpacesFinished,
    isLoadSpaceFulfilled,
    isCreateSpacePending,
    isReviewSpacePending,
    isUpdateSpacePending,
    isDeleteSpacePending,
    loadSpaces: (params: LoadingParams) => dispatch(loadSpaces(params)),
    loadRentRequestedSpaces: (params: LoadingParams) => dispatch(loadRentRequestedSpaces(params)),
    loadRentedSpaces: (params: LoadingParams) => dispatch(loadRentedSpaces(params)),
    loadSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) =>
      dispatch(loadSpace({ buildingId, spaceId })),
    createSpace: (newSpace: ListingFormSubmitData) => dispatch(createSpace(newSpace)),
    reviewSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) =>
      dispatch(reviewSpace({ buildingId, spaceId })),
    updateSpace: ({
      buildingId,
      spaceId,
      updatedSpace,
    }: {
      buildingId: ID;
      spaceId: ID;
      updatedSpace: ListingFormSubmitData | ListingUpdateAvailableDateData;
    }) => dispatch(updateSpace({ buildingId, spaceId, space: updatedSpace })),
    unpublishSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) =>
      dispatch(unpublishSpace({ buildingId, spaceId })),
    makeUnbookableSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) =>
      dispatch(makeUnbookableSpace({ buildingId, spaceId })),
    makeBookableSpace: ({ buildingId, spaceId, availableAt }: { buildingId: ID; spaceId: ID; availableAt: string }) =>
      dispatch(makeBookableSpace({ buildingId, spaceId, availableAt })),
    deleteSpace: ({ buildingId, spaceId }: { buildingId: ID; spaceId: ID }) =>
      dispatch(deleteSpace({ buildingId, spaceId })),
    resetSpace: () => dispatch(resetSpace()),
    space,
    spaces,
    rentRequestedSpaces,
    rentedSpaces,
    meta,
    suggestedSpaces,
    isLoadSuggestSpacesFinished,
    loadSuggestedSpaces: (params: LoadingParams) => dispatch(loadSuggestedSpaces(params)),
  };
};

export default useSpaces;
