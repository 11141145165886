import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: {
      mobile: '100%',
      desktop: '205px',
    },
    height: '60px',
    backgroundColor: 'white.main',
    paddingLeft: '25px',
    borderRight: (theme: Theme) => `1px solid ${theme.palette.gray.lighter}`,
  },
  calendarIconBlock: {
    marginTop: '6px',
  },
  dateOfStart: {
    marginLeft: {
      mobile: '7px',
      desktop: '11px',
    },
    opacity: 1,
  },
  setDate: {
    color: 'black.main',
  },
  mobileDateField: {
    border: (theme: Theme) => `1px solid ${theme.palette.gray.lighter}`,
    marginBottom: '10px',
  },
};

export default styles;
