import AwsS3 from '@uppy/aws-s3';
import XHRUpload from '@uppy/xhr-upload';
import Uppy, { FileProgress } from '@uppy/core';
import { useEffect, useState } from 'react';
import { useUppy } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Upload from 'types/resources/Upload';

import type { UppyOptions, UppyFile, UploadResult } from '@uppy/core';

const uploadUrl = '/api/site/v1/uploads';
const presignUrl = '/api/site/v1/presign';
const isDevelopment = Settings.env === 'development';

export const fileData = (file: UppyFile): Upload => {
  if (isDevelopment) return file.response.body;

  return {
    id: file.meta.key.match(/^cache\/(.+)/)[1],
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  };
};

type UseUploadType = {
  uppy: Uppy;
  files: UppyFile[];
  removeFile: (fileId: string) => void;
  uploadFiles: () => Promise<UploadResult>;
  reset: () => void;
  fileData: (file: UppyFile) => Upload;
};

type Thumbnail = {
  width?: number;
  height?: number;
};

type UseUploadProps = {
  thumbnails?: Thumbnail;
  config: UppyOptions;
  onRemove?: (args?: unknown) => void;
  onError?: (args?: unknown) => void;
  onAdd?: (args?: unknown) => void;
  onFinishUpload?: (file: UppyFile) => void;
  onComplete?: (result: UploadResult) => void;
  onProgress?: (file: UppyFile, progress: FileProgress) => void;
  onRestrictionFailed?: (file: UppyFile, error: { message: string }) => void;
};

// https://github.com/transloadit/uppy/issues/1575
const changeConfigForSingleUpload = (config: UppyOptions) => {
  const shouldReplace =
    config &&
    config.restrictions &&
    config.restrictions.maxNumberOfFiles &&
    config.restrictions.maxNumberOfFiles === 1 &&
    !config.onBeforeFileAdded;

  const onBeforeFileAdded = (current: UppyFile, files: { [key: string]: UppyFile }): boolean => {
    // eslint-disable-next-line no-param-reassign
    Object.keys(files).forEach(key => delete files[key]);
    return true;
  };

  return { ...config, ...(shouldReplace && { onBeforeFileAdded }) };
};

const useUpload = (props: UseUploadProps): UseUploadType => {
  const {
    config = {},
    thumbnails,
    onRemove,
    onError,
    onAdd,
    onFinishUpload,
    onComplete,
    onProgress,
    onRestrictionFailed,
    onThumbnailGenerated,
  } = props;

  const newConfig = changeConfigForSingleUpload(config);
  const initUppy = () => new Uppy(newConfig);
  const [files, setFiles] = useState([]);
  const uppy = useUppy(initUppy);

  const safetyCallback = (callback: (...args: unknown[]) => void, ...args: unknown[]): void =>
    callback && callback(...args);

  const removeFile = (fileId: string) => uppy.removeFile(fileId);
  const reset = () => uppy.reset();

  const handleError = (...args: unknown[]) => safetyCallback(onError, ...args);

  const handleUploaded = (...args: unknown[]) => safetyCallback(onFinishUpload, ...args);

  const handleCompleted = (result: UploadResult) => onComplete && onComplete(result);

  const handleProgress = (...args: unknown[]) => safetyCallback(onProgress, ...args);

  const handleRestrictionFailed = (file: UppyFile, error: { message: string }) =>
    onRestrictionFailed && onRestrictionFailed(file, error);

  const handleAdded = (...args: unknown[]) => {
    setFiles(uppy.getFiles());
    safetyCallback(onAdd, ...args);
  };

  const handleRemoved = (...args: unknown[]) => {
    setFiles(uppy.getFiles());
    safetyCallback(onRemove, ...args);
  };

  const handleAllAdded = () => setFiles(uppy.getFiles());
  const handleThumbnailGenerated = () => {
    setFiles(uppy.getFiles());
    const previews = uppy.getFiles().map(file => ({ id: file.id, preview: file.preview }));
    safetyCallback(onThumbnailGenerated, previews);
  };
  const handleUploadError = () => setFiles(uppy.getFiles());

  const uploadFiles = () => uppy.upload();

  useEffect(() => {
    uppy
      .on('file-removed', handleRemoved)
      .on('error', handleError)
      .on('file-added', handleAdded)
      .on('upload-success', handleUploaded)
      .on('complete', handleCompleted)
      .on('upload-progress', handleProgress)
      .on('files-added', handleAllAdded)
      .on('thumbnail:generated', handleThumbnailGenerated)
      .on('upload-error', handleUploadError)
      .on('restriction-failed', handleRestrictionFailed);

    if (thumbnails)
      uppy.use(ThumbnailGenerator, { thumbnailWidth: thumbnails.width, thumbnailHeight: thumbnails.height });
    if (isDevelopment) {
      uppy.use(XHRUpload, { endpoint: uploadUrl });
    } else {
      uppy.use(AwsS3, { companionUrl: presignUrl });
    }
  }, []);

  return {
    uppy,
    files,
    removeFile,
    uploadFiles,
    reset,
    fileData,
  };
};

export default useUpload;
