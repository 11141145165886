import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  link: {
    color: 'black.main',
    textDecoration: 'underline',
  },
  root: {
    display: 'flex',
  },
  preview: {
    width: '54px',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '4px',
    marginRight: '8px',
    flexShrink: 0,
    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(3)',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  descriptionWithOpacity: {
    opacity: 0.6,
  },
  title: {
    marginBottom: '4px',
  },
  subtitle: {
    opacity: 0.5,
    fontSize: 13,
  },
};

export default styles;
