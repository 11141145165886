import Building from 'types/resources/Building';

export const getAddressAndCity = (addressLine: string): { address: string; city: string } => {
  const [address, city] = addressLine.split(', ');

  return {
    address,
    city,
  };
};

export const isSpaceInBuilding = (building: Building, spaceId: ID): boolean => {
  return building.spaces.map(space => space.id).includes(spaceId);
};
