import { signOut } from 'store/sessionsSlice';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';

import useUsers from './useUsers';

type UseSessionsType = {
  signOut: () => void;
  isSignOutFinished: boolean;
};

const useSessions = (): UseSessionsType => {
  const {
    signout: { fetchStatus },
  } = useAppSelector(state => state.sessions);
  const { isFinished: isSignOutFinished } = getFetchStatus(fetchStatus);
  const dispatch = useAppDispatch();
  const { loadCurrentUser } = useUsers();

  return {
    signOut: async () => {
      await dispatch(signOut());
      loadCurrentUser();
    },
    isSignOutFinished,
  };
};

export default useSessions;
