import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '21px',
  },
  message: {
    wordWrap: 'break-word',
    padding: '12px 20px 16px',
    marginLeft: 0,
    marginRight: {
      mobile: '48px',
      tablet: 'auto',
    },
    border: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
    borderRadius: '4px',
    maxWidth: {
      mobile: '270px',
      tablet: '434px',
    },
    width: '100%',
    position: 'relative',
    backgroundColor: 'white.main',
  },
  myMessage: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  largeMessage: {
    padding: '5px',
    maxWidth: '550px',
  },
  triangle: {
    position: 'absolute',
    left: '-16px',
    bottom: '6px',
    transform: 'rotate(180deg)',
  },
  triangleForMe: {
    right: '-16px',
    bottom: '0px',
    transform: 'none',
    left: 'initial',
  },
  image: {
    height: 30,
    width: 30,
    marginRight: '18px',
    marginBottom: '3px',
  },
  myImage: {
    order: 1,
    marginRight: 0,
    marginLeft: '18px',
  },
};

export default styles;
