/* eslint-disable no-new */
import { FC, useRef } from 'react';
import { FileInput } from '@uppy/react';
import { Box } from '@material-ui/core';
import Uppy from '@uppy/core';

import styles from './styles';

type UploadWrapperProps = {
  render: FC;
  uppy: Uppy;
  handlerKey?: string;
  id?: string;
};

const UploadWrapper: FC<UploadWrapperProps> = (props): JSX.Element => {
  const { render, uppy, handlerKey, id = 'FileInput' } = props;
  const ref = useRef();

  const handleUpload = () => {
    if (!ref.current) return;
    const input = ref.current.container.children[0].children[0];
    input.click();
  };

  return (
    <>
      <Box sx={styles.input}>
        <FileInput uppy={uppy} ref={ref} inputName="upload" id={id} />
      </Box>
      {render({ [handlerKey]: handleUpload })}
    </>
  );
};

export default UploadWrapper;
