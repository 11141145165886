import { useParams, useLocation, useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import { useMemo } from 'react';
import qs from 'qs';
import { camelizeKeys } from 'utils/keysConverter';

type Router = {
  push: (path: string, state?: unknown) => void;
  replace: (path: string, state?: unknown) => void;
  replaceLocation: (path: string) => void;
  pathname: string;
  camelizedQuery: Record<string, string>;
  match: unknown;
  matchPath: unknown;
  location: unknown;
  history: unknown;
  params: unknown;
};

const useRouter = (): Router => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch;

  const replaceLocation = (path: string) => window.location.replace(path);

  return useMemo(() => {
    const camelizedQuery: Params = camelizeKeys({
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      ...params,
    });
    return {
      push: history.push,
      replace: history.replace,
      replaceLocation,
      pathname: location.pathname,
      camelizedQuery,
      match,
      matchPath,
      location,
      history,
      params,
    };
  }, [params, match, location, history]);
};

export default useRouter;
