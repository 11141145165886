import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    maxHeight: '524px',
    overflowY: 'scroll',
  },
  card: {
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px 12px 12px',
    border: '1px solid',
    borderColor: 'navy.secondary',
  },
  cardImage: {
    width: '75px',
    height: '75px',
    flexShrink: 0,
  },
  spaceInfo: {
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  photo: {
    borderRadius: '4px',
    width: '100%',
    height: '100%',
  },
  availableFrom: {
    opacity: 0.5,
  },
  checkbox: {
    marginLeft: 'auto',
  },
};

export default styles;
