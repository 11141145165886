import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';

import styles from './styles';

type ChannelItemTextProps = {
  title: string;
  subtitle: string;
};

const ChannelItemText: FC<ChannelItemTextProps> = props => {
  const { title, subtitle } = props;
  return (
    <Box sx={styles.itemText}>
      <Typography sx={styles.title} variant="subtitle2" component="p">
        {title}
      </Typography>
      <Typography sx={styles.subtitle} variant="body4" component="p">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default ChannelItemText;
