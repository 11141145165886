import {
  NotificationsSliceStateType,
  NotificationsSliceActionsType,
  useNotificationsActions,
} from 'store/notificationsSlice';
import { useAppSelector } from 'hooks/useRedux';

type UseNotiificationType = NotificationsSliceStateType & NotificationsSliceActionsType;

const useNotifications = (): UseNotiificationType => {
  const { isOpened, title, description } = useAppSelector(state => state.notifications);
  const { closeNotification, openNotification } = useNotificationsActions();

  return {
    openNotification,
    closeNotification,
    isOpened,
    title,
    description,
  };
};

export default useNotifications;
