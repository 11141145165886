import { FC } from 'react';
import { Typography } from '@material-ui/core';
import Box from 'components/Box';
import Building from 'types/resources/Building';
import { BuildingPresenter } from 'presenters';

import styles from './styles';

type BuildingStatusBadgeProps = {
  building: Building;
};

const BuildingStatusBadge: FC<BuildingStatusBadgeProps> = props => {
  const { building } = props;
  const style = `state_${building.state}`;
  const statusText = BuildingPresenter.state(building);

  return (
    <Box sx={styles[style]}>
      <Typography variant="bold7">{statusText}</Typography>
    </Box>
  );
};

export default BuildingStatusBadge;
