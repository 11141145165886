import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { FavoriteSpaceResources, FavoriteSpace } from 'types/resources/Space';
import { LoadingParams } from 'utils/loadingParams';

const FavoriteSpacesRepository = {
  index(params: LoadingParams): Promise<FavoriteSpaceResources> {
    const url = apiRoutes.apiSiteV1TrotterFavoriteSpacesPath();
    return Fetcher.get(url, params);
  },
  create(spaceId: ID): Promise<FavoriteSpace> {
    const url = apiRoutes.apiSiteV1TrotterFavoriteSpacesPath();
    return Fetcher.post(url, { spaceId });
  },
  delete(spaceId: ID): Promise<void> {
    const url = apiRoutes.apiSiteV1TrotterFavoriteSpacePath(spaceId);
    return Fetcher.delete(url);
  },
};

export default FavoriteSpacesRepository;
