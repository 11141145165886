import { FC } from 'react';
import { CircularProgress, Backdrop, BackdropProps } from '@material-ui/core';

import styles from './styles';

const Loader: FC<BackdropProps> = (props): JSX.Element => {
  return (
    <Backdrop sx={styles.backdrop} {...props}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
