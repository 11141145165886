import { makeRoutes } from 'utils/routes';

const adminRoutes = makeRoutes(
  {
    rootPath: () => '/',
    buildingsPath: () => '/buildings',
    landlordsPath: () => '/landlords',
    listingsPath: () => '/listings',
    chatPath: () => '/chat',
    trottersPath: () => '/trotters',
    listingPath: (spaceId: ID) => `/listings/${spaceId}`,
    messagesPath: () => '/messages',
    newLandlordPath: () => '/landlords/new',
    editLandlordPath: (id: ID) => `/landlords/${id}/edit`,
    landlordBuildingsPath: (id: ID) => `/landlords/${id}/buildings`,
    landlordListingsPath: (id: ID) => `/landlords/${id}/listings`,
    buildingPath: (id: ID) => `/buildings/${id}`,
  },
  '/admin/',
);

export default adminRoutes;
