import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    marginBottom: {
      desktop: '2px',
    },
    marginTop: {
      tablet: '4px',
    },
  },
  spacesButton: {
    mt: 'auto',
    color: 'brightBlue.main',
    textTransform: 'uppercase',
  },
  icon: {
    transition: 'transform 0.2s linear',
  },
  iconRotated: {
    transform: 'rotate(-180deg)',
  },
};

export default styles;
