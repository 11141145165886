export default {
  Dashboard: {
    title: 'Dashboard',
    listings: 'Listings waiting for approval',
    pricePerMonth: '${{price}} / month', // eslint-disable-line
  },
  Landlords: {
    allLandlords: 'All Landlords',
    addNewLandlord: 'Add new landlord',
    landlords: 'Landlords',
    buildings: 'Buildings',
    listings: 'Listings',
    state: 'Status',
    registered: 'Registered',
    buildingsCount: '{{count}} Building',
    listingsCount: '{{count}} Listing',
    buildingsCount_plural: '{{count}} Buildings',
    listingsCount_plural: '{{count}} Listings',
    stats: 'Status: ',
    all: 'All',
    dashboard: 'Dashboard',
    on: 'On',
    off: 'Off',
  },
  Buildings: {
    allBuildings: 'All Buildings',
    deleteBuildingTitle: 'Do you really want to delete the building?',
    deleteBuildingSubtitle: 'All listings in this building will be deleted',
  },
  NewLandlord: {
    goBack: 'Go back',
    inviteNewLandlord: 'Invite new landlord',
    inviteNewLandlordDesc: 'Edit the landlord’s information and send invitation. New company and user will be created.',
  },
  EditLandlord: {
    goBack: 'Go back',
    inviteNewLandlordDesc: 'Edit the landlord’s information and details',
  },
  LandlordPages: {
    landlord: 'Landlord',
    editLandlord: 'Edit landlord',
    impersonateLandlord: 'Impersonate',
    buildings: 'Buildings',
    listings: 'Listings',
    building: 'Building',
    listingsCount: '{{count}} Listing',
    listingsCount_plural: '{{count}} Listings',
    listing: 'Listing',
    state: 'Status',
    age: 'Age',
    QRCode: 'QR-code',
    view: 'View',
    verification: 'Verification',
    startDate: 'Date of start',
    rentedBy: 'Rented by',
  },
  Listing: {
    declineTitle: 'You are going to decline this listing.',
    declineSubtitle: 'Please explain the reason why.',
    declineReason: 'Decline reason',
    typeText: 'Type text',
    goToTheBuilding: 'Go to the Building',
    submitButton: 'Ok',
    cancelButton: 'Cancel',
    back: 'Back',
    decline: 'Decline',
  },
  Listings: {
    allListings: 'All listings',
    deleteListingTitle: 'Do you really want to delete the listing?',
  },
  Trotters: {
    title: 'Trotters',
    name: 'Name',
    state: 'Status',
    stateAnnotation: 'Specify the user status',
    email: 'Email',
    phone: 'Phone',
    company: 'Company',
    stateFilter: 'Status: ',
    actions: 'Actions',
    edit: 'Edit {{fullName}}',
    save: 'Save user',
  },
};
