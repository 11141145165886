export default {
  Buildings: {
    QRCode: 'QR-code',
    view: 'View',
    yourBuildings: 'Your buildings',
    addNewBuilding: 'Add new building',
    deleteBuildingTitle: 'Do you really want to delete the building?',
    deleteBuildingSubtitle: 'All listings in this building will be deleted',
    noBuildingsText: 'To list a space on TROT, first add a building using the button above',
  },
  Listings: {
    yourListings: 'Your listings',
    addNewListing: 'Add new listing',
    deleteListingTitle: 'Do you really want to delete the listing?',
    deleteListingSubtitle: 'You will not be able to restore the listing without the help of the admin.',
    listingToReviewTitle: 'Do you want to send this listing for TROT approval?',
    seeDetails: 'see details',
    bookingRequest: 'You have received a new booking request.',
    bookingRequestSubtitle: 'To clarify all your questions, you can contact Trotter in the chat.',
    startDate: 'Start Date ',
    endDate: 'End Date ',
    chatWith: 'chat with {{name}}',
    AvailableAtConfirmationDialog: {
      title: 'You are about to change the "available from" date for your property.',
      subtitle:
        "Doing this will make the listing active and will not allow the current Trotter to renew. If you or the current Trotter do not want to renew the current agreement, click Yes to activate your listing and enable others to book it for the date you've selected.",
    },
    unpublishListingTitle: 'Do you want to unpublish this listing?',
    rentalPeriod: 'Rental Period ',
    month: '{{count}} Month',
    month_plural: '{{count}} Months',
    amount: 'Renewal Amount',
    separator: ' - ',
    AvailableAtRemoveConfirmationDialog: {
      title: 'You are going to remove the availability date',
      subtitle: 'Be aware, removing this will cause your listing to be unavailable for booking.',
    },
    ConfirmCancellation: {
      title: 'Confirm Cancellation',
      subtitle:
        'Please confirm you want to cancel the upcoming renewal for {{fullName}}. Upon cancellation, any upcoming payments will be canceled.',
      cancellationReason: 'Cancellation Reason',
      cancellationReasonPlaceholder: 'Select a reason',
      cancellationReasonOtherPlaceholder: 'Add a cancellation reason',
      cancellationReasonValues: {
        noLonger: 'No longer looking to offer this flex space',
        tenantExpanding: 'I have an existing tenant expanding into this space',
        tenantLongTerm: 'Secured a new long term tenant',
        issues: 'Issues with the Trotter',
        complicated: 'Too complicated / difficult to use TROT',
        expensive: 'Too expensive to use TROT',
        other: 'Other (add comment)',
      },
      cancellationNote: 'Cancellation Note',
      cancellationNotePlaceholder: 'Add a personal note for {{firstName}}',
      cancelLicense: 'cancel license',
      cancel: 'cancel',
    },
    SpaceRentCancelFinish: {
      title: 'The license has been cancelled',
      text: 'You’ve cancelled the license for {{firstName}} and we’ve sent them an email to confirm this. However, if you have additional spaces, you can share them using the button below',
      suggestSpaces: 'suggest spaces',
      chat: 'chat with them',
      finish: 'finish',
    },
    SpaceRentCancelSuggestSpaces: {
      title: 'Offer {{firstName}} additional spaces:',
      otherSpaces: 'Offer Trotter other spaces',
      text: 'Select additional vacant spaces below to share them with {{firstName}}.',
      suggestSpaces: 'suggest spaces',
      sorry: 'Sorry you have no additional vacant spaces at this time. Feel free to add an additional listing below',
      upload: 'upload a listing',
      cancel: 'cancel',
    },
    SpaceRentCancelSuggestSpacesFinish: {
      title: 'Great, we’ve shared those spaces with {{firstName}}',
      text: '{{firstName}} will receive an email with the shared flex spaces and if they’d like to book one of the spaces, we’ll let you know.',
      ok: 'Okay, I got it',
    },
  },
  NewListing: {
    createNewListing: 'Create new listing',
    createNewListingSubtitle: 'Select an existing building or create new one, before adding the new listing.',
    selectExisting: 'Select existing building',
    selectExistingSubtitle: 'Add new listing to one of your existing buildings',
    startNewBuilding: 'Start with new building and than add listing details',
    selectBuilding: 'Select building',
    Settings: {
      listingSettings: 'Listing settings',
      listingSettingsDescription: 'Area, price and capacity',
      header: 'Add a Listing',
      subheader: 'Tell us a little about the space.',
      subheaderTips: 'Get tips on how to write a great listing',
      subheaderHere: 'here',
      title: 'Space title',
      titleAnnotation: 'Examples: Entire Second Floor, Third Floor Loft, or Suite 102',
      titlePlaceholder: 'Space name',
      building: 'Building',
      buildingAnnotation: 'Select the building',
      description: 'About the space',
      descriptionAnnotation: 'Add an attractive description',
      descriptionPlaceholder: 'Add a few sentences about the space',
      square: 'Size',
      sqaureAnnotation: 'Enter the square footage of the space',
      squarePlaceholder: '2000',
      price: 'Price per month',
      priceAnnotation: 'Enter the workspace monthly price',
      pricePlaceholder: '2000',
      capacity: 'Estimated capacity',
      capacityAnnotation: 'How many desks can fit in the space?',
      capacityMinPlaceholder: 'Min',
      capacityMaxPlaceholder: 'Max',
      rentableArea: 'Rentable area',
      availableFrom: 'Available from',
      bookingWindow: 'Booking window',
      bookingWindowAnnotation: 'Amount of days after availability date that the space can be rented',
      minimalRentalPeriod: 'Rental Period',
      minimalRentalPeriodAnnotation: 'Specify the minimum possible rental period',
    },
    Gallery: {
      listingGallery: 'Listing gallery',
      listingGalleryDescription: 'Upload and reorder photo and video gallery',
      header: 'Listing gallery',
      subheader: 'Upload high quality images and videos of the listing.',
      subheaderTips: 'Get tips about the best photography to highlight your space',
      subheaderHere: 'here',
      subheaderNote: 'Note that the first photo will be used as the title photo of gallery.',
    },
    Matterport: {
      label: 'Matterport project',
      hint: 'Upload a link to the Matterport project',
      placeholder: 'https://my.matterport.com/show/?m=AoMmWXGS3p3',
      addLink: 'Add a link',
      removeALink: 'Remove a link',
      validation: 'Should be a valid matterport link. Example: https://my.matterport.com/show/?m=AoMmWXGS3p3',
    },
    Amenities: {
      amenities: 'Amenities',
      amenitiesDescription: 'Set up available amenities',
      header: 'Add amenities',
      subheader:
        'Check the boxes next to the amenities available at your listing. If you offer amenities not seen here, you can always edit your listing description to mention them.',
      subheaderTips: 'To learn about the amenities Trotters are looking for most, go',
      subheaderHere: 'here',
    },
    ReviewAndPublish: {
      reviewAndPublish: 'Review and publish',
      reviewAndPublishDescription: 'Review and send for approval',
      header: 'Review and send for approval',
      subheader:
        'Please be sure to read through the details of your listing carefully. We will use this information to verify the listing, but you will have the chance to edit later',
      address: 'Address',
      images: 'Images',
    },
  },
  NewBuilding: {
    createNewBuilding: 'Create new building',
    Details: {
      buildingDetails: 'Building details',
      buildingDetailsDescription: 'Create new building for this listing',
      subheader: 'Let’s start with the building, you want to add new listing to',
      address: 'Address',
      aboutTheLocation: 'About the location',
      aboutTheLocationAnnotation: 'Add an attractive description.',
      aboutTheLocationTips: 'See our tips for creating a listing',
      aboutTheLocationHere: 'here',
      aboutTheLocationPlaceholder: 'Add a few sentences about the location',
      whyChoose: 'Why choose this builiding?',
      whyChooseAnnotation: 'What amenities help this building stand out?',
      saveAndContinue: 'Save and Continue',
      buildingClassTips: 'Unsure of your building class? Get guidance',
      buildingClassHere: 'here',
      buildingTypeTips: 'See more about each type',
      buildingAccessHoursPlaceholder:
        'Let people know when they can get into the building, and if those hours are different on weekends or holidays',
      lobbyAttendantHoursPlaceholder:
        'Let people know when the lobby attendant is on duty, and if those hours are different on weekends and holidays',
      commonAreaBathroomsPlaceholder:
        'List the total number of bathrooms in the common areas. Please specify the number that are handicap accessible',
      legalEntityAddress: 'Legal entity address',
      legalEntityName: 'Legal entity name',
      elevator: 'Elevator',
    },
    Gallery: {
      buildingGallery: 'Building gallery',
      buildingGalleryDescription: 'Upload images and videos of the building',
      subheader: 'Upload high quality images and videos of the building.',
      subheaderTips: 'Get tips about the best photography to highlight your space',
      subheaderHere: 'here',
      subheaderNote: 'Note that the first photo will be used as the title photo of gallery.',
    },
  },
  Settings: {
    companyDetails: 'Company details',
    teamAndContacts: 'Team and contacts',
    legalEntities: 'Legal entities',
    billingDetails: 'Billing details',
    title: 'Billing & bank account',
    subtitle: 'Funds from each booking will be deposited in this account automatically',
  },
  CompanyDetails: {
    menuSubheader: 'Manage your company account',
    accountName: 'Account Name',
    accountAddress: 'Account Address',
    companyLogo: 'Company Logo',
    companyVideo: 'Company Video',
    uploadImage: 'Upload image',
    email: 'Email',
    emailPlaceholder: 'example@mail.com',
    emailAnnotation: 'The admin needs it for communication',
    phone: 'Phone',
    phoneAnnotation: 'The admin needs it for communication',
    website: 'Website',
    websitePlaceholder: 'https://example.com',
    websiteAnnotation: 'The admin needs it for communication',
    description: 'Description',
    descriptionPlaceholder: 'Add a few sentences about the company',
    descriptionAnnotation: 'Add an attractive description',
  },
  EditBuilding: {
    editTheBuilding: 'Edit the building',
  },
  Building: {
    backToAllBuildings: 'Back to all buildings',
    buildingSettings: 'Building settings',
    buildingAddress: 'Building address',
    locationAndNeighborhood: 'Location & neighborhood',
    aboutTheLocation: 'About the location',
    whyIsThisBuilding: 'Why this building?',
    QRCodeOfTheBuilding: 'QR-code of the building',
    viewQRCode: 'View QR-code',
    QRCode: 'QR-code',
    QRCodeRenderingError: 'Error rendering QR code',
    returnToDashboard: 'Return to Dashboard',
  },
  Listing: {
    backToAllListings: 'Back to all listings',
    aboutTheSpace: 'About the space',
    whyThisListing: 'Why this listing?',
    editListingSettings: 'Edit listing settings',
    UserRental: {
      title: 'User who has paid for the rental of the property',
      name: 'Name',
      dateOfStart: 'Date of start',
      amount: 'Amount',
      action: 'Action',
      approveButton: 'Approve',
      declineButton: 'Decline',
      declineAndOfferSpacesButton: 'Decline and offer spaces',
      declineTheRequestAndOfferSpaces: 'Decline the request and offer spaces',
      popup: {
        title: 'You are going to decline this request.',
      },
    },
  },
  TeamAndContacts: {
    title: 'Team and contacts',
    subtitle: 'Invite your team members and grant permissions for platform use',
    addNewMember: 'Add new member',
    areYouSure: 'Are you sure to delete the employee "{{user}}"?',
    Form: {
      inviteNewTeamMember: 'Invite new team member',
      editTeamMember: 'Edit team member',
      permissions: 'Permissions',
      setUpTheAccessLevelForThisUser: 'Set up the access level for this user',
      addUser: 'Add User',
      updateUser: 'Update User',
    },
  },
  LegalEntities: {
    title: 'Legal entities',
    subtitle: 'You can link multiple legal entities to one account in order to manage your entire building portfolio',
    ein: 'ein',
    Form: {
      addTitle: 'Add new legal entity',
      editTitle: 'Edit legal entity',
      fields: {
        companyName: {
          label: 'Company name',
          annotation: 'Enter an official legal entity name',
          placeholder: 'Apple, Inc.',
        },
        address: {
          label: 'Address',
          annotation: 'Enter business address',
          placeholder: '3891 Ranchview Dr. Richardson, California 62639',
        },
        ein: {
          label: 'EIN',
          annotation: 'Enter Employer Identification Number',
          placeholder: '30-1273465',
        },
      },
      buttons: {
        add: 'Add legal entity',
        edit: 'Save and continue',
        cancel: 'Cancel',
      },
    },
    confirmationTitle: 'Do you really want to delete the legal entity?',
  },
  Dashboard: {
    title: 'Dashboard',
    addNewBuilding: 'Add new building',
    newLicenses: 'New licenses waiting for approval',
    nextPaymentUpcoming: 'Next payment upcoming',
    unreadMessages: 'Unread messages',
    noMessagesFound: 'No messages found',
  },
};
