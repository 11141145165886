import { ModalsSliceStateType, ModalsSliceActionsType, useModalsActions } from 'store/modalsSlice';
import { useAppSelector } from 'hooks/useRedux';

type UseModalType = ModalsSliceStateType & ModalsSliceActionsType;

const useModals = (): UseModalType => {
  const { isOpened, type, extra } = useAppSelector(state => state.modals);
  const { closeModal, openModal } = useModalsActions();

  return {
    openModal,
    closeModal,
    isOpened,
    type,
    extra,
  };
};

export default useModals;
