import { ComponentsOverrides } from '@material-ui/core';

import palette from '../palette';

type MuiSwitchType = {
  styleOverrides: ComponentsOverrides['MuiSwitch'];
};

const MuiSwitch: MuiSwitchType = {
  styleOverrides: {
    root: {
      width: 34,
      height: 24,
      backgroundColor: palette.gray.light,
      padding: 0,
      borderRadius: '24px',
    },
    switchBase: {
      padding: '6px 2px 0 4px',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    thumb: {
      width: 10,
      height: 10,
      backgroundColor: palette.white.main,
    },
  },
};

export default MuiSwitch;
