import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  editLink: {
    marginLeft: '10px',
    lineHeight: '14px',
    display: 'inline-block',
    color: 'brightBlue.main',
    '& a': {
      color: 'inherit',
    },
    '& svg': {
      display: 'inline-flex',
      marginRight: '5px',
      width: '14px',
      height: '14px',
      verticalAlign: 'bottom',
    },
  },
};

export default styles;
