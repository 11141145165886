import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Order from 'types/resources/Order';

const OrdersRepository = {
  create({
    spaceId,
    dateOfStart,
    rentalPeriod,
  }: {
    spaceId: ID;
    dateOfStart: string;
    rentalPeriod: number;
  }): Promise<Order> {
    const url = apiRoutes.apiSiteV1TrotterOrdersPath();
    return Fetcher.post(url, {
      spaceId,
      order: {
        projected_activated_at: dateOfStart,
        rental_period: rentalPeriod,
      },
    });
  },
  show(orderId: ID): Promise<Order> {
    const url = apiRoutes.apiSiteV1TrotterOrderPath(orderId);
    return Fetcher.get(url);
  },
};

export default OrdersRepository;
