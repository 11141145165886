import MuiContainer from './MuiContainer';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiFilledInput from './MuiFilledInput';
import MuiButton from './MuiButton';
import MuiTabs from './MuiTabs';
import MuiTab from './MuiTab';
import MuiCssBaseline from './MuiCssBaseline';
import MuiFormHelperText from './MuiFormHelperText';
import MuiPaper from './MuiPaper';
import MuiTableContainer from './MuiTableContainer';
import MuiTableCell from './MuiTableCell';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiPagination from './MuiPagination';
import MuiPaginationItem from './MuiPaginationItem';
import MuiLink from './MuiLink';
import MuiAutocomplete from './MuiAutocomplete';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiCheckbox from './MuiCheckbox';
import MuiSwitch from './MuiSwitch';
import MuiTooltip from './MuiTooltip';

export default {
  components: {
    MuiTab,
    MuiTabs,
    MuiButton,
    MuiContainer,
    MuiOutlinedInput,
    MuiFilledInput,
    MuiCssBaseline,
    MuiFormHelperText,
    MuiPaper,
    MuiTableContainer,
    MuiTableCell,
    MuiTableSortLabel,
    MuiPagination,
    MuiPaginationItem,
    MuiLink,
    MuiAutocomplete,
    MuiFormControlLabel,
    MuiCheckbox,
    MuiSwitch,
    MuiTooltip,
  },
};
