import React, { FC } from 'react';
import { Tabs } from '@material-ui/core';
import Box from 'components/Box';

type TabsContainerProps = {
  tabIndex: number;
  onChange: (event: React.SyntheticEvent, index: number) => void;
};

const TabsContainer: FC<TabsContainerProps> = props => {
  const { tabIndex, onChange, children } = props;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 4 }}>
      <Tabs value={tabIndex} onChange={onChange} aria-label="tabs">
        {children}
      </Tabs>
    </Box>
  );
};

export default TabsContainer;
