import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import Icon from 'components/Icon';

import styles from './styles';

const PropertyVerifiedBadge: FC = (): JSX.Element => {
  const { t } = useTranslation(['resources']);

  return (
    <Tooltip title={t('resources:listing.verifiedDescription')} arrow placement="top" enterTouchDelay={0}>
      <span>
        <Box sx={styles.root}>
          <Icon name="security" sx={styles.verifiedIcon} />
          <Typography variant="subtitle3">{t('resources:listing.verified')}</Typography>
        </Box>
      </span>
    </Tooltip>
  );
};

export default PropertyVerifiedBadge;
