import { FC } from 'react';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { Link as MUILink, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isNil } from 'ramda';
import clsx from 'utils/clsx';

import Box from '../Box';

import styles from './styles';

type PreviewImageProps = {
  imageUrl: string | undefined;
  link: string;
  title: string;
  subtitle?: string;
  withOpacity?: boolean;
};

const TableResourcePreview: FC<PreviewImageProps> = props => {
  const { imageUrl, link, title, subtitle = '', withOpacity = false } = props;

  return (
    <MUILink sx={styles.link} to={link} component={Link}>
      <Box sx={styles.root}>
        <Box sx={styles.preview}>
          {isNil(imageUrl) ? <ImagePlaceholder name="space" /> : <img src={imageUrl} alt={title} />}
        </Box>
        <Box sx={clsx(styles.description, [[styles.descriptionWithOpacity, withOpacity]])}>
          <Typography sx={styles.title} variant="body3">
            {title}
          </Typography>
          {subtitle && (
            <Typography sx={styles.subtitle} variant="body3">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </MUILink>
  );
};

export default TableResourcePreview;
