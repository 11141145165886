import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiFormControlLabelType = {
  defaultProps: ComponentsProps['MuiFormControlLabel'];
  styleOverrides: ComponentsOverrides['MuiFormControlLabel'];
};

const MuiFormControlLabel: MuiFormControlLabelType = {
  styleOverrides: {
    root: {
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: '#959595 !important',
      },
    },
    label: {
      fontFamily: 'Heebo',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '15px',
    },
  },
  defaultProps: {},
};

export default MuiFormControlLabel;
