import {
  loadBuildings,
  createBuilding,
  loadBuilding,
  updateBuilding,
  resetBuilding,
  resetBuildings,
  deleteBuilding,
} from 'store/landlord/buildingsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Building, { BuildingFormSubmitData } from 'types/resources/Building';
import { Meta } from 'types/meta';
import { LoadingParams } from 'utils/loadingParams';
import getFetchStatus from 'utils/fetchStatus';

type UseBuildingsType = {
  isLoadBuildingsFinished: boolean;
  isLoadBuildingFinished: boolean;
  isCreateBuildingPending: boolean;
  isUpdateBuildingPending: boolean;
  resetBuilding: () => void;
  resetBuildings: () => void;
  loadBuildings: (params: LoadingParams) => void;
  loadBuilding: (buildingId: ID) => { unwrap: () => void };
  createBuilding: (newBuilding: BuildingFormSubmitData) => void;
  updateBuilding: (buildingId: ID, building: BuildingFormSubmitData) => void;
  buildings: Building[];
  building: Building;
  meta: Meta;
  showError: unknown;
  deleteBuilding: (buildingId: ID) => void;
};

const useBuildings = (): UseBuildingsType => {
  const dispatch = useAppDispatch();
  const {
    building,
    buildings,
    meta,
    index: { fetchStatus: indexFetchStatus },
    create: { fetchStatus: createFetchStatus },
    show: { fetchStatus: showFetchStatus, error: showError },
    update: { fetchStatus: updateFetchStatus },
  } = useAppSelector(globalState => globalState.landlordBuildings);

  const { isFinished: isLoadBuildingsFinished } = getFetchStatus(indexFetchStatus);
  const { isPending: isCreateBuildingPending } = getFetchStatus(createFetchStatus);
  const { isFinished: isLoadBuildingFinished } = getFetchStatus(showFetchStatus);
  const { isPending: isUpdateBuildingPending } = getFetchStatus(updateFetchStatus);

  return {
    isLoadBuildingsFinished,
    isLoadBuildingFinished,
    isCreateBuildingPending,
    isUpdateBuildingPending,
    loadBuildings: (params: LoadingParams) => dispatch(loadBuildings(params)),
    loadBuilding: (buildingId: ID) => dispatch(loadBuilding(buildingId)),
    createBuilding: (newBuilding: BuildingFormSubmitData) => dispatch(createBuilding(newBuilding)),
    updateBuilding: (buildingId: ID, updatableBuilding: BuildingFormSubmitData) =>
      dispatch(updateBuilding({ buildingId, building: updatableBuilding })),
    resetBuilding: () => dispatch(resetBuilding()),
    resetBuildings: () => dispatch(resetBuildings()),
    buildings,
    building,
    meta,
    showError,
    deleteBuilding: (buildingId: ID) => dispatch(deleteBuilding(buildingId)),
  };
};

export default useBuildings;
