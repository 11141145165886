import { FC } from 'react';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import styles from './styles';

type SearchButtonProps = {
  onSubmit: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const SearchButton: FC<SearchButtonProps> = props => {
  const { onSubmit } = props;
  const { t } = useTranslation(['components', 'common']);

  return (
    <Button size="small" sx={styles.searchButton} onClick={onSubmit} href="#">
      <Typography variant="body2">{t('common:search')}</Typography>
    </Button>
  );
};

export default SearchButton;
