import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

import palette from '../palette';

const styledBy = (property: string, mapping) => props => mapping[props[property]];

type MuiButtonType = {
  defaultProps: ComponentsProps['MuiButton'];
  styleOverrides: ComponentsOverrides['MuiButton'];
};

const MuiButton: MuiButtonType = {
  styleOverrides: {
    root: {
      fontWeight: 500,
      borderRadius: 0,
      boxSizing: 'border-box',
    },
    sizeLarge: {
      padding: [16, 25],
      width: 253,
      height: 50,
      fontSize: 15,
      lineHeight: '18px',
    },
    sizeMedium: {
      padding: [14, 20],
      width: 202,
      height: 44,
      fontSize: 13,
      lineHeight: '15px',
    },
    sizeSmall: {
      padding: [10, 12],
      width: 180,
      height: 34,
      fontSize: 11,
      lineHeight: '13px',
    },
    colorInherit: {
      background: palette.white.main,
    },
    containedPrimary: {
      backgroundColor: palette.brightBlue.main,
      '&$disabled': {
        backgroundColor: palette.black.disabled,
        color: palette.white.main,
      },
    },
    contained: {
      backgroundColor: styledBy('color', {
        navy: palette.navy.main,
      }),
    },

    outlinedPrimary: {
      backgroundColor: palette.white.main,
      color: palette.brightBlue.main,
      border: `2px solid ${palette.brightBlue.main}`,
      '&:hover': {
        border: `2px solid ${palette.brightBlue.main}`,
        backgroundColor: palette.white.main,
        color: palette.brightBlue.main,
      },
    },
  },
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
    color: 'primary',
    size: 'large',
  },
};

export default MuiButton;
