import { SxStyles } from 'types/theme';

const statusBadge = {
  display: 'inline-flex',
  padding: '6.5px 8.5px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'transparent',
  whiteSpace: 'nowrap',
};

const styles: SxStyles = {
  state_approved: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'gray.main',
  },
  state_active: {
    ...statusBadge,
    color: 'white.main',
    backgroundColor: 'navy.main',
  },
};

export default styles;
