import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: '448px',
    height: '110px',
    backgroundColor: 'white.main',
    borderRadius: '4px',
    boxShadow: '0px 4px 30px rgba(7, 13, 65, 0.15)',
    display: 'flex',
  },
  warning: {
    color: 'red.main',
    margin: '26px',
  },
  content: {
    width: '100%',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: 'red.main',
    marginTop: '24px',
  },
  closeButton: {
    alignSelf: 'baseline',
  },
};

export default styles;
