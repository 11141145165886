import i18n from 'locales/i18n';
import { SearchFormData } from 'forms/public/searchForm';
import { isNil } from 'ramda';
import { SpaceState, SpaceStatePresentation } from 'enums/resources/Space';

export const prettyFilter = (name: string, filters: SearchFormData): null | string => {
  const minName = `${name}Min`;
  const maxName = `${name}Max`;
  const min = filters[minName];
  const max = filters[maxName];

  if (!min && !max) {
    return null;
  }

  const label = i18n.t(`common:space.filters.namesToLabels.${name}`);
  const prettyName = i18n.t(`common:space.filters.${name}`);

  if (min && max) {
    return i18n.t('common:space.filters.minMax', {
      min,
      max,
      name: prettyName,
      label,
    });
  }
  if (min) {
    return i18n.t('common:space.filters.onlyMin', {
      min,
      name: prettyName,
      label,
    });
  }
  return i18n.t('common:space.filters.onlyMax', {
    max,
    name: prettyName,
    label,
  });
};

export const prettyElevatorFilter = (elevatorValue: boolean | null): null | string => {
  return isNil(elevatorValue) ? null : i18n.t('common:space.filters.elevator');
};

export const prettyOnlyAvailableFilter = (value: boolean | null): null | string => {
  return isNil(value) ? null : i18n.t('common:space.filters.onlyAvailable');
};

export const adminSpaceStates = Object.keys(SpaceStatePresentation)
  .filter(state => ![SpaceState.archived, SpaceState.disabled].includes(state as SpaceState))
  .map(value => ({
    value,
    label: SpaceStatePresentation[value],
  }));
