/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { FetchStatus } from 'enums/FetchStatus';
import OrdersRepository from 'repositories/trotter/OrdersRepository';
import Order from 'types/resources/Order';

export type SpaceRentsSliceStateType = {
  order: Order;
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const createOrder = createAsyncThunk('trotter/orders/create', OrdersRepository.create);
export const loadOrder = createAsyncThunk('trotter/orders/load', OrdersRepository.show);

const initialState: SpaceRentsSliceStateType = {
  order: {} as Order,
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'trotter/orders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // create
    builder.addCase(createOrder.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createOrder.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createOrder.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });

    // show
    builder.addCase(loadOrder.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadOrder.fulfilled, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.order = payload;
    });
    builder.addCase(loadOrder.rejected, (state, { error }) => {
      state.show.fetchStatus = FetchStatus.failed;
      state.show.error = error;
    });
  },
});

export default slice.reducer;
