import { FC } from 'react';
import { Radio as MuiRadio, RadioProps } from '@material-ui/core';
import Icon from 'components/Icon';

import styles from './styles';

const Radio: FC<RadioProps> = props => {
  return (
    <MuiRadio sx={styles.select} {...props} icon={<Icon name="radio" />} checkedIcon={<Icon name="radioChecked" />} />
  );
};

export default Radio;
