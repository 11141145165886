import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import clsx from 'utils/clsx';
import { useUsers, useScreen } from 'hooks';
import { UsersPresenter } from 'presenters';
import { appRoutes } from 'routes';
import Icon from 'components/Icon';
import Box from 'components/Box';
import { HeaderLayout } from 'enums/HeaderLayout';
import { ChatContext } from 'contexts/ChatContext';

import styles from './styles';

type MessagesBadgeProps = {
  type: HeaderLayout;
};

const MessagesBadge: FC<MessagesBadgeProps> = props => {
  const { type } = props;
  const { currentUser } = useUsers();
  const { lessThanTablet } = useScreen();
  const { isChatReady, unreadCount } = useContext(ChatContext);

  const isHomeLayout = type === HeaderLayout.home;

  const shouldShowMessages = !UsersPresenter.hasEmployeeMessagingNoAccess(currentUser);
  const isAdmin = UsersPresenter.hasAdminAccess(currentUser);
  const isLandlord = UsersPresenter.hasLandlordAccess(currentUser);
  const isEmployee = UsersPresenter.hasEmployeeAccess(currentUser);

  const linkForChat = () => {
    if (isAdmin) {
      return appRoutes.admin.chatPath();
    }
    if (isLandlord || isEmployee) {
      return appRoutes.landlord.chatPath();
    }
    return appRoutes.trotter.chatPath();
  };

  if (!isChatReady) {
    return null;
  }

  return (
    shouldShowMessages && (
      <Badge badgeContent={unreadCount} color="error" sx={clsx({}, [[styles.wrapperMobile, lessThanTablet]])}>
        <Link to={linkForChat()}>
          <Box sx={clsx(styles.messages, [[styles.messagesWhite, isHomeLayout]])}>
            <Icon name="message" />
          </Box>
        </Link>
      </Badge>
    )
  );
};

export default MessagesBadge;
