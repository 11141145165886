import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  select: {
    width: {
      mobile: '100%',
    },
    minWidth: '160px',
    borderRadius: '0 !important',
    border: (theme: Theme) => ({
      mobile: `1px solid ${theme.palette.navy.secondary}`,
      desktop: 'none',
    }),
    height: {
      mobile: 50,
      desktop: 60,
    },
    '& fieldset': {
      display: 'none',
    },
    paddingLeft: '25px',
  },
  iconRetail: {
    width: '30px',
    height: '30px',
  },
  selectWithRetail: {
    paddingLeft: '25px',
    '& svg': {
      marginRight: '2px !important',
    },
    '& .MuiSelect-icon': {
      marginRight: '8px !important',
    },
  },
};

export default styles;
