import SpaceRentsRepository from 'repositories/landlord/SpaceRentsRepository';
import useLoading from 'hooks/useLoading';
import { SpaceRentDeclineParams, SpaceRentCancelParams } from 'types/resources/Space';

type UseSpaceRentsType = {
  isApprovePending: boolean;
  approve: (spaceRentId: ID) => Promise<void>;
  isDeclinePending: boolean;
  decline: (spaceRentId: ID, params: SpaceRentDeclineParams) => Promise<void>;
  isCancelPending: boolean;
  cancel: (spaceRentId: ID, params: SpaceRentCancelParams) => Promise<void>;
};

const useSpaceRents = (): UseSpaceRentsType => {
  const { approve, decline, cancel } = SpaceRentsRepository;

  const { isPending: isApprovePending, funcWithLoading: approveWithLoading } = useLoading(approve);
  const { isPending: isDeclinePending, funcWithLoading: declineWithLoading } = useLoading(decline);
  const { isPending: isCancelPending, funcWithLoading: cancelWithLoading } = useLoading(cancel);

  return {
    isApprovePending,
    approve: approveWithLoading,
    isDeclinePending,
    decline: declineWithLoading,
    isCancelPending,
    cancel: cancelWithLoading,
  };
};

export default useSpaceRents;
