import { FC } from 'react';
import Icon from 'components/Icon';
import { Typography, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './styles';

const GoBackLink: FC = () => {
  const { t } = useTranslation('common');
  const history = useHistory();

  return (
    <ButtonBase onClick={history.goBack} component="a" sx={styles.backLink}>
      <Typography variant="subtitle3" component="p">
        <Icon name="arrow" />
        {t('goBack')}
      </Typography>
    </ButtonBase>
  );
};
export default GoBackLink;
