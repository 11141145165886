import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

const AuthsRepository = {
  create(): Promise<{ authorizationUrl: string }> {
    const url = apiRoutes.apiSiteV1GoogleAuthPath();
    return Fetcher.post(url);
  },
};

export default AuthsRepository;
