import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: {
      mobile: '300px',
      tablet: '564px',
    },
    padding: {
      mobile: '32px 23px 32px',
      tablet: '50px 48px 50px',
    },
    position: 'relative',
    textAlign: 'center',
    alignItems: 'center',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '14px',
  },
  title: {
    marginBottom: '24px',
  },
  socialNetworks: {
    width: '100%',
    display: 'flex',
    paddingBottom: {
      mobile: 0,
      tablet: '16px',
    },
    justifyContent: {
      mobile: 'space-between',
      tablet: 'flex-start',
    },
    marginBottom: '8px',
    flexWrap: {
      mobile: 'wrap',
      tablet: 'nowrap',
    },
  },
  socialNetworkIconWrapper: {
    width: '64px',
    height: '64px',
    backgroundColor: 'gray.athens',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '64px',
      height: '64px',
    },
  },
  socialNetworkWrapper: {
    marginRight: {
      mobile: 0,
      tablet: '16px',
    },
    marginBottom: {
      mobile: '16px',
      tablet: 0,
    },
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },
  socialNetwork: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginTop: '8px',
  },
  copy: {
    width: '100%',
    position: 'relative',
  },
  copyButton: {
    position: 'absolute',
    top: 'calc(50% - 6px)',
    right: '16px',
    color: 'brightBlue.main',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  copyInput: {
    width: '100%',
    pointerEvents: 'none',

    '& .MuiOutlinedInput-root': {
      height: '60px !important',
      '& input': {
        paddingRight: '60px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
};

export default styles;
