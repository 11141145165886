import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { SpaceRent, SpaceRentResources, SpaceRentPayResponse, SpaceRentCreateParams } from 'types/resources/Space';

const SpaceRentsRepository = {
  index(params: QueryParams): Promise<SpaceRentResources> {
    const url = apiRoutes.apiSiteV1TrotterSpaceRentsPath();
    return Fetcher.get(url, params);
  },
  show(spaceRentId: ID): Promise<SpaceRent> {
    const url = apiRoutes.apiSiteV1TrotterSpaceRentPath(spaceRentId);
    return Fetcher.get(url);
  },
  create({ params }: { params: SpaceRentCreateParams }): Promise<SpaceRentPayResponse> {
    const url = apiRoutes.apiSiteV1TrotterSpaceRentsPath();
    return Fetcher.post(url, params);
  },
  failPayment(spaceRentId: ID): Promise<SpaceRent> {
    const url = apiRoutes.failPaymentApiSiteV1TrotterSpaceRentPath(spaceRentId);
    return Fetcher.put(url, {});
  },
  cancelSubscription(spaceRentId: ID): Promise<SpaceRent> {
    const url = apiRoutes.cancelSubscriptionApiSiteV1TrotterSpaceRentPath(spaceRentId);
    return Fetcher.put(url, {});
  },
  cancelPayment(spaceRentId: ID): Promise<SpaceRent> {
    const url = apiRoutes.cancelPaymentApiSiteV1TrotterSpaceRentPath(spaceRentId);
    return Fetcher.put(url, {});
  },
};

export default SpaceRentsRepository;
