import { FC } from 'react';
import Box from 'components/Box';
import clsx from 'utils/clsx';

import styles from './styles';

type ChannelItemCardProps = {
  handleCardClick: () => void;
  isActive: boolean;
};

const ChannelItemCard: FC<ChannelItemCardProps> = props => {
  const { handleCardClick, isActive } = props;

  return (
    <Box onClick={handleCardClick} sx={clsx(styles.item, [[styles.itemActive, isActive]])}>
      {props.children}
    </Box>
  );
};

export default ChannelItemCard;
