import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiAutocompleteType = {
  defaultProps: ComponentsProps['MuiAutocomplete'];
  styleOverrides: ComponentsOverrides['MuiAutocomplete'];
};

const MuiAutocomplete: MuiAutocompleteType = {
  styleOverrides: {
    paper: {
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    },
  },
  defaultProps: {},
};

export default MuiAutocomplete;
