import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: '420px',
    padding: '64px 60px 68px',
    position: 'relative',
    textAlign: 'center',
    alignItems: 'center',
  },
  closeButton: {
    color: 'navy.main',
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.5,
    cursor: 'pointer',
    position: 'absolute',
    top: '19px',
    right: '14px',
  },
  qr: {
    width: '268px',
    marginBottom: '48px',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  hidden: {
    display: 'none',
  },
  title: {
    textAlign: 'center',
    maxWidth: '300px',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  button: {
    width: 'auto',
    svg: {
      fontSize: 'inherit !important',
      marginTop: '-3px',
    },
  },
  error: {
    color: 'red.main',
  },
};

export default styles;
