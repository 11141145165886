import { FC, useContext } from 'react';
import { appRoutes } from 'routes';
import { useUsers, useRouter, useSessions } from 'hooks';
import { UsersPresenter } from 'presenters';
import { MenuItem, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChatContext } from 'contexts/ChatContext';

import styles from './styles';

const AccountMenu: FC = () => {
  const { currentUser } = useUsers();
  const { signOut } = useSessions();
  const { push } = useRouter();
  const { t } = useTranslation(['publicPages']);
  const { chatClient } = useContext(ChatContext);

  const handleLogout = async () => {
    await signOut();
    chatClient?.disconnectUser();
    push(appRoutes.public.rootPath());
  };

  const items = UsersPresenter.menuItems(currentUser);

  return (
    <>
      {items
        .filter(item => item.show)
        .map(item => {
          return (
            <MenuItem to={item.href} component={Link} key={item.label} sx={styles.menuItem}>
              <Typography sx={styles.menuItemText} variant="subtitle7" component="p">
                {item.label}
              </Typography>
              <Divider sx={styles.divider} />
            </MenuItem>
          );
        })}
      <MenuItem onClick={handleLogout} key="logout" sx={styles.menuItem}>
        <Typography sx={styles.menuItemText} variant="subtitle7" component="p">
          {t('publicPages:Profile.navigation.logout')}
        </Typography>
      </MenuItem>
    </>
  );
};

export default AccountMenu;
