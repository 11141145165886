import Box from 'components/Box';
import { ChannelListMessengerProps } from 'stream-chat-react';
import { ChatChannel } from 'types/chat';
import ChannelListItemLandlord from 'components/Chat/components/ChannelListItemLandlord';

import styles from './styles';

const CustomChannelListLandlord = (props: ChannelListMessengerProps): JSX.Element => {
  const { error, loading, LoadingErrorIndicator, LoadingIndicator, loadedChannels } = props;

  if (error) {
    return <LoadingErrorIndicator type="connection" />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={styles.channelList}>
      <ChannelListItemLandlord loadedChannels={loadedChannels as ChatChannel[]} />
    </Box>
  );
};

export default CustomChannelListLandlord;
