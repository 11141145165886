import { FC } from 'react';
import { NewYork, NewJersey, makeSearchPathWithCoordinates } from 'utils/locations';
import { MenuItem, Typography } from '@material-ui/core';
import { externalRoutes, marketingRoutes } from 'routes';
import { useTranslation } from 'react-i18next';
import { getNewBlankAttributes, getPathTypeAttributes } from 'utils/linkUtils';

import styles from './styles';

type PlaceholderType = 'locations' | 'forOwners' | 'forTrotters' | 'aboutTrot' | 'contactUs';

type DropDownMenuListPropsType = {
  placeholder: PlaceholderType;
};

const DropDownMenuList: FC<DropDownMenuListPropsType> = props => {
  const { placeholder } = props;
  const { t } = useTranslation(['publicLayouts']);

  const locationMenu = [
    {
      label: t('publicLayouts:Locations.NY'),
      link: makeSearchPathWithCoordinates(NewYork),
    },
    {
      label: t('publicLayouts:Locations.NJ'),
      link: makeSearchPathWithCoordinates(NewJersey),
    },
  ];

  const forOwnersMenu = [
    {
      label: t('publicLayouts:WhyTrot'),
      href: marketingRoutes.whyTrotForOwnersPath(),
    },
    {
      label: t('publicLayouts:ForOwners.flexSpaceRevolution'),
      href: marketingRoutes.spaceRevolutionPath(),
    },
    // {
    //   label: t('publicLayouts:ExploreResources'),
    //   href: marketingRoutes.exploreResourcesForOwnersPath(),
    // },
    {
      label: t('publicLayouts:HowItWorks'),
      href: marketingRoutes.howItWorksForOwnersPath(),
    },
    {
      label: t('publicLayouts:scheduleDemo'),
      href: marketingRoutes.scheduleDemoPath(),
    },
  ];

  const forTrottersMenu = [
    {
      label: t('publicLayouts:WhyTrot'),
      href: marketingRoutes.whyTrotForTrottersPath(),
    },
    // {
    //   label: t('publicLayouts:ExploreResources'),
    //   href: marketingRoutes.exploreResourcesForTrottersPath(),
    // },
    {
      label: t('publicLayouts:HowItWorks'),
      href: marketingRoutes.howItWorksForTrottersPath(),
    },
  ];

  const aboutTrotMenu = [
    {
      label: t('publicLayouts:AboutTrot.whoWeAre'),
      href: marketingRoutes.rootPath(),
    },
    {
      label: t('publicLayouts:AboutTrot.ourServices'),
      href: marketingRoutes.ourServicesPath(),
    },
    {
      label: t('publicLayouts:AboutTrot.faqS'),
      href: externalRoutes.intercomHelp(),
      isNewTabOpen: true,
    },
    {
      label: t('publicLayouts:AboutTrot.meetTheTeam'),
      href: marketingRoutes.meetTheTeamPath(),
    },
  ];

  const contactUsMenu = [
    {
      label: t('publicLayouts:ContactUs.helpCenter'),
      href: externalRoutes.intercomHelp(),
      isNewTabOpen: true,
    },
    {
      label: t('publicLayouts:ContactUs.contactSupport'),
      href: externalRoutes.intercomHelp(),
      isNewTabOpen: true,
    },
  ];

  const getmenuList = () => {
    switch (placeholder) {
      case 'locations':
        return locationMenu;
      case 'forOwners':
        return forOwnersMenu;
      case 'forTrotters':
        return forTrottersMenu;
      case 'aboutTrot':
        return aboutTrotMenu;
      case 'contactUs':
        return contactUsMenu;
      default:
        return null;
    }
  };

  return (
    <>
      {getmenuList().map(item => (
        <MenuItem
          {...getPathTypeAttributes(item)}
          {...getNewBlankAttributes(item)}
          key={item.label}
          sx={styles.menuItem}
        >
          <Typography sx={styles.menuItemText} variant="subtitle7" component="p">
            {item.label}
          </Typography>
        </MenuItem>
      ))}
    </>
  );
};

export default DropDownMenuList;
