import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  socialLinks: {
    alignItems: 'center',

    a: {
      height: '24px',
      textDecoration: 'none',
      color: 'navy.main',

      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
};

export default styles;
