import { FC } from 'react';
import InfoTable from 'components/InfoTable';
import { BankAccount } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

type BankAccountDetailsProps = {
  bankAccount: BankAccount & {
    bankName: string | null;
    routingNumber: string | null;
    last4: string;
  };
  withBorder?: boolean;
};

const BankAccountDetails: FC<BankAccountDetailsProps> = props => {
  const { t } = useTranslation('publicPages');

  const { bankAccount, withBorder } = props;

  const rows = [
    {
      label: t('ProfileBilling.bankName'),
      description: t('ProfileBilling.bankNameDescription'),
      data: bankAccount.bankName,
    },
    {
      label: t('ProfileBilling.lastDigits'),
      description: t('ProfileBilling.lastDigitsDescription'),
      data: bankAccount.last4,
    },
    {
      label: t('ProfileBilling.routingNumber'),
      description: t('ProfileBilling.routingNumberDescription'),
      data: bankAccount.routingNumber,
    },
  ];

  return <InfoTable withBorder={withBorder} rows={rows} />;
};

export default BankAccountDetails;
