import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  search: {
    marginBottom: {
      desktop: '12px',
    },
    paddingTop: {
      mobile: '24px',
      desktop: '0',
    },
    flexDirection: {
      mobile: 'column',
      desktop: 'row',
    },
    border: (theme: Theme) => ({
      mobile: 'none',
      desktop: `1px solid ${theme.palette.navy.secondary}`,
    }),
    backgroundColor: {
      mobile: 'navy.background',
      desktop: 'common.white',
    },
    '& .search': {
      '& input': {
        padding: '10px 20px 10px 50px !important',
      },
    },
    '& .workers, .office': {
      flexShrink: 0,
      flexGrow: 1,
      flexBasis: '15%',
      '& svg': {
        mr: 1,
      },
    },
    '& .office': {
      flexGrow: 0,
      flexBasis: 0,
    },
    '& input': {
      border: 'none !important',
    },
    '& svg': {
      color: 'brightBlue.main',
    },
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: {
      mobile: 'column',
      desktop: 'row',
    },
    backgroundColor: {
      mobile: 'navy.background',
      desktop: 'common.white',
    },
    alignItems: 'center',
    marginLeft: {
      mobile: 'initial',
      desktop: 'auto',
    },
  },
  drawerPaper: {
    width: '80%',
  },
  drawerInnerWrapper: {
    padding: '16px',
  },
  mainFiltersText: {
    display: {
      mobile: 'block',
      desktop: 'none',
    },
    marginBottom: {
      mobile: '8px',
    },
  },
  dateOfStart: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    visibility: 'hidden',
    right: '14px',
    top: '14px',
    '& svg': {
      color: 'red.main',
    },
  },
  closeButtonShow: {
    visibility: 'visible',
  },
  dateOfStartButton: {
    width: '100%',
    paddingRight: 0,
    marginLeft: 0,
  },
};

export default styles;
