import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageContext, useChatContext } from 'stream-chat-react';
import { getDistanceFromNowInWords } from 'utils/dateTimeUtils';
import MessageBox from 'components/Chat/components/MessageBox';
import MessageHeader from 'components/Chat/components/MessageHeader';
import MessageText from 'components/Chat/components/MessageText';
import MessageWithSpaceInfo from 'components/Chat/components/MessageWithSpaceInfo';
import { ChatChannel } from 'types/chat';

const CustomMessageAdmin: FC = () => {
  const { t } = useTranslation('chatComponents');

  const { message, isMyMessage } = useMessageContext();
  const { channel } = useChatContext();
  const { members } = channel.state;

  const isMe = isMyMessage();

  const userIdFromMessage = message.user.id;
  const isClient = members[userIdFromMessage]?.role === 'owner';
  const isLandlord = members[userIdFromMessage]?.role === 'member';

  const getMessageTitle = () => {
    if (isMe) {
      return t('adminMessageLabel');
    }
    if (isLandlord) {
      return t('landlordMessageLabel');
    }
    if (isClient) {
      return t('clientMessageLabel');
    }
    return '';
  };

  const time = t('timeAgo', { time: getDistanceFromNowInWords(new Date(message.created_at)) });

  if (message.first) {
    const spaceId = (channel as ChatChannel)?.data?.space?.id;
    return <MessageWithSpaceInfo isMyMessage={isMe} spaceId={spaceId} />;
  }

  return (
    <MessageBox isMyMessage={isMe} img={message.user.image} alt={message.user.name} withImage>
      <MessageHeader leftContent={time} rightContent={getMessageTitle()} />
      <MessageText text={message.text} />
    </MessageBox>
  );
};

export default CustomMessageAdmin;
