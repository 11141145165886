import {
  GoogleServiceSliceStateType,
  GoogleServiceSliceActionsType,
  useGoogleServiceActions,
} from 'store/googleServiceSlice';
import { useAppSelector } from 'hooks/useRedux';
import { FetchStatuses } from 'types/fetchStatus';

type UseGoogleServiceType = GoogleServiceSliceStateType &
  GoogleServiceSliceActionsType & {
    ready: boolean;
    google: Google;
    autocomplete: google.maps.places.AutocompleteService;
    geocoder: google.maps.Geocoder;
  };

const useGoogleService = (): UseGoogleServiceType => {
  const { state } = useAppSelector(globalState => globalState.googleService);
  const { initGoogle } = useGoogleServiceActions();
  const { google } = window;

  const ready = state === FetchStatuses.fulfilled;
  const autocomplete = ready && new google.maps.places.AutocompleteService();
  const geocoder = ready && new google.maps.Geocoder();

  return {
    ready,
    state,
    google,
    initGoogle,
    autocomplete,
    geocoder,
  };
};

export default useGoogleService;
