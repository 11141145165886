/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import { FetchStatus } from 'enums/FetchStatus';
import FavoriteSpacesRepository from 'repositories/trotter/FavoriteSpacesRepository';
import { Meta } from 'types/meta';
import Space from 'types/resources/Space';

export type FavoriteSpaceSliceStateType = {
  favoriteSpaces: Space[];
  meta: Meta;
  index: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  create: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
  delete: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadFavoriteSpaces = createAsyncThunk('trotter/favoriteSpaces/load', FavoriteSpacesRepository.index);
export const createFavoriteSpace = createAsyncThunk('trotter/favoriteSpaces/create', FavoriteSpacesRepository.create);
export const deleteFavoriteSpace = createAsyncThunk('trotter/favoriteSpaces/delete', FavoriteSpacesRepository.delete);

const initialState: FavoriteSpaceSliceStateType = {
  favoriteSpaces: [],
  meta: { perPage: 10 } as Meta,
  index: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  create: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
  delete: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'trotter/favoriteSpaces',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // index
    builder.addCase(loadFavoriteSpaces.pending, state => {
      state.index.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadFavoriteSpaces.fulfilled, (state, { payload }) => {
      state.favoriteSpaces = payload.items.map(item => item.space);
      state.index.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadFavoriteSpaces.rejected, (state, { error }) => {
      state.index.fetchStatus = FetchStatus.failed;
      state.index.error = error;
    });

    // create
    builder.addCase(createFavoriteSpace.pending, state => {
      state.create.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(createFavoriteSpace.fulfilled, state => {
      state.create.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(createFavoriteSpace.rejected, (state, { error }) => {
      state.create.fetchStatus = FetchStatus.failed;
      state.create.error = error;
    });

    // delete
    builder.addCase(deleteFavoriteSpace.pending, state => {
      state.delete.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(deleteFavoriteSpace.fulfilled, state => {
      state.delete.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(deleteFavoriteSpace.rejected, (state, { error }) => {
      state.delete.fetchStatus = FetchStatus.failed;
      state.delete.error = error;
    });
  },
});

export default slice.reducer;
