const MILES_TO_METERES = 1609.34;
export const milesToMeters = (miles: number): number => miles * MILES_TO_METERES;

export const boundsToCardinalPoints = (bounds: google.maps.LatLngBounds): google.maps.LatLngBoundsLiteral => {
  return {
    north: bounds.getNorthEast().lat(),
    east: bounds.getNorthEast().lng(),
    south: bounds.getSouthWest().lat(),
    west: bounds.getSouthWest().lng(),
  };
};

export const cardinalPointsToTopLeftAndBottomRight = (
  cardinalPoints: google.maps.LatLngBoundsLiteral,
): GeoBoundingBox => {
  return {
    topLeft: {
      latitude: cardinalPoints.north,
      longitude: cardinalPoints.west,
    },
    bottomRight: {
      latitude: cardinalPoints.south,
      longitude: cardinalPoints.east,
    },
  };
};

export const styledMapType = (google: Google): google.maps.StyledMapType =>
  new google.maps.StyledMapType(
    [
      { elementType: 'geometry', stylers: [{ color: '#e8e8e8' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#839aab' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#e8e8e8' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#818284' }],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#818284' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [{ color: '#a5b076' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#839aab' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#c2d2df' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#9cb3c7' }],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ color: '#c2d2df' }],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#9cb3c7' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#839aab' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#839aab' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#e8e8e8' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#c4d7e6' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#839aab' }],
      },
    ],
    { name: 'Styled Map' },
  );

// https://developers.google.com/maps/documentation/javascript/symbols
export const makeMarkerSymbol = (
  color: string,
): {
  path: string;
  fillColor: string;
  fillOpacity: number;
  strokeWeight: number;
  rotation: number;
  scale: number;
  anchor: google.maps.Point;
} => ({
  path: 'M 15.5,0 A 15.5,15.5 0 0 1 0,15.5 15.5,15.5 0 0 1 -15.5,0 15.5,15.5 0 0 1 0,-15.5 15.5,15.5 0 0 1 15.5,0 Z',
  fillColor: color,
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 1,
  anchor: new google.maps.Point(0, 0),
});
