import {
  loadEmployees,
  createEmployee,
  loadEmployee,
  updateEmployee,
  deleteEmployee,
  resetEmployee,
  resetEmployees,
} from 'store/landlord/employeesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Employee, EmployeeFormSubmitData } from 'types/resources/Employee';
import { Meta } from 'types/meta';
import { LoadingParams } from 'utils/loadingParams';
import getFetchStatus from 'utils/fetchStatus';

type UseEmployeesType = {
  isLoadEmployeesFinished: boolean;
  isLoadEmployeeFinished: boolean;
  isCreateEmployeePending: boolean;
  isUpdateEmployeePending: boolean;
  resetEmployee: () => void;
  resetEmployees: () => void;
  loadEmployees: (params: LoadingParams) => { unwrap: () => void };
  loadEmployee: (employeeId: ID) => { unwrap: () => void };
  createEmployee: (newEmployee: EmployeeFormSubmitData) => { unwrap: () => void };
  updateEmployee: (employeeId: ID, employee: EmployeeFormSubmitData) => { unwrap: () => void };
  deleteEmployee: (employeeId: ID) => { unwrap: () => void };
  employees: Employee[];
  employee: Employee;
  meta: Meta;
  showError: unknown;
};

const useEmployees = (): UseEmployeesType => {
  const dispatch = useAppDispatch();
  const {
    employee,
    employees,
    meta,
    index: { fetchStatus: indexFetchStatus },
    create: { fetchStatus: createFetchStatus },
    show: { fetchStatus: showFetchStatus, error: showError },
    update: { fetchStatus: updateFetchStatus },
  } = useAppSelector(globalState => globalState.landlordEmployees);

  const { isFinished: isLoadEmployeesFinished } = getFetchStatus(indexFetchStatus);
  const { isPending: isCreateEmployeePending } = getFetchStatus(createFetchStatus);
  const { isFinished: isLoadEmployeeFinished } = getFetchStatus(showFetchStatus);
  const { isPending: isUpdateEmployeePending } = getFetchStatus(updateFetchStatus);

  return {
    isLoadEmployeesFinished,
    isLoadEmployeeFinished,
    isCreateEmployeePending,
    isUpdateEmployeePending,
    loadEmployees: (params: LoadingParams) => dispatch(loadEmployees(params)),
    loadEmployee: (employeeId: ID) => dispatch(loadEmployee(employeeId)),
    createEmployee: (newEmployee: EmployeeFormSubmitData) => dispatch(createEmployee(newEmployee)),
    updateEmployee: (employeeId: ID, updatableEmployee: EmployeeFormSubmitData) =>
      dispatch(updateEmployee({ employeeId, employee: updatableEmployee })),
    resetEmployee: () => dispatch(resetEmployee()),
    resetEmployees: () => dispatch(resetEmployees()),
    deleteEmployee: (employeeId: ID) => dispatch(deleteEmployee(employeeId)),
    employees,
    employee,
    meta,
    showError,
  };
};

export default useEmployees;
