import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { SpaceRentDeclineParams, SpaceRentCancelParams } from 'types/resources/Space';

const SpaceRentsRepository = {
  approve(spaceRentId: ID): Promise<void> {
    const url = apiRoutes.approveApiSiteV1LandlordSpaceRentPath(spaceRentId);
    return Fetcher.put(url, {});
  },
  decline(spaceRentId: ID, params: SpaceRentDeclineParams): Promise<void> {
    const url = apiRoutes.declineApiSiteV1LandlordSpaceRentPath(spaceRentId);
    return Fetcher.put(url, { spaceRent: params });
  },
  cancel(spaceRentId: ID, params: SpaceRentCancelParams): Promise<void> {
    const url = apiRoutes.cancelApiSiteV1LandlordSpaceRentPath(spaceRentId);
    return Fetcher.put(url, { spaceRent: params });
  },
};

export default SpaceRentsRepository;
