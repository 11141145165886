import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import numeral from 'numeral';

import en, { ns } from './en';

export const defaultNS = 'common';
export const resources = { en } as const;

const i18next = i18n.createInstance();

i18next.use(initReactI18next).init({
  interpolation: {
    format(value, format) {
      if (format === 'Oo') {
        return numeral(value).format('Oo');
      }
      return value;
    },
  },
  resources: {
    en,
  },
  defaultNS,
  lng: 'en',
  ns,
});

export default i18next;
