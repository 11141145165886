import { SxStyles, Theme } from 'types/theme';

const styles: SxStyles = {
  root: {
    border: (theme: Theme) => `1px solid ${theme.palette.brightBlue.light}`,
    padding: {
      mobile: '24px',
      largeScreen: '60px',
    },
    backgroundColor: 'white.main',
  },
  paymentSchedules: {
    marginTop: '20px',
    border: (theme: Theme) => `1px solid ${theme.palette.brightBlue.light}`,
    padding: {
      mobile: '24px 0px 0px 0px',
      largeScreen: '60px',
    },
    backgroundColor: 'white.main',
  },
  rootNavy: {
    backgroundColor: 'pale.light',
  },
  rootWithoutBorder: {
    border: 'none',
    padding: 0,
  },
  title: {
    marginBottom: '16px',
    letterSpacing: '0.015em',
  },
  divider: {
    marginTop: '25px',
    marginBottom: '25px',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.navy.secondary}`,
  },
  table: {},
  row: {
    display: {
      mobile: 'flex',
      tablet: 'table-row',
    },
    flexDirection: 'column',
  },
  rowLabel: {
    fontWeight: 500,
  },
  rowData: {
    typography: 'body4',
  },
  rowDataBig: {
    typography: 'h3',
  },
  cell: {
    padding: '8px 0',
    border: 'none',
    verticalAlign: 'baseline',
  },
  cellLeft: {
    width: '212px',
    paddingRight: {
      mobile: '16px',
    },
  },
  description: {
    color: 'gray.main',
  },
};

export default styles;
