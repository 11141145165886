import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'baseline',
    position: 'relative',
  },
  adornmentText: {
    marginRight: '8px',
  },
  input: {
    width: 'min-content',
    border: theme => ({
      mobile: `1px solid ${theme.palette.navy.secondary}`,
      desktop: 'none',
    }),
    marginBottom: {
      mobile: '8px',
      desktop: 0,
    },
    '& fieldset': {
      borderRadius: '0 !important',
      border: 'none !important',
    },
  },
  capacityMinInput: {
    minWidth: {
      mobile: '152px',
      desktop: '145px',
    },
    marginRight: '8px',
    borderLeft: theme => ({
      desktop: `1px solid ${theme.palette.navy.secondary}`,
    }),
    '& > div': {
      paddingRight: 0,
    },
  },
  capacityMaxInput: {
    minWidth: {
      mobile: '55px',
      tablet: '80px',
    },
    marginLeft: '8px',
    borderRight: theme => ({
      desktop: `1px solid ${theme.palette.navy.secondary}`,
    }),
    '& > div': {
      paddingLeft: '10px',
      paddingRight: {
        mobile: '10px',
        tablet: '24px',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: 0,
    '& svg': {
      color: 'red.main',
    },
  },
};

export default styles;
