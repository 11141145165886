import qs from 'qs';
import { SearchFormData } from 'forms/public/searchForm';

export const parse = (params: Params): Params => {
  return qs.parse(params);
};

export const stringify = (searchForm: SearchFormData): string => {
  return qs.stringify(searchForm, { skipNulls: true, addQueryPrefix: true });
};
