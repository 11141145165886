import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  image: {
    marginLeft: {
      mobile: '16px',
      desktop: '4px',
    },
    marginRight: {
      mobile: '16px',
      desktop: '4px',
    },
    flexShrink: 0,
    width: '100%',
    maxWidth: {
      mobile: 295,
      desktop: 220,
    },
    height: {
      mobile: '190px',
      desktop: '280px',
      largeScreen: '190px',
    },
    marginBottom: {
      mobile: '16px',
      desktop: '4px',
    },
  },
};

export default styles;
