import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    position: 'absolute',
    bottom: '135px',
    left: '60px',
    width: '80%',
    padding: '15px 30px 15px 23px',
    backgroundColor: 'white.main',
    borderRadius: '4px',
    boxShadow: '0px 4px 30px rgba(7, 13, 65, 0.15)',
    ':after': {
      content: '""',
      position: 'absolute',
      left: '37px',
      bottom: '-25px',
      border: '16px solid transparent',
      borderTop: '16px solid white',
    },
  },
  rootClosed: {
    display: 'none',
  },
  iconBlock: {
    marginRight: '18px',
    marginTop: '3px',
    '& svg': {
      color: 'navy.main',
      width: '32px',
      height: '32px',
    },
  },
  descriptionBlock: {
    color: 'navy.main',
  },
  closeButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    width: '24px',
    height: '24px',
    opacity: 0.2,
  },
  link: {
    textDecoration: 'none',
    color: 'brightBlue.main',
  },
};

export default styles;
