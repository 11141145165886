import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  itemContainer: {
    marginBottom: 5,
  },
  contentTitle: {
    marginBottom: 1,
  },
  amenities: {
    marginTop: '20px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '10px',
    rowGap: '22px',
  },
  amenitiesDecreased: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
};

export default styles;
