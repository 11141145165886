/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'utils/createAsyncThunk';
import Building from 'types/resources/Building';
import Space from 'types/resources/Space';
import SpacesRepository from 'repositories/SpacesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { Meta } from 'types/meta';

export type SpacesSliceStateType = {
  space: Space;
  meta: Meta;
  building: Building;
  show: {
    fetchStatus: FetchStatus;
    error: unknown;
  };
};

export const loadSpace = createAsyncThunk('spaces/loadSpace', SpacesRepository.show);

const initialState: SpacesSliceStateType = {
  space: {} as Space,
  building: {} as Building,
  meta: { perPage: 10 } as Meta,
  show: {
    fetchStatus: FetchStatus.idle,
    error: null,
  },
};

const slice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    setSpaceLiked(state) {
      state.space.isFavorite = true;
    },
    setSpaceNotLiked(state) {
      state.space.isFavorite = false;
    },
    resetSpace(state) {
      state.space = initialState.space;
      state.show = initialState.show;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSpace.pending, state => {
      state.show.fetchStatus = FetchStatus.pending;
    });
    builder.addCase(loadSpace.fulfilled, (state, { payload }) => {
      state.space = payload.space;
      state.building = payload.building;
      state.show.fetchStatus = FetchStatus.fulfilled;
    });
    builder.addCase(loadSpace.rejected, (state, { payload }) => {
      state.show.fetchStatus = FetchStatus.failed;
      state.show.error = payload;
    });
  },
});

export default slice.reducer;

const {
  actions: { setSpaceLiked, setSpaceNotLiked, resetSpace },
} = slice;

export { setSpaceLiked, setSpaceNotLiked, resetSpace };
